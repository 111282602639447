import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'containers/withRouter';
import { consumesDossier } from 'contexts/DossierContext';
import { consumesPeriods } from 'contexts/PeriodsContext';
import { getAccessTokenQueryString } from 'lib/configureAxios.js';

export default function withExport(WrappedComponent) {
  class ExportContainer extends Component {
    constructor(props) {
      super(props);

      this.onExport = this.onExport.bind(this);
      this.onListExport = this.onListExport.bind(this);
      this.onSimpleAccountingExport = this.onSimpleAccountingExport.bind(this);
    }

    onListExport(account, includeTransactions) {
      let query = { account, includeTransactions };
      window.open(`${this._getURLPrefix()}/export-overview?${getAccessTokenQueryString(query)}`);
    }

    onExport() {
      window.open(`${this._getURLPrefix()}/export?${getAccessTokenQueryString()}`);
    }

    onSimpleAccountingExport() {
      window.open(`${this._getURLPrefix()}/simple-accounting?${getAccessTokenQueryString()}`);
    }

    _getURLPrefix() {
      return `${process.env.REACT_APP_API_ENDPOINT}/dossiers/${this.props.dossier.id}/${this.props.selectedPeriod.id}`;
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          onListExport={this.onListExport}
          onExport={this.onExport}
          onSimpleAccountingExport={this.onSimpleAccountingExport}
        />
      );
    }
  }

  ExportContainer.propTypes = {
    dossier: PropTypes.object.isRequired,
    selectedPeriod: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  return withRouter(consumesPeriods(consumesDossier(ExportContainer)));
}
