import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { withRouter } from 'containers/withRouter';
import errorBus from 'lib/errorBus.js';

export default function withSearchResult(WrappedComponent) {
  const SearchResultContainer = class SearchResultContainer extends Component {
    constructor(props) {
      super(props);

      this.state = {
        error: null,
        accountingDocument: null,
        loading: true,
      };
    }

    componentDidMount() {
      this.fetchResults(this.props);
    }

    componentDidUpdate(prevProps) {
      if (JSON.stringify(this.props) === JSON.stringify(prevProps)) {
        return;
      }
      this.fetchResults(this.props);
    }

    fetchResults(props) {
      const searchTerm = props.match.params.searchTerm;

      if (searchTerm) {
        this.setState({ loading: true });
        this.cancelTokenSource = axios.CancelToken.source();

        axios
          .get(`${process.env.REACT_APP_API_ENDPOINT}/search/${searchTerm}`, {
            cancelToken: this.cancelTokenSource.token,
          })
          .then((result) => {
            if (result.data.length) {
              this.setState({ accountingDocument: result.data[0] });
            }
            this.setState({ loading: false });
          })
          .catch((err) => {
            if (err instanceof axios.Cancel) {
              return;
            }
            errorBus.emit('error', err);

            this.setState({
              error: err,
            });
          });
      }
    }

    render() {
      if (this.state.error) {
        return <div>Error</div>;
      }

      if (this.state.loading) {
        return <div>loading...</div>;
      }

      return (
        <WrappedComponent
          {...this.props}
          accountingDocument={this.state.accountingDocument}
          searchTerm={this.props.match.params.searchTerm}
        />
      );
    }
  };
  SearchResultContainer.propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };
  return withRouter(SearchResultContainer);
}
