import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Select from 'react-select';
import { Col, Container, Row } from 'reactstrap';
import { withRouter } from 'containers/withRouter';
import translate from 'containers/translate';
import BankingTransactionList from 'components/bankingTransactionList/BankingTransactionList';
import SidePanelToggle from 'components/sidePanelToggle/SidePanelToggle';
import { consumesDossier } from 'contexts/DossierContext';
import { consumesBankingTransactions } from 'contexts/BankingTransactionsContext';
import { consumesAccounts, providesAccounts } from 'contexts/AccountsContext';
import { consumesPeriods } from 'contexts/PeriodsContext';
import RemoteDataPropType from 'lib/prop-types/RemoteDataPropType';
import './BankingDocumentList.scss';

const ALL_ID = 'ALL';

class BankingDocumentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAccountId: this.getRememberedAccountId(),
      closed: false,
    };

    this.onAccountSelected = this.onAccountSelected.bind(this);
    this.onBankingTransactionListToggle = this.onBankingTransactionListToggle.bind(this);
  }

  onAccountSelected(selectedOption) {
    const id = selectedOption.value;

    this.setState({
      selectedAccountId: id,
    });

    localStorage.setItem(`accountId${this.props.dossier.id}`, JSON.stringify(id));
  }

  getRememberedAccountId() {
    let idFromLocalStorage = null;

    try {
      idFromLocalStorage = JSON.parse(localStorage.getItem(`accountId${this.props.dossier.id}`));
    } catch (ignore) {
      //We just want to avoid crashing with malformed/legacy data in localStorage.
    }

    return idFromLocalStorage || ALL_ID;
  }

  getSelectOptions() {
    const { t } = this.props;

    let options = [
      {
        value: ALL_ID,
        label: t('all'),
      },
    ];

    options.push(
      ...this.props.accounts
        .filter((a) => a.hasDocument)
        .map((account) => {
          return {
            value: account.id,
            label: `${account.name} (${account.currency})`,
          };
        })
    );

    return options;
  }

  getBankingTransactions() {
    if (this.state.selectedAccountId === ALL_ID) {
      return this.props.bankingTransactions;
    }

    return this.props.bankingTransactions.filter((t) => t.bankingDocument.account.id === this.state.selectedAccountId);
  }

  onBankingTransactionListToggle() {
    this.setState((prevState) => {
      return {
        closed: !prevState.closed,
      };
    });
  }

  render() {
    const wrapperClass = classnames('BankingDocumentList', { 'is-closed': this.state.closed });

    if (this.state.closed) {
      return (
        <div className={wrapperClass}>
          <SidePanelToggle closed={this.state.closed} onToggle={this.onBankingTransactionListToggle} />
        </div>
      );
    }

    const locked = this.props.remotePeriods.isReadOnlyPeriod;
    const options = this.getSelectOptions();
    const value = options.find((option) => option.value === this.state.selectedAccountId);

    return (
      <div className={wrapperClass}>
        <Container className="BankingDocumentList__Filter">
          <Row>
            <Col xs={10}>
              <Select
                value={value}
                options={options}
                isClearable={false}
                backspaceRemovesValue={false}
                onChange={this.onAccountSelected}
                className="Select"
              />
            </Col>
          </Row>
        </Container>
        <BankingTransactionList
          fetchBankingTransactions={this.props.remoteBankingTransactions.api.fetch}
          bankingTransactions={this.getBankingTransactions()}
          readOnly={locked}
        />
        <SidePanelToggle closed={this.state.closed} onToggle={this.onBankingTransactionListToggle} />
      </div>
    );
  }
}

BankingDocumentList.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.object).isRequired,
  bankingTransactions: PropTypes.arrayOf(PropTypes.object).isRequired,
  remoteBankingTransactions: PropTypes.object.isRequired,
  remotePeriods: RemoteDataPropType,
  t: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  dossier: PropTypes.object.isRequired,
};

export default consumesDossier(
  providesAccounts(
    consumesBankingTransactions(consumesAccounts(consumesPeriods(withRouter(translate(BankingDocumentList)))))
  )
);
