import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'containers/withRouter';
import moment from 'moment';
import Constants from 'config/Constants';
import DocumentEdit from 'components/documentEdit/DocumentEdit';
import BankingDocumentList from 'components/bankingDocumentList/BankingDocumentList';
import PeriodStateEdit from 'components/periodStateEdit/PeriodStateEdit';
import PeriodEdit from 'components/periodEdit/PeriodEdit';
import PostEditStepContainer from 'components/steps/postEditStep/PostEditStepContainer';
import translate from 'containers/translate';
import { consumesPeriods } from 'contexts/PeriodsContext';
import { providesDocument } from 'contexts/DocumentContext';
import RemoteDataPropType from 'lib/prop-types/RemoteDataPropType';
import { providesDocumentIds } from 'contexts/DocumentIdsContext';
import { providesBankingTransactions } from 'contexts/BankingTransactionsContext';
import './EditStep.scss';

class EditStep extends Component {
  constructor(props) {
    super(props);

    this.onPeriodStarted = this.onPeriodStarted.bind(this);

    this.state = {
      error: null,
    };
  }

  onPeriodStarted() {
    const period = Object.assign({}, this.props.selectedPeriod, { state: Constants.PERIOD_STATE.ACTIVE });
    this.props.remotePeriods.api.update(period);
  }

  renderDateText() {
    const { t } = this.props;
    const startDate = this.formatDate(this.props.selectedPeriod.startDate);
    const endDate = this.formatDate(this.props.selectedPeriod.endDate);
    return `${startDate} ${t('until')} ${endDate}`;
  }

  formatDate(date) {
    return moment(date).format('MM.YYYY');
  }

  renderPeriodStart() {
    const { t } = this.props;

    return (
      <div className={'EditStep periodStart'}>
        <h2>
          {t('period')}: {this.renderDateText()}{' '}
        </h2>
        <span className={'periodInfoText'}>{t('period_start_info')}</span>
        <PeriodEdit />
        <PeriodStateEdit
          buttonColor="primary"
          onStateChange={this.onPeriodStarted}
          buttonLabel={t('start_period')}
          currentState={this.props.selectedPeriod.state}
          targetState={Constants.PERIOD_STATE.ACTIVE}
        />
      </div>
    );
  }

  render() {
    if (this.state.error) {
      return <div>Error</div>;
    }
    if (this.props.selectedPeriod.state === Constants.PERIOD_STATE.OPEN) {
      return this.renderPeriodStart();
    }
    return (
      <div className="EditStep content-with-sidepanel content-with-sidepanel--contain">
        <BankingDocumentList />
        {this.props.match.params.documentId === 'post-edit' ? <PostEditStepContainer /> : <DocumentEdit />}
      </div>
    );
  }
}

EditStep.propTypes = {
  selectedPeriod: PropTypes.object,
  remotePeriods: RemoteDataPropType,
  t: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default translate(
  withRouter(consumesPeriods(providesBankingTransactions(providesDocumentIds(providesDocument(EditStep)))))
);
