import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clone from 'clone';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import Constants from 'config/Constants';
import ListExportSelection from 'components/listExportSelection/ListExportSelection';
import PeriodStateEdit from 'components/periodStateEdit/PeriodStateEdit';
import Tooltip from 'components/tooltip/Tooltip';
import translate from 'containers/translate';
import withExport from 'containers/withExport';
import { consumesDossier } from 'contexts/DossierContext';
import { consumesPeriods } from 'contexts/PeriodsContext';
import { consumesPeriodMetadata } from 'contexts/PeriodMetadataContext';
import RemoteDataPropType from 'lib/prop-types/RemoteDataPropType';
import ClarificationList from 'components/clarificationList/ClarificationList';
import './CSVExport.scss';
import { consumesStartClarification, providesStartClarification } from 'contexts/StartClarificationContext';
import { consumesClarification, providesClarification } from 'contexts/ClarificationContext';
import { consumesPreviewClarification, providesPreviewClarification } from 'contexts/PreviewClarificationContext';

class CSVExport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showListExportSelection: false,
      showClarificationDialog: false,
    };

    this.onListExportSelectionDismiss = this.onListExportSelectionDismiss.bind(this);
    this.onPeriodClarification = this.onPeriodClarification.bind(this);
    this.startCustomerClarification = this.startCustomerClarification.bind(this);
    this.previewClarifications = this.previewClarifications.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.clarifications) !== JSON.stringify(this.props.clarifications)) {
      this.forceUpdate();
    }
  }

  onListExportSelectionDismiss(account, includeTransactions) {
    this.setState({ showListExportSelection: false });
    if (account) {
      this.props.onListExport(account, includeTransactions);
    }
  }

  onPeriodClarification() {
    const period = Object.assign({}, this.props.selectedPeriod, { state: Constants.PERIOD_STATE.CLARIFICATION });
    this.props.remotePeriods.api.update(period);
  }

  startCustomerClarification() {
    const period = clone(this.props.selectedPeriod);
    period.customerClarification = !period.customerClarification;
    this.props.remoteStartClarification.api.create(period, () => this.setState({ showClarificationDialog: false }));
  }

  renderListExportSelection() {
    if (!this.state.showListExportSelection) {
      return null;
    }
    return <ListExportSelection onDismiss={this.onListExportSelectionDismiss} />;
  }

  renderPeriodClarification() {
    const { t } = this.props;

    const currentState = this.props.selectedPeriod.state;

    if (currentState !== Constants.PERIOD_STATE.ACTIVE) {
      return null;
    }

    return (
      <PeriodStateEdit
        buttonColor="primary"
        onStateChange={this.onPeriodClarification}
        buttonLabel={t('clarification_period')}
        currentState={currentState}
        targetState={Constants.PERIOD_STATE.CLARIFICATION}
      />
    );
  }

  renderExportButton() {
    const { t, periodMetadata, selectedPeriod } = this.props;

    let buttonDisabled = false;
    let title = '';

    if (selectedPeriod.state !== Constants.PERIOD_STATE.ARCHIVE) {
      if (!periodMetadata.isEndDateInPast) {
        buttonDisabled = true;
        title = t('unable_to_archive_period_date_in_future');
      } else if (!periodMetadata.hasNoOpenDocuments) {
        buttonDisabled = true;
        title = t('unable_to_archive_period_open_documents');
      } else if (!periodMetadata.hasNoOpenBankingTransactions) {
        buttonDisabled = true;
        title = t('unable_to_archive_period_open_transactions');
      }
    }

    return (
      <Tooltip title={title} placement="right" wrap={buttonDisabled}>
        <Button disabled={buttonDisabled} color="success" onClick={this.props.onExport}>
          {t('export')}
        </Button>
      </Tooltip>
    );
  }

  renderSimpleAccountingButton() {
    const { t, selectedPeriod } = this.props;

    if (selectedPeriod.vatType !== 'none') {
      return;
    }

    return (
      <Button color="primary" onClick={this.props.onSimpleAccountingExport}>
        {t('export_simple_accounting')}
      </Button>
    );
  }

  renderStartClarificationButton() {
    const { t, selectedPeriod, clarifications, clarificationsErrors } = this.props;

    const enabled =
      !this.props.remoteClarification.isLoading && clarifications.length == 0 && clarificationsErrors.length == 0;

    if (selectedPeriod.state !== Constants.PERIOD_STATE.CLARIFICATION) {
      return null;
    }

    const buttonText = t('activate_clarification');

    const title = t('tooltip_activate_clarification');

    const color = 'danger';

    return (
      <Tooltip title={title} placement="right">
        <Button color={color} onClick={this.previewClarifications} disabled={!enabled}>
          {buttonText}
        </Button>
      </Tooltip>
    );
  }

  renderClarificationDialog() {
    const { t, previewClarifications } = this.props;

    if (!this.state.showClarificationDialog) {
      return null;
    }

    return (
      <Modal
        isOpen
        className="ClarificationConfirmModal"
        size="lg"
        toggle={() => this.setState({ showClarificationDialog: false })}
      >
        <ModalHeader toggle={() => this.setState({ showClarificationDialog: false })}>
          {t('clarification_confirm_dialog_title')}
        </ModalHeader>
        <ModalBody>
          <div className={'clarificationConfirmBody'}>
            <p>{t('clarification_confirmation_dialog_text')}</p>
            <div className={'buttonBar'}>{this.renderClarificationConfirmButton()}</div>
            <p>{t('clarification_missing_documents')}</p>
            <ul>
              {previewClarifications?.missingDocuments.map((d, i) => (
                <li key={i}>
                  {d.date} || {d.amount} || {d.description} || {d.trusteeComment}
                </li>
              ))}
            </ul>
            <p>{t('clarification_unclear_documents')}</p>
            <ul>
              {previewClarifications?.unclearDocuments.map((d, i) => (
                <li key={i}>
                  {d.date} || {d.totalAmount} || {d.issuer} || {d.trusteeComment}
                </li>
              ))}
            </ul>
          </div>
        </ModalBody>
      </Modal>
    );
  }

  previewClarifications() {
    const period = clone(this.props.selectedPeriod);
    this.props.remotePreviewClarification.api.create(period, () => this.setState({ showClarificationDialog: true }));
  }

  renderClarificationConfirmButton() {
    const { t } = this.props;

    return (
      <Button color="danger" onClick={this.startCustomerClarification}>
        {t('confirm_start_customer_clarification')}
      </Button>
    );
  }

  renderClarificationErrors() {
    const { startClarification, clarificationsErrors } = this.props;

    if (startClarification && startClarification.errors.length > 0) {
      return <p className="Error">{startClarification.errors}</p>;
    }

    if (clarificationsErrors && clarificationsErrors.length > 0) {
      return <p className="Error">{clarificationsErrors}</p>;
    }
  }

  render() {
    const { t } = this.props;

    return (
      <div className={'CSVExport'}>
        {this.renderListExportSelection()}
        {this.renderPeriodClarification()}
        <Button color="primary" onClick={() => this.setState({ showListExportSelection: true })}>
          {t('export_customer_information')}
        </Button>
        {this.renderSimpleAccountingButton()}
        {this.renderStartClarificationButton()}
        <ClarificationList />
        {this.renderExportButton()}
        {this.renderClarificationErrors()}
        {this.renderClarificationDialog()}
      </div>
    );
  }
}
CSVExport.propTypes = {
  dossier: PropTypes.object.isRequired,
  selectedPeriod: PropTypes.object.isRequired,
  startClarification: PropTypes.object,
  previewClarifications: PropTypes.array,
  clarifications: PropTypes.array,
  clarificationsErrors: PropTypes.array,
  remotePeriods: RemoteDataPropType,
  remoteStartClarification: RemoteDataPropType,
  remotePreviewClarification: RemoteDataPropType,
  remoteClarification: RemoteDataPropType,
  periodMetadata: PropTypes.object.isRequired,
  onListExport: PropTypes.func.isRequired,
  onSimpleAccountingExport: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default consumesPeriods(
  consumesPeriodMetadata(
    providesClarification(
      consumesClarification(
        providesPreviewClarification(
          consumesPreviewClarification(
            providesStartClarification(consumesStartClarification(consumesDossier(withExport(translate(CSVExport)))))
          )
        )
      )
    )
  )
);
