import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import NewWindow from 'react-new-window';
import { Alert, Button } from 'reactstrap';
import { FaChevronRight, FaChevronLeft, FaExpand } from 'react-icons/fa';
import { withRouter } from 'containers/withRouter';
import Constants from 'config/Constants';
import absoluteURL from 'lib/absoluteURL';
import translate from 'containers/translate';
import ResponsiveImage from 'components/responsiveImage/ResponsiveImage';
import { getAccessTokenQueryString } from 'lib/configureAxios.js';
import './ImageContainer.scss';

const MIN_ZOOM = 0.25;
const MAX_ZOOM = 3;
const ZOOM_FACTOR = -0.001;
const NUMPAD_ZERO_KEY_CODE = 'Numpad0';
const LINE_HEIGHT = 17;
const DOM_DELTA_PIXEL = 0;
const DOM_DELTA_LINE = 1;
const DOM_DELTA_PAGE = 2;

function getImageSrc(id, page, small = false) {
  let query = {};

  if (small) {
    query.size = 'small';
  }

  let src = `${process.env.REACT_APP_API_ENDPOINT}/images/${id}/${page}?${getAccessTokenQueryString(query)}`;

  //Make sure the URL is always absolute to make it work inside the popup.
  return absoluteURL(src);
}

class InlineImageContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      currentPage: 1,
    };

    this.nextPage = this.nextPage.bind(this);
    this.prevPage = this.prevPage.bind(this);
    this.handleImageLoad = this.handleImageLoad.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.imageFile.id !== prevProps.imageFile.id) {
      this.setState({
        loading: true,
        currentPage: 1,
      });
    }
  }

  renderArrowLeft() {
    if (this.props.imageFile.numPages > 1 && this.state.currentPage > 1) {
      return (
        <Button className={'arrow-left'} onClick={this.prevPage}>
          <FaChevronLeft />
        </Button>
      );
    }
  }

  renderArrowRight() {
    if (this.props.imageFile.numPages > 1 && this.state.currentPage < this.props.imageFile.numPages) {
      return (
        <Button className={'arrow-right'} onClick={this.nextPage}>
          <FaChevronRight />
        </Button>
      );
    }
  }

  nextPage() {
    this.setState((prevState) => ({
      currentPage: prevState.currentPage + 1,
      loading: true,
    }));
  }

  prevPage() {
    this.setState((prevState) => ({
      currentPage: prevState.currentPage - 1,
      loading: true,
    }));
  }

  handleImageLoad() {
    this.setState({
      loading: false,
    });
  }

  renderImage() {
    return (
      <ResponsiveImage
        alt="Beleg"
        smallSrc={getImageSrc(this.props.imageFile.id, this.state.currentPage, true)}
        largeSrc={getImageSrc(this.props.imageFile.id, this.state.currentPage)}
        onError={this.props.onImageError}
        onLoad={this.handleImageLoad}
      />
    );
  }

  render() {
    return (
      <div
        className={classNames('ImageContainer', {
          inFlexbox: this.props.inFlexbox,
        })}
      >
        <div className={classNames('ImageContainerContainer', { loadingOverlay: this.state.loading })}>
          {this.renderImage()}
          <div className="loader" />
        </div>
        <Button className={'expand'} onClick={this.props.onToggleDetach}>
          <FaExpand />
        </Button>
        {this.renderArrowLeft()}
        {this.renderArrowRight()}
      </div>
    );
  }
}

InlineImageContainer.propTypes = {
  t: PropTypes.func.isRequired,
  imageFile: PropTypes.object.isRequired,
  inFlexbox: PropTypes.bool.isRequired,
  onImageError: PropTypes.func.isRequired,
  onToggleDetach: PropTypes.func.isRequired,
};

class DetachedImageContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      zoom: 1,
      panX: 0,
      panY: 0,
    };

    this.windowRef = React.createRef();

    this.handleImageLoad = this.handleImageLoad.bind(this);
    this.handleWheel = this.handleWheel.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
  }

  componentDidMount() {
    this.windowRef.current.window.addEventListener('keyup', this.handleKeyUp);
  }

  componentWillUnmount() {
    this.windowRef.current.window.removeEventListener('keyup', this.handleKeyUp);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.imageFile.id !== prevProps.imageFile.id) {
      this.setState({
        loading: true,
      });
    }

    if (this.state.panX !== prevState.panX || this.state.panY !== prevState.panY) {
      this.windowRef.current.window.scroll(this.state.panX, this.state.panY);
    }
  }

  handleImageLoad() {
    //TODO: this will remove the spinner even if just 1 out of 7 images is loaded.
    this.setState({
      loading: false,
    });
  }

  handleWheel(e) {
    if (!e.ctrlKey) {
      return;
    }

    e.preventDefault();

    let body = this.windowRef.current.window.document.body;
    let deltaY;

    switch (e.deltaMode) {
      case DOM_DELTA_LINE:
        deltaY = e.deltaY * LINE_HEIGHT;
        break;
      case DOM_DELTA_PAGE:
        deltaY = e.deltaY * body.offsetHeight;
        break;
      case DOM_DELTA_PIXEL:
      default:
        deltaY = e.deltaY;
        break;
    }

    let zoomDelta = deltaY * ZOOM_FACTOR;
    let pixelJumpX = Math.round((zoomDelta * (body.scrollLeft + e.clientX)) / this.state.zoom);
    let pixelJumpY = Math.round((zoomDelta * (body.scrollTop + e.clientY)) / this.state.zoom);

    this.setState((prevState) => {
      let zoom = Math.max(Math.min(prevState.zoom + zoomDelta, MAX_ZOOM), MIN_ZOOM);

      return {
        zoom,
        panX: body.scrollLeft + pixelJumpX,
        panY: body.scrollTop + pixelJumpY,
      };
    });
  }

  handleKeyUp(e) {
    if (e.ctrlKey && e.code === NUMPAD_ZERO_KEY_CODE) {
      this.setState({
        zoom: 1,
      });
    }
  }

  renderImages() {
    const images = [];

    for (let i = 0; i < this.props.imageFile.numPages; i++) {
      images.push(
        <ResponsiveImage
          key={i}
          alt="Beleg"
          width={Constants.SMALL_IMAGE_WIDTH}
          zoom={this.state.zoom}
          smallSrc={getImageSrc(this.props.imageFile.id, i + 1, true)}
          largeSrc={getImageSrc(this.props.imageFile.id, i + 1)}
          onError={this.props.onImageError}
          onLoad={this.handleImageLoad}
        />
      );
    }

    return images;
  }

  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        <div className="ImageContainer NoImage">
          <p>{t('image_is_displayed_in_separate_window')}</p>
          <Button onClick={this.props.onToggleDetach}>{t('display_here')}</Button>
        </div>
        <NewWindow
          ref={this.windowRef}
          name="lea-image"
          title="LEA"
          onUnload={this.props.onPopupUnload}
          onBlock={this.props.onPopupBlock}
        >
          <div className="ImageContainer">
            <div className={classNames('ImageContainerContainer', { loadingOverlay: this.state.loading })}>
              <div
                className="ImageContainerImageList"
                onWheel={this.handleWheel}
                style={{ transform: `scale(${this.state.zoom})` }}
              >
                {this.renderImages()}
              </div>
              <div className="loader" />
            </div>
          </div>
        </NewWindow>
      </React.Fragment>
    );
  }
}

DetachedImageContainer.propTypes = {
  t: PropTypes.func.isRequired,
  imageFile: PropTypes.object.isRequired,
  onToggleDetach: PropTypes.func.isRequired,
  onPopupUnload: PropTypes.func.isRequired,
  onPopupBlock: PropTypes.func.isRequired,
  onImageError: PropTypes.func,
};

class ImageContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      detached: false,
      imageError: false,
    };

    this.toggleDetach = this.toggleDetach.bind(this);
    this.handlePopupUnload = this.handlePopupUnload.bind(this);
    this.handlePopupBlock = this.handlePopupBlock.bind(this);
    this.handleImageError = this.handleImageError.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.imageFile.id !== prevProps.imageFile.id) {
      this.setState({
        imageError: false,
      });
    }
  }

  toggleDetach() {
    this.setState((prevState) => {
      return {
        detached: !prevState.detached,
      };
    });
  }

  handlePopupUnload() {
    this.setState({
      detached: false,
    });
  }

  handlePopupBlock() {
    this.setState({
      detached: false,
    });
  }

  handleImageError() {
    this.setState({ imageError: true });
  }

  render() {
    if (this.state.imageError) {
      return (
        <div className="ImageContainer NoImage">
          <Alert color={'info'}>Kein Bild vorhanden</Alert>
        </div>
      );
    }

    if (this.state.detached) {
      return (
        <DetachedImageContainer
          {...this.props}
          onImageError={this.handleImageError}
          onToggleDetach={this.toggleDetach}
          onPopupBlock={this.handlePopupBlock}
          onPopupUnload={this.handlePopupUnload}
        />
      );
    } else {
      return (
        <InlineImageContainer {...this.props} onImageError={this.handleImageError} onToggleDetach={this.toggleDetach} />
      );
    }
  }
}

ImageContainer.propTypes = {
  t: PropTypes.func.isRequired,
  imageFile: PropTypes.object.isRequired,
  inFlexbox: PropTypes.bool.isRequired,
};

export default withRouter(translate(ImageContainer));
