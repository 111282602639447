import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'containers/withRouter';
import classnames from 'classnames';
import BankingTransactionEdit from 'components/bankingTransactionEdit/BankingTransactionEdit';
import BankingTransactionListSpacer from 'components/bankingTransactionListSpacer/BankingTransactionListSpacer';
import translate from 'containers/translate';
import { consumesBankingDocuments } from 'contexts/BankingDocumentsContext';
import { consumesBankingTransaction, providesBankingTransaction } from 'contexts/BankingTransactionContext';
import RemoteDataPropType from 'lib/prop-types/RemoteDataPropType';
import isInPeriod from 'lib/isInPeriod';
import { AutoSizer, List } from 'react-virtualized';

class Transactions extends PureComponent {
  constructor(props) {
    super(props);

    this.handleBankingTransactionDelete = this.handleBankingTransactionDelete.bind(this);
    this.renderTransaction = this.renderTransaction.bind(this);
    this.getRowHeight = this.getRowHeight.bind(this);
  }

  render() {
    const { t, loading } = this.props;

    this.sortedTransactions = this._getSortedTransactions();
    const oldBalances = this.balances;
    this.balances = this._getBalances(this.sortedTransactions);
    const didBalancesChange = this._didBalancesChange(oldBalances, this.balances);

    return (
      <div className={classnames('Transactions', { loading })}>
        <div className="loader" />
        <h2>{t('transactions')}</h2>
        <div className="">
          {this.sortedTransactions.length === 0 && (
            <BankingTransactionListSpacer
              position={0}
              bankingDocumentId={this.props.bankingDocument.id}
              disabled={this.props.readOnly}
              onBankingTransactionMove={this.props.onBankingTransactionMove}
              onCreateBankingTransaction={this.props.remoteBankingTransaction.api.create}
            />
          )}
          <AutoSizer>
            {({ width }) => (
              <List
                className={''}
                height={750 /* Duplicated in BankingDocumentEdit.scss for padding-bottom */}
                rowCount={this.sortedTransactions.length}
                rowHeight={this.getRowHeight}
                rowRenderer={this.renderTransaction}
                width={width}
                ref={(list) => (this.transactionList = list)}
                bankingDocumentId={this.props.bankingDocument.id}
                edited={this.props.bankingDocument.edited}
                didBalancesChange={didBalancesChange}
              />
            )}
          </AutoSizer>
        </div>
      </div>
    );
  }

  getRowHeight(indexObj) {
    const { index } = indexObj;
    const transaction = this.sortedTransactions[index];
    let childrenCount = transaction.bankingTransactions ? transaction.bankingTransactions.length : 0;
    childrenCount = childrenCount == 0 ? 1 : childrenCount;
    return !index ? 170 + 52 * childrenCount : 100 + 52 * childrenCount;
  }

  _getSortedTransactions() {
    return this.props.bankingDocument.bankingTransactions.slice().sort((a, b) => a.position - b.position);
  }

  _getBalances(transactions) {
    const balances = new Map();
    let currentBalance = this.props.startingBalance;

    for (let i = 0; i < transactions.length; i++) {
      const currentTransaction = transactions[this.props.summationInverted ? transactions.length - i - 1 : i];
      const inPeriod = isInPeriod(currentTransaction.date, this.props.bankingDocument.period);

      if (inPeriod) {
        if (currentTransaction.type === 'credit') {
          currentBalance = currentBalance + currentTransaction.amount;
        } else if (currentTransaction.type === 'debit') {
          currentBalance = currentBalance - currentTransaction.amount;
        }

        balances.set(currentTransaction, currentBalance);
      } else {
        balances.set(currentTransaction, null);
      }
    }

    return balances;
  }

  _didBalancesChange(oldBalances, newBalances) {
    if (oldBalances === newBalances) {
      return false;
    }
    if (!oldBalances || !newBalances) {
      return true;
    }
    const lastOldBalance = this._getTotalFromBalances(oldBalances);
    const lastNewBalance = this._getTotalFromBalances(newBalances);

    return lastOldBalance === lastNewBalance;
  }

  _getTotalFromBalances(balances) {
    const balanceArray = Array.from(balances).filter((b) => !!b[1]);
    return balanceArray.length > 0 && balanceArray[balanceArray.length - 1][1];
  }

  handleBankingTransactionDelete(transaction) {
    const { t } = this.props;

    this.props.remoteBankingTransaction.api.delete(transaction, this.props.remoteBankingDocuments.api.fetch, (err) => {
      if (err && err.response && err.response.status === 409) {
        this.props.onError(t('transaction_is_assigned'));
      }
    });
  }

  renderTransaction({ index, key, style }) {
    const transaction = this.sortedTransactions[index];
    const balance = this.balances.get(transaction);

    return (
      <Fragment key={key}>
        {!index && (
          <BankingTransactionListSpacer
            position={0}
            bankingDocumentId={this.props.bankingDocument.id}
            disabled={this.props.readOnly}
            onBankingTransactionMove={this.props.onBankingTransactionMove}
            onCreateBankingTransaction={this.props.remoteBankingTransaction.api.create}
            style={{ marginBottom: '1rem' }}
          />
        )}
        <div style={{ ...style, top: !index ? style.top + 70 : style.top }}>
          <BankingTransactionEdit
            currency={this.props.currency}
            bankingDocument={this.props.bankingDocument}
            bankingTransaction={transaction}
            balance={balance}
            readOnly={this.props.readOnly}
            onBankingTransactionDelete={this.handleBankingTransactionDelete}
            onBankingTransactionChange={this.props.remoteBankingTransaction.api.update}
          />
          <BankingTransactionListSpacer
            position={transaction.position}
            bankingDocumentId={this.props.bankingDocument.id}
            disabled={this.props.readOnly}
            newDate={transaction.date}
            onBankingTransactionMove={(id, position) => {
              this.props.onBankingTransactionMove(id, position, () => {
                this.transactionList.forceUpdateGrid();
              });
            }}
            onCreateBankingTransaction={this.props.remoteBankingTransaction.api.create}
          />
        </div>
      </Fragment>
    );
  }
}

Transactions.propTypes = {
  bankingDocument: PropTypes.object.isRequired,
  remoteBankingDocuments: RemoteDataPropType,
  remoteBankingTransaction: RemoteDataPropType,
  onBankingTransactionMove: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
  summationInverted: PropTypes.bool.isRequired,
  startingBalance: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

export default providesBankingTransaction(
  consumesBankingTransaction(consumesBankingDocuments(withRouter(translate(Transactions))))
);
