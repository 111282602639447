import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import deepEqual from 'deep-equal';
import classnames from 'classnames';
import { Col, Container, FormGroup, Input, Label, Row } from 'reactstrap';
import { FaTrash } from 'react-icons/fa';
import { MdDeleteSweep } from 'react-icons/md';
import formatAmount from 'lib/formatAmount.js';
import isInPeriod from 'lib/isInPeriod.js';
import translate from 'containers/translate';
import CurrencyInput from 'components/currencyInput/CurrencyInput';
import DateInput from 'components/dateInput/DateInput';
import Tooltip from 'components/tooltip/Tooltip';
import { withRouter } from 'containers/withRouter';
import './BankingTransactionEdit.scss';

class BankingTransactionEdit extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      dirtyTransaction: this.props.bankingTransaction,
      focused: false,
      errorMessage: '',
    };

    this.commitChanges = this.commitChanges.bind(this);
    this.onDragStart = this.onDragStart.bind(this);
    this.onContainerBlur = this.onContainerBlur.bind(this);
    this.handleBankingTransactionDelete = this.handleBankingTransactionDelete.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (!deepEqual(this.props.bankingTransaction, prevProps.bankingTransaction)) {
      this.setState({
        dirtyTransaction: this.props.bankingTransaction,
        errorMessage: '',
      });
    }
  }

  componentWillUnmount() {
    // Unmounting will happen regularly due to virtual scrolling.
    // When scrolling a transaction out of view, make sure to commit all changes.
    this.commitChanges();
  }

  handleChange(key, value, cb) {
    this.setState(
      (prevState) => {
        return {
          dirtyTransaction: Object.assign({}, prevState.dirtyTransaction, { [key]: value }),
        };
      },
      () => cb && cb()
    );
  }

  handleBankingTransactionDelete() {
    this.props.onBankingTransactionDelete(this.props.bankingTransaction);
  }

  commitChanges() {
    if (!deepEqual(this.props.bankingTransaction, this.state.dirtyTransaction)) {
      this.props.onBankingTransactionChange(this.state.dirtyTransaction);
    }
  }

  onDragStart(e) {
    e.dataTransfer.setData('text/plain', this.state.dirtyTransaction.id);
  }

  onContainerBlur(e) {
    const { currentTarget } = e;

    requestAnimationFrame(() => {
      // We don't want to commit changes every single time an input changes.
      // Only when the focus leaves the container (the transaction).
      const childIsFocused = currentTarget.contains(document.activeElement);

      if (!childIsFocused) {
        this.commitChanges();
      }
    });
  }

  renderDeleteButton(locked) {
    if (locked || this.hasChildren()) {
      return;
    }

    return (
      <button className="btn btn-light" type="button" onClick={this.handleBankingTransactionDelete}>
        <FaTrash className="delete" />
      </button>
    );
  }

  renderChildDeleteButton(locked) {
    const { t } = this.props;

    const isChildAssigned = this.state.dirtyTransaction.bankingTransactions.some((t) => t.accountingDocumentId);

    if (locked || !this.hasChildren() || isChildAssigned) {
      return;
    }

    return (
      <Tooltip title={t('delete_children')}>
        <button className="btn btn-light" type="button" onClick={this.handleBankingTransactionDelete}>
          <MdDeleteSweep className="delete" />
        </button>
      </Tooltip>
    );
  }

  renderAccountingDocumentLink(transaction) {
    const { t } = this.props;
    const { accountingDocumentId } = transaction;

    if (!accountingDocumentId) {
      return;
    }

    const url = this.props.history.resolveDossierURL(`/edit/${accountingDocumentId}`);

    return (
      <a href={url} className="btn btn-link">
        {t('document')}
      </a>
    );
  }

  renderBalance() {
    if (this.props.balance === null) {
      return;
    }

    const { t } = this.props;
    const formattedBalance = formatAmount(this.props.balance / 100);

    return (
      <span className="continuousBalance">
        {t('balance')}: {this.props.currency} {formattedBalance}
      </span>
    );
  }

  renderChildTransactions() {
    if (!this.hasChildren()) {
      return <div />;
    }

    return this.state.dirtyTransaction.bankingTransactions.map((transaction) => {
      return (
        <Row key={transaction.id} className={'childRow'}>
          <Col xs="auto">
            <CurrencyInput
              onInputChange={() => {}}
              value={transaction.amount}
              unit={this.props.currency}
              disabled={true}
            />
          </Col>
          <Col xs="" className="smallCol">
            {this.renderAccountingDocumentLink(transaction)}
          </Col>
        </Row>
      );
    });
  }

  hasChildren() {
    return (
      !this.state.dirtyTransaction.bankingTransactions || this.state.dirtyTransaction.bankingTransactions.length !== 0
    );
  }

  render() {
    const { t } = this.props;

    let lockedNoChild = this.props.readOnly || !!this.state.dirtyTransaction.accountingDocumentId;
    lockedNoChild = lockedNoChild || this.props.bankingDocument.edited;
    const locked = lockedNoChild || this.hasChildren();

    const inPeriod = isInPeriod(this.state.dirtyTransaction.date, this.props.bankingDocument.period);

    return (
      <Container
        className={classnames('BankingTransactionEdit', { locked })}
        draggable={!locked}
        onDragStart={this.onDragStart}
        onBlur={this.onContainerBlur}
        tabIndex={this.state.dirtyTransaction.position}
      >
        <Row>
          <Col xs="1" className="smallCol">
            {this.state.dirtyTransaction.position / 2}
          </Col>
          <Col xs="auto">
            <FormGroup>
              <DateInput
                date={this.state.dirtyTransaction.date}
                onInputChange={(date) => this.handleChange('date', date)}
                locked={locked}
              />
              <Input
                name="clarificationText"
                onChange={(e) => this.handleChange('clarificationText', e.target.value)}
                value={this.state.dirtyTransaction.clarificationText ?? ''}
                placeholder={t('clarificationText')}
                disabled={locked || !inPeriod}
              />
            </FormGroup>
          </Col>
          <Col xs={3}>
            <Input
              type="textarea"
              name="description"
              onChange={(e) => this.handleChange('description', e.target.value)}
              value={this.state.dirtyTransaction.description}
              disabled={locked || !inPeriod}
            />
          </Col>
          <Col xs="auto">
            <CurrencyInput
              value={this.state.dirtyTransaction.amount}
              onInputChange={(amount) => this.handleChange('amount', amount)}
              unit={this.props.currency}
              disabled={locked || !inPeriod}
            />
            {this.renderBalance()}
          </Col>
          <Col xs="auto">
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  name={`type-${this.state.dirtyTransaction.id}`}
                  value="credit"
                  checked={this.state.dirtyTransaction.type === 'credit'}
                  onChange={(e) => this.handleChange('type', e.target.value)}
                  disabled={locked || !inPeriod}
                />
                {t('credit_transaction')}
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  name={`type-${this.state.dirtyTransaction.id}`}
                  value="debit"
                  checked={this.state.dirtyTransaction.type === 'debit'}
                  onChange={(e) => this.handleChange('type', e.target.value)}
                  disabled={locked || !inPeriod}
                />
                {t('debit_transaction')}
              </Label>
            </FormGroup>
          </Col>
          <Col xs="1" className="smallCol">
            {this.renderDeleteButton(locked || !inPeriod)}
            {this.renderChildDeleteButton(lockedNoChild || !inPeriod)}
          </Col>
          <Col xs="1" className="smallCol">
            {this.renderAccountingDocumentLink(this.state.dirtyTransaction)}
          </Col>
          <span className={'errorMessage'}>{this.state.errorMessage}</span>
        </Row>
        {this.renderChildTransactions()}
      </Container>
    );
  }
}

BankingTransactionEdit.propTypes = {
  bankingDocument: PropTypes.object.isRequired,
  bankingTransaction: PropTypes.object.isRequired,
  currency: PropTypes.string.isRequired,
  balance: PropTypes.number,
  onBankingTransactionDelete: PropTypes.func.isRequired,
  onBankingTransactionChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default translate(withRouter(BankingTransactionEdit));
