import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import deepEqual from 'deep-equal';
import axios from 'axios';
import classnames from 'classnames';
import * as immutable from 'object-path-immutable';
import { Table, Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { FaPlus, FaTrash } from 'react-icons/fa';
import formatUID from 'lib/formatUID';
import translate from 'containers/translate';
import withRule from 'containers/withRule';
import { consumesCompanyNames } from 'contexts/CompanyNamesContext';
import RemoteDataPropType from 'lib/prop-types/RemoteDataPropType';
import AccountEdit from 'components/accountEdit/AccountEdit';
import Tooltip from 'components/tooltip/Tooltip';
import './OverviewCompanyEdit.scss';

class OverviewCompanyEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dirtyCompany: this.props.company,
      createRule: false,
      account: '1000',
      newUID: '',
      isValidating: false,
      validationError: false,
    };

    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleAccountChange = this.handleAccountChange.bind(this);
    this.handleRemoveAccountClick = this.handleRemoveAccountClick.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleNewUIDChange = this.handleNewUIDChange.bind(this);
    this.handleNewUIDKeyPress = this.handleNewUIDKeyPress.bind(this);
    this.removeUID = this.removeUID.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (!deepEqual(prevProps.company, this.props.company)) {
      this.setState({
        dirtyCompany: this.props.company,
      });
    }
  }

  handleNameChange(e) {
    const name = e.target.value;

    this.setState((prevState) => {
      return {
        dirtyCompany: Object.assign({}, prevState.dirtyCompany, { name }),
      };
    });
  }

  handleAccountChange(account) {
    if (this.state.createRule) {
      this.setState({
        account: account,
      });
    } else {
      const rule = {
        ...this.props.rule,
        account,
      };

      this.props.onRuleChange(rule);
    }
  }

  handleRemoveAccountClick() {
    if (this.state.createRule) {
      this.setState({
        createRule: false,
        account: null,
      });
    } else {
      this.props.onRuleDelete(this.props.rule);
    }
  }

  handleSaveClick() {
    if (this.state.createRule && this.state.account) {
      const rule = {
        account: this.state.account,
        company: this.props.company,
      };

      this.props.onNewRule(rule, false, () => {
        this.props.onDismiss(this.state.dirtyCompany);
      });

      return;
    }

    this.props.onDismiss(this.state.dirtyCompany);
  }

  handleNewUIDChange(e) {
    this.setState({
      newUID: e.target.value,
      validationError: false,
    });
  }

  handleNewUIDKeyPress(e) {
    if (e.key !== 'Enter') {
      return;
    }

    const uid = parseInt(this.state.newUID.replace(/[^\d]+/g, ''), 10);

    this.setState({
      isValidating: true,
    });

    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/companies/uids/${uid}`)
      .then(() => {
        this.setState((prevState) => {
          return {
            dirtyCompany: immutable.push(prevState.dirtyCompany, 'uids', uid),
            newUID: '',
            validationError: false,
          };
        });

        this.props.remoteCompanyNames.api.fetch();
      })
      .catch(() => {
        this.setState({
          validationError: true,
        });
      })
      .finally(() => {
        this.setState({
          isValidating: false,
        });
      });
  }

  removeUID(uid) {
    this.setState((prevState) => {
      const index = this.state.dirtyCompany.uids.indexOf(uid);

      if (index !== -1) {
        return {
          dirtyCompany: immutable.del(prevState.dirtyCompany, ['uids', index]),
        };
      }
    });
  }

  renderUID(uid) {
    const title = this.props.companyNames[uid] || '';

    return (
      <Tooltip title={title}>
        <span className="uid">
          {formatUID(uid)}
          <FaTrash onClick={() => this.removeUID(uid)} />
        </span>
      </Tooltip>
    );
  }

  renderUIDs() {
    const uids = this.state.dirtyCompany.uids;

    return (
      <ul className="uids">
        {uids.map((uid) => (
          <li key={uid}>{this.renderUID(uid)}</li>
        ))}
        <li>
          <Input
            value={this.state.newUID}
            className={classnames({ 'is-invalid': this.state.validationError })}
            disabled={this.state.isValidating}
            onChange={this.handleNewUIDChange}
            onKeyPress={this.handleNewUIDKeyPress}
          />
        </li>
      </ul>
    );
  }

  renderRule() {
    if (this.props.rule || this.state.createRule) {
      const account = this.props.rule ? this.props.rule.account : this.state.account;

      return (
        <div>
          <AccountEdit onAccountChange={this.handleAccountChange} account={account} />
          <FaTrash onClick={this.handleRemoveAccountClick} />
        </div>
      );
    }

    return (
      <Button onClick={() => this.setState({ createRule: true })}>
        <FaPlus />
      </Button>
    );
  }

  render() {
    const { t } = this.props;

    return (
      <Modal isOpen size="lg" toggle={() => this.props.onDismiss()}>
        <ModalHeader toggle={() => this.props.onDismiss()}>{t('edit_companies')}</ModalHeader>
        <ModalBody>
          <Table>
            <thead>
              <tr>
                <th className={'companyNameColumn'}>{t('company_name')}</th>
                <th>{t('rule')}</th>
                <th>{t('uid')}</th>
              </tr>
            </thead>
            <tbody>
              <tr className="companyInput">
                <td>
                  <Input value={this.state.dirtyCompany.name} onChange={this.handleNameChange} />
                </td>
                <td>{this.renderRule()}</td>
                <td>{this.renderUIDs()}</td>
              </tr>
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.handleSaveClick}>
            {t('save')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

OverviewCompanyEdit.propTypes = {
  company: PropTypes.object.isRequired,
  rule: PropTypes.object,
  onDismiss: PropTypes.func.isRequired,
  companyNames: PropTypes.object.isRequired,
  remoteCompanyNames: RemoteDataPropType,
  onNewRule: PropTypes.func.isRequired,
  onRuleChange: PropTypes.func.isRequired,
  onRuleDelete: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default consumesCompanyNames(withRule(translate(OverviewCompanyEdit)));
