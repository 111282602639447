import PropTypes from 'prop-types';

export default PropTypes.shape({
  isLoading: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  error: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  api: PropTypes.shape({
    fetch: PropTypes.func.isRequired,
    create: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
    delete: PropTypes.func.isRequired,
  }).isRequired,
}).isRequired;
