import React, { Component } from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import translate from 'containers/translate';
import withGenerateBanks from 'containers/withGenerateBanks';
import './BankPrediction.scss';

class BankPrediction extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate() {
    this.input && this.input.focus();
  }

  render() {
    return <div className={'BankPrediction'}>{this.renderButton()}</div>;
  }

  renderButton() {
    const { t } = this.props;

    return (
      <Button className={'assignBanks'} onClick={this.props.generateBanks} color={'link'}>
        {t('assign_banks_automatically')}
      </Button>
    );
  }
}

BankPrediction.propTypes = {
  generateBanks: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withGenerateBanks(translate(BankPrediction));
