import React from 'react';
import RemoteDataProvider from './RemoteDataProvider';

const DocumentIdsContext = React.createContext();

function providesDocumentIds(Component) {
  class RemoteDocumentIds extends React.Component {
    render() {
      return (
        <RemoteDataProvider
          baseURL="/accounting-documents"
          isCollection
          passThroughSearch
          render={(remoteData) => {
            return (
              <DocumentIdsContext.Provider value={remoteData}>
                <Component {...this.props} />
              </DocumentIdsContext.Provider>
            );
          }}
        />
      );
    }
  }

  return RemoteDocumentIds;
}

function consumesDocumentIds(Component, provideValueAs = 'documentIds') {
  return function ComponentThatConsumesDocumentIds(props) {
    return (
      <DocumentIdsContext.Consumer>
        {(remoteData) => {
          const consumedProps = {
            remoteDocumentIds: remoteData,
            [provideValueAs]: remoteData.value,
          };

          return <Component {...props} {...consumedProps} />;
        }}
      </DocumentIdsContext.Consumer>
    );
  };
}

export { providesDocumentIds, consumesDocumentIds };
