import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Col, Container, Row } from 'reactstrap';
import translate from 'containers/translate';
import './Settings.scss';

class Settings extends Component {
  constructor(props) {
    super(props);

    const { t } = this.props;

    this.setLanguage = this.setLanguage.bind(this);

    this.languages = [
      { value: 'de', label: t('german') },
      { value: 'en', label: t('english') },
      { value: 'fr', label: t('french') },
      { value: 'it', label: t('italian') },
    ];

    this.state = {
      selectedLanguage: localStorage.getItem('language'),
    };
  }

  setLanguage(option) {
    const language = option.value;

    this.setState(
      {
        selectedLanguage: language,
      },
      () => {
        localStorage.setItem('language', language);
        window.location.reload();
      }
    );
  }

  render() {
    const { t } = this.props;
    const value = this.languages.find((l) => l.value === this.state.selectedLanguage);

    return (
      <div className={'Settings'}>
        <h2>{t('settings')}</h2>
        <Container>
          <Row>
            <Col xs={5}>{t('language')}</Col>
            <Col xs={6}>
              <Select
                options={this.languages}
                isClearable={false}
                backspaceRemovesValue={false}
                value={value}
                onChange={this.setLanguage}
                className="Select"
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

Settings.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate(Settings);
