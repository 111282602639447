import React, { useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import 'lib/configureAxios.js';
import LeaGeneric from './LeaGeneric';
import LeaDossier from './LeaDossier';
import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react';

const TOKEN_STORAGE_KEY = 'lea.auth0.token';

const Lea = () => {
  const { getAccessTokenSilently, logout } = useAuth0();
  const [axiosInitialized, setAxiosInitialized] = useState(false);

  useEffect(() => {
    getAccessTokenSilently().then(
      (token) => {
        localStorage.setItem(TOKEN_STORAGE_KEY, token);
        setAxiosInitialized(true);
      },
      (error) => {
        // eslint-disable-next-line no-console
        console.log(error);
        logout();
      }
    );
  }, [getAccessTokenSilently]);

  if (!axiosInitialized) {
    return <div>Loading</div>;
  }

  return (
    <div className={'Lea'}>
      <Routes>
        <Route path={'/dossier/:dossierId/:periodId/*'} element={<LeaDossier />}></Route>
        <Route path={'/*'} element={<LeaGeneric />}></Route>
      </Routes>
    </div>
  );
};

export default withAuthenticationRequired(Lea);
