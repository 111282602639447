import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withRouter } from 'containers/withRouter';
import { Badge, Button, Col, Container, Form, FormGroup, Label, Row } from 'reactstrap';
import MultiToggle from 'react-multi-toggle';
import { FaDownload } from 'react-icons/fa';
import isInPeriod from 'lib/isInPeriod';
import RemoteDataPropType from 'lib/prop-types/RemoteDataPropType';
import withFileDownload from 'containers/withFileDownload';
import AccountEdit from 'components/accountEdit/AccountEdit';
import ImageContainer from 'components/imageContainer/ImageContainer';
import CurrencyInput from 'components/currencyInput/CurrencyInput';
import DateInput from 'components/dateInput/DateInput';
import Tooltip from 'components/tooltip/Tooltip';
import BankSelect from 'components/bankSelect/BankSelect';
import translate from 'containers/translate';
import { consumesBankingDocuments } from 'contexts/BankingDocumentsContext';
import { consumesBankingTransaction, providesBankingTransaction } from 'contexts/BankingTransactionContext';
import { consumesAccounts } from 'contexts/AccountsContext';
import Transactions from './Transactions';
import StartingBalanceProposal from './StartingBalanceProposal';
import './BankingDocumentEdit.scss';

class BankingDocumentEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.deriveStateFromProps(props),
      summationInverted: false,
      loading: false,
    };

    this.renderBankingDocument = this.renderBankingDocument.bind(this);
    this.handleChangeAndUpdateDocument = this.handleChangeAndUpdateDocument.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updateDocument = this.updateDocument.bind(this);
    this.deleteBankingDocument = this.deleteBankingDocument.bind(this);
    this.onBankingTransactionMove = this.onBankingTransactionMove.bind(this);
    this.handleBnkingTransactionDelete = this.handleBnkingTransactionDelete.bind(this);
    this.onError = this.onError.bind(this);
    this.editAccounts = this.editAccounts.bind(this);
    this.editBanks = this.editBanks.bind(this);
  }

  componentDidUpdate(prevProps) {
    const prevBankingDocument = this.getBankingDocument(prevProps);
    const bankingDocument = this.getBankingDocument(this.props);

    if (prevBankingDocument !== bankingDocument) {
      this.setState(this.deriveStateFromProps(this.props));
    }
  }

  deriveStateFromProps(props) {
    const bankingDocument = this.getBankingDocument(props);
    if (!bankingDocument) {
      return;
    }
    return {
      dirtyBankingDocument: bankingDocument,
      dateFrom: bankingDocument.dateFrom || moment().format('YYYY-MM-DD'),
      dateTo: bankingDocument.dateTo || moment().format('YYYY-MM-DD'),
      startingBalance: bankingDocument && bankingDocument.startingBalance,
      endBalance: this.getEndBalance(bankingDocument),
      errorMessage: '',
    };
  }

  render() {
    const { t } = this.props;

    return (
      <div className={'BankingDocumentEdit'}>
        <h2>
          {t('edit_banking_document')} {this.renderEditedBadge()}
        </h2>
        {this.renderBankingDocument()}
      </div>
    );
  }

  renderEditedBadge() {
    if (!this.state.dirtyBankingDocument || !this.state.dirtyBankingDocument.edited) {
      return;
    }
    const { t } = this.props;

    return <Badge color={'success'}>{t('edited')}</Badge>;
  }

  getEndBalance(bankingDocument) {
    if (!bankingDocument) {
      return 0;
    }
    const transactionTotal = this.getTransactionTotal(bankingDocument);
    return transactionTotal + bankingDocument.startingBalance;
  }

  renderBankingDocument() {
    const { t } = this.props;

    const bankingDocument = this.state.dirtyBankingDocument;
    if (!bankingDocument) {
      return <div />;
    }

    const locked = bankingDocument.edited || this.props.readOnly;

    return (
      <Form>
        <Container>
          <Row className={'buttons'}>
            <Col>
              <FormGroup check inline>
                {this.renderEditedButton(bankingDocument)}
                <Button onClick={this.deleteBankingDocument} color="danger" disabled={locked}>
                  {t('delete')}
                </Button>
                {this.renderDownloadButton()}
                <span className={'errorMessage'}>{this.state.errorMessage}</span>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <FormGroup>
                <Label>
                  {t('contra_account')}{' '}
                  <Button color={'link'} onClick={this.editAccounts} className={'editAccounts'}>
                    {t('edit_accounts')}
                  </Button>
                </Label>
                <AccountEdit
                  onAccountChange={(account) => {
                    const accountObject = this.props.accounts.find((a) => a.account === account);

                    this.handleChangeAndUpdateDocument('account', accountObject);
                  }}
                  account={bankingDocument.account.account}
                  accountProposals={this.props.accounts.filter((a) => a.hasDocument && !a.deleted)}
                  locked={locked}
                />
              </FormGroup>
            </Col>
            <Col>{this.renderSummationInverseToggle()}</Col>
          </Row>
          <Row>
            <Col xs={6}>
              <FormGroup>
                {t('bank')}{' '}
                <Button color={'link'} onClick={this.editBanks} className={'editBanks'}>
                  {t('edit_banks')}
                </Button>
                <BankSelect bank={bankingDocument.account.bank} locked={true} />
              </FormGroup>
            </Col>
            <Col xs={6}>
              <FormGroup>
                <Label className="startingBalance">
                  {t('starting_balance')}
                  {!locked && this.renderStartingBalanceProposal()}
                </Label>
                <CurrencyInput
                  value={this.state.startingBalance}
                  onInputChange={(startingBalance) => {
                    this.setState({ startingBalance });
                    this.handleChangeAndUpdateDocument('startingBalance', startingBalance);
                  }}
                  unit={bankingDocument.account.currency}
                  disabled={locked}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={3}>
              <FormGroup>
                <Label>{t('date_from')}</Label>
                <DateInput
                  date={this.state.dateFrom}
                  onInputChange={(date) =>
                    this.setState({ dateFrom: date }, () =>
                      this.handleChangeAndUpdateDocument('dateFrom', this.state.dateFrom)
                    )
                  }
                  locked={locked}
                />
              </FormGroup>
            </Col>
            <Col xs={3}>
              <FormGroup>
                <Label>{t('date_to')}</Label>
                <DateInput
                  date={this.state.dateTo}
                  onInputChange={(date) =>
                    this.setState({ dateTo: date }, () =>
                      this.handleChangeAndUpdateDocument('dateTo', this.state.dateTo)
                    )
                  }
                  locked={locked}
                />
              </FormGroup>
            </Col>
            <Col xs={6}>
              <FormGroup>
                <Label>{t('end_balance')}</Label>
                <br />
                <CurrencyInput
                  value={this.state.endBalance}
                  onInputChange={() => {}}
                  unit={bankingDocument.account.currency}
                  disabled={true}
                />
              </FormGroup>
            </Col>
          </Row>
        </Container>
        {bankingDocument.imageFile && <ImageContainer imageFile={bankingDocument.imageFile} inFlexbox={false} />}
        <Transactions
          t={t}
          bankingDocument={this.state.dirtyBankingDocument}
          currency={bankingDocument.account.currency}
          remoteBankingDocuments={this.props.remoteBankingDocuments}
          remoteBankingTransaction={this.props.remoteBankingTransaction}
          summationInverted={this.state.summationInverted}
          startingBalance={this.state.startingBalance}
          onBankingTransactionMove={this.onBankingTransactionMove}
          onError={this.onError}
          loading={this.state.loading}
          readOnly={locked}
        />
      </Form>
    );
  }

  renderEditedButton(bankingDocument) {
    const { t } = this.props;
    const hasZeroTransactions = this.hasZeroTransactions(bankingDocument);
    const title = hasZeroTransactions ? t('unable_to_mark_edited_has_zero_transactions') : '';
    let disabled = hasZeroTransactions || this.props.readOnly;
    disabled = disabled && !bankingDocument.edited;

    return (
      <Tooltip title={title} wrap={disabled}>
        <Button
          onClick={() => this.handleChangeAndUpdateDocument('edited', !bankingDocument.edited)}
          color={bankingDocument.edited ? 'secondary' : 'success'}
          disabled={disabled}
        >
          {bankingDocument.edited ? t('to_edit') : t('finish_edit')}
        </Button>
      </Tooltip>
    );
  }

  hasZeroTransactions(bankingDocument) {
    return bankingDocument.bankingTransactions.some((transaction) => {
      return transaction.amount === 0 && isInPeriod(transaction.date, bankingDocument.period);
    });
  }

  deleteBankingDocument() {
    this.props.remoteBankingDocuments.api.delete(this.state.dirtyBankingDocument, (err) => {
      if (err) {
        return this.setState({
          errorMessage: 'Einzelne Transaktionen sind einem Beleg zugeordnet!',
        });
      }

      this.props.history.pushDossier('/banking-documents');
    });
  }

  getTransactionTotal(bankingDocument) {
    return bankingDocument.bankingTransactions.reduce((sum, b) => {
      const inPeriod = isInPeriod(b.date, bankingDocument.period);

      if (!inPeriod) {
        return sum;
      }

      if (b.type === 'debit') {
        return sum - b.amount;
      } else if (b.type === 'credit') {
        return sum + b.amount;
      }
      return sum;
    }, 0);
  }

  getBankingDocument(props) {
    const documentId = parseInt(props.match.params.documentId, 10);
    return props.bankingDocuments.find((b) => b.id === documentId);
  }

  renderDownloadButton() {
    if (!this.state.dirtyBankingDocument) {
      return;
    }
    return (
      <Button color="info" onClick={() => this.props.onBankingDocumentDownload(this.state.dirtyBankingDocument.id)}>
        <FaDownload className="download-icon" />
      </Button>
    );
  }

  handleBnkingTransactionDelete(bankingTransaction) {
    const bankingTransactions = this.state.dirtyBankingDocument.bankingTransactions.filter(
      (oldTransaction) => oldTransaction.id !== bankingTransaction.id
    );
    this.handleChangeAndUpdateDocument('bankingTransactions', bankingTransactions);
  }

  onError(errorMessage) {
    this.setState({ errorMessage });
  }

  handleChange(key, value, cb) {
    const bankingDocument = Object.assign({}, this.state.dirtyBankingDocument, { [key]: value });
    this.setState({ dirtyBankingDocument: bankingDocument }, () => cb && cb());
  }

  updateDocument() {
    this.props.remoteBankingDocuments.api.update(this.state.dirtyBankingDocument);
  }

  onBankingTransactionMove(id, position, cb) {
    const bankingTransactions = this.state.dirtyBankingDocument.bankingTransactions.slice();
    const transaction = bankingTransactions.find((t) => t.id === id);
    const newTransaction = Object.assign({}, transaction, { position });

    this.setState({ loading: true }, () => {
      this.props.remoteBankingTransaction.api.update(newTransaction, () => {
        this.props.remoteBankingDocuments.api.fetch(() => {
          this.setState({ loading: false });
          cb && cb();
        });
      });
    });
  }

  editAccounts() {
    this.props.history.pushDossier('/accounts');
  }

  editBanks() {
    this.props.history.push('/banks');
  }

  renderSummationInverseToggle() {
    const { t } = this.props;
    return (
      <FormGroup>
        <Label>{t('continuous_summation')}</Label>
        <MultiToggle
          options={[
            {
              displayName: t('normal'),
              value: false,
            },
            {
              displayName: t('inverted'),
              value: true,
            },
          ]}
          selectedOption={this.state.summationInverted}
          onSelectOption={(value) => this.setState({ summationInverted: value })}
        />
      </FormGroup>
    );
  }

  renderStartingBalanceProposal() {
    return (
      <StartingBalanceProposal
        bankingDocument={this.state.dirtyBankingDocument}
        startingBalance={this.state.startingBalance}
        onStartingBalanceProposalSelected={(startingBalanceProposal) => {
          this.setState({ startingBalance: startingBalanceProposal });
          this.handleChangeAndUpdateDocument('startingBalance', startingBalanceProposal);
        }}
      />
    );
  }

  handleChangeAndUpdateDocument(key, value) {
    this.handleChange(key, value, this.updateDocument);
  }
}

BankingDocumentEdit.propTypes = {
  bankingDocuments: PropTypes.arrayOf(PropTypes.object).isRequired,
  remoteBankingDocuments: RemoteDataPropType,
  remoteBankingTransaction: RemoteDataPropType,
  accounts: PropTypes.arrayOf(PropTypes.object).isRequired,
  readOnly: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  onBankingDocumentDownload: PropTypes.func.isRequired,
};

export default providesBankingTransaction(
  consumesAccounts(
    withFileDownload(consumesBankingTransaction(consumesBankingDocuments(withRouter(translate(BankingDocumentEdit)))))
  )
);
