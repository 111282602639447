import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import RemoteDataPropType from 'lib/prop-types/RemoteDataPropType';
import translate from 'containers/translate';
import {
  providesAccountingTextProposals,
  consumesAccountingTextProposals,
} from 'contexts/AccountingTextProposalsContext';
import { consumesDocument } from 'contexts/DocumentContext';
import './AccountingTextAutoSuggest.scss';

class AccountingTextAutoSuggest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.presetAccountingText,
      suggestions: [],
    };

    this.onBlur = this.onBlur.bind(this);
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.presetAccountingText !== this.props.presetAccountingText) {
      this.setState({
        value: this.props.presetAccountingText,
        suggestions: this.getAccountingTextSuggestions(this.props),
      });
    }

    if (prevProps.accountingTextProposals !== this.props.accountingTextProposals) {
      this.setState({
        suggestions: this.getAccountingTextSuggestions(this.props),
      });
    }

    if (prevProps.document.company.id !== this.props.document.company.id) {
      this.props.remoteAccountingTextProposals.api.fetch();
    }
  }

  render() {
    const { t } = this.props;
    const { value, suggestions } = this.state;

    const inputProps = {
      placeholder: t('accounting_text'),
      value,
      onChange: this.onChange,
      onBlur: this.onBlur,
      onKeyPress: this.onKeyPress,
      disabled: this.props.disabled,
    };

    return (
      <div className={'AccountingTextAutoSuggest'}>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={this.getSuggestionValue}
          shouldRenderSuggestions={this.shouldRenderSuggestions}
          renderSuggestion={this.renderSuggestion}
          inputProps={inputProps}
        />
      </div>
    );
  }

  onChange(event, { newValue }) {
    this.setState({
      value: newValue,
    });
  }

  onKeyPress(target) {
    if (target.charCode === 13) {
      this.onBlur();
    }
  }

  onBlur() {
    const { value } = this.state;
    this.props.onAccountingTextChange(value);
  }

  onSuggestionsFetchRequested({ value }) {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  }

  getSuggestions(value) {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    const accountingTextSuggestions = this.getAccountingTextSuggestions(this.props);

    return accountingTextSuggestions.filter(
      (suggestion) => suggestion.toLowerCase().slice(0, inputLength) === inputValue
    );
  }

  getAccountingTextSuggestions(props) {
    const suggestions = props.accountingTextProposals;
    if (!suggestions) {
      return [];
    }
    if (props.presetAccountingText && !suggestions.some((s) => s === props.presetAccountingText)) {
      suggestions.push(props.presetAccountingText);
    }
    return suggestions;
  }

  onSuggestionsClearRequested() {
    this.setState({
      suggestions: [],
    });
  }

  getSuggestionValue(suggestion) {
    return suggestion;
  }

  shouldRenderSuggestions() {
    return true;
  }

  renderSuggestion(suggestion) {
    return <div>{suggestion}</div>;
  }
}

AccountingTextAutoSuggest.propTypes = {
  accountingTextProposals: PropTypes.arrayOf(PropTypes.string).isRequired,
  remoteAccountingTextProposals: RemoteDataPropType,
  presetAccountingText: PropTypes.string.isRequired,
  document: PropTypes.object.isRequired,
  onAccountingTextChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default translate(
  providesAccountingTextProposals(consumesAccountingTextProposals(consumesDocument(AccountingTextAutoSuggest)))
);
