import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Table } from 'reactstrap';
import translate from 'containers/translate';
import OverviewAccountEdit from 'components/overviewAccountEdit/OverviewAccountEdit';
import OverviewAccountCreate from 'components/overviewAccountCreate/OverviewAccountCreate';
import { providesAccounts, consumesAccounts } from 'contexts/AccountsContext';
import { providesSpecialAccounts, consumesSpecialAccounts } from 'contexts/SpecialAccountsContext';
import RemoteDataPropType from 'lib/prop-types/RemoteDataPropType';
import { withRouter } from 'containers/withRouter';
import './AccountOverview.scss';

class AccountOverview extends Component {
  constructor(props) {
    super(props);

    this.state = { errorMessage: '' };

    this.renderAccount = this.renderAccount.bind(this);
    this.handleAccountChange = this.handleAccountChange.bind(this);
    this.handleAccountCreate = this.handleAccountCreate.bind(this);
    this.handleAccountDelete = this.handleAccountDelete.bind(this);
    this.handleSpecialAccount = this.handleSpecialAccount.bind(this);
    this.clearErrorMessage = this.clearErrorMessage.bind(this);
  }

  handleAccountChange(account) {
    this.props.remoteAccounts.api.update(account, (err) => {
      if (err && err.response && err.response.status === 409) {
        this.setState({ errorMessage: this.props.t('cannot_change_account') }, () => {
          this.props.remoteAccounts.api.fetch();
          setTimeout(this.clearErrorMessage, 3000);
        });
      }
    });
  }

  clearErrorMessage() {
    this.setState({ errorMessage: '' });
  }

  handleAccountCreate(accountNumber, currency, hasDocument) {
    this.props.remoteAccounts.api.create({ account: accountNumber, currency, hasDocument });
  }

  handleAccountDelete(account) {
    this.props.remoteAccounts.api.delete(account);
  }

  handleSpecialAccount(specialAccount) {
    this.props.remoteSpecialAccounts.api.create(specialAccount, this.props.remoteAccounts.api.fetch);
  }

  render() {
    return <div className={'AccountOverview'}>{this.renderAccountLists()}</div>;
  }

  renderAccountLists() {
    const { t } = this.props;

    return (
      <React.Fragment>
        <h1>{t('account_overview')}</h1>
        <Button color={'link'} onClick={() => this.props.history.push('/banks')} className={'editBanks'}>
          {t('edit_banks')}
        </Button>
        {this.renderAccounts(true, false, t('bank_document_accounts'))}
        {this.renderAccounts(false, false, t('account_document_accounts'))}
        {this.renderAccounts(false, true, t('deleted_accounts'))}
      </React.Fragment>
    );
  }

  renderAccounts(hasDocument, onlyDeleted, caption) {
    const { t } = this.props;

    const accountList = this.props.accounts.filter(
      (account) =>
        (!onlyDeleted && !!account.hasDocument === hasDocument && !account.deleted) ||
        (onlyDeleted && !!account.deleted)
    );

    if (accountList.length === 0 && !onlyDeleted) {
      return (
        <Table>
          <tbody>
            <tr>
              <td>
                <h3>
                  {t('account_create_new')} {caption}
                </h3>
              </td>
            </tr>
            {this.renderAccountCreate(hasDocument)}
          </tbody>
        </Table>
      );
    }

    return (
      <div>
        <Table>
          <tbody>
            <tr className={'title'}>
              <th colSpan="5">{caption}</th>
            </tr>
            <tr>
              <th>{t('account')}</th>
              <th>{t('currency')}</th>
              {this.renderBank(hasDocument)}
              <th>{t('name')}</th>
              <th />
              <th>{t('hasDocument')}</th>
              <th />
            </tr>
            {!onlyDeleted && this.renderAccountCreate(hasDocument)}
            {accountList.map(this.renderAccount)}
          </tbody>
        </Table>
        <span className={'errorMessage'}>{this.state.errorMessage}</span>
      </div>
    );
  }

  renderBank(hasDocument) {
    if (!hasDocument) {
      return;
    }

    const { t } = this.props;

    return <th>{t('bank')}</th>;
  }

  renderAccountCreate(hasDocument) {
    return (
      <OverviewAccountCreate
        accounts={this.props.accounts}
        onAccountCreate={(accountNumber, currency) => this.handleAccountCreate(accountNumber, currency, hasDocument)}
      />
    );
  }

  renderAccount(account, i) {
    return (
      <OverviewAccountEdit
        key={i}
        account={account}
        onAccountChange={this.handleAccountChange}
        onAccountDelete={this.handleAccountDelete}
        onSpecialAccountChange={this.handleSpecialAccount}
        locked={account.account === '2099' || !!account.deleted}
      />
    );
  }
}

AccountOverview.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.object).isRequired,
  remoteAccounts: RemoteDataPropType,
  remoteSpecialAccounts: RemoteDataPropType,
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withRouter(
  providesAccounts(consumesAccounts(providesSpecialAccounts(consumesSpecialAccounts(translate(AccountOverview)))))
);
