import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FaChevronCircleLeft, FaChevronCircleRight } from 'react-icons/fa';
import DocumentStateIndicator from 'components/documentStateIndicator/DocumentStateIndicator';
import DocumentRelevant from 'components/documentRelevant/DocumentRelevant';
import { consumesDocument } from 'contexts/DocumentContext';
import RemoteDataPropType from 'lib/prop-types/RemoteDataPropType';
import './DocumentListNavigation.scss';

const LONG_BUTTON_PRESS_DURATION = 1000;

class DocumentListNavigation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      indexValue: String(this.props.selectedIndex + 1),
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputKeypress = this.handleInputKeypress.bind(this);
    this.onIndexConfirm = this.onIndexConfirm.bind(this);
    this.handleButtonPress = this.handleButtonPress.bind(this);
    this.handleButtonRelease = this.handleButtonRelease.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedIndex !== this.props.selectedIndex) {
      this.setState({
        indexValue: String(this.props.selectedIndex + 1),
      });
    }
  }

  handleInputChange(e) {
    this.setState({ indexValue: e.target.value });
  }

  handleInputKeypress(e) {
    if (e.key !== 'Enter') {
      return;
    }

    this.onIndexConfirm();
  }

  onIndexConfirm() {
    const newIndex = parseInt(this.state.indexValue, 10) - 1;

    if (isNaN(newIndex)) {
      return;
    }

    this.props.onNavigateToDocumentAtIndex(newIndex);
  }

  renderLeftNavButton() {
    const invisible = this.props.previousDocumentId === -1;
    return (
      <FaChevronCircleLeft
        size={30}
        className={classnames('clickable prevDocument', { invisible })}
        onClick={this.props.onPreviousDocument}
        onMouseDown={() => this.handleButtonPress('left')}
        onMouseUp={this.handleButtonRelease}
        onMouseLeave={this.handleButtonRelease}
      />
    );
  }

  renderRightNavButton() {
    const invisible = this.props.nextDocumentId === -1;
    return (
      <FaChevronCircleRight
        size={30}
        className={classnames('clickable nextDocument', { invisible })}
        onClick={this.props.onNextDocument}
        onMouseDown={() => this.handleButtonPress('right')}
        onMouseUp={this.handleButtonRelease}
        onMouseLeave={this.handleButtonRelease}
      />
    );
  }
  handleButtonPress(direction) {
    this.buttonPressTimer = setTimeout(() => {
      if (direction === 'right') {
        this.props.onLastDocument();
      } else if (direction === 'left') {
        this.props.onFirstDocument();
      }
    }, LONG_BUTTON_PRESS_DURATION);
  }

  handleButtonRelease() {
    clearTimeout(this.buttonPressTimer);
  }

  renderLoading() {
    if (this.props.loading) {
      return <div className="loader" />;
    }
  }

  renderIndexInput() {
    return (
      <input
        type="text"
        className="IndexInput"
        style={{ width: `${this.state.indexValue.length}ch` }}
        ref={(input) => {
          this.textInput = input;
        }}
        value={this.state.indexValue}
        onChange={this.handleInputChange}
        onKeyPress={this.handleInputKeypress}
        onClick={() => this.textInput.select()}
        onBlur={this.onIndexConfirm}
      />
    );
  }

  renderStar() {
    if (!this.props.document) {
      return;
    }

    return (
      <DocumentRelevant
        document={this.props.document}
        onDocumentChange={(document) => this.props.remoteDocument.api.update(document)}
      />
    );
  }

  render() {
    return (
      <div className={'DocumentListNavigation'}>
        {this.renderLeftNavButton()}
        <span className="documentCounter">
          {this.renderIndexInput()}/{this.props.numberOfDocuments}
        </span>
        {this.renderRightNavButton()}
        <DocumentStateIndicator state={this.props.state} isInNextPeriod={this.props.isInNextPeriod} />
        {this.renderStar()}
        {this.renderLoading()}
      </div>
    );
  }
}

DocumentListNavigation.propTypes = {
  numberOfDocuments: PropTypes.number.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  previousDocumentId: PropTypes.number.isRequired,
  nextDocumentId: PropTypes.number.isRequired,
  state: PropTypes.string.isRequired,
  onNavigateToDocumentAtIndex: PropTypes.func.isRequired,
  onNextDocument: PropTypes.func.isRequired,
  onLastDocument: PropTypes.func.isRequired,
  onFirstDocument: PropTypes.func.isRequired,
  onPreviousDocument: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  isInNextPeriod: PropTypes.bool.isRequired,
  document: PropTypes.object,
  remoteDocument: RemoteDataPropType,
};

export default consumesDocument(DocumentListNavigation);
