import React from 'react';
import PropTypes from 'prop-types';
import RemoteDataProvider from './RemoteDataProvider';

const PreviewClarificationContext = React.createContext();

function providesPreviewClarification(Component) {
  class PreviewClarification extends React.Component {
    render() {
      return (
        <RemoteDataProvider
          scope="period"
          baseURL="/clarifications/preview"
          render={(remoteData) => {
            return (
              <PreviewClarificationContext.Provider value={remoteData}>
                <Component {...this.props} />
              </PreviewClarificationContext.Provider>
            );
          }}
        />
      );
    }
  }

  PreviewClarification.propTypes = {
    selectedPeriod: PropTypes.object.isRequired,
  };

  return PreviewClarification;
}

function consumesPreviewClarification(Component, provideValueAs = 'previewClarifications') {
  return function ComponentThatConsumesPreviewClarification(props) {
    return (
      <PreviewClarificationContext.Consumer>
        {(remoteData) => {
          if (!remoteData) {
            return <Component {...props} />;
          }
          const consumedProps = {
            remotePreviewClarification: remoteData,
            [provideValueAs]: remoteData.value,
          };

          return <Component {...props} {...consumedProps} />;
        }}
      </PreviewClarificationContext.Consumer>
    );
  };
}

export { providesPreviewClarification, consumesPreviewClarification };
