import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'containers/withRouter';
import RemoteDataProvider, { createEmptyRemoteData } from './RemoteDataProvider';

const DossierContext = React.createContext(createEmptyRemoteData());

function providesDossier(Component) {
  class RemoteDossier extends React.Component {
    getObjectId() {
      const id = parseInt(this.props.match.params.dossierId, 10);

      if (isNaN(id)) {
        return null;
      }

      return id;
    }

    render() {
      const baseURL = '/dossiers';
      const objectId = this.getObjectId();

      return (
        <RemoteDataProvider
          scope="root"
          baseURL={baseURL}
          objectId={objectId}
          render={(remoteData) => {
            return (
              <DossierContext.Provider value={remoteData}>
                <Component {...this.props} />
              </DossierContext.Provider>
            );
          }}
        />
      );
    }
  }

  RemoteDossier.propTypes = {
    match: PropTypes.object.isRequired,
  };

  return withRouter(RemoteDossier);
}

function consumesDossier(Component, provideValueAs = 'dossier') {
  return function ComponentThatConsumesDossier(props) {
    return (
      <DossierContext.Consumer>
        {(remoteData) => {
          const consumedProps = {
            remoteDossier: remoteData,
            [provideValueAs]: remoteData.value,
          };

          return <Component {...props} {...consumedProps} />;
        }}
      </DossierContext.Consumer>
    );
  };
}

export { providesDossier, consumesDossier };
