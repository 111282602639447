import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import Select from 'react-select';
import translate from 'containers/translate';
import AccountEdit from 'components/accountEdit/AccountEdit';
import Constants from 'config/Constants';

class OverviewAccountCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: '',
      account: '1020',
      currency: null,
    };

    this.onCreateNewAccount = this.onCreateNewAccount.bind(this);
  }

  onCreateNewAccount() {
    const accountNames = this.props.accounts.map((account) => account.account);
    if (accountNames.includes(this.state.account)) {
      this.setState({ errorMessage: this.props.t('error_account_exists') });
    } else {
      this.props.onAccountCreate(this.state.account, this.state.currency);
    }
  }

  render() {
    const { t } = this.props;

    const currencyOptions = Constants.CURRENCY_OPTIONS;
    const currencyValue = currencyOptions.find((option) => option.value === this.state.currency);

    return (
      <tr className={'OverviewAccountCreate'}>
        <td>
          <AccountEdit onAccountChange={(account) => this.setState({ account })} account={this.state.account} />
        </td>
        <td>
          <Select
            options={currencyOptions}
            isClearable={false}
            backspaceRemovesValue={false}
            value={currencyValue}
            onChange={({ value }) => this.setState({ currency: value })}
            className="Select"
          />
        </td>
        <td colSpan={2}>
          <Button type="button" onClick={this.onCreateNewAccount} color="primary" disabled={!this.state.currency}>
            {t('add')}
          </Button>
        </td>
        <td colSpan={3}>
          <span className={'errorMessage'}>{this.state.errorMessage}</span>
        </td>
      </tr>
    );
  }
}

OverviewAccountCreate.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.object).isRequired,
  onAccountCreate: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default translate(OverviewAccountCreate);
