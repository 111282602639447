import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import translate from 'containers/translate';
import AccountEdit from 'components/accountEdit/AccountEdit';
import Tooltip from 'components/tooltip/Tooltip';
import './ListExportSelection.scss';

class ListExportSelection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      account: '2099',
    };
  }

  onConfirmClick(account, includeTransactions) {
    this.props.onDismiss(account, includeTransactions);
  }

  render() {
    const { t } = this.props;

    return (
      <Modal isOpen className="ListExportSelection" size="lg" toggle={() => this.props.onDismiss()}>
        <ModalHeader toggle={() => this.props.onDismiss()}>{t('account_export_list_selection')}</ModalHeader>
        <ModalBody>
          <div className={'editMask'}>
            <h3>{t('clarification_account')}</h3>
            <div className={'buttonBar'}>
              <Tooltip title={t('tooltip_open_transactions_list')}>
                <Button color="info" onClick={() => this.onConfirmClick('1099', true)}>
                  {t('open_transactions_list_export')}
                </Button>
              </Tooltip>
              <Tooltip title={t('tooltip_open_documents_list')}>
                <Button color="info" onClick={() => this.onConfirmClick('2099', false)}>
                  {t('open_documents_list_export')}
                </Button>
              </Tooltip>
              <AccountEdit
                onAccountChange={(account) => this.setState({ account })}
                account={this.state.account}
                setFocusOnMount={true}
              />
              <Button color="orange" onClick={() => this.onConfirmClick(this.state.account, false)}>
                {t('custom_list_export')}
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}
ListExportSelection.propTypes = {
  onDismiss: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default translate(ListExportSelection);
