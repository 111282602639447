import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Table, Button } from 'reactstrap';
import deepEqual from 'deep-equal';
import chartOfAccounts from 'data/chartOfAccounts.json';
import keyRegistry from 'lib/GlobalKeyRegistry';
import translate from 'containers/translate';
import { consumesAccountOverview, providesAccountOverview } from 'contexts/AccountOverviewContext';
import { consumesAccounts, providesAccounts } from 'contexts/AccountsContext';
import DocumentWindow from 'components/documentWindow/DocumentWindow';
import './ControlStep.scss';

const DEBIT_INDICATORS = ['Soll', 'Débit', 'Dare'];

class ControlStep extends Component {
  constructor(props) {
    super(props);

    this.state = this.getStateFromProps(props);

    this.handleManualAccountSelect = this.handleManualAccountSelect.bind(this);
    this.renderAccountTableLine = this.renderAccountTableLine.bind(this);
    this.handleRightPressed = this.handleRightPressed.bind(this);
    this.handleLeftPressed = this.handleLeftPressed.bind(this);
  }

  componentDidUpdate(prevProps) {
    const isSameLength = prevProps.accountOverview.length === this.props.accountOverview.length;

    if (isSameLength || deepEqual(prevProps.accountOverview, this.props.accountOverview)) {
      return;
    }

    this.setState(this.getStateFromProps(this.props));
  }

  getStateFromProps(props) {
    const accountGroups = this.getAccountGroups(props.accountOverview);
    const firstAccount = Object.keys(accountGroups)[0];
    return {
      accountGroups,
      sortedAccountList: this.getSortedAccountList(accountGroups),
      selectedAccount: firstAccount,
      documentId: null,
    };
  }

  getAccountGroups(accountOverview) {
    const sortedOverview = accountOverview.sort((a, b) => +b.lineAccount - +a.lineAccount);

    const accountGroups = {};
    let currentAccount = null;
    let currentGroup = { lines: [] };
    for (let i = 0; i < sortedOverview.length; i++) {
      const line = sortedOverview[i];
      if (line.lineAccount !== currentAccount) {
        currentGroup = {
          lines: [],
        };
        currentAccount = line.lineAccount;
        accountGroups[currentAccount] = currentGroup;
      }
      currentGroup.lines.push(line);
    }
    return accountGroups;
  }

  getSortedAccountList(accountGroups) {
    const result = [];

    for (let group in accountGroups) {
      if (Object.prototype.hasOwnProperty.call(accountGroups, group)) {
        result.push(group);
      }
    }

    return result.sort((a, b) => +a - +b);
  }

  componentDidMount() {
    keyRegistry.register('right', this.handleRightPressed);
    keyRegistry.register('left', this.handleLeftPressed);
  }

  componentWillUnmount() {
    keyRegistry.deregister('right');
    keyRegistry.deregister('left');
  }

  handleRightPressed() {
    this.changeSelectedAccount(1);
  }

  handleLeftPressed() {
    this.changeSelectedAccount(-1);
  }

  changeSelectedAccount(delta) {
    const { sortedAccountList } = this.state;

    const currentIndex = sortedAccountList.indexOf(this.state.selectedAccount);
    const nextIndex = currentIndex + delta;

    if (nextIndex < sortedAccountList.length && nextIndex >= 0) {
      this.setState({ selectedAccount: sortedAccountList[nextIndex] });
    }
  }

  render() {
    return (
      <div className={'ControlStep'}>
        {this.renderDocumentWindow()}
        {this.renderAccountNavigation()}
        {this.renderSelectedAccountGroup()}
      </div>
    );
  }

  renderDocumentWindow() {
    const documentId = this.state.documentId;
    if (!documentId) {
      return <div />;
    }

    return <DocumentWindow documentId={documentId} onDismiss={() => this.setState({ documentId: null })} />;
  }

  renderAccountNavigation() {
    const { sortedAccountList } = this.state;

    return (
      <div className={'accountNavigation'}>
        {sortedAccountList.map((account) => {
          return (
            <Button key={account} color={'link'} onClick={() => this.handleManualAccountSelect(account)}>
              {this.renderAccountNavigationItem(account)}
            </Button>
          );
        })}
      </div>
    );
  }

  renderAccountNavigationItem(account) {
    const { selectedAccount } = this.state;

    if (account === selectedAccount) {
      return <span className={'activeAccount'}>{account}</span>;
    } else {
      return account;
    }
  }

  renderSelectedAccountGroup() {
    const group = this.state.accountGroups[this.state.selectedAccount];

    return (
      <div>
        <h2>{this.renderAccountTitle()}</h2>
        {this.renderAccountTable(group)}
      </div>
    );
  }

  renderAccountTitle() {
    const account = this.state.selectedAccount;
    const accountModel = this.props.accounts.find((a) => a.account === account);

    let accountName = accountModel ? accountModel.name : null;
    if (!accountName) {
      const accounts = chartOfAccounts[this.props.language];
      const accountFromChart = accounts.find((a) => a.value === account);
      accountName = accountFromChart ? accountFromChart.label : '';
    }

    return `${account} - ${accountName}`;
  }

  handleManualAccountSelect(account) {
    this.setState({ selectedAccount: account });
  }

  renderAccountTable(group) {
    const { t } = this.props;

    if (!group) {
      return null;
    }

    return (
      <Table>
        <thead>
          <tr>
            <th>{t('document')}</th>
            <th>{t('date')}</th>
            <th>{t('contra_account')}</th>
            <th>{t('accounting_text')}</th>
            <th>{t('debit')}</th>
            <th>{t('credit')}</th>
            <th>{t('vat')}</th>
            <th>{t('total')}</th>
          </tr>
        </thead>

        <tbody className={'tableBody'}>{this.renderAccountTableLines(group.lines)}</tbody>
        {this.renderTotal(group.lines)}
      </Table>
    );
  }

  renderAccountTableLines(lines) {
    lines = lines.sort((a, b) => this.getMomentDate(a).diff(moment(this.getMomentDate(b))));
    this.addContinuousSummation(lines);
    return lines.map(this.renderAccountTableLine);
  }

  getMomentDate(line) {
    return moment(line.lineDate, 'DD.MM.YYYY');
  }

  addContinuousSummation(lines) {
    lines.reduce((acc, line) => {
      if (this.isDebitLine(line)) {
        acc += +line.lineAmountCHF;
      } else {
        acc -= +line.lineAmountCHF;
      }

      const roundedAcc = acc.toFixed(2);

      if (acc === 0) {
        line.continuousSum = '0.00';
      } else if (acc < 0) {
        line.continuousSum = `H ${Math.abs(roundedAcc)}`;
      } else {
        line.continuousSum = `S ${roundedAcc}`;
      }

      return acc;
    }, 0);
  }

  renderAccountTableLine(line, index) {
    return (
      <tr
        key={index}
        onClick={() => {
          this.handleLineClick(line);
        }}
      >
        <td>{this.getDocumentNumber(line)}</td>
        <td>{line.lineDate}</td>
        <td>{line.virtualContraAccount}</td>
        <td>{line.lineDescription}</td>
        <td>{this.renderDebitAmount(line)}</td>
        <td>{this.renderCreditAmount(line)}</td>
        <td>{this.renderVatCode(line)}</td>
        <td>{line.continuousSum}</td>
      </tr>
    );
  }

  handleLineClick(line) {
    this.setState({ documentId: this.getDocumentId(line) });
  }

  getDocumentId(line) {
    const documentNumber = this.getDocumentNumber(line);
    const documentId = documentNumber.replace('#', '');
    return parseInt(documentId, 36);
  }

  getDocumentNumber(line) {
    return line.lineDescription.match(/#.*/)[0];
  }

  renderDebitAmount(line) {
    if (!this.isDebitLine(line)) {
      return null;
    }

    return line.lineAmountCHF;
  }

  renderCreditAmount(line) {
    if (this.isDebitLine(line)) {
      return null;
    }

    return line.lineAmountCHF;
  }

  renderVatCode(line) {
    if (!line.lineVatCode) {
      return '';
    }

    const { t } = this.props;
    return t(`human_vat_code_${line.lineVatCode}`).replace('human vat code ', '');
  }

  isDebitLine(line) {
    return DEBIT_INDICATORS.includes(line.lineCreditDebitDistinction);
  }

  renderTotal(lines) {
    return (
      <tfoot>
        <tr>
          <td colSpan="4" />
          <td>
            <b>{this.getTotalFromLines(lines, true)}</b>
          </td>
          <td>
            <b>{this.getTotalFromLines(lines, false)}</b>
          </td>
          <td colSpan="2" />
        </tr>
      </tfoot>
    );
  }

  getTotalFromLines(lines, isDebit) {
    return lines
      .reduce((acc, line) => {
        if ((this.isDebitLine(line) && isDebit) || (!this.isDebitLine(line) && !isDebit)) {
          acc += +line.lineAmountCHF;
        }
        return acc;
      }, 0)
      .toFixed(2);
  }
}

ControlStep.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.object).isRequired,
  language: PropTypes.string.isRequired,
  accountOverview: PropTypes.array,
  t: PropTypes.func.isRequired,
};

export default providesAccounts(
  consumesAccounts(providesAccountOverview(consumesAccountOverview(translate(ControlStep))))
);
