import React, { Component } from 'react';
import axios from 'axios';
import errorBus from 'lib/errorBus';

export default function withCompanyStatistic(WrappedComponent) {
  class withCompanyStatistic extends Component {
    constructor(props) {
      super(props);
      this.state = {
        error: null,
      };

      this.generateStatistic = this.generateStatistic.bind(this);
    }

    componentWillUnmount() {
      this.getCancelTokenSource && this.getCancelTokenSource.cancel('Component unmounted');
      this.cancelTokenSourcePost && this.cancelTokenSourcePost.cancel('Component unmounted');
    }

    generateStatistic() {
      this.cancelTokenSourcePost && this.cancelTokenSourcePost.cancel('New request');

      this.cancelTokenSourcePost = axios.CancelToken.source();

      axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}/generate-company-statistic`,
          {},
          {
            cancelToken: this.cancelTokenSourcePost.token,
          }
        )
        .then(() => {
          this._loadData();
        })
        .catch((err) => {
          if (err instanceof axios.Cancel) {
            return;
          }
          errorBus.emit('error', err);

          this.setState({
            error: err,
          });
        });
    }

    render() {
      if (this.state.error) {
        return <div>Error</div>;
      }

      return <WrappedComponent {...this.props} generateCompanyStatistic={this.generateStatistic} />;
    }
  }
  return withCompanyStatistic;
}
