import React from 'react';
import RemoteDataProvider from './RemoteDataProvider';

const RelevantDocumentIDsContext = React.createContext();

function providesRelevantDocumentIDs(Component) {
  class RemoteRelevantDocumentIDs extends React.Component {
    render() {
      return (
        <RemoteDataProvider
          baseURL="/relevant-accounting-documents"
          isCollection
          updateStrategy="fetch"
          render={(remoteData) => {
            return (
              <RelevantDocumentIDsContext.Provider value={remoteData}>
                <Component {...this.props} />
              </RelevantDocumentIDsContext.Provider>
            );
          }}
        />
      );
    }
  }

  return RemoteRelevantDocumentIDs;
}

function consumesRelevantDocumentIDs(Component) {
  return function ComponentThatConsumesRelevantDocumentIDs(props) {
    return (
      <RelevantDocumentIDsContext.Consumer>
        {(remoteData) => {
          const consumedProps = {
            remoteRelevantDocumentIDs: remoteData,
            relevantDocumentIDs: remoteData.value,
          };

          return <Component {...props} {...consumedProps} />;
        }}
      </RelevantDocumentIDsContext.Consumer>
    );
  };
}

export { providesRelevantDocumentIDs, consumesRelevantDocumentIDs };
