import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Alert, Button, FormGroup, Label, Modal, ModalBody, ModalHeader } from 'reactstrap';
import RemoteDataPropType from 'lib/prop-types/RemoteDataPropType';
import translate from 'containers/translate';
import { consumesAdminPeriodEdit, providesAdminPeriodEdit } from 'contexts/AdminPeriodEditContext';
import DateInput from 'components/dateInput/DateInput';
import { consumesPeriods } from 'contexts/PeriodsContext';
import Constants from 'config/Constants';
import './AdminPeriodEdit.scss';

class AdminPeriodEdit extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState(props);

    this.onDialogDismiss = this.onDialogDismiss.bind(this);
    this.changePeriod = this.changePeriod.bind(this);
    this.afterPeriodChanged = this.afterPeriodChanged.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedPeriod && this.props.selectedPeriod) {
      if (prevProps.selectedPeriod.endDate !== this.props.selectedPeriod.endDate) {
        this.setState(this.getInitialState(this.props));
      }
    } else if (!prevProps.selectedPeriod && this.props.selectedPeriod) {
      this.setState(this.getInitialState(this.props));
    }
  }

  render() {
    const { t } = this.props;

    return (
      <div className={'AdminPeriodEdit'}>
        {this.renderAdminPeriodDialog()}
        <Button color="warning" onClick={() => this.setState({ showEditDialog: true })}>
          {t('edit_period')}
        </Button>
      </div>
    );
  }

  renderAdminPeriodDialog() {
    const { t } = this.props;

    if (!this.state.showEditDialog) {
      return null;
    }

    return (
      <Modal isOpen className="AdminPeriodEditModal" size="lg" toggle={this.onDialogDismiss}>
        <ModalHeader toggle={this.onDialogDismiss}>{t('admin_period_edit')}</ModalHeader>
        <ModalBody>
          <div className={'editMask'}>
            <p>{t('admin_period_edit_explanation')}</p>
            <FormGroup>
              <Label for="endDate">{t('end_date')}</Label>
              <DateInput
                date={this.state.endDate}
                locked={false}
                id="endDate"
                onInputChange={(date) => this.setState({ endDate: date })}
              />
            </FormGroup>
            {this.renderVatType()}
            {this.renderDialogError()}
            <div className={'buttonBar'}>{this.renderConfirmButton()}</div>
          </div>
        </ModalBody>
      </Modal>
    );
  }

  renderVatType() {
    const { t, selectedPeriod } = this.props;

    if (selectedPeriod.state !== Constants.PERIOD_STATE.OPEN) {
      return <Alert color={'info'}>{t('period_vat_type_change_not_possible')}</Alert>;
    }

    const vatTypes = [
      { value: Constants.VAT_TYPE.SALDO, label: t('proportion') },
      { value: Constants.VAT_TYPE.EFFECTIVE, label: t('effective') },
      { value: Constants.VAT_TYPE.NONE, label: t('none') },
    ];
    const vatType = vatTypes.find((option) => option.value === this.state.vatType);

    return (
      <FormGroup>
        <Label for="vatType">{t('vat_type')}</Label>
        <Select
          id="vatType"
          simpleValue
          options={vatTypes}
          isClearable={false}
          backspaceRemovesValue={false}
          value={vatType}
          onChange={(vatType) => this.setState({ vatType: vatType.value })}
          className="Select"
        />
      </FormGroup>
    );
  }

  onDialogDismiss() {
    this.setState(this.getInitialState(this.props));
  }

  afterPeriodChanged(err) {
    if (err) {
      return this.setState({ editDialogError: true });
    }

    this.setState(this.getInitialState(this.props));
    this.props.remotePeriods.api.fetch();
  }

  getInitialState(props) {
    const endDate = props.selectedPeriod ? props.selectedPeriod.endDate : null;
    const vatType = props.selectedPeriod ? props.selectedPeriod.vatType : null;

    return {
      showEditDialog: false,
      editDialogError: false,
      endDate,
      vatType,
    };
  }

  renderDialogError() {
    if (!this.state.editDialogError) {
      return;
    }
    const { t } = this.props;

    return <Alert color={'danger'}>{t('period_date_change_not_possible')}</Alert>;
  }

  renderConfirmButton() {
    const { t } = this.props;

    const { selectedPeriod } = this.props;
    if (!selectedPeriod) {
      return;
    }

    const hasEndDateChanged = this.props.selectedPeriod.endDate !== this.state.endDate;
    const hasVatTypeChanged = this.props.selectedPeriod.vatType !== this.state.vatType;
    const disabled = !hasEndDateChanged && !hasVatTypeChanged;

    return (
      <Button color="danger" disabled={disabled} onClick={this.changePeriod}>
        {t('change_period')}
      </Button>
    );
  }

  changePeriod() {
    this.props.remoteAdminPeriodEdit.api.create(
      { endDate: this.state.endDate, vatType: this.state.vatType },
      this.afterPeriodChanged
    );
  }
}

AdminPeriodEdit.propTypes = {
  selectedPeriod: PropTypes.object,
  dossier: PropTypes.object.isRequired,
  remoteAdminPeriodEdit: RemoteDataPropType,
  remotePeriods: RemoteDataPropType,
  t: PropTypes.func.isRequired,
};

export default providesAdminPeriodEdit(consumesPeriods(consumesAdminPeriodEdit(translate(AdminPeriodEdit))));
