import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import moment from 'moment';
import Constants from 'config/Constants';
import translate from 'containers/translate';
import DateInput from 'components/dateInput/DateInput';
import PeriodStateEdit from 'components/periodStateEdit/PeriodStateEdit';
import { consumesPeriods } from 'contexts/PeriodsContext';
import { consumesPeriodMetadata } from 'contexts/PeriodMetadataContext';
import RemoteDataPropType from 'lib/prop-types/RemoteDataPropType';
import './PeriodEdit.scss';

class PeriodEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: '',
    };
    this.onPeriodDateEdit = this.onPeriodDateEdit.bind(this);
    this.handlePeriodEnd = this.handlePeriodEnd.bind(this);
    this.onPeriodEndDateYearEdit = this.onPeriodEndDateYearEdit.bind(this);
    this.onPeriodEndDateMonthEdit = this.onPeriodEndDateMonthEdit.bind(this);
  }

  render() {
    const { selectedPeriod, t } = this.props;

    const endMonthOptions = this.getEndMonthOptions();
    const endMonth = moment(selectedPeriod.endDate).month().toString();
    const endMonthValue = endMonthOptions.find((option) => option.value === endMonth);
    const endYearOptions = this.getEndYearOptions();
    const endYear = moment(selectedPeriod.endDate).format('YYYY');
    const endYearValue = endYearOptions.find((option) => option.value === endYear);

    return (
      <div className={'PeriodEdit'}>
        <DateInput
          date={selectedPeriod.startDate}
          locked={this.isStartDateLocked()}
          onInputChange={(date) => this.onPeriodDateEdit(date, 'startDate')}
        />
        <div className={'until'}>
          <span> {t('until')} </span>
        </div>
        <Select
          options={endMonthOptions}
          isClearable={false}
          backspaceRemovesValue={false}
          value={endMonthValue}
          onChange={this.onPeriodEndDateMonthEdit}
          isDisabled={this.isEditLocked()}
          className="Select"
        />
        <Select
          options={endYearOptions}
          isClearable={false}
          value={endYearValue}
          onChange={this.onPeriodEndDateYearEdit}
          isDisabled={this.isEditLocked()}
          className="Select"
        />
        {this.renderPeriodEnd()}
        <span className={'errorMessage'}>{this.state.errorMessage}</span>
      </div>
    );
  }

  getEndMonthOptions() {
    const options = [];

    for (let i = 0; i < 12; i++) {
      options.push({ value: i.toString(), label: moment(i + 1, 'MM').format('MMM') });
    }

    return options;
  }

  getEndYearOptions() {
    const currentEndDate = moment(this.props.selectedPeriod.endDate).format('YYYY');
    const currentYear = moment().format('YYYY');
    const numberOfYearsBack = 5;
    const options = [];

    for (let i = 0; i < numberOfYearsBack; i++) {
      options.push({ value: (currentYear - i).toString(), label: (currentYear - i).toString() });
    }

    if (!options.some((o) => o.value === currentEndDate)) {
      options.push({ value: currentEndDate, label: currentEndDate });
    }

    return options;
  }

  isStartDateLocked() {
    return this.isEditLocked() || !this.props.periodMetadata.isStartDateEditable;
  }

  isEditLocked() {
    const isStateNotOpen = this.props.selectedPeriod.state !== Constants.PERIOD_STATE.OPEN;
    return isStateNotOpen;
  }

  onPeriodDateEdit(date, property) {
    let period = this.props.selectedPeriod;
    const newPeriod = Object.assign({}, period, { [property]: date });

    if (!moment(newPeriod.endDate).isSameOrBefore(moment(newPeriod.startDate))) {
      period = newPeriod;
    } else if (property === 'startDate') {
      newPeriod.endDate = moment(date).add(3, 'months').endOf('month').format('YYYY-MM-DD');
      period = newPeriod;
    }

    this.props.remotePeriods.api.update(period);
  }

  onPeriodEndDateMonthEdit(option) {
    const month = option.value;
    const endDate = moment(this.props.selectedPeriod.endDate).month(month).endOf('month');

    this.onPeriodDateEdit(endDate.format('YYYY-MM-DD'), 'endDate');
  }

  onPeriodEndDateYearEdit(option) {
    const year = option.value;
    const endDate = moment(this.props.selectedPeriod.endDate).year(year).endOf('month');

    this.onPeriodDateEdit(endDate.format('YYYY-MM-DD'), 'endDate');
  }

  renderPeriodEnd() {
    const { t } = this.props;

    const currentState = this.props.selectedPeriod.state;

    if (currentState !== Constants.PERIOD_STATE.CLARIFICATION) {
      return;
    }

    return (
      <PeriodStateEdit
        buttonClassName={'periodEndButton'}
        buttonColor="primary"
        onStateChange={this.handlePeriodEnd}
        buttonLabel={t('end_period')}
        currentState={currentState}
        targetState={Constants.PERIOD_STATE.ARCHIVE}
      />
    );
  }

  handlePeriodEnd() {
    const { t } = this.props;

    const period = Object.assign({}, this.props.selectedPeriod, { state: Constants.PERIOD_STATE.ARCHIVE });
    this.props.remotePeriods.api.update(period, (err) => {
      if (err && err.response && err.response.status === 409) {
        this.setState({ errorMessage: t('err_unable_to_archive_period') });
      }

      if (!err) {
        if (this.props.onPeriodEnd) {
          this.props.onPeriodEnd();
        }
      }
    });
  }
}

PeriodEdit.propTypes = {
  selectedPeriod: PropTypes.object,
  remotePeriods: RemoteDataPropType,
  periodMetadata: PropTypes.object.isRequired,
  isStartDateEditable: PropTypes.bool,
  t: PropTypes.func.isRequired,
  onPeriodEnd: PropTypes.func,
};

export default consumesPeriods(consumesPeriodMetadata(translate(PeriodEdit)));
