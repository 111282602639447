import { withRouter } from 'containers/withRouter';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NewWindow from 'react-new-window';
import { FaEdit } from 'react-icons/fa/';
import absoluteURL from 'lib/absoluteURL';
import Document from 'components/document/Document';
import withCompanies from 'containers/withCompanies';
import { providesBankingTransactions } from 'contexts/BankingTransactionsContext';
import { consumesDocument, providesDocument } from 'contexts/DocumentContext';
import RemoteDataPropType from 'lib/prop-types/RemoteDataPropType';
import { providesUsedCompanies } from 'contexts/UsedCompaniesContext';
import './DocumentWindow.scss';

const WINDOW_FEATURES = {
  width: 1200,
  height: 1000,
};

const WINDOW_NAVIGATION_DISMISS_DELAY = 500;

class DocumentWindow extends Component {
  constructor(props) {
    super(props);

    this.handleEditClick = this.handleEditClick.bind(this);
  }

  handleEditClick() {
    //Firefox will not properly navigate if we dismiss the popup before the link was opened.
    setTimeout(() => {
      this.props.onDismiss();
    }, WINDOW_NAVIGATION_DISMISS_DELAY);
  }

  renderDocument() {
    if (this.props.remoteDocument.isLoading) {
      return;
    }

    //Make sure the URL is always absolute to make it work inside the popup.
    const href = absoluteURL(this.props.history.resolveDossierURL(`/edit/${this.props.document.id}`));

    return (
      <div className="DocumentWindow">
        <a target={'_blank'} href={href} onClick={this.handleEditClick} rel="noreferrer">
          <FaEdit size={20} className="editIcon" />
        </a>
        <hr />
        <Document companyNames={this.props.companyNames} locked={true} inFlexbox={false} />
      </div>
    );
  }

  render() {
    return (
      <NewWindow
        name="lea-document"
        title="LEA"
        features={WINDOW_FEATURES}
        onUnload={() => this.props.onDismiss()}
        onBlock={() => this.props.onDismiss()}
      >
        {this.renderDocument()}
      </NewWindow>
    );
  }
}

DocumentWindow.propTypes = {
  document: PropTypes.object,
  remoteDocument: RemoteDataPropType,
  onDismiss: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  companyNames: PropTypes.array.isRequired,
};

//providesDocument needs to be the outer-most HoC because it needs the documentId prop.
export default providesUsedCompanies(
  providesDocument(consumesDocument(providesBankingTransactions(withCompanies(withRouter(DocumentWindow)))))
);
