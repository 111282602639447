import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import { Badge, Button, Container, Input, Table } from 'reactstrap';
import MultiToggle from 'react-multi-toggle';
import RemoteDataPropType from 'lib/prop-types/RemoteDataPropType';
import translate from 'containers/translate';
import AddDossier from 'components/addDossier/AddDossier';
import Constants from 'config/Constants';
import { consumesDossiers, providesDossiers } from 'contexts/DossiersContext';
import { consumesAggregatedDossiers, providesAggregatedDossiers } from 'contexts/AggregatedDossiersContext';
import normalizeFilterText from 'lib/normalizeFilterText.js';
import './DossierList.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const MAX_INITIAL_RESULTS = 50;

class DossierList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAddDossier: false,
      showTestDossiers: false,
      showAllDossiers: false,
      nameFilter: '',
      periodFilter: '',
      filteredDossiers: [],
      slicedDossiers: [],
    };

    this.handlePeriodFilterChange = this.handlePeriodFilterChange.bind(this);
    this.handleNameFilterChange = this.handleNameFilterChange.bind(this);
  }

  static getDerivedStateFromProps({ dossiers }, state) {
    let filteredDossiers = dossiers.filter((d) => d.isTest === state.showTestDossiers);

    if (state.periodFilter !== '') {
      filteredDossiers = filteredDossiers.filter((d) => {
        return d.currentPeriod.startDate === state.periodFilter;
      });
    }

    if (state.nameFilter !== '') {
      let normalizedNameFilter = normalizeFilterText(state.nameFilter);

      filteredDossiers = filteredDossiers.filter((d) => {
        return !!d.name && normalizeFilterText(d.name).includes(normalizedNameFilter);
      });
    }

    filteredDossiers = filteredDossiers.sort((a, b) => {
      let aName = '';
      if (a.name) {
        aName = a.name;
      }
      return aName.localeCompare(b.name);
    });

    let slicedDossiers = filteredDossiers;

    if (!state.showAllDossiers) {
      slicedDossiers = slicedDossiers.slice(0, MAX_INITIAL_RESULTS);
    }

    return { filteredDossiers, slicedDossiers };
  }

  handlePeriodFilterChange(e) {
    this.setState({ periodFilter: e.target.value });
  }

  handleNameFilterChange(e) {
    this.setState({ nameFilter: e.target.value });
  }
  render() {
    const { t } = this.props;

    return (
      <div className={'DossierList'}>
        <h2>{t('dossiers')}</h2>
        {this.renderAddDossier()}
        <MultiToggle
          options={[
            {
              displayName: t('active_dossiers'),
              value: false,
            },
            {
              displayName: t('test_dossiers'),
              value: true,
            },
          ]}
          selectedOption={this.state.showTestDossiers}
          onSelectOption={(showTestDossiers) => this.setState({ showTestDossiers })}
        />
        <Container>
          <Table>
            <thead>
              <tr>
                <th>
                  <div>{t('name')}</div>
                  {this.renderDossierFilter()}
                </th>
                <th>{t('period_state')}</th>
                <th>
                  <div>{t('period')}</div>
                  {this.renderPeriodFilter()}
                </th>
                <th>{t('rule_correctness')}</th>
              </tr>
            </thead>
            <tbody>{this.renderDossiers()}</tbody>
          </Table>
          {this.renderShowAllButton()}
        </Container>
      </div>
    );
  }

  renderDossierFilter() {
    return (
      <>
        <div className="search-box">
          <div className="search-icon">
            <FontAwesomeIcon icon={faSearch} />
          </div>
          <Input
            className="input_search"
            placeholder="search customers"
            type="search"
            value={this.state.nameFilter}
            onChange={this.handleNameFilterChange}
          />
        </div>
      </>
    );
  }

  renderPeriodFilter() {
    const { t } = this.props;

    const uniquePeriods = Array.from(new Set(this.props.dossiers.map((d) => d.currentPeriod.startDate))).sort();

    return (
      <select
        className="form-control"
        value={this.state.periodFilter}
        onChange={this.handlePeriodFilterChange}
        disabled={this.props.dossiers.length === 0}
      >
        <option value="">{t('select_all')}</option>
        {uniquePeriods.map((p) => {
          return (
            <option key={p} value={p}>
              {moment(p).format('MMM YYYY')}
            </option>
          );
        })}
      </select>
    );
  }

  renderShowAllButton() {
    if (this.state.showAllDossiers) {
      return '';
    }

    if (this.state.filteredDossiers.length <= MAX_INITIAL_RESULTS) {
      return '';
    }

    return (
      <p>
        <Button color="primary" onClick={() => this.setState({ showAllDossiers: true })}>
          + {this.state.filteredDossiers.length - MAX_INITIAL_RESULTS}
        </Button>
      </p>
    );
  }

  renderAddDossier() {
    const { t } = this.props;

    if (this.state.showAddDossier) {
      return (
        <div>
          <AddDossier
            presetName=""
            onCreate={(dossier) => {
              this.props.remoteDossiers.api.create(dossier);
            }}
          />
        </div>
      );
    } else {
      return (
        <Button className={'addDossier'} onClick={() => this.setState({ showAddDossier: true })} color={'link'}>
          {t('add_dossier')}
        </Button>
      );
    }
  }

  renderDossiers() {
    return this.state.slicedDossiers.map((dossier) => {
      return (
        <tr key={dossier.id}>
          <td>
            <NavLink to={`/dossier/${dossier.id}/${dossier.currentPeriod.id}/upload`}>
              <span>{dossier.name} </span>
            </NavLink>
          </td>
          <td>{this.renderBadges(dossier)}</td>
          <td>
            <Moment date={dossier.currentPeriod.startDate} format="MMM YYYY" />
            {this.renderHyphen(dossier)}
            {this.renderEndDate(dossier)}
          </td>
          <td>{this.renderCorrectRules(dossier)}</td>
        </tr>
      );
    });
  }

  renderBadges(dossier) {
    const { t } = this.props;
    const { state } = dossier.currentPeriod;
    const highlightClosed = this.isDossierClosed(dossier);
    const highlightClarification = highlightClosed || state === Constants.PERIOD_STATE.CLARIFICATION;
    const highlightInProgress = highlightClarification || state === Constants.PERIOD_STATE.ACTIVE;
    const highlightActive = highlightInProgress || this.hasBankingDocumentsInPeriod(dossier);
    let activeColor;

    switch (highlightActive + highlightInProgress + highlightClarification + highlightClosed) {
      case 1:
        activeColor = 'state-active';
        break;
      case 2:
        activeColor = 'state-progress';
        break;
      case 3:
        activeColor = 'state-clarification';
        break;
      case 4:
        activeColor = 'state-finished';
        break;
      default:
        activeColor = 'light';
        break;
    }

    return (
      <React.Fragment>
        <Badge color={highlightActive ? activeColor : 'light'}>{t('active')}</Badge>
        <Badge color={highlightInProgress ? activeColor : 'light'}>{t('period_active_state')}</Badge>
        <Badge color={highlightClarification ? activeColor : 'light'}>{t('clarification')}</Badge>
        <Badge color={highlightClosed ? activeColor : 'light'}>{t('finished')}</Badge>
      </React.Fragment>
    );
  }

  hasBankingDocumentsInPeriod(dossier) {
    return this.props.aggregatedDossiers.hasBankingDocumentsInPeriodsByPeriod[dossier.currentPeriod.id];
  }

  isDossierClosed(dossier) {
    const { state } = dossier.currentPeriod;
    return state === Constants.PERIOD_STATE.OPEN && !this.hasBankingDocumentsInPeriod(dossier);
  }

  renderHyphen(dossier) {
    return this.isDossierClosed(dossier) ? '' : ' - ';
  }

  renderEndDate(dossier) {
    if (this.isDossierClosed(dossier)) {
      return;
    }

    return <Moment date={dossier.currentPeriod.endDate} format="MMM YYYY" />;
  }

  renderCorrectRules(dossier) {
    let percentage = this.props.aggregatedDossiers.percentageOfCorrectRulesByDossier[dossier.id];

    if (percentage) {
      return `${percentage / 100}%`;
    }

    return '';
  }
}

DossierList.propTypes = {
  remoteDossiers: RemoteDataPropType,
  dossiers: PropTypes.arrayOf(PropTypes.object).isRequired,
  aggregatedDossiers: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default providesDossiers(
  providesAggregatedDossiers(consumesDossiers(consumesAggregatedDossiers(translate(DossierList))))
);
