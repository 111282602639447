import keyboardJS from 'keyboardjs';

class GlobalKeyRegistry {
  constructor() {
    keyboardJS.bind('esc', (e) => this._triggerBlur(e));
    this._contextStack = [];
  }

  register(key, downCallback, upCallback) {
    if (upCallback) {
      return keyboardJS.bind(
        key,
        (e) => this._handleEvent(e, downCallback),
        (e) => this._handleEvent(e, upCallback)
      );
    } else {
      return keyboardJS.bind(key, (e) => this._handleEvent(e, downCallback));
    }
  }

  deregister(key) {
    keyboardJS.unbind(key);
  }

  pushContext(contextName) {
    this._contextStack.push(contextName);
    keyboardJS.setContext(contextName);
  }

  popContext() {
    this._contextStack.pop();

    if (this._contextStack.length === 0) {
      keyboardJS.setContext('global');
    } else {
      keyboardJS.setContext(this._contextStack[this._contextStack.length - 1]);
    }
  }

  _handleEvent(e, callback) {
    const ignoredInputTypes = ['INPUT', 'TEXTAREA', 'SELECT', 'OPTION'];
    if (!ignoredInputTypes.includes(e.target.tagName.toUpperCase())) {
      callback(e);
      e.preventDefault();
    }
  }

  _triggerBlur(e) {
    if (document.activeElement && !document.activeElement.classList.contains('modal')) {
      document.activeElement.blur();
      e.preventDefault();
    }
  }
}

export default new GlobalKeyRegistry();
