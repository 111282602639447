import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalHeader, Badge, FormGroup, Input } from 'reactstrap';
import clone from 'clone';
import RemoteDataPropType from 'lib/prop-types/RemoteDataPropType';
import AccountEdit from 'components/accountEdit/AccountEdit';
import VatSelect from 'components/vatSelect/VatSelect';
import translate from 'containers/translate';
import { consumesDossier } from 'contexts/DossierContext';
import { consumesGlobalMutation, providesGlobalMutation } from 'contexts/GlobalMutationContext';
import { consumesAccounts, providesAccounts } from 'contexts/AccountsContext';
import CompanyNamePicker from 'components/CompanyNamePicker';
import { consumesUsedCompanies, providesUsedCompanies } from 'contexts/UsedCompaniesContext';
import withCompanies from 'containers/withCompanies';
import './GlobalMutation.scss';

class GlobalMutation extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();

    this.onDialogDismiss = this.onDialogDismiss.bind(this);
    this.handleVatChange = this.handleVatChange.bind(this);
    this.applyMutation = this.applyMutation.bind(this);
  }

  onDialogDismiss() {
    this.setState(this.getInitialState());
  }

  getInitialState() {
    return {
      showMutationDialog: false,
      account: '1000',
      vat: 810,
      vatType: 'NORMAL',
      companyName: '',
      accountingText: '',
      properties: {
        account: true,
        vat: false,
        companyName: false,
        accountingText: false,
      },
    };
  }

  handleVatChange(value) {
    if (this.isNumeric(value)) {
      this.setVatToState(value, 'NORMAL');
    } else {
      this.setVatToState(0, value);
    }
  }

  isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  setVatToState(vat, vatType) {
    this.setState({
      vat,
      vatType,
    });
  }

  changeMutationProps(property) {
    const properties = clone(this.state.properties);
    properties[property] = !properties[property];

    this.setState({ properties });
  }

  applyMutation() {
    const patch = this.createPatch();
    const documentIds = this.getDocumentIds();

    const body = { patch, documentIds };

    this.props.remoteGlobalMutation.api.create(body, () => this.setState(this.getInitialState()));
  }

  createPatch() {
    const patch = {};
    if (this.state.properties.account) {
      patch.account = this.state.account;
    }
    if (this.state.properties.vat) {
      patch.vat = this.state.vat;
      patch.vatType = this.state.vatType;
    }
    if (this.state.properties.companyName) {
      patch.companyName = this.state.companyName;
    }
    if (this.state.properties.accountingText) {
      patch.accountingText = this.state.accountingText;
    }
    return patch;
  }

  getDocumentIds() {
    return this.props.visibleTransactions.reduce((acc, t) => {
      const ids = t.accountingDocuments.map((d) => d.id);
      acc = acc.concat(ids);
      return acc;
    }, []);
  }

  renderGlobalMutationDialog() {
    const { t } = this.props;

    if (!this.state.showMutationDialog) {
      return null;
    }

    return (
      <Modal isOpen className="ListExportSelection" size="lg" toggle={this.onDialogDismiss}>
        <ModalHeader toggle={this.onDialogDismiss}>{t('global_mutation')}</ModalHeader>
        <ModalBody>
          <div className={'editMask'}>
            <p>{t('global_mutation_explanation')}</p>
            {this.renderPropertiesBadges()}
            <div className={'propertiesEdit'}>
              {this.renderAccountEdit()}
              {this.renderVatSelect()}
              {this.renderCompanySelect()}
              {this.renderAccountingText()}
            </div>
            <div className={'buttonBar'}>{this.renderConfirmButton()}</div>
          </div>
        </ModalBody>
      </Modal>
    );
  }

  renderPropertiesBadges() {
    const { t } = this.props;

    return (
      <React.Fragment>
        <FormGroup check inline>
          <Badge
            onClick={() => this.changeMutationProps('account')}
            color={this.state.properties.account ? 'success' : 'secondary'}
          >
            {t('account')}
          </Badge>
        </FormGroup>
        <FormGroup check inline>
          <Badge
            onClick={() => this.changeMutationProps('vat')}
            color={this.state.properties.vat ? 'success' : 'secondary'}
          >
            {t('vat')}
          </Badge>
        </FormGroup>
        <FormGroup check inline>
          <Badge
            onClick={() => this.changeMutationProps('companyName')}
            color={this.state.properties.companyName ? 'success' : 'secondary'}
          >
            {t('company')}
          </Badge>
        </FormGroup>
        <FormGroup check inline>
          <Badge
            onClick={() => this.changeMutationProps('accountingText')}
            color={this.state.properties.accountingText ? 'success' : 'secondary'}
          >
            {t('accounting_text')}
          </Badge>
        </FormGroup>
      </React.Fragment>
    );
  }

  renderAccountEdit() {
    if (!this.state.properties.account) {
      return null;
    }

    return (
      <AccountEdit
        onAccountChange={(account) => this.setState({ account })}
        account={this.state.account}
        setFocusOnMount={true}
        accountBlacklist={this.props.accounts.filter((a) => a.hasDocument)}
      />
    );
  }

  renderVatSelect() {
    if (!this.state.properties.vat) {
      return null;
    }

    return (
      <VatSelect
        locked={false}
        dossier={this.props.dossier}
        onVatChange={this.handleVatChange}
        presetVat={this.state.vat}
        presetVatType={this.state.vatType}
      />
    );
  }

  renderCompanySelect() {
    if (!this.state.properties.companyName) {
      return null;
    }

    return (
      <CompanyNamePicker
        presetCompanyName={this.state.companyName}
        allCompanyNames={this.props.companyNames}
        usedCompanyNames={this.props.usedCompanyNames}
        onCompanyNameChange={(name) => this.setState({ companyName: name })}
        clearable={false}
        disabled={false}
      />
    );
  }

  renderAccountingText() {
    const { t } = this.props;

    if (!this.state.properties.accountingText) {
      return null;
    }

    return (
      <Input
        value={this.state.accountingText}
        onChange={(e) => this.setState({ accountingText: e.target.value })}
        disabled={false}
        placeholder={t('accounting_text')}
      />
    );
  }

  renderConfirmButton() {
    const { t } = this.props;

    const { account, vat, companyName, accountingText } = this.state.properties;

    const disabled = !account && !vat && !companyName && !accountingText;

    return (
      <Button color="danger" disabled={disabled} onClick={this.applyMutation}>
        {t('apply_mutation')}
      </Button>
    );
  }

  render() {
    const { t } = this.props;

    return (
      <div className={'GlobalMutation'}>
        {this.renderGlobalMutationDialog()}
        <Button color="warning" onClick={() => this.setState({ showMutationDialog: true })}>
          {t('global_mutation')}
        </Button>
      </div>
    );
  }
}

GlobalMutation.propTypes = {
  usedCompanyNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  companyNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  dossier: PropTypes.object.isRequired,
  visibleTransactions: PropTypes.arrayOf(PropTypes.object).isRequired,
  accounts: PropTypes.arrayOf(PropTypes.object).isRequired,
  remoteGlobalMutation: RemoteDataPropType,
  t: PropTypes.func.isRequired,
};

export default withCompanies(
  providesAccounts(
    providesUsedCompanies(
      consumesUsedCompanies(
        consumesAccounts(providesGlobalMutation(consumesGlobalMutation(consumesDossier(translate(GlobalMutation)))))
      )
    )
  )
);
