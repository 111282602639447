import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Badge, Form, FormGroup, Input, Label } from 'reactstrap';
import clone from 'clone';

class BankingTransactionFilter extends Component {
  toggleType(type) {
    const filter = clone(this.props.filter);

    const newShowType = Object.assign({}, filter.showType);
    newShowType[type] = !newShowType[type];
    filter.showType = newShowType;
    this.props.onFilterChange(filter);
  }

  toggleState(state) {
    const filter = clone(this.props.filter);

    const newShowState = Object.assign({}, filter.showState);
    newShowState[state] = !newShowState[state];
    filter.showState = newShowState;
    this.props.onFilterChange(filter);
  }

  handleInputChange(key, e) {
    const filter = clone(this.props.filter);

    filter[key] = e.target.value;
    this.props.onFilterChange(filter);
  }

  renderFilterBar() {
    if (!this.props.showFilter) {
      return <tr />;
    }

    const { t } = this.props;

    return (
      <tr>
        <td colSpan="2">
          <Form onSubmit={(e) => e.preventDefault()}>
            <div className="form-row">
              <FormGroup className="col-md-6">
                <Label for="bankingTransactionFilterDateFrom">{t('date_from')}</Label>
                <Input
                  type="text"
                  name="date-from"
                  id="bankingTransactionFilterDateFrom"
                  value={this.props.filter.dateFrom}
                  placeholder="DD.MM.YYYY"
                  onChange={this.handleInputChange.bind(this, 'dateFrom')}
                />
              </FormGroup>
              <FormGroup className="col-md-6">
                <Label for="bankingTransactionFilterDateTo">{t('date_to')}</Label>
                <Input
                  type="text"
                  name="date-to"
                  id="bankingTransactionFilterDateTo"
                  value={this.props.filter.dateTo}
                  placeholder="DD.MM.YYYY"
                  onChange={this.handleInputChange.bind(this, 'dateTo')}
                />
              </FormGroup>
            </div>
            <div className="form-row">
              <FormGroup className="col-md-6">
                <Label for="bankingTransactionFilterAmountFrom">{t('amount_from')}</Label>
                <Input
                  type="text"
                  name="amount-from"
                  id="bankingTransactionFilterAmountFrom"
                  value={this.props.filter.amountFrom}
                  onChange={this.handleInputChange.bind(this, 'amountFrom')}
                />
              </FormGroup>
              <FormGroup className="col-md-6">
                <Label for="bankingTransactionFilterAmountTo">{t('amount_to')}</Label>
                <Input
                  type="text"
                  name="amount-to"
                  id="bankingTransactionFilterAmountTo"
                  value={this.props.filter.amountTo}
                  onChange={this.handleInputChange.bind(this, 'amountTo')}
                />
              </FormGroup>
            </div>
            <FormGroup>
              <Label for="bankingTransactionFilterDescription">{t('description')}</Label>
              <Input
                type="text"
                name="description"
                id="bankingTransactionFilterDescription"
                value={this.props.filter.description}
                onChange={this.handleInputChange.bind(this, 'description')}
              />
            </FormGroup>
            <FormGroup check inline>
              <Badge
                onClick={() => this.toggleType('credit')}
                color={this.props.filter.showType.credit ? 'success' : 'secondary'}
              >
                {t('credit_transaction')}
              </Badge>
            </FormGroup>
            <FormGroup check inline>
              <Badge
                onClick={() => this.toggleType('debit')}
                color={this.props.filter.showType.debit ? 'success' : 'secondary'}
              >
                {t('debit_transaction')}
              </Badge>
            </FormGroup>
            <br />
            <br />
            <FormGroup check inline>
              <Badge
                onClick={() => this.toggleState('open')}
                color={this.props.filter.showState.open ? 'success' : 'secondary'}
              >
                {t('open')}
              </Badge>
            </FormGroup>
            <FormGroup check inline>
              <Badge
                onClick={() => this.toggleState('confirmed')}
                color={this.props.filter.showState.confirmed ? 'success' : 'secondary'}
              >
                {t('confirmed')}
              </Badge>
            </FormGroup>
            <FormGroup check inline>
              <Badge
                onClick={() => this.toggleState('unconfirmed')}
                color={this.props.filter.showState.unconfirmed ? 'success' : 'secondary'}
              >
                {t('unconfirmed')}
              </Badge>
            </FormGroup>
          </Form>
        </td>
      </tr>
    );
  }

  render() {
    return this.renderFilterBar();
  }
}

BankingTransactionFilter.propTypes = {
  t: PropTypes.func.isRequired,
  showFilter: PropTypes.bool.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
};

export default BankingTransactionFilter;
