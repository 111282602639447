import React, { Component } from 'react';
import './UserManual.scss';

export default class UserManual extends Component {
  render() {
    return (
      <div className={'UserManual'}>
        <h2>Shortcuts</h2>
        <dl>
          <dt>a</dt>
          <dd>
            Löschen (<strong>A</strong>blehnen)
          </dd>
          <dt>b</dt>
          <dd>
            <strong>B</strong>estätigen
          </dd>
          <dt>o</dt>
          <dd>
            <strong>O</strong>pen (Unlock, Öffnen)
          </dd>
          <dt>t</dt>
          <dd>
            <strong>T</strong>otal Betrag fokussieren
          </dd>
          <dt>f</dt>
          <dd>
            Rechnungssteller (<strong>F</strong>irmenname) fokussieren
          </dd>
          <dt>Pfeiltasten links / rechts</dt>
          <dd>Navigation durch Dokumente</dd>
        </dl>
      </div>
    );
  }
}
