import React from 'react';
import RemoteDataProvider from './RemoteDataProvider';

const GlobalMutationContext = React.createContext();

function providesGlobalMutation(Component) {
  class RemoteGlobalMutation extends React.Component {
    render() {
      return (
        <RemoteDataProvider
          baseURL="/global-mutation"
          render={(remoteData) => {
            return (
              <GlobalMutationContext.Provider value={remoteData}>
                <Component {...this.props} />
              </GlobalMutationContext.Provider>
            );
          }}
        />
      );
    }
  }
  return RemoteGlobalMutation;
}

function consumesGlobalMutation(Component, provideValueAs = 'globalMutation') {
  return function ComponentThatConsumesGlobalMutation(props) {
    return (
      <GlobalMutationContext.Consumer>
        {(remoteData) => {
          if (!remoteData) {
            return <Component {...props} />;
          }
          const consumedProps = {
            remoteGlobalMutation: remoteData,
            [provideValueAs]: remoteData.value,
          };

          return <Component {...props} {...consumedProps} />;
        }}
      </GlobalMutationContext.Consumer>
    );
  };
}

export { providesGlobalMutation, consumesGlobalMutation };
