import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import ExportStep from './steps/exportStep/ExportStep';
import UploadStep from './steps/uploadStep/UploadStep';
import BankingDocumentsStep from './steps/BankingDocumentsStep/BankingDocumentsStep';
import EditStep from './steps/EditStep/EditStep';
import ControlStep from './steps/controlStep/ControlStep';
import RuleOverview from './ruleOverview/RuleOverview';
import AccountOverview from './accountOverview/AccountOverview';
import { consumesDossier } from 'contexts/DossierContext';
import { consumesPeriods } from 'contexts/PeriodsContext';
import RemoteDataPropType from 'lib/prop-types/RemoteDataPropType';
import DossierDelete from './dossierDelete/DossierDelete';
import DossierStatistic from './dossierStatistic/DossierStatistic';
import TemplateList from './templateList/TemplateList';

class Steps extends Component {
  render() {
    if (this.props.remoteDossier.isLoading || this.props.remotePeriods.isLoading) {
      return <div>Loading</div>;
    }

    return (
      <Routes>
        <Route path={'/upload'} element={<UploadStep />}></Route>
        <Route path={'/edit'} element={<EditStep />}></Route>
        <Route path={'/edit/:documentId'} element={<EditStep />}></Route>
        <Route path={'/edit/:documentId/:transactionId'} element={<EditStep />}></Route>
        <Route path={'/banking-documents'} element={<BankingDocumentsStep />}></Route>
        <Route path={'/banking-documents/:documentId'} element={<BankingDocumentsStep />}></Route>
        <Route path={'/export'} element={<ExportStep />}></Route>
        <Route path={'/control'} element={<ControlStep />}></Route>
        <Route path={'/rules'} element={<RuleOverview />}></Route>
        <Route path={'/accounts'} element={<AccountOverview />}></Route>
        <Route path={'/templates'} element={<TemplateList />}></Route>
        <Route path={'/delete'} element={<DossierDelete />}></Route>
        <Route path={'/statistic'} element={<DossierStatistic />}></Route>
      </Routes>
    );
  }
}

Steps.propTypes = {
  remoteDossier: RemoteDataPropType,
  remotePeriods: RemoteDataPropType,
};

export default consumesDossier(consumesPeriods(Steps));
