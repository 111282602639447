import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import translate from 'containers/translate';
import withDocumentCount from 'containers/withDocumentCount';
import './DocumentCount.scss';

class DocumentCount extends Component {
  render() {
    const { t } = this.props;

    const documentLabel = ' ' + t('documents');
    const transactionLabel = ' ' + t('banking_transactions');

    const accountingDocuments = this.props.count.accountingDocuments || {};
    const bankingTransactions = this.props.count.bankingTransactions || {};

    const openDocuments = accountingDocuments.open || '';
    const openBankingTransaction = bankingTransactions.open || '';

    const verifiedDocuments = accountingDocuments.verified || '';
    const verifiedBankingTransactions = 0;

    const confirmedDocuments = accountingDocuments.confirmed || '';
    const confirmedBankingTransactions = bankingTransactions.confirmed || '';

    const rejectedDocuments = accountingDocuments.rejected || '';
    const rejectedBankingTransactions = bankingTransactions.rejected || '';

    const archivedDocuments = accountingDocuments.archived || '';
    const archivedBankingTransactions = bankingTransactions.archived || '';

    return (
      <div className={'DocumentCount'}>
        <Table>
          <tbody>
            <tr>
              <td>
                <b>{t('open')}</b>
              </td>
              <td>{openDocuments}</td>
              <td>{documentLabel}</td>
              <td>{openBankingTransaction}</td>
              <td>{transactionLabel}</td>
            </tr>
            <tr>
              <td>
                <b>{t('verified')}</b>
              </td>
              <td>{verifiedDocuments}</td>
              <td>{documentLabel}</td>
              <td>{verifiedBankingTransactions}</td>
              <td>{transactionLabel}</td>
            </tr>
            <tr>
              <td>
                <b>{t('confirmed')}</b>
              </td>
              <td>{confirmedDocuments}</td>
              <td>{documentLabel}</td>
              <td>{confirmedBankingTransactions}</td>
              <td>{transactionLabel}</td>
            </tr>
            <tr>
              <td>
                <b>{t('deleted')}</b>
              </td>
              <td>{rejectedDocuments}</td>
              <td>{documentLabel}</td>
              <td>{rejectedBankingTransactions}</td>
              <td>{transactionLabel}</td>
            </tr>
            <tr>
              <td>
                <b>{t('archived')}</b>
              </td>
              <td>{archivedDocuments}</td>
              <td>{documentLabel}</td>
              <td>{archivedBankingTransactions}</td>
              <td>{transactionLabel}</td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  }
}

DocumentCount.propTypes = {
  t: PropTypes.func.isRequired,
  count: PropTypes.object.isRequired,
};

export default withDocumentCount(translate(DocumentCount));
