import React from 'react';
import { NavLink as RRNavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import { Nav, NavItem, DropdownItem, NavLink } from 'reactstrap';
import Constants from 'config/Constants';
import { withRouter } from 'containers/withRouter';
import translate from 'containers/translate';
import Navigation from './Navigation';
import RRNavLinkWithActiveClass from './RRNavLinkWithActiveClass';
import { consumesDossier } from 'contexts/DossierContext';
import { consumesPeriods } from 'contexts/PeriodsContext';
import RemoteDataPropType from 'lib/prop-types/RemoteDataPropType';

class NavigationDossier extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.handlePeriodChange = this.handlePeriodChange.bind(this);
  }

  handlePeriodChange(e) {
    const periodId = parseInt(e.target.value, 10);
    this.props.history.replacePeriod(periodId);
  }

  renderDateText(period) {
    const startDate = period.startDate;
    const endDate = period.endDate;
    const startDateText = this.formatDate(startDate);
    const endDateText = this.formatDate(endDate);

    return `${startDateText}-${endDateText}`;
  }

  formatDate(date) {
    const momentDate = moment(date);
    return momentDate.format('DD.MM.YYYY');
  }

  renderPeriodState(period) {
    const { t } = this.props;

    const state = period.state;
    if (state === Constants.PERIOD_STATE.OPEN) {
      return t('period_open_state');
    } else if (state === Constants.PERIOD_STATE.ACTIVE) {
      return t('period_active_state');
    } else if (state === Constants.PERIOD_STATE.CLARIFICATION) {
      return t('period_clarification_state');
    } else if (state === Constants.PERIOD_STATE.ARCHIVE) {
      return t('period_archive_state');
    }
  }

  renderExportNavigationItem() {
    const { t } = this.props;

    const currentState = this.props.selectedPeriod.state;
    if (currentState === Constants.PERIOD_STATE.OPEN) {
      return null;
    }

    return (
      <React.Fragment>
        <NavItem>
          <NavLink
            tag={RRNavLinkWithActiveClass}
            to={`/dossier/${this.props.dossier.id}/${this.props.selectedPeriod.id}/export${this.props.location.search}`}
          >
            {t('to_export')}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            tag={RRNavLinkWithActiveClass}
            to={`/dossier/${this.props.dossier.id}/${this.props.selectedPeriod.id}/control`}
          >
            {t('to_control')}
          </NavLink>
        </NavItem>
      </React.Fragment>
    );
  }

  renderPeriodsDropdown() {
    return (
      <div className="form-group">
        <select
          className="form-control form-control-sm"
          value={this.props.selectedPeriod.id}
          onChange={this.handlePeriodChange}
        >
          {this.props.periods.map((period) => (
            <option key={period.id} value={period.id}>
              {this.renderDateText(period)} ({this.renderPeriodState(period)})
            </option>
          ))}
        </select>
      </div>
    );
  }

  renderWorkflow() {
    const { t } = this.props;
    const dossierPropsClass = classnames('dossierProps navbar-text', {
      'is-archived': this.props.selectedPeriod.state === Constants.PERIOD_STATE.ARCHIVE,
    });

    return (
      <Nav navbar>
        <NavItem>
          <NavLink
            tag={RRNavLinkWithActiveClass}
            to={`/dossier/${this.props.dossier.id}/${this.props.selectedPeriod.id}/upload${this.props.location.search}`}
          >
            {t('upload')}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            tag={RRNavLinkWithActiveClass}
            to={`/dossier/${this.props.dossier.id}/${this.props.selectedPeriod.id}/banking-documents${this.props.location.search}`}
          >
            {t('edit_banking_documents')}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            tag={RRNavLinkWithActiveClass}
            to={`/dossier/${this.props.dossier.id}/${this.props.selectedPeriod.id}/edit${this.props.location.search}`}
          >
            {t('book')}
          </NavLink>
        </NavItem>
        {this.renderExportNavigationItem()}
        <div className={dossierPropsClass}>
          <b>{this.props.dossier.name}</b> {t('period')}: {this.renderPeriodsDropdown()}
        </div>
      </Nav>
    );
  }

  renderRuleOverview() {
    const { t } = this.props;

    return (
      <DropdownItem tag={RRNavLink} to={`/dossier/${this.props.dossier.id}/${this.props.selectedPeriod.id}/rules`}>
        {t('edit_rules')}
      </DropdownItem>
    );
  }

  renderAccountOverview() {
    const { t } = this.props;

    return (
      <DropdownItem tag={RRNavLink} to={`/dossier/${this.props.dossier.id}/${this.props.selectedPeriod.id}/accounts`}>
        {t('edit_accounts')}
      </DropdownItem>
    );
  }

  renderTemplateOverview() {
    const { t } = this.props;

    return (
      <DropdownItem tag={RRNavLink} to={`/dossier/${this.props.dossier.id}/${this.props.selectedPeriod.id}/templates`}>
        {t('edit_templates')}
      </DropdownItem>
    );
  }

  renderDeleteDossier() {
    const { t } = this.props;

    return (
      <DropdownItem tag={RRNavLink} to={`/dossier/${this.props.dossier.id}/${this.props.selectedPeriod.id}/delete`}>
        {t('dossier_delete')}
      </DropdownItem>
    );
  }

  renderStatistic() {
    const { t } = this.props;

    return (
      <DropdownItem tag={RRNavLink} to={`/dossier/${this.props.dossier.id}/${this.props.selectedPeriod.id}/statistic`}>
        {t('dossier_statistic')}
      </DropdownItem>
    );
  }

  render() {
    if (this.props.remoteDossier.isLoading || this.props.remotePeriods.isLoading) {
      return <Navigation />;
    }

    const dropdownItems = (
      <React.Fragment>
        {this.renderRuleOverview()}
        {this.renderAccountOverview()}
        {this.renderTemplateOverview()}
        {this.renderDeleteDossier()}
        {this.renderStatistic()}
      </React.Fragment>
    );

    return <Navigation dropdownItems={dropdownItems}>{this.renderWorkflow()}</Navigation>;
  }
}

NavigationDossier.propTypes = {
  t: PropTypes.func.isRequired,
  dossier: PropTypes.object,
  remoteDossier: RemoteDataPropType,
  remotePeriods: RemoteDataPropType,
  periods: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedPeriod: PropTypes.object,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default consumesDossier(consumesPeriods(withRouter(translate(NavigationDossier))));
