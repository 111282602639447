import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Creatable from 'react-select/creatable';
import Select from 'react-select';
import chartOfAccounts from 'data/chartOfAccounts.json';
import translate from 'containers/translate';
import './AccountEdit.scss';

const ONLY_DIGITS_REGEX = /^\d+$/;

class AccountEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: '',
      account: props.account,
      matchPos: 'any',
    };

    this.onBlur = this.onBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.account === prevProps.account) {
      return;
    }

    this.setState({
      account: this.props.account,
    });
  }

  componentDidMount() {
    if (this.props.setFocusOnMount) {
      this.input && this.input.focus();
    }
  }

  render() {
    return (
      <div className="AccountEdit">
        {this.renderSelect()}
        {this.renderErrorMessage()}
      </div>
    );
  }

  renderSelect() {
    const SelectOrCreateable = this.props.creatable ? Creatable : Select;
    const options = this.getOptions().map((option) => {
      return {
        value: option.value,
        label: `${option.value} -  ${option.label}`,
      };
    });
    const value = options.find((option) => option.value === this.state.account);

    return (
      <SelectOrCreateable
        type="number"
        value={value}
        onChange={this.handleChange}
        onBlur={this.onBlur}
        onInputChange={this.handleInputChange}
        ref={(input) => (this.input = input)}
        options={options}
        isClearable={false}
        matchPos={this.state.matchPos}
        isDisabled={this.props.locked}
        className="Select"
      />
    );
  }

  getOptions() {
    if (this.props.accountProposals && this.props.accountProposals.length > 0) {
      return this.getOptionsFromProps();
    }

    const options = chartOfAccounts[this.props.language];
    if (!options.find((o) => o.value === this.state.account)) {
      options.push({ value: this.state.account, label: this.state.account });
    }
    return options.filter((o) => {
      if (!this.props.accountBlacklist) {
        return true;
      }
      const isInBlackList = this.props.accountBlacklist.some((a) => a.account === o.value);
      return !isInBlackList;
    });
  }

  getOptionsFromProps() {
    const { t } = this.props;

    const options = this.props.accountProposals.map((account) => {
      const label = account.name ? account.name : t('bank');
      return { value: account.account, label };
    });

    if (!options.find((o) => o.value === this.state.account)) {
      options.push({ value: this.state.account, label: this.state.account });
    }

    return options;
  }

  handleChange(option) {
    const account = option ? option.value : null;

    if (account && (account > 9999 || account < 1000)) {
      return;
    }
    this.setState({ account, errorMessage: '' }, this.onBlur);
  }

  onBlur() {
    const { t } = this.props;
    const { account } = this.state;
    if (account > 9999 || account < 1000) {
      this.setState({ errorMessage: t('err_invalid_account') });
      return;
    }
    this.props.onAccountChange(account);
  }

  handleInputChange(value) {
    if (ONLY_DIGITS_REGEX.test(value)) {
      if (this.state.matchPos !== 'start') {
        this.setState({
          matchPos: 'start',
        });
      }
    } else {
      if (this.state.matchPos !== 'any') {
        this.setState({
          matchPos: 'any',
        });
      }
    }

    return value;
  }

  renderErrorMessage() {
    if (!this.state.errorMessage) {
      return;
    }
    return <span className={'errorMessage'}>{this.state.errorMessage}</span>;
  }
}

AccountEdit.defaultProps = {
  creatable: true,
};

AccountEdit.propTypes = {
  onAccountChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  account: PropTypes.string,
  setFocusOnMount: PropTypes.bool,
  locked: PropTypes.bool,
  creatable: PropTypes.bool,
  accountProposals: PropTypes.arrayOf(PropTypes.object),
  accountBlacklist: PropTypes.arrayOf(PropTypes.object),
  language: PropTypes.string.isRequired,
};

export default translate(AccountEdit);
