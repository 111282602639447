import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Creatable from 'react-select/creatable';
import translateWithForwardRef from 'containers/translateWithForwardRef';
import normalizeFilterText from 'lib/normalizeFilterText.js';

const MAX_RESULTS = 100;

class CompanyNamePicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedValue: props.presetCompanyName,
      newCompanyNames: [],
      inputValue: '',
    };

    this.onChange = this.onChange.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onPickerBlur = this.onPickerBlur.bind(this);
    this.onNewOptionClick = this.onNewOptionClick.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.presetCompanyName !== this.props.presetCompanyName) {
      this.setState({
        selectedValue: this.props.presetCompanyName,
      });
    }

    if (
      prevProps.usedCompanyNames !== this.props.usedCompanyNames ||
      prevProps.allCompanyNames !== this.props.allCompanyNames
    ) {
      this.setState({
        newCompanyNames: [],
      });
    }
  }

  handleInputChange(inputValue) {
    this.setState({
      inputValue,
    });

    return inputValue;
  }

  onChange(selectedOption, actionMeta) {
    if (actionMeta === 'create-option') {
      return this.onNewOptionClick(selectedOption);
    }

    const selectedCompanyName = selectedOption ? selectedOption.value : '';

    this.setState({
      selectedValue: selectedCompanyName,
    });

    if (this.props.onCompanyNameChange) {
      this.props.onCompanyNameChange(selectedCompanyName);
    }
  }

  onFocus() {
    this.setState({
      selectedValue: null,
    });
  }

  onPickerBlur() {
    if (!this.state.selectedValue) {
      this.setState({
        selectedValue: this.props.presetCompanyName,
      });
    }
  }

  onNewOptionClick(option) {
    this.setState(
      (prevState) => {
        return {
          inputValue: '',
          selectedValue: option.value,
          newCompanyNames: [...prevState.newCompanyNames, option.value],
        };
      },
      () => {
        this.textInput.select.closeMenu();
        this.onChange(option);
      }
    );
  }

  focusTextInput() {
    // The "CreatableSelect: `ref` is not a prop" warning in the console is known to the author an in the process
    // of beeing fixed: https://github.com/JedWatson/react-select/issues/2181
    this.textInput.focus();
  }

  render() {
    const { t } = this.props;

    const companyNames = this.state.inputValue.length > 0 ? this.props.allCompanyNames : this.props.usedCompanyNames;

    let options = companyNames.concat(this.state.newCompanyNames).map((name) => {
      return { value: name, label: name };
    });

    // add company to list if company was soft deleted
    if (this.state.selectedValue && !options.some((o) => o.value === this.state.selectedValue)) {
      options.push({ value: this.state.selectedValue, label: this.state.selectedValue });
    }

    const value = options.find((option) => option.value === this.state.selectedValue);

    let normalizedInputValue = normalizeFilterText(this.state.inputValue);
    let indexSymbol = Symbol('index');

    options.forEach((o) => {
      o[indexSymbol] = normalizeFilterText(o.value).indexOf(normalizedInputValue);
    });

    options = options
      .filter((o) => o[indexSymbol] !== -1)
      .sort((a, b) => a[indexSymbol] - b[indexSymbol])
      .slice(0, MAX_RESULTS);

    return (
      <div className={'CompanyNamePicker'}>
        <Creatable
          className={'Select'}
          styles={{ menu: (base) => ({ ...base, zIndex: 9999 }) }}
          value={value}
          options={options}
          placeholder={t('company')}
          onChange={this.onChange}
          onFocus={this.onFocus}
          onBlur={this.onPickerBlur}
          onInputChange={this.handleInputChange}
          isClearable={this.props.clearable}
          ref={(input) => {
            this.textInput = input;
          }}
          formatCreateLabel={(label) => `Firma "${label}" erstellen`}
          isDisabled={this.props.disabled}
          filterOption={() => true}
        />
      </div>
    );
  }
}

CompanyNamePicker.propTypes = {
  clearable: PropTypes.bool.isRequired,
  allCompanyNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  usedCompanyNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  t: PropTypes.func.isRequired,
  presetCompanyName: PropTypes.string,
  onCompanyNameChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default translateWithForwardRef(CompanyNamePicker);
