import { vsprintf } from 'sprintf-js';
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/it';
import de from 'locales/de/translations.json';
import en from 'locales/en/translations.json';
import fr from 'locales/fr/translations.json';
import it from 'locales/it/translations.json';

const TRANSLATIONS = { de, en, fr, it };
const language = localStorage.getItem('language') ?? 'de';

export default function translate(token, ...sprintfArgs) {
  if (TRANSLATIONS[language] && TRANSLATIONS[language][token]) {
    return vsprintf(TRANSLATIONS[language][token], sprintfArgs);
  } else {
    return token.replace(/_/g, ' ');
  }
}

export { language };
