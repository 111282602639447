import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FaCalendarAlt } from 'react-icons/fa';
import Tooltip from 'components/tooltip/Tooltip';
import translate from 'containers/translate';
import './DocumentRelevant.scss';

class DocumentRelevant extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dirtyDocument: props.document,
    };

    this.toggleRelevant = this.toggleRelevant.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.document !== this.props.document) {
      this.setState({
        dirtyDocument: this.props.document,
      });
    }
  }

  toggleRelevant() {
    const document = Object.assign({}, this.state.dirtyDocument, {
      relevant: !this.state.dirtyDocument.relevant,
    });

    this.setState({
      dirtyDocument: document,
    });

    this.props.onDocumentChange(document);
  }

  render() {
    const { t } = this.props;

    if (this.state.dirtyDocument.relevant) {
      return (
        <Tooltip key={this.state.dirtyDocument.id} title={t('tooltip_document_relevant')}>
          <FaCalendarAlt size="20" className="DocumentRelevant relevant clickable" onClick={this.toggleRelevant} />
        </Tooltip>
      );
    } else {
      return (
        <Tooltip key={this.state.dirtyDocument.id} title={t('tooltip_document_not_relevant')}>
          <FaCalendarAlt size="20" className="DocumentRelevant not-relevant clickable" onClick={this.toggleRelevant} />
        </Tooltip>
      );
    }
  }
}

DocumentRelevant.propTypes = {
  document: PropTypes.object.isRequired,
  onDocumentChange: PropTypes.func,
  t: PropTypes.func.isRequired,
};

export default translate(DocumentRelevant);
