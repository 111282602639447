import React from 'react';
import RemoteDataProvider from './RemoteDataProvider';

const AggregatedDossiersContext = React.createContext();
const EMPTY_VALUE = { hasBankingDocumentsInPeriodsByPeriod: {}, percentageOfCorrectRulesByDossier: {} };

function providesAggregatedDossiers(Component) {
  class RemoteAggregatedDossiers extends React.Component {
    render() {
      const baseURL = '/aggregated-dossiers';

      return (
        <RemoteDataProvider
          scope="root"
          baseURL={baseURL}
          isCollection
          render={(remoteData) => {
            return (
              <AggregatedDossiersContext.Provider value={remoteData}>
                <Component {...this.props} />
              </AggregatedDossiersContext.Provider>
            );
          }}
        />
      );
    }
  }

  return RemoteAggregatedDossiers;
}

function consumesAggregatedDossiers(Component, provideValueAs = 'aggregatedDossiers') {
  return function ComponentThatConsumesAggregatedDossiers(props) {
    return (
      <AggregatedDossiersContext.Consumer>
        {(remoteData) => {
          const consumedProps = {
            remoteAggregatedDossiers: remoteData,
            [provideValueAs]: remoteData.value[0] ?? EMPTY_VALUE,
          };

          return <Component {...props} {...consumedProps} />;
        }}
      </AggregatedDossiersContext.Consumer>
    );
  };
}

export { providesAggregatedDossiers, consumesAggregatedDossiers };
