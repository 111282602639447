import { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import qs from 'qs';
import * as immutable from 'object-path-immutable';
import { withRouter } from 'containers/withRouter';
import deepEqual from 'deep-equal';
import identity from 'lodash/identity';
import AwaitLock from 'await-lock';
import { compare, applyPatch } from 'fast-json-patch';
import errorBus from 'lib/errorBus';
import { consumesDossier } from 'contexts/DossierContext';
import { consumesPeriods } from 'contexts/PeriodsContext';

export function createEmptyRemoteData(defaultValue = null) {
  return {
    isLoading: true,
    hasError: false,
    error: null,
    value: defaultValue,
    api: {
      fetch: () => {},
      create: () => {},
      update: () => {},
      delete: () => {},
    },
  };
}

class RemoteDataProvider extends Component {
  constructor(props) {
    super(props);

    this.handleError = this.handleError.bind(this);
    this.fetch = this.fetch.bind(this);
    this.create = this.create.bind(this);
    this.update = this.update.bind(this);
    this.delete = this.delete.bind(this);
    this.lock = new AwaitLock();

    let initialValue = props.initialValue;

    if (!initialValue) {
      initialValue = this.props.isCollection ? [] : null;
    }

    this.state = {
      isLoading: true,
      hasError: false,
      error: null,
      value: initialValue,
      api: {
        fetch: this.fetch,
        create: this.create,
        update: this.update,
        delete: this.delete,
      },
    };
  }

  componentDidMount() {
    if (this.props.autoFetch) {
      this.fetch();
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.autoFetch) {
      return;
    }

    if (prevProps.lastUpdateRequested !== this.props.lastUpdateRequested) {
      return this.fetch();
    }

    if (['dossier', 'period'].includes(this.props.scope)) {
      const dossierChanged = this.props.dossier !== prevProps.dossier;
      const periodChanged = this.props.selectedPeriod !== prevProps.selectedPeriod;

      if (this.props.scope === 'dossier' && dossierChanged) {
        return this.fetch();
      } else if (this.props.scope === 'period' && (dossierChanged || periodChanged)) {
        return this.fetch();
      }
    }

    if (prevProps.baseURL !== this.props.baseURL) {
      return this.fetch();
    }

    if (this.props.isCollection) {
      const searchChanged = prevProps.location.search !== this.props.location.search;
      const filterChanged = !deepEqual(prevProps.filter, this.props.filter);

      if (filterChanged) {
        this.fetch();
      } else if (this.props.passThroughSearch && searchChanged) {
        this.fetch();
      }
    } else {
      if (prevProps.objectId !== this.props.objectId) {
        this.fetch();
      }
    }
  }

  componentWillUnmount() {
    if (this.fetchCancelTokenSource) {
      this.fetchCancelTokenSource.cancel('Component unmounted');
    }
  }

  handleError(err, disableGlobalErrorHandler = false) {
    if (err instanceof axios.Cancel) {
      return false;
    }

    if (err.response && err.response.status === 409) {
      return true;
    }

    if (!disableGlobalErrorHandler) errorBus.emit('error', err);

    this.setState({
      hasError: true,
      error: err,
    });

    return true;
  }

  buildURL(type, id) {
    let url = process.env.REACT_APP_API_ENDPOINT;
    let query = {};

    if (this.props.scope === 'period') {
      url += `/dossiers/${this.props.dossier.id}/${this.props.selectedPeriod.id}`;
    } else if (this.props.scope === 'dossier') {
      url += `/dossiers/${this.props.dossier.id}`;
    }

    url += this.props.baseURL;

    if (this.props.isCollection) {
      if (['update', 'delete'].includes(type)) {
        url += '/' + id;
      }

      if (this.props.filter) {
        Object.assign(query, this.props.filter);
      } else if (this.props.passThroughSearch) {
        Object.assign(
          query,
          qs.parse(this.props.location.search, { strictNullHandling: true, ignoreQueryPrefix: true })
        );
      }
    } else {
      if (['fetch', 'update', 'delete'].includes(type)) {
        url += '/' + id + this.props.subresource;
      }
    }

    const search = qs.stringify(query, { strictNullHandling: true, ignoreQueryPrefix: true });
    if (search) {
      url += '?' + search;
    }

    return url;
  }

  async fetch(cb, disableGlobalErrorHandler = false) {
    //To fetch a non-collection we need the id of the object.
    if (!this.props.isCollection && !this.props.objectId) {
      return;
    }

    if (['dossier', 'period'].includes(this.props.scope)) {
      if (!this.props.dossier) {
        return;
      }

      if (this.props.scope === 'period' && !this.props.selectedPeriod) {
        return;
      }
    }

    this.setState({ isLoading: true });

    if (this.fetchCancelTokenSource) {
      this.fetchCancelTokenSource.cancel('New request');
    }

    this.fetchCancelTokenSource = axios.CancelToken.source();

    await this.lock.acquireAsync();

    axios
      .get(this.buildURL('fetch', this.props.objectId), {
        cancelToken: this.fetchCancelTokenSource.token,
      })
      .then(({ data }) => {
        this.setState({
          value: data,
          isLoading: false,
        });
        cb && typeof cb === 'function' && cb(null, data);
        this.lock.release();
      })
      .catch((err) => {
        if (this.handleError(err, disableGlobalErrorHandler)) {
          cb && typeof cb === 'function' && cb(err);
        }
        this.lock.release();
      });
  }

  async create(body, cb, disableGlobalErrorHandler = false) {
    await this.lock.acquireAsync();
    axios
      .post(this.buildURL('create'), body)
      .then(({ data }) => {
        if (this.props.isCollection) {
          if (this.props.updateStrategy === 'fetch') {
            this.fetch();
          } else if (this.props.updateStrategy === 'merge') {
            this.setState((prevState) => {
              const value = immutable.push(prevState.value, '', data);
              return { value };
            });
          }
        } else {
          this.setState({
            value: data,
          });
        }

        this.props.onAfterCreate();
        cb && typeof cb === 'function' && cb(null, data);
        this.lock.release();
      })
      .catch((err) => {
        if (this.handleError(err, disableGlobalErrorHandler)) {
          cb && typeof cb === 'function' && cb(err);
        }
        this.lock.release();
      });
  }

  async update(body, cb, disableGlobalErrorHandler = false) {
    let patches = [];

    // A second update request might mutate the underlying document while we wait for the lock.
    // We save the changes this update wants to make and apply them back later.
    if (this.props.isCollection) {
      const currentBody = this.state.value.find((item) => {
        return item.id === body.id;
      });

      if (currentBody) {
        patches = compare(currentBody, body);
      }
    } else {
      patches = compare(this.state.value, body);
    }

    await this.lock.acquireAsync();

    if (this.props.isCollection) {
      const currentBody = this.state.value.find((item) => {
        return item.id === body.id;
      });

      if (currentBody) {
        body = applyPatch(currentBody, patches, true, false).newDocument;
      } else if (patches.length > 0) {
        this.handleError(
          new Error(`Tried to patch document ${body.id} which is not in the collection`),
          disableGlobalErrorHandler
        );
        return;
      }
    } else {
      if (body.id !== this.state.value.id) {
        this.handleError(
          new Error(`Tried to patch document ${body.id} found ${this.state.value.id}`),
          disableGlobalErrorHandler
        );
        return;
      }

      body = applyPatch(this.state.value, patches, true, false).newDocument;
    }

    body = this.props.onBeforeUpdate(body);

    axios
      .put(this.buildURL('update', body.id), body)
      .then(({ data }) => {
        if (this.props.isCollection) {
          if (this.props.updateStrategy === 'fetch') {
            this.fetch();
          } else if (this.props.updateStrategy === 'merge') {
            this.setState((prevState) => {
              const index = prevState.value.findIndex((item) => {
                return item.id === body.id;
              });

              if (index !== -1) {
                const value = immutable.set(prevState.value, [index], data);
                return { value };
              }
            });
          }
        } else {
          this.setState({
            value: data,
          });
        }

        this.props.onAfterUpdate();
        cb && typeof cb === 'function' && cb(null, data);
        this.lock.release();
      })
      .catch((err) => {
        if (this.handleError(err, disableGlobalErrorHandler)) {
          cb && typeof cb === 'function' && cb(err);
        }
        this.lock.release();
      });
  }

  async delete(body, cb, disableGlobalErrorHandler = false) {
    await this.lock.acquireAsync();

    axios
      .delete(this.buildURL('delete', body.id))
      .then(({ data }) => {
        if (this.props.isCollection) {
          if (this.props.updateStrategy === 'fetch') {
            this.fetch();
          } else if (this.props.updateStrategy === 'merge') {
            this.setState((prevState) => {
              const index = prevState.value.findIndex((item) => {
                return item.id === body.id;
              });

              if (index !== -1) {
                const value = immutable.del(prevState.value, [index]);
                return { value };
              }
            });
          }
        } else {
          this.setState({
            value: null,
          });
        }

        cb && typeof cb === 'function' && cb(null, data);
        this.lock.release();
      })
      .catch((err) => {
        if (this.handleError(err, disableGlobalErrorHandler)) {
          cb && typeof cb === 'function' && cb(err);
        }
        this.lock.release();
      });
  }

  render() {
    return this.props.render(this.state);
  }
}

RemoteDataProvider.defaultProps = {
  scope: 'period',
  autoFetch: true,
  isCollection: false,
  passThroughSearch: false,
  updateStrategy: 'merge',
  subresource: '',
  filter: null,
  lastUpdateRequested: 0,
  onBeforeUpdate: identity,
  onAfterUpdate: identity,
  onAfterCreate: identity,
};

RemoteDataProvider.propTypes = {
  scope: PropTypes.oneOf(['root', 'dossier', 'period']).isRequired,
  baseURL: PropTypes.string.isRequired,
  objectId: PropTypes.number,
  autoFetch: PropTypes.bool.isRequired,
  isCollection: PropTypes.bool.isRequired,
  passThroughSearch: PropTypes.bool.isRequired,
  updateStrategy: PropTypes.oneOf(['merge', 'fetch']).isRequired,
  initialValue: PropTypes.any,
  subresource: PropTypes.string.isRequired,
  filter: PropTypes.object,
  onBeforeUpdate: PropTypes.func.isRequired,
  onAfterUpdate: PropTypes.func.isRequired,
  onAfterCreate: PropTypes.func.isRequired,
  render: PropTypes.func.isRequired,
  dossier: PropTypes.object,
  selectedPeriod: PropTypes.object,
  location: PropTypes.object.isRequired,
  lastUpdateRequested: PropTypes.number.isRequired,
};

export default consumesDossier(consumesPeriods(withRouter(RemoteDataProvider)));
