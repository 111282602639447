import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import errorBus from 'lib/errorBus';
import deepEqual from 'deep-equal';
import { consumesDocument } from 'contexts/DocumentContext';

export default function withTotalAmountCHF(WrappedComponent) {
  class TotalAmountCHFContainer extends Component {
    constructor(props) {
      super(props);

      this.state = {
        totalAmountCHF: 0,
        error: null,
      };

      this.loadTotalAmountChfFromServer = this.loadTotalAmountChfFromServer.bind(this);
    }

    componentDidMount() {
      this.loadTotalAmountChfFromServer();
    }

    componentDidUpdate(prevProps) {
      if (!deepEqual(prevProps.document, this.props.document)) {
        this.loadTotalAmountChfFromServer();
      }
    }

    componentWillUnmount() {
      this.getCancelTokenSource && this.getCancelTokenSource.cancel('Component unmounted');
    }

    loadTotalAmountChfFromServer() {
      this.getCancelTokenSource && this.getCancelTokenSource.cancel('New request');

      this.getCancelTokenSource = axios.CancelToken.source();

      axios
        .get(`${process.env.REACT_APP_API_ENDPOINT}/total-amount-chf/?accountingDocumentId=${this.props.document.id}`, {
          cancelToken: this.getCancelTokenSource.token,
        })

        .then((response) => {
          this.setState({
            totalAmountCHF: response.data,
          });
        })
        .catch((err) => {
          if (err instanceof axios.Cancel) {
            return;
          }
          errorBus.emit('error', err);

          this.setState({
            error: err,
          });
        });
    }

    render() {
      if (this.state.error) {
        return <div>Error</div>;
      }

      return <WrappedComponent {...this.props} totalAmountCHF={this.state.totalAmountCHF} />;
    }
  }

  TotalAmountCHFContainer.propTypes = {
    document: PropTypes.object.isRequired,
  };

  return consumesDocument(TotalAmountCHFContainer);
}
