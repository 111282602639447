import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';
import { withAuth0 } from '@auth0/auth0-react';
import './App.scss';

import errorBus from 'lib/errorBus.js';
import Lea from 'components/Lea.js';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      errorInfo: null,
    };

    this.onError = this.onError.bind(this);
  }

  onError(err, info) {
    console.error(err);

    if (err.response.status === 401) {
      const { logout } = this.props.auth0;
      logout();
    }

    this.setState({
      error: err,
      errorInfo: info,
    });
  }

  componentDidMount() {
    errorBus.on('error', this.onError);
  }

  componentWillUnmount() {
    errorBus.off('error', this.onError);
  }

  componentDidCatch(err, info) {
    this.onError(err, info);
  }

  renderConfig() {
    if (this.state.error.config) {
      return (
        <div>
          <h2>Config</h2>
          <table>
            <tbody>
              <tr>
                <td>URL</td>
                <td>{this.state.error.config.url}</td>
              </tr>
              <tr>
                <td>Method</td>
                <td>{this.state.error.config.method}</td>
              </tr>
              <tr>
                <td>Data</td>
                <td>{this.state.error.config.data}</td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    }
  }

  renderResponse() {
    if (this.state.error.response?.data?.stack) {
      return (
        <div>
          <h2>Server</h2>
          <pre>{this.state.error.response?.data?.stack}</pre>
        </div>
      );
    } else {
      return (
        <div>
          <h2>Server</h2>
          <pre>{JSON.stringify(this.state.error.response?.data, null, 4)}</pre>
        </div>
      );
    }
  }

  render() {
    if (this.state.error) {
      return (
        <Alert color={'danger'}>
          <h1>Fehler</h1>
          <p>{this.state.error.message}</p>
          <pre>{this.state.error.stack}</pre>
          <h2>Info</h2>
          <p>{this.state.info}</p>
          {this.renderConfig()}
          {this.renderResponse()}
        </Alert>
      );
    }

    return (
      <div className={'App'}>
        <Lea />
      </div>
    );
  }
}

App.propTypes = {
  auth0: PropTypes.object.isRequired,
};

export default withAuth0(App);
