import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import withRules from 'containers/withRules';
import translate from 'containers/translate';
import OverviewRuleEdit from 'components/overviewRuleEdit/OverviewRuleEdit';
import './RuleOverview.scss';

class RuleOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  renderGlobalRules() {
    const globalRules = this.props.rules.filter((rule) => !rule.dossierId);
    return this.renderRules(globalRules);
  }

  renderDossierRules() {
    const dossierRules = this.props.rules.filter((rule) => rule.dossierId);
    return this.renderRules(dossierRules);
  }

  renderRules(rules) {
    return rules.map((rule, i) => {
      return (
        <OverviewRuleEdit
          key={i}
          rule={rule}
          onRuleChange={this.props.onRuleChange}
          onRuleDelete={this.props.onRuleDelete}
          editable={true}
        />
      );
    });
  }

  renderRulesWithTitle(rules, title) {
    if (rules.length === 0) {
      return;
    }
    const { t } = this.props;

    return (
      <Table>
        <tbody>
          <tr className={'title'}>
            <th colSpan={3}>{title}</th>
          </tr>
          <tr>
            <th className={'companyNameColumn'}>{t('company_name')}</th>
            <th>{t('account')}</th>
          </tr>
          {rules}
        </tbody>
      </Table>
    );
  }

  render() {
    return (
      <div className={'RuleOverview'}>
        <h1>{this.props.t('edit_rules')}</h1>
        {this.renderRulesWithTitle(this.renderDossierRules(), 'Dieses Dossier')}
        {this.renderRulesWithTitle(this.renderGlobalRules(), 'Alle Dossier')}
      </div>
    );
  }
}

RuleOverview.propTypes = {
  rules: PropTypes.arrayOf(PropTypes.object).isRequired,
  onRuleChange: PropTypes.func.isRequired,
  onRuleDelete: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withRules(translate(RuleOverview));
