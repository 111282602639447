import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Table } from 'reactstrap';
import { consumesDossierStatistic, providesDossierStatistic } from 'contexts/DossierStatisticContext';
import translate from 'containers/translate';

class DossierStatistic extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  formatMillis(milliseconds) {
    const duration = moment.duration(+milliseconds, 'milliseconds');
    return `${duration.hours()}h ${duration.minutes()}m ${duration.seconds()}s`;
  }

  render() {
    const { t, dossierStatistic } = this.props;

    if (dossierStatistic.length === 0) {
      return <div className={'DossierStatistic'} />;
    }

    const { statistic } = dossierStatistic[0];

    return (
      <div className={'DossierStatistic'}>
        <h1>{t('statistic')}</h1>
        <Table size="sm">
          <tbody>
            <tr>
              <td colSpan={2}>
                <h2>{t('documents')}</h2>
              </td>
            </tr>
            <tr>
              <td>{t('total_documents')}</td>
              <td>{statistic.documentCount}</td>
            </tr>
            <tr>
              <td>{t('total_edit_time')}</td>
              <td>{this.formatMillis(statistic.totalEditTime)}</td>
            </tr>
            <tr>
              <td>{t('average_edit_time')}</td>
              <td>{this.formatMillis(statistic.averageTotalEditTime)}</td>
            </tr>
            <tr>
              <td>{t('total_mutations')}</td>
              <td>{statistic.totalDocumentMutationCount}</td>
            </tr>
            <tr>
              <td>{t('average_mutations')}</td>
              <td>{statistic.averageMutationsPerDocument}</td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  }
}

DossierStatistic.propTypes = {
  t: PropTypes.func.isRequired,
  dossierStatistic: PropTypes.array.isRequired,
};

export default providesDossierStatistic(consumesDossierStatistic(translate(DossierStatistic)));
