import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ListGroup, ListGroupItem, Modal, ModalBody, ModalHeader } from 'reactstrap';
import translate from 'containers/translate';
import { consumesDuplicateDocuments } from 'contexts/DuplicateDocumentsContext';
import './DuplicateDocuments.scss';

class DuplicateDocuments extends Component {
  renderDocuments() {
    return this.props.duplicateDocuments.map((document) => (
      <ListGroupItem key={document.id} tag="button" type="button" action onClick={() => this.props.onDismiss(document)}>
        {document.company.name}
      </ListGroupItem>
    ));
  }

  render() {
    const { t } = this.props;

    return (
      <Modal isOpen className="DuplicateDocuments" toggle={() => this.props.onDismiss()}>
        <ModalHeader toggle={() => this.props.onDismiss()}>{t('duplicate_documents')}</ModalHeader>
        <ModalBody>
          <ListGroup>{this.renderDocuments()}</ListGroup>
        </ModalBody>
      </Modal>
    );
  }
}

DuplicateDocuments.propTypes = {
  onDismiss: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  duplicateDocuments: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default consumesDuplicateDocuments(translate(DuplicateDocuments));
