import React from 'react';
import RemoteDataProvider from './RemoteDataProvider';

const AccountOverviewContext = React.createContext();

function providesAccountOverview(Component) {
  class RemoteAccountOverview extends React.Component {
    render() {
      return (
        <RemoteDataProvider
          baseURL="/account-overview"
          isCollection
          updateStrategy="fetch"
          render={(remoteData) => {
            return (
              <AccountOverviewContext.Provider value={remoteData}>
                <Component {...this.props} />
              </AccountOverviewContext.Provider>
            );
          }}
        />
      );
    }
  }

  return RemoteAccountOverview;
}

function consumesAccountOverview(Component, provideValueAs = 'accountOverview') {
  return function ComponentThatConsumesAccountOverview(props) {
    return (
      <AccountOverviewContext.Consumer>
        {(remoteData) => {
          const consumedProps = {
            remoteAccountOverview: remoteData,
            [provideValueAs]: remoteData.value,
          };

          return <Component {...props} {...consumedProps} />;
        }}
      </AccountOverviewContext.Consumer>
    );
  };
}

export { providesAccountOverview, consumesAccountOverview };
