import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import errorBus from 'lib/errorBus';

export default function withCompanies(WrappedComponent) {
  class WithCompanies extends Component {
    constructor(props) {
      super(props);
      this.state = {
        companyNames: [],
        companies: [],
        onlyTop: false,
        error: null,
      };

      this.onCompanyChange = this.onCompanyChange.bind(this);
      this.onMergeCompanies = this.onMergeCompanies.bind(this);
      this.onToggleTopOnly = this.onToggleTopOnly.bind(this);
      this._loadCompanies = this._loadCompanies.bind(this);
    }

    componentDidMount() {
      this._loadCompanies();
    }

    componentWillUnmount() {
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel('Component unmounted');
      }
    }

    _loadCompanies() {
      this.cancelTokenSource = axios.CancelToken.source();

      let url = `${process.env.REACT_APP_API_ENDPOINT}/companies`;

      if (this.state.onlyTop) {
        url = url + '?topOnly=true';
      }

      axios
        .get(url, { cancelToken: this.cancelTokenSource.token })
        .then((response) => {
          const companies = response.data;

          this.setState({
            companies: companies,
            companyNames: companies.map((c) => c.name),
          });
        })
        .catch((err) => {
          if (err instanceof axios.Cancel) {
            return;
          }
          errorBus.emit('error', err);

          this.setState({
            error: err,
          });
        });
    }

    onCompanyChange(company, cb) {
      this.putCancelTokenSource && this.putCancelTokenSource.cancel('New request');

      this.putCancelTokenSource = axios.CancelToken.source();

      axios
        .put(`${process.env.REACT_APP_API_ENDPOINT}/companies/${company.id}`, company, {
          cancelToken: this.putCancelTokenSource.token,
        })
        .then((response) => {
          this._loadCompanies();
          cb && cb(response.data);
        })
        .catch((err) => {
          if (err instanceof axios.Cancel) {
            return;
          }
          errorBus.emit('error', err);

          this.setState({
            error: err,
          });
        });
    }

    onMergeCompanies(companiesToMerge, correctMergeCompany, cb) {
      if (this.mergeCancelTokenSource) {
        this.mergeCancelTokenSource.cancel('New request');
      }

      this.mergeCancelTokenSource = axios.CancelToken.source();

      axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}/merge-companies`,
          { companiesToMerge, correctMergeCompany },
          {
            cancelToken: this.mergeCancelTokenSource.token,
          }
        )
        .then(() => {
          this._loadCompanies();
          cb && cb();
        })
        .catch((err) => {
          if (err instanceof axios.Cancel) {
            return;
          }
          errorBus.emit('error', err);

          this.setState({
            error: err,
          });
        });
    }

    onToggleTopOnly() {
      this.setState({ onlyTop: !this.state.onlyTop }, this._loadCompanies);
    }

    render() {
      const { forwardedRef, ...remainingProps } = this.props;
      if (this.state.error) {
        return <div>Error</div>;
      }
      return (
        <WrappedComponent
          {...remainingProps}
          ref={forwardedRef}
          onCompanyChange={this.onCompanyChange}
          companyNames={this.state.companyNames}
          companies={this.state.companies}
          onMergeCompanies={this.onMergeCompanies}
          toggleTopOnly={this.onToggleTopOnly}
          showTopOnly={this.state.onlyTop}
        />
      );
    }
  }
  WithCompanies.propTypes = {
    forwardedRef: PropTypes.func,
  };
  return WithCompanies;
}
