import parseDecimalNumber from 'parse-decimal-number';

const parsers = [
  (input) => parseDecimalNumber(input, { thousands: '’', decimal: ',' }),
  (input) => parseDecimalNumber(input, { thousands: '’', decimal: '.' }),
  (input) => parseDecimalNumber(input, { thousands: "'", decimal: ',' }),
  (input) => parseDecimalNumber(input, { thousands: "'", decimal: '.' }),
];

export default (input) => {
  for (let i = 0; i < parsers.length; i++) {
    let parse = parsers[i];
    let result = parse(input);

    if (!isNaN(result)) {
      return result;
    }
  }

  return NaN;
};
