import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Modal, ModalBody, ModalHeader } from 'reactstrap';
import translate from 'containers/translate';
import './ShortcutHelpModal.scss';

class ShortcutHelpModal extends Component {
  renderGroup(headline, shortcuts) {
    return (
      <Col>
        <table>
          <thead>
            <tr>
              <th />
              <th>{headline}</th>
            </tr>
          </thead>
          <tbody>
            {shortcuts.map((shortcut) => (
              <tr key={shortcut.label}>
                <td>
                  {shortcut.keys.map((key) => (
                    <span className="key" key={key}>
                      {key}
                    </span>
                  ))}
                </td>
                <td>{shortcut.label}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Col>
    );
  }

  render() {
    const { t } = this.props;

    return (
      <Modal isOpen size="lg" className="ShortcutHelpModal" toggle={() => this.props.onDismiss()}>
        <ModalHeader toggle={() => this.props.onDismiss()}>{t('keyboard_shortcuts')}</ModalHeader>
        <ModalBody>
          <Row>
            {this.renderGroup(t('keyboard_shortcuts_document_state'), [
              {
                keys: ['a'],
                label: t('keyboard_shortcuts_document_state_delete'),
              },
              {
                keys: ['b'],
                label: t('keyboard_shortcuts_document_state_confirm'),
              },
              {
                keys: ['o'],
                label: t('keyboard_shortcuts_document_state_unlock'),
              },
            ])}

            {this.renderGroup(t('keyboard_shortcuts_navigation'), [
              {
                keys: ['◀'],
                label: t('keyboard_shortcuts_navigation_prev'),
              },
              {
                keys: ['▶'],
                label: t('keyboard_shortcuts_navigation_next'),
              },
            ])}
          </Row>
          <Row className="mt-4">
            {this.renderGroup(t('keyboard_shortcuts_edit_document'), [
              {
                keys: ['t'],
                label: t('keyboard_shortcuts_edit_document_focus_total_amount'),
              },
              {
                keys: ['f'],
                label: t('keyboard_shortcuts_edit_document_focus_company'),
              },
            ])}

            {this.renderGroup(t('keyboard_shortcuts_other'), [
              {
                keys: ['?'],
                label: t('keyboard_shortcuts_other_self'),
              },
            ])}
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

ShortcutHelpModal.propTypes = {
  onDismiss: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default translate(ShortcutHelpModal);
