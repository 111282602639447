import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'containers/withRouter';
import translate from 'containers/translate';
import withSearchResult from 'containers/withSearchResult';
import './SearchResult.scss';

class SearchResult extends React.Component {
  render() {
    const { t } = this.props;
    const document = this.props.accountingDocument;

    if (document) {
      this.props.history.push(`/dossier/${document.dossierId}/${document.periodId}/edit/${document.id}`);
      return <div>{t('redirecting')}</div>;
    }

    return (
      <div className="SearchResult">
        <h2>{t('search')}</h2>
        <span>{t('no_search_results', this.props.searchTerm)}</span>
      </div>
    );
  }
}

SearchResult.propTypes = {
  t: PropTypes.func.isRequired,
  accountingDocument: PropTypes.object,
  searchTerm: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
};

export default withSearchResult(withRouter(translate(SearchResult)));
