import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import errorBus from 'lib/errorBus';
import { consumesDossier } from 'contexts/DossierContext';

export default function withRules(WrappedComponent) {
  class WithRules extends Component {
    constructor(props) {
      super(props);

      this.state = {
        rules: [],
        error: null,
      };

      this.loadRules = this.loadRules.bind(this);
      this.onRuleChange = this.onRuleChange.bind(this);
      this.onRuleDelete = this.onRuleDelete.bind(this);
    }

    componentDidMount() {
      this.loadRules();
    }

    onRuleChange(rule, cb) {
      delete rule.isSelected;

      if (this.putCancelTokenSource) {
        this.putCancelTokenSource.cancel('New request');
      }

      this.putCancelTokenSource = axios.CancelToken.source();

      rule = Object.assign({}, rule);
      delete rule.isSelected;

      axios
        .put(`${process.env.REACT_APP_API_ENDPOINT}/rules/${rule.id}`, rule, {
          cancelToken: this.putCancelTokenSource.token,
        })
        .then((response) => {
          this.loadRules();
          cb && cb(response.data);
        })
        .catch((err) => {
          if (err instanceof axios.Cancel) {
            return;
          }
          errorBus.emit('error', err);

          this.setState({
            error: err,
          });
        });
    }

    loadRules() {
      if (this.cancelTokenSourceGet) {
        this.cancelTokenSourceGet.cancel('New request');
      }

      this.cancelTokenSourceGet = axios.CancelToken.source();

      let dossierIdQuery = '';
      if (this.props.dossier) {
        dossierIdQuery = `?dossierId=${this.props.dossier.id}`;
      }

      axios
        .get(`${process.env.REACT_APP_API_ENDPOINT}/rules${dossierIdQuery}`, {
          cancelToken: this.cancelTokenSourceGet.token,
        })
        .then((response) => {
          const rules = response.data.map((rule) => Object.assign(rule, { isSelected: false }));
          this.setState({ rules });
        })
        .catch((err) => {
          if (!(err instanceof axios.Cancel)) {
            errorBus.emit('error', err);

            this.setState({
              error: err,
            });
          }
        });
    }

    onRuleDelete(rule, cb) {
      axios
        .delete(`${process.env.REACT_APP_API_ENDPOINT}/rules/${rule.id}`)
        .then(() => {
          this.setState((prevState) => {
            const rules = prevState.rules.filter((oldRule) => oldRule.id !== rule.id);
            cb && cb();
            return { rules };
          });
        })
        .catch((err) => {
          if (!(err instanceof axios.Cancel)) {
            errorBus.emit('error', err);

            this.setState({
              error: err,
            });
          }
        });
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          rules={this.state.rules}
          onRuleChange={this.onRuleChange}
          onRuleDelete={this.onRuleDelete}
          loadRules={this.loadRules}
        />
      );
    }
  }

  WithRules.propTypes = {
    dossier: PropTypes.object,
  };

  return consumesDossier(WithRules);
}
