import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Badge, FormText } from 'reactstrap';
import Moment from 'react-moment';
import translate from 'containers/translate';
import Currency from 'components/currency/Currency';
import BankingTransactionDescription from 'components/bankingTransactionDescription/BankingTransactionDescription';
import './BankingTransactionProposal.scss';

class BankingTransactionProposal extends Component {
  render() {
    const { bankingTransaction } = this.props;

    return (
      <div className={'BankingTransactionProposal'} onClick={(e) => this.props.onClick(e, bankingTransaction)}>
        <div className={'wrapper'}>
          <div className={'details'}>
            <FormText color={'muted'}>
              <Moment date={bankingTransaction.date} format={'DD.MM.YYYY'} />
              <p>{this.renderTransactionType(bankingTransaction.type)}</p>
              <p className={'currency'}>
                <Currency value={bankingTransaction.amount} currency={this.props.currency} />
              </p>
              {this.renderProposalBadge(bankingTransaction)}
            </FormText>
          </div>
          <div className={'description'}>
            <BankingTransactionDescription description={bankingTransaction.description} cropDescription={false} />
          </div>
        </div>
      </div>
    );
  }

  renderTransactionType(transactionType) {
    const { t } = this.props;

    if (transactionType === 'credit') {
      return t('credit_transaction');
    } else if (transactionType === 'debit') {
      return t('debit_transaction');
    }
  }

  renderProposalBadge(bankingTransaction) {
    if (!bankingTransaction.proposalType) {
      return null;
    }
    return (
      <Badge color={this.getProposalBadgeColor(bankingTransaction)}>
        {this.getProposalBadgeText(bankingTransaction)}
      </Badge>
    );
  }

  getProposalBadgeText(bankingTransaction) {
    const { t } = this.props;

    let badgeText = '';
    if (bankingTransaction.proposalType === 'exactMatch') {
      badgeText = t('exact_match');
    } else if (bankingTransaction.proposalType === 'cashDiscount') {
      badgeText = t('cash_discount');
    } else if (bankingTransaction.proposalType === 'foreignCurrency') {
      badgeText = t('foreign_currency');
    }
    return badgeText;
  }

  getProposalBadgeColor(bankingTransaction) {
    let badgeColor = '';
    if (bankingTransaction.proposalType === 'exactMatch') {
      badgeColor = 'success';
    } else if (bankingTransaction.proposalType === 'cashDiscount') {
      badgeColor = 'danger';
    } else if (bankingTransaction.proposalType === 'foreignCurrency') {
      badgeColor = 'warning';
    }
    return badgeColor;
  }
}

BankingTransactionProposal.propTypes = {
  bankingTransaction: PropTypes.object.isRequired,
  currency: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default translate(BankingTransactionProposal);
