import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'reactstrap';
import BasicDocumentInformation from 'components/basicDocumentInformation/BasicDocumentInformation';
import AccountingTransactionList from 'components/accountingTransactionList/AccountingTransactionList';
import './DocumentInformation.scss';

export default class DocumentInformation extends Component {
  render() {
    return (
      <div className={'DocumentInformation'}>
        <Container>
          <Row>
            <Col>
              <BasicDocumentInformation companyNames={this.props.companyNames} locked={this.props.locked} />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <AccountingTransactionList locked={this.props.locked} />
            </Col>
          </Row>
        </Container>
        <hr className={'mb-0'} />
      </div>
    );
  }
}

DocumentInformation.propTypes = {
  companyNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  locked: PropTypes.bool.isRequired,
};
