import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './UploadDropzone.scss';
import { IoMdAdd } from 'react-icons/io';

class UploadDropzone extends Component {
  constructor(props) {
    super(props);

    this.handleDrop = this.handleDrop.bind(this);
  }

  handleDrop(files) {
    const filesToBeUploaded = files.map((f) => {
      return {
        key: `${f.name}:${Date.now()}`,
        privateContraAccount: this.props.privateContraAccount,
        documentType: this.props.documentType,
        documentProcessor: this.props.newDocumentRecognition ? 'v2' : 'v1',
        nativeFile: f,
      };
    });

    this.props.onUploadDocument(filesToBeUploaded);
  }

  render() {
    return (
      <div className={`UploadDropzone ${this.props.className}`}>
        <Dropzone
          onDrop={this.handleDrop}
          accept="image/jpeg, image/png, application/pdf, text/xml,.mt940,.xlsm,.xlsx"
          useFsAccessApi={false}
        >
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <div {...getRootProps()} className={classNames('dropzone', { 'dropzone--isActive': isDragActive })}>
                <div className={'content'}>
                  <input {...getInputProps()} />
                  <IoMdAdd />
                  <span>{this.props.label}</span>
                </div>
              </div>
            );
          }}
        </Dropzone>
      </div>
    );
  }
}

UploadDropzone.propTypes = {
  label: PropTypes.string.isRequired,
  documentType: PropTypes.string.isRequired,
  onUploadDocument: PropTypes.func.isRequired,
  privateContraAccount: PropTypes.bool.isRequired,
  newDocumentRecognition: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

export default UploadDropzone;
