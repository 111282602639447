import React, { Component } from 'react';
import t, { language } from 'lib/translate';
import PropTypes from 'prop-types';

export default function translate(WrappedComponent) {
  class Translate extends Component {
    constructor(props) {
      super(props);
      this.translate = this.translate.bind(this);
    }

    translate(token, ...args) {
      return t(token, ...args);
    }

    render() {
      const { forwardedRef, ...rest } = this.props;

      return <WrappedComponent language={language} ref={forwardedRef} {...rest} t={this.translate} />;
    }
  }

  Translate.propTypes = {
    forwardedRef: PropTypes.func,
  };

  return Translate;
}
