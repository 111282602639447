import React from 'react';
import RemoteDataProvider from './RemoteDataProvider';

const BankingTransactionsContext = React.createContext();

function providesBankingTransactions(Component) {
  class RemoteBankingTransactions extends React.Component {
    render() {
      return (
        <RemoteDataProvider
          baseURL="/banking-transactions"
          isCollection
          updateStrategy="fetch"
          render={(remoteData) => {
            return (
              <BankingTransactionsContext.Provider value={remoteData}>
                <Component {...this.props} />
              </BankingTransactionsContext.Provider>
            );
          }}
        />
      );
    }
  }

  return RemoteBankingTransactions;
}

function consumesBankingTransactions(Component, provideValueAs = 'bankingTransactions') {
  return function ComponentThatConsumesBankingTransactions(props) {
    return (
      <BankingTransactionsContext.Consumer>
        {(remoteData) => {
          const consumedProps = {
            remoteBankingTransactions: remoteData,
            [provideValueAs]: remoteData.value,
          };

          return <Component {...props} {...consumedProps} />;
        }}
      </BankingTransactionsContext.Consumer>
    );
  };
}

export { providesBankingTransactions, consumesBankingTransactions };
