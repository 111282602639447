import React, { Component } from 'react';
import qs from 'qs';
import { withRouter } from 'containers/withRouter';
import PropTypes from 'prop-types';
import StateFilter from 'components/StateFilter';
import CompanyNamePicker from 'components/CompanyNamePicker';
import { Col, Container, Row } from 'reactstrap';
import translate from 'containers/translate';
import { consumesUsedCompanies } from 'contexts/UsedCompaniesContext';
import './FilterBar.scss';

class FilterBar extends Component {
  constructor(props) {
    super(props);

    this.handleStateFilterChange = this.handleStateFilterChange.bind(this);
    this.handleCompanyNameFilterChange = this.handleCompanyNameFilterChange.bind(this);
  }

  _getFilterFromURL() {
    const query = qs.parse(this.props.location.search, { strictNullHandling: true, ignoreQueryPrefix: true });

    return {
      state: '',
      companyName: '',
      ...query,
    };
  }

  updateURL(filter) {
    if (filter.state === '') {
      delete filter.state;
    }

    if (filter.companyName === '') {
      delete filter.companyName;
    }

    const filterString = qs.stringify(filter, { strictNullHandling: true, ignoreQueryPrefix: true });
    this.props.history.pushDossier(`/edit/?${filterString}`);
  }

  handleStateFilterChange(state) {
    const filter = {
      ...this._getFilterFromURL(),
      state,
    };

    this.updateURL(filter);
  }

  handleCompanyNameFilterChange(companyName) {
    const filter = {
      ...this._getFilterFromURL(),
      companyName,
    };

    this.updateURL(filter);
  }

  render() {
    const { t } = this.props;
    const filter = this._getFilterFromURL();

    return (
      <div className={'FilterBar'}>
        <Container>
          <Row className={'align-items-center'}>
            <Col xs={'auto'}>{t('filter')}: </Col>
            <Col xs={'4'}>
              <StateFilter
                selectedState={filter.state}
                onStateFilterChange={this.handleStateFilterChange}
                lastUpdateRequested={new Date()}
              />
            </Col>
            <Col xs={'4'}>
              <CompanyNamePicker
                allCompanyNames={this.props.usedCompanyNames}
                usedCompanyNames={this.props.usedCompanyNames}
                presetCompanyName={filter.companyName}
                onCompanyNameChange={this.handleCompanyNameFilterChange}
                clearable={true}
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

FilterBar.propTypes = {
  t: PropTypes.func.isRequired,
  usedCompanyNames: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default consumesUsedCompanies(withRouter(translate(FilterBar)));
