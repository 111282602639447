const MAXIMUM_TRY = 1000;

class DocumentMatcher {
  constructor() {
    this.proposalHistory = [];
    this.documents = [];
    this.transaction = null;
    this.currentDepth = 0;
  }

  loadData(transaction, documents) {
    this.proposalHistory = [];
    this.transaction = transaction;
    this.documents = documents;
  }

  propose() {
    this.currentDepth = 0;
    const totalAmount = this.transaction.amount;
    const result = this._propose(totalAmount, this.documents, []);
    if (!result) {
      return [];
    }
    const history = result.map((d) => d.id);
    this.proposalHistory.push(history);
    return result;
  }

  _propose(totalAmount, documents, previousProposals) {
    if (documents.length === 0 || totalAmount === 0 || this.currentDepth > MAXIMUM_TRY) {
      return previousProposals;
    }

    this.currentDepth++;

    for (let i = 0; i < documents.length; i++) {
      const document = documents[i];
      const proposals = previousProposals.slice();
      proposals.push(document);

      const remainingDocuments = documents.slice().filter((d) => document.id !== d.id);
      const remainingAmount = totalAmount - document.totalAmount;

      if (remainingAmount < 0) {
        continue;
      }

      const result = this._propose(remainingAmount, remainingDocuments, proposals);
      if (this._isResultValid(result)) {
        return result;
      }
    }

    return null;
  }

  _isResultValid(result) {
    if (!result || result.length < 2) {
      return false;
    }
    if (this._isResultInHistory(result)) {
      return false;
    }
    const amount = result.reduce((acc, document) => {
      return acc + document.totalAmount;
    }, 0);

    return amount === this.transaction.amount;
  }

  _isResultInHistory(result) {
    return this.proposalHistory.reduce((acc, historyEntry) => {
      const resultEntry = result.map((d) => d.id);
      const areProposalsEqual = this._areProposalsEqual(historyEntry, resultEntry);
      return acc || areProposalsEqual;
    }, false);
  }

  _areProposalsEqual(arr1, arr2) {
    arr1 = arr1.slice();
    arr2 = arr2.slice();
    arr1.sort();
    arr2.sort();
    return JSON.stringify(arr1) === JSON.stringify(arr2);
  }
}

export default DocumentMatcher;
