import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RemoteDataPropType from 'lib/prop-types/RemoteDataPropType';
import Constants from 'config/Constants';
import { RemoteDocumentsProvider } from 'contexts/DocumentsContext';
import { consumesBankingTransactions } from 'contexts/BankingTransactionsContext';
import { withRouter } from 'containers/withRouter';
import PostEditStep from './PostEditStep';

class PostEditStepContainer extends Component {
  constructor(props) {
    super(props);
    this.getTransaction = this.getTransaction.bind(this);
  }
  render() {
    if (this.props.remoteBankingTransactions.isLoading) {
      return <div className="PostEditStep" />;
    }

    const transaction = this.getTransaction();
    if (!transaction) {
      return <div className="PostEditStep" />;
    }

    return (
      <RemoteDocumentsProvider
        filter={{
          state: Constants.DOCUMENT_STATE.VERIFIED,
          currency: transaction.bankingDocument.account.currency,
        }}
      >
        <PostEditStep transaction={transaction} />
      </RemoteDocumentsProvider>
    );
  }

  getTransaction() {
    const transactionId = parseInt(this.props.match.params.transactionId, 10);

    return this.props.bankingTransactions.find(({ id }) => {
      return id === transactionId;
    });
  }
}

PostEditStepContainer.propTypes = {
  bankingTransactions: PropTypes.arrayOf(PropTypes.object).isRequired,
  remoteBankingTransactions: RemoteDataPropType,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(consumesBankingTransactions(PostEditStepContainer));
