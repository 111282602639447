import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const dossierAndPeriodPathRegex = new RegExp('^/dossier/\\d+(/\\d+)?');

// Based on https://github.com/remix-run/react-router/blob/59b319feaa12745a434afdef5cadfcabd01206f9/docs/faq.md#what-happened-to-withrouter-i-need-it
// A compatiblity layer with the new Router API that also contains our helpers originally in lib/history.js
export function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    const history = {
      push: function (path, state) {
        if (state) {
          navigate(path, { state });
        } else {
          navigate(path);
        }
      },
      pushDossier: function (path, state) {
        return history.push(this.resolveDossierURL(path), state);
      },
      resolveDossierURL: function (path) {
        const match = location.pathname.match(dossierAndPeriodPathRegex);

        if (!match) {
          throw new Error('You are trying to use resolveDossierURL in a non-dossier context');
        }

        return match[0] + path;
      },
      replacePeriod: function (periodId) {
        const parts = location.pathname.split('/');
        parts[3] = periodId;
        const newParts = parts.slice(0, 5);

        const newURL = newParts.join('/') + location.search;
        history.push(newURL);
      },
    };

    return <Component location={location} history={history} match={{ params }} {...props} />;
  }

  return ComponentWithRouterProp;
}
