import React, { Component } from 'react';
import { withRouter } from 'containers/withRouter';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, Table } from 'reactstrap';
import qs from 'qs';
import clone from 'clone';
import { AutoSizer, List } from 'react-virtualized';
import { FaPlusCircle } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import translate from 'containers/translate';
import BankingTransaction from 'components/bankingTransaction/BankingTransaction';
import { consumesDocument } from 'contexts/DocumentContext';
import RemoteDataPropType from 'lib/prop-types/RemoteDataPropType';
import { consumesDocumentIds } from 'contexts/DocumentIdsContext';
import Constants from 'config/Constants';
import TemplateSelect from 'components/templateSelect/TemplateSelect';
import Tooltip from 'components/tooltip/Tooltip';
import BankingTransactionFilter from './BankingTransactionFilter';
import parseAmount from 'lib/parseAmount';
import formatDescription from 'lib/formatDescription';
import './BankingTransactionList.scss';
import { ProgressModal } from 'components/progress';

class BankingTransactionList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showFilter: false,
      showProgressDialog: false,
      showTemplateSelect: false,
      selectedBankingTransactionIds: new Set(),
      progressDialog: {
        progressValue: 0,
        errors: '',
        maxProgress: 0,
        hasError: false,
      },
      filter: {
        dateFrom: '',
        dateTo: '',
        amountFrom: '',
        amountTo: '',
        description: '',
        showType: {
          credit: true,
          debit: true,
        },
        showState: {
          open: true,
          unconfirmed: true,
          confirmed: true,
        },
      },
    };

    this.toggleFilter = this.toggleFilter.bind(this);
    this.handleTransactionClick = this.handleTransactionClick.bind(this);
    this.renderBankingTransaction = this.renderBankingTransaction.bind(this);
    this.generateDocumentFromBankingTransactions = this.generateDocumentFromBankingTransactions.bind(this);
    this.handleBankingTransactionAssigned = this.handleBankingTransactionAssigned.bind(this);
    this.sortTransactions = this.sortTransactions.bind(this);
    this.getTransactionWithStateProperty = this.getTransactionWithStateProperty.bind(this);
    this.toggleTransactionSelection = this.toggleTransactionSelection.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.updateGrid = this.updateGrid.bind(this);
    this.getRowHeight = this.getRowHeight.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.bankingTransactions !== this.props.bankingTransactions) {
      this.setState({
        selectedBankingTransactionIds: new Set(),
      });
    }

    if (JSON.stringify(prevProps.bankingTransactions) !== JSON.stringify(this.props.bankingTransactions)) {
      this.updateGrid();
    }
  }

  selectAll() {
    const bankingTransactions = this.getTransactionWithStateProperty();

    const filteredTransaction = this.filterTransactions(bankingTransactions);

    const ids = filteredTransaction.filter((b) => b.state === Constants.DOCUMENT_STATE.OPEN).map((b) => b.id);

    this.setState({ selectedBankingTransactionIds: new Set(ids) }, this.updateGrid);
  }

  closeModal = (value = false) => {
    this.setState({
      showProgressDialog: false,
      progressDialog: { hasError: value, progressValue: 0, maxProgress: 0 },
    });
  };

  async generateDocumentFromBankingTransactions(template) {
    const transactionIds = Array.from(this.state.selectedBankingTransactionIds.values());
    this.setState({
      showTemplateSelect: false,
      progressDialog: {
        ...this.state.progressDialog,
        maxProgress: transactionIds.length,
      },
    });

    if (!template) {
      return;
    } else if (template === 'DEFAULT') {
      template = null;
    }

    for (const txId of transactionIds) {
      this.setState({ showProgressDialog: true });
      if (this.state.progressDialog.hasError) {
        this.setState({
          showProgressDialog: false,
          progressDialog: { hasError: false, progressValue: 0, maxProgress: 0 },
        });
        break;
      }
      await new Promise((resolve) => {
        this.props.remoteDocument.api.create(
          {
            bankingTransactionId: txId,
            template,
          },
          (err) => {
            if (!err) {
              this.setState((prevState) => ({
                progressDialog: {
                  ...this.state.progressDialog,
                  progressValue: prevState.progressDialog.progressValue + 1,
                },
              }));
            } else if (err) {
              this.setState((prevState) => ({
                progressDialog: {
                  ...this.state.progressDialog,
                  progressValue: prevState.progressDialog.progressValue + 1,
                  errors: 'error',
                },
              }));
              return;
            }
            resolve();
          },
          true
        );
      });
    }

    this.setState({
      selectedBankingTransactionIds: new Set(),
    });

    this.props.remoteDocumentIds.api.fetch(() => {
      const lastDocumentId = this.props.documentIds.slice(-1).pop();
      const queryWithoutState = this.removeStateFromURLQuery();
      this.props.history.pushDossier(`/edit/${lastDocumentId + queryWithoutState}`);
      this.props.fetchBankingTransactions(this.updateGrid);
    });
  }

  handleBankingTransactionAssigned(bankingTransaction) {
    if (!this.props.document) {
      return;
    }
    const newDocument = Object.assign({}, this.props.document, { bankingTransaction });
    this.props.remoteDocument.api.update(newDocument, () => {
      this.props.fetchBankingTransactions(this.updateGrid);
    });
  }

  handleTransactionClick(e, bankingTransaction) {
    const documentId = bankingTransaction.accountingDocumentId;

    if (documentId) {
      const queryWithoutState = this.removeStateFromURLQuery();
      this.setState({ selectedBankingTransactionIds: new Set() });
      this.props.history.pushDossier(`/edit/${documentId + queryWithoutState}`);
    } else {
      this.toggleTransactionSelection(bankingTransaction, e.metaKey || e.ctrlKey);
    }
  }

  toggleTransactionSelection(bankingTransaction, keepSelection) {
    this.setState((prevState) => {
      const selectedBankingTransactionIds = new Set(prevState.selectedBankingTransactionIds);

      if (!keepSelection) {
        selectedBankingTransactionIds.clear();
      }

      if (keepSelection && selectedBankingTransactionIds.has(bankingTransaction.id)) {
        selectedBankingTransactionIds.delete(bankingTransaction.id);
      } else {
        selectedBankingTransactionIds.add(bankingTransaction.id);
      }

      return {
        selectedBankingTransactionIds,
      };
    }, this.updateGrid);
  }

  updateGrid() {
    this.transactionList && this.transactionList.forceUpdateGrid();
  }

  removeStateFromURLQuery() {
    const query = qs.parse(this.props.location.search, { strictNullHandling: true, ignoreQueryPrefix: true });
    delete query.state;
    return qs.stringify(query, { strictNullHandling: true, ignoreQueryPrefix: true });
  }

  toggleFilter() {
    this.setState((prevState) => ({
      showFilter: !prevState.showFilter,
    }));
  }

  filterTransactions(transactions) {
    const amountFrom = Math.round(100 * parseAmount(this.state.filter.amountFrom));
    const amountTo = Math.round(100 * parseAmount(this.state.filter.amountTo));
    const dateFrom = moment(this.state.filter.dateFrom, 'DD.MM.YYYY', true);
    const dateTo = moment(this.state.filter.dateTo, 'DD.MM.YYYY', true);
    const descriptionSearchTerms = this.state.filter.description.toLowerCase().trim().split(/\s+/);

    return transactions
      .filter((trx) => this.state.filter.showType[trx.type])
      .filter((trx) => this.state.filter.showState[trx.state])
      .filter((trx) => {
        if (!isNaN(amountFrom) && trx.amount < amountFrom) {
          return false;
        }

        if (!isNaN(amountTo) && trx.amount > amountTo) {
          return false;
        }

        return true;
      })
      .filter((trx) => {
        const date = moment(trx.date, 'YYYY-MM-DD');

        if (dateFrom.isValid() && date < dateFrom) {
          return false;
        }

        if (dateTo.isValid() && date > dateTo) {
          return false;
        }

        return true;
      })
      .filter((trx) => {
        return descriptionSearchTerms.every((term) => {
          if (!trx.description) {
            return true;
          }
          return trx.description.toLowerCase().includes(term);
        });
      });
  }

  renderBankingTransaction({ index, key, style }) {
    const transaction = this.filteredTransactions[index];

    return (
      <BankingTransaction
        key={key}
        style={style}
        bankingTransaction={transaction}
        onAssign={(cb) => this.handleBankingTransactionAssigned(transaction, cb)}
        onGenerate={() => this.toggleTemplateSelect(transaction)}
        cropDescription={false}
        onClick={this.handleTransactionClick}
        currency={transaction.bankingDocument.account.currency}
        readOnly={this.props.readOnly}
        selected={this.state.selectedBankingTransactionIds.has(transaction.id)}
        onUpdateGrid={() => this.transactionList.forceUpdateGrid()}
      />
    );
  }

  getRowHeight({ index }) {
    const transaction = this.filteredTransactions[index];
    if (!transaction) {
      return 0;
    }
    const { description } = transaction;
    const countNewLines = formatDescription(description).split(/\r\n|\r|\n/).length;

    let baseHeight = 95;
    let baseLineCount = 4;
    let lineHeight = 11;
    let numberOfAdditionalLines = Math.max(0, countNewLines - baseLineCount);

    return baseHeight + numberOfAdditionalLines * lineHeight;
  }

  toggleTemplateSelect(bankingTransaction) {
    this.setState({ selectedBankingTransactionIds: new Set([bankingTransaction.id]), showTemplateSelect: true });
  }

  renderTemplateSelect() {
    if (!this.state.showTemplateSelect) {
      return;
    }
    return <TemplateSelect onDismiss={this.generateDocumentFromBankingTransactions} />;
  }
  renderProgress() {
    if (this.state.showProgressDialog) {
      return (
        <ProgressModal
          error={this.state.progressDialog.errors}
          progressValue={this.state.progressDialog.progressValue}
          maxProgress={this.state.progressDialog.maxProgress}
          closeModal={this.closeModal}
        />
      );
    }
  }
  renderTransactionHeader() {
    const { t } = this.props;

    const numberOfTransactions = this.filterTransactions(this.getTransactionWithStateProperty()).length;

    return (
      <Table className={'transactionHeader'}>
        <tbody>
          <tr className={'title'}>
            <td>
              <Button color="link" onClick={this.toggleFilter}>
                {t('filter')}
              </Button>
            </td>
            <td>{t('number_of_transactions', numberOfTransactions)}</td>
          </tr>
          <BankingTransactionFilter
            t={t}
            showFilter={this.state.showFilter}
            onFilterChange={this.onFilterChange}
            filter={this.state.filter}
          />
          {this.renderSelectionInfo()}
        </tbody>
      </Table>
    );
  }

  renderSelectionInfo() {
    const { t } = this.props;

    if (this.state.selectedBankingTransactionIds.size === 0) {
      return;
    }

    return (
      <tr>
        <th colSpan="3">
          <div className="selectionInfo">
            <span>
              <Tooltip title={t('tooltip_clear_selection')}>
                <MdClose size="16" onClick={() => this.setState({ selectedBankingTransactionIds: new Set() })} />
              </Tooltip>
            </span>
            <span>
              {t('num_selected', this.state.selectedBankingTransactionIds.size)}
              <Button color={'link'} onClick={this.selectAll}>
                {t('select_all')}
              </Button>
            </span>
            <span>
              <Tooltip title={t('tooltip_create_documents_from_transactions')}>
                <FaPlusCircle size="16" onClick={() => this.setState({ showTemplateSelect: true })} />
              </Tooltip>
            </span>
          </div>
        </th>
      </tr>
    );
  }

  onFilterChange(filter) {
    this.setState({ filter });
    this.setState({ selectedBankingTransactionIds: new Set() });
  }

  renderTransactions(bankingTransactions) {
    if (bankingTransactions.length === 0) {
      return <div />;
    }

    this.filteredTransactions = this.filterTransactions(bankingTransactions);

    return (
      <div className={'transactionBody'}>
        <AutoSizer>
          {({ width, height }) => (
            <List
              height={height}
              rowCount={this.filteredTransactions.length}
              rowHeight={this.getRowHeight}
              width={width}
              rowRenderer={this.renderBankingTransaction}
              ref={(list) => (this.transactionList = list)}
            />
          )}
        </AutoSizer>
      </div>
    );
  }

  getTransactionWithStateProperty() {
    return this.props.bankingTransactions.slice().map((t) => {
      t = clone(t);
      const open = !t.accountingDocumentId;
      const states = [Constants.DOCUMENT_STATE.OPEN, Constants.DOCUMENT_STATE.VERIFIED];
      const unconfirmed = !open && states.includes(t.accountingDocument.state);

      let state = 'confirmed';
      if (open) {
        state = 'open';
      } else if (unconfirmed) {
        state = 'unconfirmed';
      }
      t.state = state;
      return t;
    });
  }

  /**
   * sort by property "state" in this order: open -> unconfirmed -> confirmed
   */
  sortTransactions(a, b) {
    const sortResult =
      ['open', 'unconfirmed', 'confirmed'].indexOf(a.state) - ['open', 'unconfirmed', 'confirmed'].indexOf(b.state);
    const dateSortResult = moment.utc(a.date).diff(moment.utc(b.date));
    return sortResult !== 0 ? sortResult : dateSortResult;
  }

  render() {
    const bankingTransactions = this.getTransactionWithStateProperty().sort(this.sortTransactions);
    return (
      <div className={'BankingTransactionList'}>
        {this.renderTemplateSelect()}
        {this.renderProgress()}
        {this.renderTransactionHeader()}
        {this.renderTransactions(bankingTransactions)}
      </div>
    );
  }
}

BankingTransactionList.propTypes = {
  bankingTransactions: PropTypes.arrayOf(PropTypes.object).isRequired,
  remoteDocument: PropTypes.object.isRequired,
  document: PropTypes.object,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  fetchBankingTransactions: PropTypes.func.isRequired,
  remoteDocumentIds: RemoteDataPropType,
  documentIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  readOnly: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

export default withRouter(translate(consumesDocument(consumesDocumentIds(BankingTransactionList))));
