import React, { Component } from 'react';
import { Table, Input } from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment';
import translate from 'containers/translate';
import MultiToggle from 'react-multi-toggle';
import clone from 'clone';
import classnames from 'classnames';
import { FaSortDown, FaSortUp, FaFilePdf } from 'react-icons/fa';
import { consumesRelevantDocumentIDs, providesRelevantDocumentIDs } from 'contexts/RelevantDocumentIDsContext';
import Currency from 'components/currency/Currency';
import DocumentWindow from 'components/documentWindow/DocumentWindow';
import RemoteDataPropType from 'lib/prop-types/RemoteDataPropType';
import DocumentRelevant from 'components/documentRelevant/DocumentRelevant';
import DateInput from 'components/dateInput/DateInput';
import GlobalMutation from 'components/globalMutation/GlobalMutation';
import withDocumentOverview from 'containers/withDocumentOverview';
import './DocumentOverview.scss';

class DocumentOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: {
        date: '',
        transactionAmount: '',
        documentAmount: '',
        description: '',
        lineAmount: '',
        account: '',
        vatCode: '',
      },
      sortColumn: null,
      sortDirection: 'ASC',
      documentId: null,
    };

    this.renderTransactionEntryWithChildren = this.renderTransactionEntryWithChildren.bind(this);
    this.renderTransactionEntry = this.renderTransactionEntry.bind(this);
    this.renderDocumentEntry = this.renderDocumentEntry.bind(this);
    this.renderDocumentEntryWithTransactions = this.renderDocumentEntryWithTransactions.bind(this);
    this.renderAccountingTransactionEntries = this.renderAccountingTransactionEntries.bind(this);
    this.filterTransaction = this.filterTransaction.bind(this);
    this.documentFilterReduction = this.documentFilterReduction.bind(this);
    this.accountingTransactionReduction = this.accountingTransactionReduction.bind(this);
    this.handleDocumentRelevantChange = this.handleDocumentRelevantChange.bind(this);
    this.sortTransactions = this.sortTransactions.bind(this);
  }

  handleDocumentRelevantChange(document) {
    this.props.remoteRelevantDocumentIDs.api.update(document);
  }

  filterDocumentOverview(documentOverview) {
    return documentOverview.filter(this.filterTransaction);
  }

  filterTransaction(transaction) {
    let isIncluded = this.doesSatisfyFilter(transaction, 'date', 'date');
    isIncluded = isIncluded && this.doesSatisfyFilter(transaction, 'transactionAmount', 'amount');
    isIncluded = isIncluded && transaction.accountingDocuments.reduce(this.documentFilterReduction, false);
    return isIncluded;
  }

  doesSatisfyFilter(obj, filterKey, propertyName) {
    if (!this.state.filter[filterKey] || this.state.filter[filterKey].length === 0) {
      return true;
    }
    return (
      Object.prototype.hasOwnProperty.call(obj, propertyName) &&
      this.areValuesEqual(this.state.filter[filterKey], obj[propertyName])
    );
  }

  areValuesEqual(expectation, value) {
    if (typeof value === 'string') {
      if (this.isDate(value)) {
        return this.areDatesEqual(expectation, value);
      }
      return value.toLowerCase().includes(expectation.toLowerCase());
    }
    const intExpectation = +expectation;
    return intExpectation && intExpectation === value / 100;
  }

  areDatesEqual(expectation, value) {
    const expectationMoment = moment(expectation, 'DD.MM.YYYY');
    const valueMoment = moment(value, 'YYYY-MM-DD');
    return expectationMoment.isSame(valueMoment, 'day');
  }

  isDate(value) {
    return /[0-9]{4}-[0-9]{2}-[0-9]{2}/.test(value);
  }

  documentFilterReduction(acc, document) {
    let isInFilter = this.doesSatisfyFilter(document, 'documentAmount', 'totalAmount');
    isInFilter =
      isInFilter &&
      document.accountingTransactions.reduce((acc, a) => this.accountingTransactionReduction(acc, a, document), false);
    return acc || isInFilter;
  }

  accountingTransactionReduction(acc, accTrans, document) {
    const isAmountInTransaction = this.doesSatisfyFilter(accTrans, 'lineAmount', 'amount');
    const isDebitAccountInTransaction = this.doesSatisfyFilter(accTrans, 'account', 'exportDebitAccount');
    const isCreditAccountInTransaction = this.doesSatisfyFilter(accTrans, 'account', 'exportCreditAccount');
    const isVatCodeInTransaction = this.doesSatisfyFilter(accTrans, 'vatCode', 'exportVatCode');
    const isAccountingTextInTransaction = this.doesSatisfyFilter(accTrans, 'description', 'accountingText');
    const isAccountingTextInDocument = this.doesSatisfyFilter(document, 'description', 'accountingText');
    const isTextInCompanyName = this.doesSatisfyFilter(document.company, 'description', 'name');

    const isInFilter =
      isAmountInTransaction &&
      (isDebitAccountInTransaction || isCreditAccountInTransaction) &&
      isVatCodeInTransaction &&
      (isAccountingTextInTransaction || isAccountingTextInDocument || isTextInCompanyName);
    return acc || isInFilter;
  }

  sortDocumentOverview(documentOverview) {
    if (!this.state.sortColumn) {
      return documentOverview;
    }

    documentOverview = documentOverview.slice().sort(this.sortTransactions);

    if (this.state.sortDirection === 'DESC') {
      documentOverview.reverse();
    }

    return documentOverview;
  }

  sortTransactions(a, b) {
    const dA = a.accountingDocuments[0];
    const dB = b.accountingDocuments[0];

    const accA = dA.accountingTransactions[0];
    const accB = dB.accountingTransactions[0];

    let result = this.compareValues(a, b, 'date', 'date');
    result += this.compareValues(a, b, 'transactionAmount', 'amount');

    result += this.compareValues(dA, dB, 'exchangeRate', 'exportExchangeRate');
    result += this.compareValues(dA, dB, 'documentAmount', 'totalAmount');
    result += this.compareValues(dA, dB, 'documentRelevant', 'relevant');

    result += this.compareDescription(accA, accB, dA, dB);
    result += this.compareValues(accA, accB, 'lineAmount', 'amount');
    result += this.compareValues(accA, accB, 'debit', 'exportDebitAccount');
    result += this.compareValues(accA, accB, 'credit', 'exportCreditAccount');
    result += this.compareValues(accA, accB, 'vatCode', 'exportVatCode');

    return result;
  }

  compareValues(a, b, columnName, propertyName) {
    if (this.state.sortColumn !== columnName) {
      return 0;
    }
    const aV = a[propertyName];
    const bV = b[propertyName];

    if (typeof aV === 'string') {
      return aV.localeCompare(bV);
    }

    if (aV > bV) {
      return 1;
    } else if (aV < bV) {
      return -1;
    }

    return 0;
  }

  compareDescription(transaction1, transaction2, document1, document2) {
    if (this.state.sortColumn !== 'description') {
      return 0;
    }
    const descA = this.renderAccountingText(transaction1, document1);
    const descB = this.renderAccountingText(transaction2, document2);

    return descA.localeCompare(descB);
  }

  renderMainTableHeaderLine() {
    const { t } = this.props;

    return (
      <React.Fragment>
        <tr>
          <td />
          {this.renderFilterCell('date')}
          {this.renderFilterCell('transactionAmount')}
          <td />
          {this.renderFilterCell('documentAmount')}
          <td />
          <td />
          {this.renderFilterCell('description')}
          {this.renderFilterCell('lineAmount')}
          {this.renderFilterCell('account', 2)}
          {this.renderFilterCell('vatCode')}
          <td />
        </tr>
        <tr>
          <th>#</th>
          <th onClick={() => this.updateSorting('date')}>
            {t('date')}
            {this.renderSortIcon('date')}
          </th>
          <th onClick={() => this.updateSorting('transactionAmount')}>
            {t('amount')}
            {this.renderSortIcon('transactionAmount')}
          </th>
          <th onClick={() => this.updateSorting('exchangeRate')}>
            {t('document_exchange_rate')}
            {this.renderSortIcon('exchangeRate')}
          </th>
          <th onClick={() => this.updateSorting('documentAmount')}>
            {t('document_amount')}
            {this.renderSortIcon('documentAmount')}
          </th>
          <th onClick={() => this.updateSorting('documentRelevant')}>
            {t('document_relevant')}
            {this.renderSortIcon('documentRelevant')}
          </th>
          <th>{t('has_file')}</th>
          <th onClick={() => this.updateSorting('description')}>
            {t('accounting_text')}
            {this.renderSortIcon('description')}
          </th>
          <th onClick={() => this.updateSorting('lineAmount')}>
            {t('accounting_transaction_amount')}
            {this.renderSortIcon('lineAmount')}
          </th>
          <th onClick={() => this.updateSorting('debit')}>
            {t('debit')}
            {this.renderSortIcon('debit')}
          </th>
          <th onClick={() => this.updateSorting('credit')}>
            {t('credit')}
            {this.renderSortIcon('credit')}
          </th>
          <th onClick={() => this.updateSorting('vatCode')}>
            {t('vat_code')}
            {this.renderSortIcon('vatCode')}
          </th>
        </tr>
      </React.Fragment>
    );
  }

  renderFilterCell(filterKey, collSpan = 1) {
    const id = 'datalist-' + filterKey;
    return (
      <td colSpan={collSpan}>
        <Input
          type="text"
          value={this.state.filter[filterKey]}
          list={id}
          onChange={(e) => this.handleFilterInputChange(e, filterKey)}
        />
      </td>
    );
  }

  handleFilterInputChange(e, filterKey) {
    const value = e.target.value;

    this.setState((prevState) => {
      return {
        filter: {
          ...prevState.filter,
          [filterKey]: value,
        },
      };
    });
  }

  updateSorting(sortColumn) {
    if (sortColumn !== this.state.sortColumn) {
      this.setState({
        sortColumn,
        sortDirection: 'ASC',
      });
    } else {
      if (this.state.sortDirection === 'ASC') {
        this.setState({
          sortDirection: 'DESC',
        });
      } else {
        this.setState({
          sortColumn: null,
        });
      }
    }
  }

  renderSortIcon(column) {
    if (column !== this.state.sortColumn) {
      return;
    }

    if (this.state.sortDirection === 'ASC') {
      return <FaSortUp />;
    } else {
      return <FaSortDown />;
    }
  }

  renderTransactionEntryWithChildren(entry, index) {
    if (!entry) {
      return null;
    }
    return (
      <React.Fragment key={entry.id + '_' + index + '_transaction'}>
        {this.renderTransactionEntry(entry, index)}
        {entry.accountingDocuments.map(this.renderDocumentEntryWithTransactions)}
        <tr className={'empty'}>
          <td colSpan={11} />
        </tr>
      </React.Fragment>
    );
  }

  renderTransactionEntry(transactionEntry, index) {
    const firstDocument = transactionEntry.accountingDocuments[0];
    const currency = transactionEntry.bankingDocument ? transactionEntry.bankingDocument.account.currency : 'CHF';
    return (
      <tr
        onClick={(e) => {
          this.setState({ documentId: firstDocument.id });
          e.stopPropagation();
        }}
        className={'transactionHeader'}
      >
        <td>{index + 1}</td>
        <td>{moment(transactionEntry.date, 'YYYY-MM-DD').format('DD.MM.YYYY')}</td>
        <td>
          <Currency currency={currency} value={transactionEntry.amount} />
        </td>
        {this.renderDocumentEntry(firstDocument)}
      </tr>
    );
  }

  renderDocumentEntry(documentEntry) {
    const firstAccTrans = documentEntry.accountingTransactions[0];
    return (
      <React.Fragment>
        <td>{documentEntry.exportExchangeRate}</td>
        <td>
          <Currency currency={documentEntry.currency} value={documentEntry.totalAmount} />
        </td>
        <td onClick={(e) => e.stopPropagation()}>
          <DocumentRelevant document={documentEntry} onDocumentChange={this.handleDocumentRelevantChange} />
        </td>
        <td>{this.renderHasFileIcon(documentEntry)}</td>
        {this.renderAccountingTransactionEntry(firstAccTrans, documentEntry)}
      </React.Fragment>
    );
  }

  renderAccountingTransactionEntry(accTransEntry, document) {
    const accountingText = this.renderAccountingText(accTransEntry, document);
    return (
      <React.Fragment>
        <td>{accountingText}</td>
        <td>
          <Currency currency={document.currency} value={accTransEntry.amount} />
        </td>
        <td>{accTransEntry.exportDebitAccount}</td>
        <td>{accTransEntry.exportCreditAccount}</td>
        <td>{this.renderExportVatCode(accTransEntry)}</td>
      </React.Fragment>
    );
  }

  renderExportVatCode(accTransEntry) {
    const className = classnames({ red: accTransEntry.hasVatCodeWarning });
    return <span className={className}>{accTransEntry.exportVatCode}</span>;
  }

  renderDocumentEntryWithTransactions(documentEntry, index) {
    const accTransactions = documentEntry.accountingTransactions;
    const documentPart = this.renderDocumentEntryWithoutTransaction(documentEntry, index);
    return (
      <React.Fragment key={documentEntry.id + '_' + index + '_document'}>
        {documentPart}
        {accTransactions.map((a, i) => this.renderAccountingTransactionEntries(a, documentEntry, i))}
      </React.Fragment>
    );
  }

  renderDocumentEntryWithoutTransaction(documentEntry, index) {
    if (index === 0) {
      return null;
    }
    return (
      <tr
        onClick={(e) => {
          this.setState({ documentId: documentEntry.id });
          e.stopPropagation();
        }}
        className={'documentHeader'}
      >
        <td colSpan={3} className={'noBorder'} />
        {this.renderDocumentEntry(documentEntry)}
      </tr>
    );
  }

  renderAccountingTransactionEntries(accTransEntry, documentEntry, index) {
    if (index === 0) {
      return null;
    }
    return (
      <tr
        key={accTransEntry.id + '_' + index + '_accTransaction'}
        onClick={(e) => {
          this.setState({ documentId: documentEntry.id });
          e.stopPropagation();
        }}
        className={'accTransHeader'}
      >
        <td colSpan={7} className={'noBorder'} />
        {this.renderAccountingTransactionEntry(accTransEntry, documentEntry)}
      </tr>
    );
  }

  renderAccountingText(accTransEntry, document) {
    let accountingText = accTransEntry.accountingText ? accTransEntry.accountingText : document.accountingText;
    if (accountingText) {
      accountingText = document.company.name + ', ' + accountingText;
    } else {
      accountingText = document.company.name;
    }
    return accountingText;
  }

  renderDocumentWindow() {
    const documentId = this.state.documentId;
    if (!documentId) {
      return <div />;
    }

    return <DocumentWindow documentId={documentId} onDismiss={() => this.setState({ documentId: null })} />;
  }

  renderRelevantToggle() {
    const { t } = this.props;

    return (
      <div>
        <MultiToggle
          options={[
            {
              displayName: t('all'),
              value: false,
            },
            {
              displayName: t('only_relevant'),
              value: true,
            },
          ]}
          selectedOption={this.props.relevantSelection.onlyRelevant}
          onSelectOption={(onlyRelevant) => this.updateRelevantSelection('onlyRelevant', onlyRelevant)}
          className="mb-2"
        />
        {this.renderDateFilter()}
      </div>
    );
  }

  renderDateFilter() {
    if (!this.props.relevantSelection.onlyRelevant) {
      return null;
    }
    return (
      <React.Fragment>
        <DateInput
          date={this.props.relevantSelection.previewFrom}
          onInputChange={(value) => this.updateRelevantSelection('previewFrom', value)}
          locked={false}
          invalid={false}
        />
        <DateInput
          date={this.props.relevantSelection.previewTo}
          onInputChange={(value) => this.updateRelevantSelection('previewTo', value)}
          locked={false}
          invalid={false}
        />
      </React.Fragment>
    );
  }

  updateRelevantSelection(property, value) {
    const relevantSelection = clone(this.props.relevantSelection);
    relevantSelection[property] = value;
    this.props.onOnlyRelevantSelectionChanged(relevantSelection);
  }

  renderHasFileIcon(document) {
    if (!document.originalFileId) {
      return null;
    }

    return <FaFilePdf size="20" />;
  }

  render() {
    const { documentOverview } = this.props;

    const filteredDocumentOverview = this.filterDocumentOverview(documentOverview);
    const sortedDocumentOverview = this.sortDocumentOverview(filteredDocumentOverview);

    return (
      <div className={'DocumentOverview'}>
        {this.renderDocumentWindow()}
        <GlobalMutation visibleTransactions={sortedDocumentOverview} />
        {this.renderRelevantToggle()}
        <Table>
          <thead>{this.renderMainTableHeaderLine()}</thead>
          <tbody>{sortedDocumentOverview.map(this.renderTransactionEntryWithChildren)}</tbody>
        </Table>
      </div>
    );
  }
}

DocumentOverview.propTypes = {
  documentOverview: PropTypes.arrayOf(PropTypes.object).isRequired,
  remoteRelevantDocumentIDs: RemoteDataPropType,
  onOnlyRelevantSelectionChanged: PropTypes.func.isRequired,
  relevantSelection: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withDocumentOverview(
  providesRelevantDocumentIDs(consumesRelevantDocumentIDs(translate(DocumentOverview)))
);
