import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Button, FormGroup, Input } from 'reactstrap';
import MultiToggle from 'react-multi-toggle';
import translate from 'containers/translate';
import PeriodEdit from 'components/periodEdit/PeriodEdit';
import { consumesDossier } from 'contexts/DossierContext';
import { consumesPeriods } from 'contexts/PeriodsContext';
import RemoteDataPropType from 'lib/prop-types/RemoteDataPropType';
import AdminPeriodEdit from 'components/adminPeriodEdit/AdminPeriodEdit';
import Constants from 'config/Constants';

import './DossierDetails.scss';

class DossierDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      language: this.props.dossier.language,
      notes: this.props.dossier.notes,
      name: this.props.dossier.name,
      debitorContraAccount: this.props.dossier.debitorContraAccount,
      canDeleteDossier: false,
      isTest: this.props.dossier.isTest,
    };

    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
    this.handleNotesChange = this.handleNotesChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleDebitorAccountChange = this.handleDebitorAccountChange.bind(this);
    this.handleIsTestChange = this.handleIsTestChange.bind(this);
    this.handlePeriodEnd = this.handlePeriodEnd.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dossier.notes !== this.props.dossier.notes) {
      this.setState({
        notes: this.props.dossier.notes,
      });
    }

    if (prevProps.dossier.language !== this.props.dossier.language) {
      this.setState({
        language: this.props.dossier.language,
      });
    }

    if (prevProps.dossier.debitorContraAccount !== this.props.dossier.debitorContraAccount) {
      this.setState({
        debitorContraAccount: this.props.dossier.debitorContraAccount,
      });
    }
  }

  render() {
    const { t, selectedPeriod } = this.props;
    const vatType = {
      [Constants.VAT_TYPE.SALDO]: t('proportion'),
      [Constants.VAT_TYPE.EFFECTIVE]: t('effective'),
      [Constants.VAT_TYPE.NONE]: t('none'),
    }[selectedPeriod.vatType];

    const languageOptions = [
      { value: 'DE', label: 'DE' },
      { value: 'FR', label: 'FR' },
      { value: 'IT', label: 'IT' },
    ];
    const languageValue = languageOptions.find((option) => option.value === this.state.language);

    return (
      <div className={'DossierDetails'}>
        <hr />
        <h1>{t('dossier_details')}</h1>
        <dl>
          <dt>{t('period')}</dt>
          <dd>
            <PeriodEdit onPeriodEnd={this.handlePeriodEnd} />
            <AdminPeriodEdit />
          </dd>
          <dt>{t('dossier_name')}</dt>
          <Input value={this.state.name} onChange={this.handleNameChange} />
          <dt>{t('contra_account_debitor')}</dt>
          <dd>
            <Input value={this.state.debitorContraAccount} onChange={this.handleDebitorAccountChange} />
          </dd>
          <dt>{t('vat_type')}</dt>
          <dd>{vatType}</dd>
          <dt>{t('language')}</dt>
          <dd>
            <Select
              options={languageOptions}
              isClearable={false}
              backspaceRemovesValue={false}
              value={languageValue}
              onChange={this.handleLanguageChange}
              className="Select"
            />
          </dd>
          <dt>{t('test_dossier')}</dt>
          <MultiToggle
            options={[
              {
                displayName: t('yes'),
                value: true,
              },
              {
                displayName: t('no'),
                value: false,
              },
            ]}
            selectedOption={this.state.isTest}
            onSelectOption={this.handleIsTestChange}
          />
          <dt>{t('notes')}</dt>
          <dd>
            <FormGroup>
              <textarea className="form-control" rows="5" value={this.state.notes} onChange={this.handleNotesChange} />
            </FormGroup>
            {this.renderSaveButton()}
          </dd>
        </dl>
      </div>
    );
  }

  handleNameChange(e) {
    this.setState({
      name: e.target.value,
    });
  }

  handleDebitorAccountChange(e) {
    this.setState({
      debitorContraAccount: e.target.value,
    });
  }

  handleLanguageChange(option) {
    this.setState({ language: option.value });
  }

  handleIsTestChange(value) {
    this.setState({ isTest: value });
  }

  handlePeriodEnd() {
    this.props.remoteDossier.api.fetch();
  }

  handleNotesChange(e) {
    this.setState({
      notes: e.target.value,
    });
  }

  renderSaveButton() {
    const { t, dossier } = this.props;

    if (
      dossier.notes === this.state.notes &&
      dossier.name === this.state.name &&
      dossier.language === this.state.language &&
      dossier.debitorContraAccount === this.state.debitorContraAccount &&
      dossier.isTest === this.state.isTest
    ) {
      return;
    }

    return (
      <Button type="button" onClick={this.handleSaveClick} color="primary">
        {t('save')}
      </Button>
    );
  }

  handleSaveClick() {
    const dossier = {
      ...this.props.dossier,
      language: this.state.language,
      notes: this.state.notes,
      name: this.state.name,
      isTest: this.state.isTest,
      debitorContraAccount: this.state.debitorContraAccount,
    };

    this.props.remoteDossier.api.update(dossier);
  }
}

DossierDetails.propTypes = {
  selectedPeriod: PropTypes.object,
  t: PropTypes.func.isRequired,
  dossier: PropTypes.object.isRequired,
  remoteDossier: RemoteDataPropType,
};

export default consumesPeriods(consumesDossier(translate(DossierDetails)));
