import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import { Draggable } from 'react-beautiful-dnd';
import deepEqual from 'deep-equal';
import AccountEdit from 'components/accountEdit/AccountEdit';
import CompanyNamePicker from 'components/CompanyNamePicker';
import VatSelect from 'components/vatSelect/VatSelect';
import { FaArrowsAltV, FaTrash } from 'react-icons/fa';
import translate from 'containers/translate';
import { consumesUsedCompanies } from 'contexts/UsedCompaniesContext';
import './TemplateListEntry.scss';

class TemplateListEntry extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dirtyTemplate: this.props.template,
    };

    this.onBlur = this.onBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleVatChange = this.handleVatChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (!deepEqual(prevProps.template, this.props.template)) {
      this.setState({
        dirtyTemplate: this.props.template,
      });
    }
  }

  onBlur() {
    this.props.onTemplateChange(this.state.dirtyTemplate);
  }

  handleChange(key, value, cb) {
    this.setState(
      (prevState) => {
        return {
          dirtyTemplate: Object.assign({}, prevState.dirtyTemplate, { [key]: value }),
        };
      },
      () => cb && cb()
    );
  }

  handleVatChange(value) {
    if (this.isNumeric(value)) {
      this.setVatToState(value, 'NORMAL');
    } else {
      this.setVatToState(0, value);
    }
  }

  isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  setVatToState(vat, vatType) {
    this.setState((prevState) => {
      return {
        dirtyTemplate: Object.assign({}, prevState.dirtyTemplate, { vat, vatType }),
      };
    }, this.onBlur);
  }

  renderTrashIcon() {
    if (this.props.locked) {
      return;
    }
    return <FaTrash onClick={() => this.props.onTemplateDelete(this.state.dirtyTemplate)} />;
  }

  render() {
    return (
      <Draggable draggableId={this.props.template.id.toString()} index={this.props.index}>
        {(provided) => (
          <tr className={'TemplateListEntry'} ref={provided.innerRef} {...provided.draggableProps}>
            <td {...provided.dragHandleProps}>
              <FaArrowsAltV />
            </td>
            <td>
              <Input
                value={this.state.dirtyTemplate.name}
                onChange={(e) => this.handleChange('name', e.target.value)}
                onBlur={this.onBlur}
                disabled={this.props.locked}
              />
            </td>
            <td>
              <CompanyNamePicker
                presetCompanyName={this.props.template.company.name}
                allCompanyNames={this.props.companyNames}
                usedCompanyNames={this.props.usedCompanyNames}
                onCompanyNameChange={(name) => this.handleChange('company', { name: name }, this.onBlur)}
                clearable={false}
                disabled={this.props.locked}
              />
            </td>
            <td>
              <AccountEdit
                onAccountChange={(account) => this.handleChange('account', account, this.onBlur)}
                account={this.state.dirtyTemplate.account}
                locked={this.props.locked}
              />
            </td>
            <td>
              <VatSelect
                locked={this.props.locked}
                dossier={this.props.dossier}
                onVatChange={this.handleVatChange}
                presetVat={this.state.dirtyTemplate.vat}
                presetVatType={this.state.dirtyTemplate.vatType}
              />
            </td>
            <td>
              <Input
                value={this.state.dirtyTemplate.accountingText}
                onChange={(e) => this.handleChange('accountingText', e.target.value)}
                onBlur={this.onBlur}
                disabled={this.props.locked}
              />
            </td>
            <td>{this.renderTrashIcon()}</td>
          </tr>
        )}
      </Draggable>
    );
  }
}

TemplateListEntry.propTypes = {
  t: PropTypes.func.isRequired,
  template: PropTypes.object.isRequired,
  dossier: PropTypes.object.isRequired,
  companyNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  usedCompanyNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  onTemplateChange: PropTypes.func.isRequired,
  onTemplateDelete: PropTypes.func.isRequired,
  locked: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
};

export default consumesUsedCompanies(translate(TemplateListEntry));
