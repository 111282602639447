import React from 'react';
import PropTypes from 'prop-types';
import RemoteDataProvider from './RemoteDataProvider';
import { consumesDocument } from 'contexts/DocumentContext';

const AccountingTextProposalsContext = React.createContext();

function providesAccountingTextProposals(Component) {
  class RemoteAccountingTextProposals extends React.Component {
    render() {
      const accountingDocumentId = this.props.document.id;
      const baseURL = `/accounting-documents/${accountingDocumentId}/accounting-text-proposals`;

      return (
        <RemoteDataProvider
          baseURL={baseURL}
          isCollection
          render={(remoteData) => {
            return (
              <AccountingTextProposalsContext.Provider value={remoteData}>
                <Component {...this.props} />
              </AccountingTextProposalsContext.Provider>
            );
          }}
        />
      );
    }
  }

  RemoteAccountingTextProposals.propTypes = {
    document: PropTypes.object.isRequired,
  };

  return consumesDocument(RemoteAccountingTextProposals);
}

function consumesAccountingTextProposals(Component, provideValueAs = 'accountingTextProposals') {
  return function ComponentThatConsumesAccountingTextProposals(props) {
    return (
      <AccountingTextProposalsContext.Consumer>
        {(remoteData) => {
          const consumedProps = {
            remoteAccountingTextProposals: remoteData,
            [provideValueAs]: remoteData.value,
          };

          return <Component {...props} {...consumedProps} />;
        }}
      </AccountingTextProposalsContext.Consumer>
    );
  };
}

export { providesAccountingTextProposals, consumesAccountingTextProposals };
