import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Container, Row, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import translate from 'containers/translate';
import withRule from 'containers/withRule';
import AccountEdit from 'components/accountEdit/AccountEdit';
import { consumesAccounts, providesAccounts } from 'contexts/AccountsContext';
import './RuleEdit.scss';

class RuleEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      account: props.presetAccount,
    };
  }

  onConfirmClick(isDossierRule, isSingleTimeRule) {
    const account = this.state.account;
    const rule = {
      account,
      company: { name: this.props.companyName },
    };
    if (!isSingleTimeRule) {
      this.props.onNewRule(rule, isDossierRule);
    }
    this.props.onDismiss(account);
  }

  renderGlobalEditButton() {
    const { t } = this.props;

    return (
      <Button color="orange" onClick={() => this.onConfirmClick(false, false)}>
        {t('for_all_dossiers')}
      </Button>
    );
  }

  render() {
    const { t } = this.props;

    return (
      <Modal isOpen className="RuleEdit" size="lg" toggle={() => this.props.onDismiss()}>
        <ModalHeader toggle={() => this.props.onDismiss()}>{t('define_rule')}</ModalHeader>
        <ModalBody>
          <div className="editMask">
            <Container className={'formContainer'}>
              <Row>
                <Col xs={3}>{t('company')}</Col>
                <Col xs={8}>{t('account')}</Col>
              </Row>
              <Row>
                <Col xs={3}>{this.props.companyName}</Col>
                <Col xs={8}>
                  <AccountEdit
                    onAccountChange={(account) => this.setState({ account })}
                    account={this.state.account}
                    setFocusOnMount={true}
                    accountBlacklist={this.props.accounts.filter((a) => a.hasDocument)}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="info" onClick={() => this.onConfirmClick(false, true)}>
            {t('for_this_document')}
          </Button>
          <Button color="warning" onClick={() => this.onConfirmClick(true, false)}>
            {t('for_this_dossier')}
          </Button>
          {this.renderGlobalEditButton()}
        </ModalFooter>
      </Modal>
    );
  }
}
RuleEdit.propTypes = {
  companyName: PropTypes.string.isRequired,
  accounts: PropTypes.arrayOf(PropTypes.object).isRequired,
  presetAccount: PropTypes.string.isRequired,
  onDismiss: PropTypes.func.isRequired,
  onNewRule: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withRule(providesAccounts(consumesAccounts(translate(RuleEdit))));
