import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import translate from 'containers/translate';
import BankingTransactionProposal from 'components/bankingTransactionProposal/BankingTransactionProposal';
import './BankingTransactionSelect.scss';

class BankingTransactionSelect extends Component {
  renderBankingTransactions() {
    return this.props.bankingTransactions.map((transaction) => {
      return (
        <BankingTransactionProposal
          key={transaction.id}
          bankingTransaction={transaction}
          onClick={(e, bankingTransaction) => this.props.onDismiss(bankingTransaction)}
          currency={transaction.bankingDocument.account.currency}
        />
      );
    });
  }

  render() {
    const { t } = this.props;

    return (
      <Modal isOpen className="BankingTransactionSelect" toggle={() => this.props.onDismiss()}>
        <ModalHeader toggle={() => this.props.onDismiss()}>{t('proposals')}</ModalHeader>
        <ModalBody>
          <div>{this.renderBankingTransactions()}</div>
        </ModalBody>
      </Modal>
    );
  }
}

BankingTransactionSelect.propTypes = {
  onDismiss: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  bankingTransactions: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default translate(BankingTransactionSelect);
