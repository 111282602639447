const MAX_CHARS_IN_LINE = 37;

function formatDescription(description) {
  if (!description) {
    return '';
  }

  let descriptionLines = description.split(/\r\n|\r|\n/);
  descriptionLines = descriptionLines.map((line) => {
    if (line.length > MAX_CHARS_IN_LINE) {
      return addNewLineAtPosition(line, MAX_CHARS_IN_LINE);
    }
    return line;
  });
  return descriptionLines.join('\n');
}

function addNewLineAtPosition(string, position) {
  return [string.slice(0, position), '\n', string.slice(position)].join('');
}

export default formatDescription;
