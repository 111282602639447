import React from 'react';
import RemoteDataProvider from './RemoteDataProvider';

const DossierStatisticContext = React.createContext();

function providesDossierStatistic(Component) {
  class RemoteDossierStatistic extends React.Component {
    render() {
      const baseURL = '/dossierStatistic';

      return (
        <RemoteDataProvider
          scope="period"
          baseURL={baseURL}
          isCollection
          render={(remoteData) => {
            return (
              <DossierStatisticContext.Provider value={remoteData}>
                <Component {...this.props} />
              </DossierStatisticContext.Provider>
            );
          }}
        />
      );
    }
  }

  return RemoteDossierStatistic;
}

function consumesDossierStatistic(Component, provideValueAs = 'dossierStatistic') {
  return function ComponentThatConsumesDossierStatistic(props) {
    return (
      <DossierStatisticContext.Consumer>
        {(remoteData) => {
          const consumedProps = {
            remoteDossierStatistic: remoteData,
            [provideValueAs]: remoteData.value,
          };

          return <Component {...props} {...consumedProps} />;
        }}
      </DossierStatisticContext.Consumer>
    );
  };
}

export { providesDossierStatistic, consumesDossierStatistic };
