import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, Button, CardBody, CardTitle } from 'reactstrap';
import Fuse from 'fuse.js';
import translate from 'containers/translate';
import './CompanyMergeSuggestions.scss';

const STOPWORDS = ['ag', 'gmbh', 'sagl', 'ltd', 'consulting', 'trading', 'management'];
const STOPWORDS_REGEX = new RegExp(`\\b${STOPWORDS.join('|')}\\b`, 'gi');

class CompanyMergeSuggestions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fuse: this.createFuse(props.companies),
      skipSuggestionsIndex: 0,
    };

    this.skipSuggestions = this.skipSuggestions.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.companies !== prevProps.companies) {
      this.setState({
        fuse: this.createFuse(this.props.companies),
      });
    }
  }

  createFuse(companies) {
    const options = {
      shouldSort: true,
      distance: 10,
      threshold: 0.05,
      minMatchCharLength: 3,
      keys: ['normalizedName'],
    };

    const companiesWithoutStopwords = companies.map((company) => {
      return {
        id: company.id,
        normalizedName: company.name.replace(STOPWORDS_REGEX, ''),
      };
    });

    return new Fuse(companiesWithoutStopwords, options);
  }

  skipSuggestions(skipSuggestionsIndex) {
    this.setState({
      skipSuggestionsIndex,
    });
  }

  getRuleForCompany(company) {
    return this.props.rules.find((rule) => rule.company.id === company.id);
  }

  isCompanyImmutable(company) {
    return !!this.getRuleForCompany(company);
  }

  renderCompanyItem(company) {
    const rule = this.getRuleForCompany(company);
    let ruleInfo = '';

    if (rule) {
      ruleInfo = ` (${rule.account})`;
    }

    return (
      <li>
        {company.name}
        {ruleInfo}
      </li>
    );
  }

  render() {
    let baseCompany;
    let suggestedCompany;
    let suggestionIndex;

    const hasSuggestion = this.props.companies.slice(this.state.skipSuggestionsIndex).some((company, index) => {
      const matches = this.state.fuse.search(company.name.replace(STOPWORDS_REGEX, ''));

      if (matches.length < 2) {
        return false;
      }

      let id = matches[1].item.id;

      if (id === company.id) {
        id = matches[0].item.id;
      }

      baseCompany = company;
      suggestedCompany = this.props.companies.find((c) => c.id === id);

      //Fuse index is out of sync with the company list.
      if (!suggestedCompany) {
        return false;
      }

      suggestionIndex = this.state.skipSuggestionsIndex + index;

      const bothImmutable = this.isCompanyImmutable(baseCompany) && this.isCompanyImmutable(suggestedCompany);

      return !bothImmutable;
    });

    if (!hasSuggestion) {
      return <div />;
    }

    const { t } = this.props;

    return (
      <Card className="mb-4">
        <CardBody>
          <CardTitle>{t('company_merge_suggestions')}</CardTitle>
          <ul>
            {this.renderCompanyItem(baseCompany)}
            {this.renderCompanyItem(suggestedCompany)}
          </ul>
          <Button color="primary" onClick={() => this.props.onAccept(baseCompany, suggestedCompany)}>
            {t('merge')}
          </Button>
          <Button color="secondary" className="ml-2" onClick={() => this.skipSuggestions(suggestionIndex + 1)}>
            {t('ignore_suggestion')}
          </Button>
        </CardBody>
      </Card>
    );
  }
}

CompanyMergeSuggestions.propTypes = {
  companies: PropTypes.arrayOf(PropTypes.object).isRequired,
  rules: PropTypes.arrayOf(PropTypes.object).isRequired,
  onAccept: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default translate(CompanyMergeSuggestions);
