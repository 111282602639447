import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import Tooltip from 'components/tooltip/Tooltip';
import translate from 'containers/translate';
import './SidePanelToggle.scss';

class SidePanelToggle extends Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.props.onToggle();
  }

  renderIcon() {
    if (this.props.closed) {
      return <FaChevronRight />;
    } else {
      return <FaChevronLeft />;
    }
  }

  render() {
    const { t } = this.props;
    const title = this.props.closed ? t('to_open') : t('close');

    return (
      <div className="SidePanelToggle content-with-sidepanel__toggle">
        <Tooltip title={title}>
          <Button onClick={this.onClick}>{this.renderIcon()}</Button>
        </Tooltip>
      </div>
    );
  }
}

SidePanelToggle.propTypes = {
  closed: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default translate(SidePanelToggle);
