import React from 'react';
import RemoteDataProvider from './RemoteDataProvider';

const UsedCompaniesContext = React.createContext();

function providesUsedCompanies(Component) {
  class RemoteUsedCompanies extends React.Component {
    render() {
      return (
        <RemoteDataProvider
          scope="dossier"
          baseURL="/companies"
          isCollection
          render={(remoteData) => {
            return (
              <UsedCompaniesContext.Provider value={remoteData}>
                <Component {...this.props} />
              </UsedCompaniesContext.Provider>
            );
          }}
        />
      );
    }
  }

  return RemoteUsedCompanies;
}

function consumesUsedCompanies(Component) {
  return function ComponentThatConsumesUsedCompanies(props) {
    return (
      <UsedCompaniesContext.Consumer>
        {(remoteData) => {
          const consumedProps = {
            remoteUsedCompanies: remoteData,
            usedCompanies: remoteData.value,
            usedCompanyNames: remoteData.value.map((c) => c.name),
          };

          return <Component {...props} {...consumedProps} />;
        }}
      </UsedCompaniesContext.Consumer>
    );
  };
}

export { providesUsedCompanies, consumesUsedCompanies };
