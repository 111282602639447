import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { withRouter } from 'containers/withRouter';
import errorBus from 'lib/errorBus';
import ServerTasksProvider from 'lib/ServerTasksProvider';
import { consumesDossier } from 'contexts/DossierContext';
import { consumesPeriods } from 'contexts/PeriodsContext';

export default function withDocumentCount(WrappedComponent) {
  class DocumentCountContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {
        count: {},
        error: null,
      };

      this.handleServerTasksDone = this.handleServerTasksDone.bind(this);
    }

    handleServerTasksDone() {
      this.loadStats();
    }

    componentDidUpdate(prevPops) {
      if (prevPops.dossier.id !== this.props.dossier.id) {
        const prevProvider = ServerTasksProvider.get(prevPops.dossier.id);
        prevProvider.release();
        prevProvider.emitter.removeEventListener('tasksdone', this.handleServerTasksDone);

        const provider = ServerTasksProvider.get(this.props.dossier.id).emitter;
        provider.acquire();
        provider.emitter.addEventListener('tasksdone', this.handleServerTasksDone);
      }
    }

    componentDidMount() {
      this.loadStats();
      const provider = ServerTasksProvider.get(this.props.dossier.id);
      provider.acquire();
      provider.emitter.addEventListener('tasksdone', this.handleServerTasksDone);
    }

    componentWillUnmount() {
      const provider = ServerTasksProvider.get(this.props.dossier.id);
      provider.release();
      provider.emitter.removeEventListener('tasksdone', this.handleServerTasksDone);

      this.cancelTokenSourceAccountingDocument && this.cancelTokenSourceAccountingDocument.cancel('unmounting');
      this.cancelTokenSourceBankingTransactions && this.cancelTokenSourceBankingTransactions.cancel('unmounting');
    }

    loadStats() {
      this.cancelTokenSourceAccountingDocument && this.cancelTokenSourceAccountingDocument.cancel('reloading stats');
      this.cancelTokenSourceBankingTransactions && this.cancelTokenSourceBankingTransactions.cancel('reloading stats');

      const accDocumentsPromise = this._requestAccDocumentCount();
      const bankingTransactionPromise = this._requestBankingTransactionCount();
      Promise.all([accDocumentsPromise, bankingTransactionPromise])
        .then((values) => {
          this.setState({
            count: {
              accountingDocuments: values[0].data,
              bankingTransactions: values[1].data,
            },
          });
        })
        .catch((err) => {
          if (err instanceof axios.Cancel) {
            return;
          }
          errorBus.emit('error', err);

          this.setState({
            error: err,
          });
        });
    }

    _requestAccDocumentCount() {
      this.cancelTokenSourceAccountingDocument = axios.CancelToken.source();

      return axios.get(`${this._getPrefix()}/accounting-documents/count?`, {
        cancelToken: this.cancelTokenSourceAccountingDocument.token,
      });
    }

    _requestBankingTransactionCount() {
      this.cancelTokenSourceBankingTransactions = axios.CancelToken.source();

      return axios.get(`${this._getPrefix()}/banking-transactions/count?`, {
        cancelToken: this.cancelTokenSourceBankingTransactions.token,
      });
    }

    _getPrefix() {
      const dossierId = this.props.dossier.id;
      const periodId = this.props.match.params.periodId;

      return `${process.env.REACT_APP_API_ENDPOINT}/dossiers/${dossierId}/${periodId}`;
    }

    render() {
      if (this.state.error) {
        return <div>Error</div>;
      }

      return <WrappedComponent {...this.props} count={this.state.count} />;
    }
  }

  DocumentCountContainer.propTypes = {
    dossier: PropTypes.object.isRequired,
    selectedPeriod: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
  };

  return consumesPeriods(consumesDossier(withRouter(DocumentCountContainer)));
}
