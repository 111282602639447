import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import Constants from 'config/Constants';
import translate from 'containers/translate';
import Tooltip from 'components/tooltip/Tooltip';

class StateButtons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  renderNextPeriodButton() {
    const { t } = this.props;

    if (![Constants.DOCUMENT_STATE.OPEN, Constants.DOCUMENT_STATE.VERIFIED].includes(this.props.currentState)) {
      return;
    }

    const title = this.props.isMoveForbidden ? t('move_forbidden') : '';

    return (
      <Tooltip title={title} wrap={this.props.isMoveForbidden}>
        <Button color="primary" onClick={this.props.moveToNextPeriod} disabled={this.props.isMoveForbidden}>
          {t('move_to_next_period')}
        </Button>
      </Tooltip>
    );
  }

  isStateButtonDisabled(targetState) {
    return this.props.currentState === targetState || (this.props.isMoveForbidden && targetState === 'rejected');
  }

  renderButton(color, targetState, text) {
    const { t } = this.props;
    const title = this.isStateButtonDisabled(targetState) ? t('move_forbidden') : '';

    return (
      <Tooltip title={title} wrap={this.isStateButtonDisabled(targetState)}>
        <Button
          color={color}
          onClick={() => this.props.changeDocumentState(targetState)}
          disabled={this.isStateButtonDisabled(targetState)}
          className={targetState}
        >
          {text}
        </Button>
      </Tooltip>
    );
  }

  render() {
    const { t } = this.props;

    if (this.props.isInNextPeriod) {
      return (
        <span className={'StateButtons'}>
          <Button color="primary" onClick={this.props.moveToCurrentPeriod}>
            {t('move_to_current_period')}
          </Button>
        </span>
      );
    } else if (this.props.currentState === 'rejected') {
      return <span className={'StateButtons'}>{this.renderButton('warning', 'open', t('restore'))}</span>;
    } else if (this.props.currentState === 'verified') {
      return (
        <span className={'StateButtons'}>
          {this.renderButton('success', Constants.DOCUMENT_STATE.CONFIRMED, t('confirm'))}
          {this.renderNextPeriodButton()}
          {this.renderButton('danger', Constants.DOCUMENT_STATE.REJECTED, t('delete'))}
        </span>
      );
    } else {
      return (
        <span className={'StateButtons'}>
          {this.renderButton('warning', Constants.DOCUMENT_STATE.VERIFIED, t('verify'))}
          {this.renderButton('success', Constants.DOCUMENT_STATE.CONFIRMED, t('confirm'))}
          {this.renderNextPeriodButton()}
          {this.renderButton('danger', Constants.DOCUMENT_STATE.REJECTED, t('delete'))}
        </span>
      );
    }
  }
}

StateButtons.propTypes = {
  changeDocumentState: PropTypes.func.isRequired,
  moveToNextPeriod: PropTypes.func.isRequired,
  moveToCurrentPeriod: PropTypes.func.isRequired,
  isInNextPeriod: PropTypes.bool.isRequired,
  isMoveForbidden: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  currentState: PropTypes.string.isRequired,
};

export default translate(StateButtons);
