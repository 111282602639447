import { useEffect } from 'react';
import { makeAutoObservable } from 'mobx';
import axios from 'axios/index';

const POLL_INTERVAL = 3000;

const SORT_ORDER = {
  'doc-generation': 0,
  preprocessing: 1,
  'doc-generation-queued': 2,
  'preprocessing-queued': 3,
  'preprocessing-failed': 4,
  'doc-generation-failed': 4,
  succeeded: 5,
};

export default class ServerTasksProvider {
  consumers = 0;
  emitter = new EventTarget();
  dossierId = 0;
  lastTaskUpdate = Date.now();
  activeTasks = [];
  failedTasks = [];
  doneTasks = [];
  isFetching = false;
  isReady = false;
  timerId = null;
  cancelTokenSource = null;

  constructor(dossierId) {
    makeAutoObservable(this);
    this.dossierId = dossierId;

    this.fetch();
  }

  acquire() {
    this.consumers++;

    if (this.consumers === 1) {
      this.fetch();
    }
  }

  release() {
    this.consumers--;

    if (this.consumers === 0) {
      this.cancelTokenSource?.cancel('No consumers');
      this.isReady = false;
    }
  }

  handleFetchSuccess(response) {
    const tasks = response.data;

    tasks.sort((a, b) => {
      return SORT_ORDER[a.state] - SORT_ORDER[b.state];
    });

    this.activeTasks = tasks.filter((t) =>
      ['preprocessing-queued', 'preprocessing', 'doc-generation-queued', 'doc-generation'].includes(t.state)
    );
    this.failedTasks = tasks.filter((t) => ['preprocessing-failed', 'doc-generation-failed'].includes(t.state));
    this.doneTasks = tasks.filter((t) => ['succeeded'].includes(t.state));

    const maxUpdatedAt = Math.max(...tasks.map((t) => Date.parse(t.updatedAt)));

    if (maxUpdatedAt > this.lastTaskUpdate) {
      this.lastTaskUpdate = maxUpdatedAt;

      if (this.activeTasks.length === 0) {
        this.emitter.dispatchEvent(new CustomEvent('tasksdone'));
      }
    }

    this.isReady = true;
  }

  async fetch() {
    if (this.consumers === 0) {
      return;
    }

    // Prevent uneccessary double fetching.
    if (this.isFetching) {
      return;
    }

    // If an additional fetch is called from the outside, cancel the current poll timer.
    if (this.timerId) {
      clearTimeout(this.timerId);
      this.timerId = null;
    }

    this.isFetching = true;

    try {
      this.cancelTokenSource = axios.CancelToken.source();

      const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/dossiers/${this.dossierId}/tasks`, {
        cancelToken: this.cancelTokenSource.token,
      });
      this.handleFetchSuccess(response);
    } catch (err) {
      // ignore
    } finally {
      this.timerId = setTimeout(() => {
        this.timerId = null;
        this.fetch();
      }, POLL_INTERVAL);
    }

    this.isFetching = false;
  }

  async retryAll() {
    await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/dossiers/${this.dossierId}/tasks/retry-all`);
    await this.fetch();
  }

  async delete(taskId) {
    await axios.delete(`${process.env.REACT_APP_API_ENDPOINT}/dossiers/${this.dossierId}/tasks/${taskId}`);
    await this.fetch();
  }

  static instances = new Map();

  static tryGet(dossierId) {
    return ServerTasksProvider.instances.get(dossierId);
  }

  static get(dossierId) {
    if (ServerTasksProvider.instances.has(dossierId)) {
      return ServerTasksProvider.instances.get(dossierId);
    }

    const manager = new ServerTasksProvider(dossierId);

    ServerTasksProvider.instances.set(dossierId, manager);

    return manager;
  }
}

export function useServerTasks(dossierId) {
  useEffect(() => {
    const provider = ServerTasksProvider.get(dossierId);
    provider.acquire();

    return () => {
      provider.release();
    };
  }, [dossierId]);

  return ServerTasksProvider.get(dossierId);
}
