import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Badge, Col, Container, FormGroup, Modal, ModalBody, ModalHeader, Row, Label, Input } from 'reactstrap';
import translate from 'containers/translate';
import DocumentCount from 'components/documentCount/DocumentCount';
import DossierDetails from 'components/dossierDetails/DossierDetails';
import UploadList from 'components/uploadList/UploadList';
import ServerTasks from 'components/serverTasks/ServerTasks';
import UploadDropzone from 'components/uploadDropzone/UploadDropzone';
import { consumesPeriods } from 'contexts/PeriodsContext';
import RemoteDataPropType from 'lib/prop-types/RemoteDataPropType';
import UploadManager from 'lib/UploadManager';
import { DOCUMENT_TYPE_EMOJI } from 'config/Constants';
import { consumesDossier } from 'contexts/DossierContext';
import AddDossier from 'components/addDossier/AddDossier';
import { consumesDossiers, providesDossiers } from '../../../contexts/DossiersContext';
import './UploadStep.scss';

class UploadStep extends Component {
  constructor(props) {
    super(props);

    this.state = {
      privateContraAccount: false,
      newDocumentRecognition: true,
    };

    this.handleUploadDocument = this.handleUploadDocument.bind(this);
  }

  handleUploadDocument(files) {
    UploadManager.get(this.props.dossier.id).addFiles(files);
  }

  renderUploadButtons() {
    if (this.props.remotePeriods.isReadOnlyPeriod) {
      return;
    }

    const { t } = this.props;

    return (
      <div>
        <div className="d-flex">
          <FormGroup check>
            <Badge
              onClick={() => this.setState({ privateContraAccount: !this.state.privateContraAccount })}
              color={this.state.privateContraAccount ? 'success' : 'secondary'}
            >
              {t('paid_with_private_account')}
            </Badge>
          </FormGroup>

          <Label check className="ml-auto">
            <Input
              type="checkbox"
              checked={this.state.newDocumentRecognition}
              onChange={() =>
                this.setState((prevState) => ({ newDocumentRecognition: !prevState.newDocumentRecognition }))
              }
            />
            {t('new_document_recognition')}
          </Label>
        </div>
        <Row>
          <Col>
            <UploadDropzone
              label={DOCUMENT_TYPE_EMOJI.accountingDocument + ' ' + t('documents')}
              documentType={'accountingDocument'}
              privateContraAccount={this.state.privateContraAccount}
              newDocumentRecognition={this.state.newDocumentRecognition}
              onUploadDocument={this.handleUploadDocument}
            />
          </Col>
          <Col>
            <UploadDropzone
              label={DOCUMENT_TYPE_EMOJI.bankingDocument + ' ' + t('banking_documents')}
              documentType={'bankingDocument'}
              privateContraAccount={this.state.privateContraAccount}
              newDocumentRecognition={this.state.newDocumentRecognition}
              onUploadDocument={this.handleUploadDocument}
            />
          </Col>
          {this.state.newDocumentRecognition && (
            <Col>
              <UploadDropzone
                label={DOCUMENT_TYPE_EMOJI.creditDocument + ' ' + t('Credit-card statements')}
                documentType={'creditDocument'}
                privateContraAccount={this.state.privateContraAccount}
                newDocumentRecognition={this.state.newDocumentRecognition}
                onUploadDocument={this.handleUploadDocument}
              />
            </Col>
          )}
        </Row>
      </div>
    );
  }

  renderUploadAndTasks() {
    if (this.props.remotePeriods.isReadOnlyPeriod) {
      return;
    }

    const { t } = this.props;

    return (
      <section>
        <h1>{t('Status')}</h1>
        <UploadList />
        <ServerTasks />
      </section>
    );
  }

  renderDossierInitModal() {
    if (this.props.dossier.initialized) {
      return <div />;
    }
    const { t } = this.props;

    return (
      <Modal isOpen={true}>
        <ModalHeader>{t('initialize_dossier')}</ModalHeader>
        <ModalBody>
          <AddDossier
            presetName={this.props.dossier.name}
            onCreate={(dossier) => {
              this.props.remoteDossiers.api.update({ ...this.props.dossier, ...dossier, initialized: true }, () =>
                this.props.remoteDossier.api.fetch()
              );
            }}
          />
        </ModalBody>
      </Modal>
    );
  }

  render() {
    const { t } = this.props;
    return (
      <div className={'UploadStep'}>
        {this.renderDossierInitModal()}
        <div className={'stepContainer'}>
          <Container>
            <Row>
              <Col xs={6}>
                <Container>
                  <Row>
                    <Col>
                      <h1>{t('upload')}</h1>
                    </Col>
                  </Row>
                  {this.renderUploadButtons()}
                  <Row>
                    <Col xs={12}>
                      <DocumentCount />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <DossierDetails />
                    </Col>
                  </Row>
                </Container>
              </Col>
              <Col xs={6}>
                <Col>{this.renderUploadAndTasks()}</Col>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

UploadStep.propTypes = {
  t: PropTypes.func.isRequired,
  remotePeriods: RemoteDataPropType,
  remoteDossier: PropTypes.object.isRequired,
  remoteDossiers: PropTypes.object.isRequired,
  dossier: PropTypes.object.isRequired,
};

export default providesDossiers(consumesPeriods(consumesDossier(consumesDossiers(translate(UploadStep)))));
