import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import translate from 'containers/translate';
import './AddDossier.scss';

class AddDossier extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: this.props.presetName,
      debitorContraAccount: '',
      vatType: '',
    };

    this.addDossier = this.addDossier.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.presetName === prevProps.presetName) {
      return;
    }

    this.setState({
      name: this.props.presetName,
    });
  }

  addDossier() {
    if (!this.hasValidInputValues()) {
      return;
    }

    this.props.onCreate({
      name: this.state.name,
      debitorContraAccount: this.state.debitorContraAccount,
      vatType: this.state.vatType,
    });

    this.setState({ name: '', debitorContraAccount: '', vatType: '' });
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.addDossier();
    }
  }

  hasValidInputValues() {
    return this.state.name && this.state.name.length >= 0 && this.state.debitorContraAccount && this.state.vatType;
  }

  render() {
    const { t } = this.props;

    return (
      <div className={'AddDossier'}>
        <Container className="newDossierContainer">
          <Row>
            <Col s={12} md={6} lg={4} xl={3}>
              <Form>
                <FormGroup>
                  <Input
                    type="text"
                    placeholder={t('company_name')}
                    className="name"
                    value={this.state.name}
                    onChange={(event) => this.setState({ name: event.target.value })}
                    onSubmit={this.addDossier}
                    onKeyDown={(e) => this.handleKeyPress(e)}
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    type="text"
                    placeholder={t('contra_account_debitor')}
                    className="debitorContraAccount"
                    value={this.state.debitorContraAccount}
                    onChange={(event) => this.setState({ debitorContraAccount: event.target.value })}
                    onSubmit={this.addDossier}
                    onKeyDown={(e) => this.handleKeyPress(e)}
                  />
                </FormGroup>
                <legend className="col-form-label">{t('vat_type')}</legend>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="mwstType"
                      value="saldo"
                      checked={this.state.vatType === 'saldo'}
                      onChange={(e) => this.setState({ vatType: e.target.value })}
                    />{' '}
                    {t('proportion')}
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="mwstType"
                      value="effective"
                      checked={this.state.vatType === 'effective'}
                      onChange={(e) => this.setState({ vatType: e.target.value })}
                    />{' '}
                    {t('effective')}
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="mwstType"
                      value="none"
                      checked={this.state.vatType === 'none'}
                      onChange={(e) => this.setState({ vatType: e.target.value })}
                    />{' '}
                    {t('none')}
                  </Label>
                </FormGroup>
                <br />
                <Button
                  className="newDossier"
                  onClick={this.addDossier}
                  color={this.hasValidInputValues() ? 'success' : 'danger'}
                  type="button"
                >
                  {t('save')}
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

AddDossier.propTypes = {
  t: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  presetName: PropTypes.string.isRequired,
};

export default translate(AddDossier);
