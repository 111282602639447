import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

// See https://reactrouter.com/docs/en/v6/upgrading/v5#remove-activeclassname-and-activestyle-props-from-navlink-
export default function RRNavLinkWithActiveClass(props) {
  const { className, ...rest } = props;

  return <NavLink className={({ isActive }) => (isActive ? 'active ' + className : className)} {...rest} />;
}

RRNavLinkWithActiveClass.propTypes = {
  className: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};
