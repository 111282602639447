import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import translate from 'containers/translate';
import Constants from 'config/Constants';

class StateFilter extends Component {
  render() {
    const { t } = this.props;

    this.states = [
      { value: '', label: t('current') },
      { value: Constants.DOCUMENT_STATE.OPEN, label: t('open') },
      { value: Constants.DOCUMENT_STATE.CONFIRMED, label: t('confirmed') },
      { value: Constants.DOCUMENT_STATE.VERIFIED, label: t('verified') },
      { value: 'next', label: t('next_period') },
      { value: Constants.DOCUMENT_STATE.REJECTED, label: t('deleted') },
    ];

    const value = this.states.find((state) => state.value === this.props.selectedState);

    return (
      <div className={'StatusFilter'}>
        <Select
          options={this.states}
          isClearable={false}
          backspaceRemovesValue={false}
          value={value}
          onChange={(newState) => this.props.onStateFilterChange(newState.value)}
          pageSize={6}
          className="Select"
        />
      </div>
    );
  }
}

StateFilter.propTypes = {
  onStateFilterChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  selectedState: PropTypes.string.isRequired,
};

export default translate(StateFilter);
