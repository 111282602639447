import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Table } from 'reactstrap';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import * as immutable from 'object-path-immutable';
import { consumesTemplates, providesTemplates } from 'contexts/TemplatesContext';
import { providesUsedCompanies } from 'contexts/UsedCompaniesContext';
import TemplateListEntry from 'components/templateListEntry/TemplateListEntry';
import { consumesDossier } from 'contexts/DossierContext';
import RemoteDataPropType from 'lib/prop-types/RemoteDataPropType';
import translate from 'containers/translate';
import withCompanies from 'containers/withCompanies';
import './TemplateList.scss';

class TemplateList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dirtyTemplates: props.templates,
    };

    this.onDragEnd = this.onDragEnd.bind(this);
    this.onCreateNewTemplate = this.onCreateNewTemplate.bind(this);
    this.onTemplateChange = this.onTemplateChange.bind(this);
    this.onTemplateDelete = this.onTemplateDelete.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.templates !== this.props.templates) {
      this.setState({
        dirtyTemplates: this.props.templates,
      });
    }
  }

  onCreateNewTemplate() {
    this.props.remoteTemplates.api.create();
  }

  onTemplateChange(template) {
    this.props.remoteTemplates.api.update(template);
  }

  onTemplateDelete(template) {
    this.props.remoteTemplates.api.delete(template);
  }

  onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    if (sourceIndex === destinationIndex) {
      return;
    }

    this.setState((prevState) => {
      const movedTemplated = prevState.dirtyTemplates[sourceIndex];
      let dirtyTemplates = prevState.dirtyTemplates;

      if (destinationIndex > sourceIndex) {
        dirtyTemplates = immutable
          .wrap(dirtyTemplates)
          .insert('', movedTemplated, destinationIndex + 1)
          .del([sourceIndex])
          .value();
      } else {
        dirtyTemplates = immutable
          .wrap(dirtyTemplates)
          .insert('', movedTemplated, destinationIndex)
          .del([sourceIndex + 1])
          .value();
      }

      this.onTemplateChange(
        Object.assign({}, movedTemplated, {
          position: destinationIndex,
        })
      );

      return {
        dirtyTemplates,
      };
    });
  }

  renderTemplates() {
    return this.state.dirtyTemplates.map((template, index) => {
      return (
        <TemplateListEntry
          dossier={this.props.dossier}
          template={template}
          key={template.id}
          index={index}
          companyNames={this.props.companyNames}
          locked={false}
          onTemplateChange={this.onTemplateChange}
          onTemplateDelete={this.onTemplateDelete}
        />
      );
    });
  }

  render() {
    const { t } = this.props;

    return (
      <div className={'TemplateList'}>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Table>
            <thead>
              <tr>
                <td />
                <th>{t('name')}</th>
                <th>{t('company')}</th>
                <th>{t('account')}</th>
                <th>{t('vat')}</th>
                <th>{t('accounting_text')}</th>
                <th />
              </tr>
            </thead>
            <Droppable droppableId="droppable">
              {(provided) => (
                <tbody ref={provided.innerRef}>
                  {this.renderTemplates()}
                  {provided.placeholder}
                </tbody>
              )}
            </Droppable>
            <tfoot>
              <tr>
                <td colSpan="7">
                  <Button type="button" onClick={this.onCreateNewTemplate} color="primary">
                    {t('add')}
                  </Button>
                </td>
              </tr>
            </tfoot>
          </Table>
        </DragDropContext>
      </div>
    );
  }
}

TemplateList.propTypes = {
  t: PropTypes.func.isRequired,
  templates: PropTypes.arrayOf(PropTypes.object).isRequired,
  remoteTemplates: RemoteDataPropType,
  dossier: PropTypes.object.isRequired,
  companyNames: PropTypes.array.isRequired,
};

export default providesUsedCompanies(
  consumesDossier(providesTemplates(consumesTemplates(withCompanies(translate(TemplateList)))))
);
