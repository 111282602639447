import React from 'react';
import RemoteDataProvider from './RemoteDataProvider';

const BankingTransactionSplitCheckContext = React.createContext();

function providesBankingTransactionSplitCheck(Component) {
  class RemoteBankingTransactionSplitCheck extends React.Component {
    render() {
      const baseURL = `/banking-transaction-split-check`;

      return (
        <RemoteDataProvider
          baseURL={baseURL}
          render={(remoteData) => {
            return (
              <BankingTransactionSplitCheckContext.Provider value={remoteData}>
                <Component {...this.props} />
              </BankingTransactionSplitCheckContext.Provider>
            );
          }}
        />
      );
    }
  }

  return RemoteBankingTransactionSplitCheck;
}

function consumesBankingTransactionSplitCheck(Component, provideValueAs = 'bankingTransactionSplitCheck') {
  return function ComponentThatConsumesBankingTransactionSplitCheck(props) {
    return (
      <BankingTransactionSplitCheckContext.Consumer>
        {(remoteData) => {
          const consumedProps = {
            remoteBankingTransactionSplitCheck: remoteData,
            [provideValueAs]: remoteData.value,
          };

          return <Component {...props} {...consumedProps} />;
        }}
      </BankingTransactionSplitCheckContext.Consumer>
    );
  };
}

export { providesBankingTransactionSplitCheck, consumesBankingTransactionSplitCheck };
