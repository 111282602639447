import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import AccountingTransaction from 'components/accountingTransaction/AccountingTransaction';
import deepEqual from 'deep-equal';
import RemoteDataPropType from 'lib/prop-types/RemoteDataPropType';
import translate from 'containers/translate';
import { consumesDocument } from 'contexts/DocumentContext';
import { consumesDossier } from 'contexts/DossierContext';
import { consumesPeriods } from 'contexts/PeriodsContext';
import './AccountingTransactionList.scss';

class AccountingTransactionList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dirtyDocument: props.document,
    };
  }

  componentDidUpdate(prevProps) {
    if (!deepEqual(prevProps.document, this.props.document)) {
      this.setState({
        dirtyDocument: this.props.document,
      });
    }
  }

  render() {
    const { t } = this.props;

    const accountingTransactions = this.state.dirtyDocument.accountingTransactions.map((a, i) => {
      return (
        <AccountingTransaction
          companyName={this.props.document.company.name}
          contraAccount={this.props.document.contraAccount.account || ''}
          currency={this.props.document.currency}
          accountingTransaction={a}
          onAccountingTransactionChange={(a, cb) => this.onAccountingTransactionChange(a, i, cb)}
          onRemoveAccountingTransaction={() => this.removeAccountingTransaction(i)}
          documentId={this.props.document.id}
          documentType={this.props.document.type}
          documentDate={this.props.document.date}
          locked={this.props.locked}
          deletable={this.state.dirtyDocument.accountingTransactions.length > 1}
          individualAccountingTexts={this.props.document.individualAccountingTexts}
          key={i}
        />
      );
    });
    return (
      <div className={'AccountingTransactionList'}>
        <table>
          <thead>
            <tr>
              <th>{t('amount')}</th>
              <th>{this.renderAccountingText()}</th>
              <th>{this.renderVat()}</th>
              <th>{this.renderVatAmount()}</th>
              <th>
                <Row>
                  <Col>{t('debit')}</Col>
                  <Col>{t('credit')}</Col>
                </Row>
              </th>
              <th />
              <th />
            </tr>
          </thead>
          <tbody>{accountingTransactions}</tbody>
        </table>
      </div>
    );
  }

  onAccountingTransactionChange(transaction, i, cb) {
    let dirtyDocument = this.state.dirtyDocument;
    dirtyDocument.accountingTransactions[i] = transaction;

    this.props.remoteDocument.api.update(dirtyDocument, cb);
  }

  removeAccountingTransaction(i) {
    let dirtyDocument = this.state.dirtyDocument;

    if (dirtyDocument.accountingTransactions[i]) {
      dirtyDocument.accountingTransactions.splice(i, 1);
      this.props.remoteDocument.api.update(dirtyDocument);
    }
  }

  renderAccountingText() {
    const { t } = this.props;

    if (!this.props.document.individualAccountingTexts) {
      return '';
    }

    return t('accounting_text');
  }

  renderVat() {
    const { t, selectedPeriod } = this.props;

    if (selectedPeriod.vatType === 'none') {
      return '';
    }

    return t('vat');
  }

  renderVatAmount() {
    const { t, selectedPeriod } = this.props;

    if (selectedPeriod.vatType === 'none') {
      return null;
    }

    return t('amount');
  }
}

AccountingTransactionList.propTypes = {
  dossier: PropTypes.object.isRequired,
  selectedPeriod: PropTypes.object.isRequired,
  document: PropTypes.object.isRequired,
  remoteDocument: RemoteDataPropType,
  t: PropTypes.func.isRequired,
  locked: PropTypes.bool.isRequired,
};

export default translate(consumesPeriods(consumesDocument(consumesDossier(AccountingTransactionList))));
