import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import Constants from 'config/Constants';
import moment from 'moment';
import translate from 'containers/translate';
import CSVExport from 'components/csvExport/CSVExport';
import DocumentOverview from 'components/documentOverview/DocumentOverview';
import { consumesPeriods } from 'contexts/PeriodsContext';
import './ExportStep.scss';

class ExportStep extends Component {
  constructor(props) {
    super(props);

    this.state = {
      relevantSelection: this.createInitialRelevantSelection(),
    };

    this.onOnlyRelevantSelectionChanged = this.onOnlyRelevantSelectionChanged.bind(this);
  }

  onOnlyRelevantSelectionChanged(selection) {
    localStorage.setItem('onlyRelevant', selection.onlyRelevant);
    this.setState({ relevantSelection: selection });
  }

  createInitialRelevantSelection() {
    let previewTo = moment().subtract(1, 'year').endOf('year');

    const previewFrom = moment(previewTo).startOf('year').format('YYYY-MM-DD');

    previewTo = previewTo.format('YYYY-MM-DD');

    let onlyRelevant = JSON.parse(localStorage.getItem('onlyRelevant'));
    if (onlyRelevant === null) {
      onlyRelevant = false;
    }
    return { onlyRelevant, previewFrom, previewTo };
  }

  render() {
    const { t } = this.props;

    // ToDo: Remove after client refactor
    const currentState = this.props.selectedPeriod.state;
    if (currentState === Constants.PERIOD_STATE.OPEN) {
      return null;
    }

    let filter;
    if (this.state.relevantSelection.onlyRelevant) {
      filter = {
        previewFrom: this.state.relevantSelection.previewFrom,
        previewTo: this.state.relevantSelection.previewTo,
      };
    } else {
      filter = {
        periodId: this.props.selectedPeriod.id,
      };
    }

    return (
      <div className={'ExportStep'}>
        <Container className={'stepContainer'}>
          <Row>
            <Col>
              <CSVExport />
            </Col>
          </Row>
          <Row>
            <Col>
              <h2>{t('entries')}</h2>
              <DocumentOverview
                filter={filter}
                relevantSelection={this.state.relevantSelection}
                onOnlyRelevantSelectionChanged={this.onOnlyRelevantSelectionChanged}
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

ExportStep.propTypes = {
  selectedPeriod: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default consumesPeriods(translate(ExportStep));
