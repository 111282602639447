import React from 'react';
import PropTypes from 'prop-types';
import { Nav, NavItem, NavLink } from 'reactstrap';
import translate from 'containers/translate';
import Navigation from './Navigation';
import RRNavLinkWithActiveClass from './RRNavLinkWithActiveClass';

class NavigationGeneric extends React.Component {
  renderMainNavigation() {
    const { t } = this.props;

    return (
      <Nav navbar>
        <NavItem>
          <NavLink tag={RRNavLinkWithActiveClass} to={`/statistic`}>
            {t('statistic')}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink tag={RRNavLinkWithActiveClass} to={`/manual`}>
            {t('user_manual')}
          </NavLink>
        </NavItem>
      </Nav>
    );
  }

  render() {
    return <Navigation>{this.renderMainNavigation()}</Navigation>;
  }
}

NavigationGeneric.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate(NavigationGeneric);
