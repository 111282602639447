import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withRouter } from 'containers/withRouter';
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalHeader } from 'reactstrap';
import DocumentMatcher from 'lib/DocumentMatcher';
import RemoteDataPropType from 'lib/prop-types/RemoteDataPropType';
import { consumesDocuments } from 'contexts/DocumentsContext';
import { consumesBankingTransactions } from 'contexts/BankingTransactionsContext';
import translate from 'containers/translate';
import Currency from 'components/currency/Currency';
import {
  consumesBankingTransactionSplit,
  providesBankingTransactionSplit,
} from 'contexts/BankingTransactionSplitContext';
import {
  consumesBankingTransactionSplitCheck,
  providesBankingTransactionSplitCheck,
} from 'contexts/BankingTransactionSplitCheckContext';
import AccountEdit from 'components/accountEdit/AccountEdit';
import Filter from './Filter';
import DocumentWindow from 'components/documentWindow/DocumentWindow';
import './PostEditStep.scss';

class PostEditStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDocuments: [],
      areProposalsInit: false,
      documentIdModal: null,
      showFilter: false,
      showBalancingModal: false,
      accountingText: '',
      account: '1000',
      isMatchable: false,
      filter: {
        description: null,
        amountFrom: null,
        amountTo: null,
      },
    };

    this.documentMatcher = new DocumentMatcher();

    this.onAccept = this.onAccept.bind(this);
    this.onNextProposal = this.onNextProposal.bind(this);
    this.renderTransactionTile = this.renderTransactionTile.bind(this);
    this.renderDocumentTile = this.renderDocumentTile.bind(this);
    this.documentVisible = this.documentVisible.bind(this);
    this.toggleFilter = this.toggleFilter.bind(this);
    this.toggleBalancingModal = this.toggleBalancingModal.bind(this);
    this.callSplitApi = this.callSplitApi.bind(this);
    this.callCheckApi = this.callCheckApi.bind(this);
    this.onCreateBalancingEntry = this.onCreateBalancingEntry.bind(this);
    this.balancingEntryCanBeCreated = this.balancingEntryCanBeCreated.bind(this);
  }

  componentDidMount() {
    if (this.props.remoteDocuments.isLoading) {
      return;
    }

    this.proposeDocuments();
  }

  componentDidUpdate(prevProps) {
    if (this.props.remoteDocuments.isLoading) {
      return;
    }

    if (
      !this.state.areProposalsInit ||
      prevProps.match.params.transactionId !== this.props.match.params.transactionId
    ) {
      this.proposeDocuments();
    }
  }

  proposeDocuments() {
    const { transaction } = this.props;

    if (!transaction) {
      return;
    }

    this.documentMatcher.loadData(transaction, this.props.documents);
    const selectedDocuments = this.documentMatcher.propose();
    this.setState({ selectedDocuments, areProposalsInit: true }, this.callCheckApi);
  }

  onDocumentSelect(document) {
    this.setState((prevState) => {
      let selectedDocuments = prevState.selectedDocuments.slice();
      const isSelected = selectedDocuments.some((selectedDocument) => selectedDocument.id === document.id);
      if (isSelected) {
        selectedDocuments = selectedDocuments.filter((selectedDocument) => selectedDocument.id !== document.id);
      } else {
        selectedDocuments.push(document);
      }
      return { selectedDocuments };
    }, this.callCheckApi);
  }

  onNextProposal() {
    const selectedDocuments = this.documentMatcher.propose();
    this.setState({ selectedDocuments });
  }

  onAccept() {
    if (!this.isAcceptable()) {
      return;
    }
    if (this.state.isMatchable) {
      this.callSplitApi();
    } else {
      this.toggleBalancingModal();
    }
  }

  onCreateBalancingEntry() {
    if (!this.balancingEntryCanBeCreated() || !this.isAcceptable()) {
      return;
    }
    this.callSplitApi(() => this.setState({ showBalancingModal: false }));
    this.resetBalanceEntry();
  }

  balancingEntryCanBeCreated() {
    return !!this.state.account && !!this.state.accountingText;
  }

  callCheckApi() {
    const body = this.createApiCallBody();

    this.props.remoteBankingTransactionSplitCheck.api.create(body, (err, data) => {
      const isMatchable = data && data.success;
      this.setState({ isMatchable });
    });
  }

  callSplitApi(cb) {
    const body = this.createApiCallBody();

    this.props.remoteBankingTransactionSplit.api.create(body, (err, data) => {
      if (data && data.success) {
        this.props.remoteDocuments.api.fetch();
        this.props.remoteBankingTransactions.api.fetch();
        cb && cb();
      }
    });
  }

  createApiCallBody() {
    const body = {
      bankingTransaction: this.props.transaction,
      accountingDocuments: this.state.selectedDocuments,
    };

    if (this.state.accountingText && this.state.account) {
      body.balancingEntry = {
        account: this.state.account,
        accountingText: this.state.accountingText,
      };
    }

    return body;
  }

  toggleFilter() {
    this.setState((prevState) => ({
      showFilter: !prevState.showFilter,
    }));
  }

  renderTransactionTile() {
    const { transaction } = this.props;

    if (!transaction) {
      return;
    }

    return (
      <div className="tile transaction selected" key={transaction.id}>
        <div>{transaction.description}</div>
        <div className={'meta'}>
          <Currency value={transaction.amount} currency={transaction.bankingDocument.account.currency} />
          <span>{this.getTypeLabel(transaction.type)}</span>
        </div>
      </div>
    );
  }

  getTypeLabel(type) {
    const { t } = this.props;

    if (type === 'debit') {
      return t('debit_transaction');
    }
    if (type === 'credit') {
      return t('credit_transaction');
    }
    return '';
  }

  getDocumentTypeLabel(type) {
    const { t } = this.props;

    if (type === 'creditor') {
      return t('debit_transaction');
    }
    if (type === 'debitor') {
      return t('credit_transaction');
    }
    return '';
  }

  renderDocumentTiles() {
    return this.props.documents.filter(this.documentVisible).map(this.renderDocumentTile);
  }

  documentVisible(document) {
    const amountFrom = this.state.filter.amountFrom;
    const amountTo = this.state.filter.amountTo;

    if (amountFrom && document.totalAmount < amountFrom * 100) {
      return false;
    }
    if (amountTo && document.totalAmount > amountTo * 100) {
      return false;
    }
    if (this.state.filter.description) {
      const accountingText = (document.accountingText && document.accountingText.toLowerCase()) || '';
      const companyName = (document.company.name && document.company.name.toLowerCase()) || '';

      if (
        !accountingText.includes(this.state.filter.description.toLowerCase()) &&
        !companyName.includes(this.state.filter.description.toLowerCase())
      )
        return false;
    }

    return true;
  }

  renderDocumentTile(document) {
    const { t } = this.props;

    const selected = this.state.selectedDocuments.some((selectedDocument) => selectedDocument.id === document.id);

    return (
      <div
        className={classnames('tile document', { selected })}
        key={document.id}
        onClick={() => this.onDocumentSelect(document)}
      >
        <div>{document.company.name}</div>
        <div>{document.accountingText}</div>
        <div>
          <Currency value={document.totalAmount} currency={document.currency} />
          <span>{this.getDocumentTypeLabel(document.type)}</span>
        </div>
        <div>
          <Button
            onClick={(e) => {
              this.setState({ documentIdModal: document.id });
              e.stopPropagation();
            }}
            color={'link'}
          >
            {t('document')}
          </Button>
        </div>
      </div>
    );
  }

  renderDocumentFilter() {
    if (!this.state.showFilter) {
      return <div />;
    }

    return <Filter onFilterChange={(filter) => this.setState({ filter })} />;
  }

  isAcceptable() {
    return this.state.selectedDocuments.length !== 0;
  }

  renderDocumentWindow() {
    const documentId = this.state.documentIdModal;
    if (!documentId) {
      return <div />;
    }

    return <DocumentWindow documentId={documentId} onDismiss={() => this.setState({ documentIdModal: null })} />;
  }

  toggleBalancingModal() {
    this.resetBalanceEntry();
    this.setState((prevState) => {
      return { showBalancingModal: !prevState.showBalancingModal };
    });
  }

  resetBalanceEntry() {
    this.setState({
      accountingText: '',
      account: '1000',
    });
  }

  renderBalancingModal() {
    if (!this.state.showBalancingModal) {
      return <div />;
    }
    const { t } = this.props;

    return (
      <Modal isOpen className="BalancingModal" size="lg" toggle={this.toggleBalancingModal}>
        <ModalHeader toggle={this.toggleBalancingModal}>{t('balance_amount')}</ModalHeader>
        <ModalBody>
          <p>{t('amount_disbalance_explanation')}</p>
          <FormGroup>
            <Label for="accountingText">{t('accounting_text')}</Label>
            <Input
              type="text"
              name="accounting_text"
              id="accountingText"
              value={this.state.accountingText}
              onChange={(e) => this.setState({ accountingText: e.target.value })}
            />
          </FormGroup>
          <FormGroup>
            <Label for="account">{t('account')}</Label>
            <AccountEdit onAccountChange={(account) => this.setState({ account })} account={this.state.account} />
          </FormGroup>
          <Button color={'primary'} onClick={this.onCreateBalancingEntry} disabled={!this.balancingEntryCanBeCreated()}>
            {t('create_balancing_entry')}
          </Button>
        </ModalBody>
      </Modal>
    );
  }

  render() {
    const { t } = this.props;

    return (
      <div className="PostEditStep">
        {this.renderBalancingModal()}
        {this.renderDocumentWindow()}
        <h1>{t('to_post_edit')} (BETA)</h1>
        <div className={'body'}>
          <div className="tileList">
            <h4>{t('transactions')}</h4>
            {this.renderTransactionTile()}
          </div>
          <div className={'tileDivider'}>
            <Button color="primary" onClick={this.onAccept} disabled={!this.isAcceptable()}>
              {t('accept')}
            </Button>
            <br />
            <Button color="primary" onClick={this.onNextProposal}>
              {t('next_proposal')}
            </Button>
          </div>
          <div className="tileList">
            <h4>
              {t('documents')}{' '}
              <Button color="link" onClick={this.toggleFilter}>
                {t('filter')}
              </Button>
            </h4>

            {this.renderDocumentFilter()}
            {this.renderDocumentTiles()}
          </div>
        </div>
      </div>
    );
  }
}

PostEditStep.propTypes = {
  documents: PropTypes.arrayOf(PropTypes.object).isRequired,
  transaction: PropTypes.object.isRequired,
  bankingTransactions: PropTypes.arrayOf(PropTypes.object).isRequired,
  remoteBankingTransactions: RemoteDataPropType,
  remoteBankingTransactionSplit: RemoteDataPropType,
  remoteBankingTransactionSplitCheck: RemoteDataPropType,
  remoteDocuments: RemoteDataPropType,
  t: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

export default translate(
  providesBankingTransactionSplit(
    providesBankingTransactionSplitCheck(
      consumesBankingTransactions(
        consumesDocuments(
          consumesBankingTransactionSplit(consumesBankingTransactionSplitCheck(withRouter(PostEditStep)))
        )
      )
    )
  )
);
