import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MultiToggle from 'react-multi-toggle';
import Select from 'react-select';
import deepEqual from 'deep-equal';
import { Badge, Button, Input } from 'reactstrap';
import { FaTrash } from 'react-icons/fa';
import translate from 'containers/translate';
import AccountEdit from 'components/accountEdit/AccountEdit';
import BankSelect from 'components/bankSelect/BankSelect';
import Constants from 'config/Constants';
import './OverviewAccountEdit.scss';

class OverviewAccountEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dirtyAccount: this.props.account,
    };

    this.onBlur = this.onBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSpecialAccountChange = this.handleSpecialAccountChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (!deepEqual(prevProps.account, this.props.account)) {
      this.setState({ dirtyAccount: this.props.account });
    }
  }

  render() {
    const currencyOptions = Constants.CURRENCY_OPTIONS;
    const currencyValue = currencyOptions.find((option) => option.value === this.state.dirtyAccount.currency);

    return (
      <tr className={'OverviewAccountEdit'}>
        <td>
          <AccountEdit
            onAccountChange={(account) => this.handleChange('account', account, this.onBlur)}
            account={this.state.dirtyAccount.account}
            locked={true}
          />
        </td>
        <td>
          <Select
            options={currencyOptions}
            isClearable={false}
            backspaceRemovesValue={false}
            value={currencyValue}
            onChange={({ value }) => this.handleChange('currency', value, this.onBlur)}
            isDisabled={this.props.locked}
            className="Select"
          />
        </td>
        {this.renderBankSelect()}
        <td>
          <Input
            value={this.getAccountName()}
            onChange={(e) => this.handleChange('name', e.target.value)}
            onBlur={this.onBlur}
            disabled={this.props.locked}
          />
        </td>
        <td>{this.renderTrashIcon()}</td>
        <td className="toggle">{this.renderHasDocumentToggle()}</td>
        <td className="badgeContainer">{this.renderBadges()}</td>
      </tr>
    );
  }

  renderBankSelect() {
    if (!this.state.dirtyAccount.hasDocument) {
      return;
    }

    return (
      <td className="bank">
        <BankSelect
          bank={this.state.dirtyAccount.bank}
          onBankChange={(bank) => this.handleChange('bank', bank, this.onBlur)}
          locked={this.props.locked}
        />
      </td>
    );
  }

  onBlur() {
    this.props.onAccountChange(this.state.dirtyAccount);
  }

  handleChange(key, value, cb) {
    this.setState(
      (prevState) => {
        return {
          dirtyAccount: Object.assign({}, prevState.dirtyAccount, { [key]: value }),
        };
      },
      () => cb && cb()
    );
  }

  getAccountName() {
    const { t } = this.props;

    if (this.state.dirtyAccount.account === '2099') {
      return t('account_clarification');
    }

    return this.state.dirtyAccount.name;
  }

  renderTrashIcon() {
    const { t } = this.props;

    if (this.props.account.deleted) {
      return (
        <Button color="warning" onClick={() => this.handleChange('deleted', false, this.onBlur)}>
          {t('restore_account')}
        </Button>
      );
    }
    if (this.props.locked) {
      return;
    }
    return <FaTrash onClick={() => this.props.onAccountDelete(this.state.dirtyAccount)} />;
  }

  renderHasDocumentToggle() {
    const { t } = this.props;

    if (this.props.locked) {
      return;
    }

    return (
      <MultiToggle
        key="multi"
        options={[
          {
            displayName: t('hasDocument'),
            value: true,
          },
          {
            displayName: t('hasNoDocument'),
            value: false,
          },
        ]}
        selectedOption={this.props.account.hasDocument}
        onSelectOption={(value) => this.handleChange('hasDocument', value, this.onBlur)}
      />
    );
  }

  renderBadges() {
    const { t } = this.props;
    const { specialAccount, hasDocument, deleted } = this.props.account;

    if (hasDocument || deleted) {
      return;
    }

    const highlightPrivate = specialAccount && specialAccount.type === Constants.SPECIAL_ACCOUNT.PRIVATE;
    const highlightCash = specialAccount && specialAccount.type === Constants.SPECIAL_ACCOUNT.CASH;
    const highlightClarification = specialAccount && specialAccount.type === Constants.SPECIAL_ACCOUNT.CLARIFICATION;

    return (
      <React.Fragment>
        <Badge
          onClick={() => this.handleSpecialAccountChange(Constants.SPECIAL_ACCOUNT.PRIVATE)}
          color={highlightPrivate ? 'success' : 'light'}
        >
          {t('account_private')}
        </Badge>
        <Badge
          onClick={() => this.handleSpecialAccountChange(Constants.SPECIAL_ACCOUNT.CASH)}
          color={highlightCash ? 'success' : 'light'}
        >
          {t('account_cash')}
        </Badge>
        <Badge color={highlightClarification ? 'success' : 'light'}>{t('account_clarification')}</Badge>
      </React.Fragment>
    );
  }

  handleSpecialAccountChange(type) {
    if (this.props.locked || !!this.props.account.specialAccount) {
      return;
    }
    this.props.onSpecialAccountChange({
      type,
      dossierId: this.props.account.dossierId,
      accountId: this.state.dirtyAccount.id,
    });
  }
}

OverviewAccountEdit.propTypes = {
  t: PropTypes.func.isRequired,
  account: PropTypes.object.isRequired,
  onAccountChange: PropTypes.func.isRequired,
  onAccountDelete: PropTypes.func.isRequired,
  onSpecialAccountChange: PropTypes.func.isRequired,
  locked: PropTypes.bool.isRequired,
};

export default translate(OverviewAccountEdit);
