import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Container, Row, Badge } from 'reactstrap';
import translate from 'containers/translate';
import CompanyOverviewRow from './CompanyOverviewRow';
import OverviewCompanyEdit from 'components/overviewCompanyEdit/OverviewCompanyEdit';
import CompanyMergeSuggestions from 'components/companyMergeSuggestions/CompanyMergeSuggestions';
import withCompanies from 'containers/withCompanies';
import withRules from 'containers/withRules';
import { providesCompanyNames } from 'contexts/CompanyNamesContext';
import { AutoSizer, List } from 'react-virtualized';
import withCompanyStatistic from 'containers/withCompanyStatistic';
import './CompanyOverview.scss';

class CompanyOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      companyToEdit: null,
      companiesToMerge: [],
      correctMergeCompany: null,
      filteredCompanies: props.companies,
      nameFilter: '',
    };

    this.mergeCompanies = this.mergeCompanies.bind(this);
    this.onCompanySelected = this.onCompanySelected.bind(this);
    this.onNameClick = this.onNameClick.bind(this);
    this.onEditDismiss = this.onEditDismiss.bind(this);
    this.renderCompany = this.renderCompany.bind(this);
    this.setCorrectMergeCompany = this.setCorrectMergeCompany.bind(this);
    this.emptySelectedCompanies = this.emptySelectedCompanies.bind(this);
    this.mergeCompanies = this.mergeCompanies.bind(this);
    this.isCompanyImmutable = this.isCompanyImmutable.bind(this);
    this.getRowHeight = this.getRowHeight.bind(this);
    this.onAcceptMerge = this.onAcceptMerge.bind(this);
    this.handleNameFilterChange = this.handleNameFilterChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.companies !== this.props.companies) {
      this.setState({
        filteredCompanies: this._getFilteredCompanies(this.state.nameFilter),
      });
    }
  }

  render() {
    const { t } = this.props;

    return (
      <Container className={'CompanyOverview'}>
        <h1>{t('edit_companies')}</h1>
        <br />
        {this.renderTopOnlyButtons()}
        {this._renderTable()}
      </Container>
    );
  }

  renderTopOnlyButtons() {
    return (
      <div className={'topOnly'}>
        {this.renderGenerateButton()}
        {this.renderOnlyTopToggle()}
      </div>
    );
  }

  renderGenerateButton() {
    const { t } = this.props;

    return (
      <Button color="warning" onClick={this.props.generateCompanyStatistic}>
        {t('generate_company_statistic')}
      </Button>
    );
  }

  renderOnlyTopToggle() {
    const { t } = this.props;

    return (
      <Badge onClick={this.props.toggleTopOnly} color={this.props.showTopOnly ? 'success' : 'secondary'}>
        {t('top_only')}
      </Badge>
    );
  }

  _renderTable() {
    const { t } = this.props;

    return (
      <Row>
        <Col xs={8}>
          <CompanyMergeSuggestions
            companies={this.props.companies}
            rules={this.props.rules}
            onAccept={this.onAcceptMerge}
          />
          <input value={this.state.nameFilter} onChange={this.handleNameFilterChange} />
          <div className={'companies'}>
            <Container>
              <Row className={'companyNameColumn'}>
                <Col xs={7}>{t('company_name')}</Col>
                <Col xs={3}>{t('uid')}</Col>
                <Col xs={1}>{t('rule')}</Col>
                <Col xs={1}> </Col>
              </Row>
              <AutoSizer>
                {({ width }) => (
                  <List
                    className={''}
                    height={700}
                    rowCount={this.state.filteredCompanies.length}
                    rowHeight={this.getRowHeight}
                    rowRenderer={this.renderCompany}
                    width={width}
                  />
                )}
              </AutoSizer>
            </Container>
          </div>
        </Col>
        <Col>{this.renderMergeBox()}</Col>
        {this.renderEditModal()}
      </Row>
    );
  }

  onAcceptMerge(companyA, companyB) {
    this.setState({ companiesToMerge: [companyA, companyB] });
  }

  handleNameFilterChange(e) {
    this.setState({
      nameFilter: e.target.value,
      filteredCompanies: this._getFilteredCompanies(e.target.value),
    });
  }

  _getFilteredCompanies(nameFilter) {
    if (!nameFilter) {
      return this.props.companies;
    }

    return this.props.companies.filter((company) => {
      return company.name.toLowerCase().includes(nameFilter.toLowerCase());
    });
  }

  getRowHeight({ index }) {
    const company = this.state.filteredCompanies[index];
    if (!company) {
      return 0;
    }
    if (company.uids.length === 0) {
      return 50;
    }

    return 20 + company.uids.length * 25;
  }

  renderCompany({ index, key, style }) {
    const company = this.state.filteredCompanies[index];
    if (!company) {
      return;
    }
    return (
      <CompanyOverviewRow
        key={key}
        style={style}
        company={company}
        rule={this.getRuleForCompany(company)}
        mergeAllowed={this.isMergeAllowed(company)}
        onCompanySelected={this.onCompanySelected}
        onNameClick={this.onNameClick}
      />
    );
  }

  isMergeAllowed(company) {
    if (this.state.companiesToMerge.length === 1) {
      if (this.getImmutableCompanies().length === 1 && this.isCompanyImmutable(company)) {
        return false;
      }
    }

    return true;
  }

  isCompanyImmutable(company) {
    return !!this.getRuleForCompany(company);
  }

  onCompanySelected(company) {
    this.setState((prevState) => {
      if (this.state.companiesToMerge.length >= 2) {
        return { companiesToMerge: [company] };
      }

      const companiesToMerge = prevState.companiesToMerge.slice();
      companiesToMerge.push(company);

      return { companiesToMerge };
    }, this.setCorrectMergeCompany);
  }

  setCorrectMergeCompany() {
    if (this.state.companiesToMerge.length === 1) {
      this.setState({ correctMergeCompany: null });
    } else if (this.state.companiesToMerge.length === 2 && this.getImmutableCompanies().length === 1) {
      const immutableCompany = this.getImmutableCompanies()[0];

      this.setState({ correctMergeCompany: immutableCompany });
    }
  }

  onNameClick(companyToEdit) {
    this.setState({
      companyToEdit,
    });
  }

  renderMergeBox() {
    const { t } = this.props;

    const hasTwoCompaniesToMerge = this.state.companiesToMerge.length === 2;
    const hasCorrectCompany = !!this.state.correctMergeCompany;
    const areBothImmutable = this.getImmutableCompanies().length === 2;
    const showMergeButton = hasTwoCompaniesToMerge && !areBothImmutable && hasCorrectCompany;

    return (
      <div className="mergeBox">
        <h2>{t('merge_companies')}</h2>

        <div className={'companiesToMerge'}>
          <b>{t('companies_to_merge')}</b>
          {this.renderCompanyToSelect(this.state.companiesToMerge[0], 'A')}
          {this.renderCompanyToSelect(this.state.companiesToMerge[1], 'B')}
        </div>

        <div className={'correctCompany'}>
          <b>{t('correct_spelling')}</b>
          <div className={'correctCompanyName'}>{this.renderCorrectCompanyName()}</div>
        </div>

        <Button disabled={!showMergeButton} onClick={this.mergeCompanies} color="primary">
          {t('merge')}
        </Button>
        <Button onClick={this.emptySelectedCompanies} color={'link'}>
          {t('empty_list')}
        </Button>
      </div>
    );
  }

  getImmutableCompanies() {
    return this.state.companiesToMerge.slice().filter(this.isCompanyImmutable);
  }

  renderCompanyToSelect(company, letter) {
    return (
      <div>
        {letter}) {this.renderCompanyName(company)}
      </div>
    );
  }

  renderCorrectCompanyName() {
    const { t } = this.props;

    if (this.getImmutableCompanies().length === 2) {
      return <span>{t('companies_cannot_be_merged')}</span>;
    }

    if (!this.state.correctMergeCompany && this.state.companiesToMerge.length === 2) {
      return (
        <div>
          <Button onClick={() => this.setCorrectMergeCompanyManually(this.state.companiesToMerge[0])} color="success">
            {this.renderCompanyName(this.state.companiesToMerge[0])}
          </Button>
          <br />
          <Button onClick={() => this.setCorrectMergeCompanyManually(this.state.companiesToMerge[1])} color="success">
            {this.renderCompanyName(this.state.companiesToMerge[1])}
          </Button>
        </div>
      );
    }

    return this.renderCompanyName(this.state.correctMergeCompany);
  }

  setCorrectMergeCompanyManually(company) {
    this.setState({ correctMergeCompany: company });
  }

  renderCompanyName(company) {
    if (!company) {
      return '';
    }

    return company.name;
  }

  mergeCompanies() {
    this.props.onMergeCompanies(
      this.state.companiesToMerge,
      this.state.correctMergeCompany,
      this.emptySelectedCompanies
    );
  }

  emptySelectedCompanies() {
    this.setState({ correctMergeCompany: null, companiesToMerge: [] });
  }

  renderEditModal() {
    if (!this.state.companyToEdit) {
      return;
    }

    return (
      <OverviewCompanyEdit
        company={this.state.companyToEdit}
        rule={this.getRuleForCompany(this.state.companyToEdit)}
        onDismiss={this.onEditDismiss}
        onRuleChange={this.props.onRuleChange}
        onRuleDelete={this.props.onRuleDelete}
      />
    );
  }

  getRuleForCompany(company) {
    return this.props.rules.find((rule) => rule.company.id === company.id);
  }

  onEditDismiss(company) {
    if (company) {
      this.props.onCompanyChange(company, () => {
        this.props.loadRules();
      });
    }

    return this.setState({ companyToEdit: null });
  }
}

CompanyOverview.propTypes = {
  companies: PropTypes.arrayOf(PropTypes.object).isRequired,
  rules: PropTypes.arrayOf(PropTypes.object).isRequired,
  loadRules: PropTypes.func.isRequired,
  onRuleChange: PropTypes.func.isRequired,
  onRuleDelete: PropTypes.func.isRequired,
  onCompanyChange: PropTypes.func.isRequired,
  onMergeCompanies: PropTypes.func.isRequired,
  toggleTopOnly: PropTypes.func.isRequired,
  showTopOnly: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  generateCompanyStatistic: PropTypes.func.isRequired,
};

export default withCompanyStatistic(withCompanies(withRules(providesCompanyNames(translate(CompanyOverview)))));
