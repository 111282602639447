import React from 'react';
import RemoteDataProvider from './RemoteDataProvider';

const BanksContext = React.createContext();

function providesBanks(Component) {
  class RemoteBanks extends React.Component {
    render() {
      return (
        <RemoteDataProvider
          scope="root"
          baseURL="/banks"
          isCollection
          updateStrategy="fetch"
          render={(remoteData) => {
            return (
              <BanksContext.Provider value={remoteData}>
                <Component {...this.props} />
              </BanksContext.Provider>
            );
          }}
        />
      );
    }
  }

  return RemoteBanks;
}

function consumesBanks(Component, provideValueAs = 'banks') {
  return function ComponentThatConsumesBanks(props) {
    return (
      <BanksContext.Consumer>
        {(remoteData) => {
          const consumedProps = {
            remoteBanks: remoteData,
            [provideValueAs]: remoteData.value,
          };

          return <Component {...props} {...consumedProps} />;
        }}
      </BanksContext.Consumer>
    );
  };
}

export { providesBanks, consumesBanks };
