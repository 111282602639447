import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import qs from 'qs';
import errorBus from 'lib/errorBus';
import { consumesDossier } from 'contexts/DossierContext';
import { consumesPeriods } from 'contexts/PeriodsContext';

const BATCH_SIZE = 100;

export default function withDocumentOverview(WrappedComponent) {
  class WithDocumentOverview extends Component {
    constructor(props) {
      super(props);

      this.state = {
        documentOverview: [],
        documentOverviewIds: [],
        error: null,
      };

      this.loadDocumentOverview = this.loadDocumentOverview.bind(this);
      this.loadDocumentOverviewIds = this.loadDocumentOverviewIds.bind(this);
      this.loadDocumentOverviewBatch = this.loadDocumentOverviewBatch.bind(this);
      this.handleBatchResponse = this.handleBatchResponse.bind(this);
    }

    componentDidMount() {
      this.loadDocumentOverview();
    }

    componentDidUpdate(prevProps) {
      if (JSON.stringify(this.props.filter) === JSON.stringify(prevProps.filter)) {
        return;
      }

      this.loadDocumentOverview();
    }

    loadDocumentOverview() {
      this.setState({ documentOverview: [], documentOverviewIds: [] }, this.loadDocumentOverviewIds);
    }

    loadDocumentOverviewIds() {
      if (this.cancelTokenSourceGet) {
        this.cancelTokenSourceGet.cancel('New request');
      }

      this.cancelTokenSourceGet = axios.CancelToken.source();

      const params = qs.stringify(this.props.filter, { strictNullHandling: true, ignoreQueryPrefix: true });

      axios
        .get(`${process.env.REACT_APP_API_ENDPOINT}/dossiers/${this.props.dossier.id}/document-overview?${params}`, {
          cancelToken: this.cancelTokenSourceGet.token,
        })
        .then((response) => {
          this.setState({ documentOverviewIds: response.data }, this.loadDocumentOverviewBatch);
        })
        .catch((err) => {
          if (!(err instanceof axios.Cancel)) {
            errorBus.emit('error', err);

            this.setState({
              error: err,
            });
          }
        });
    }

    loadDocumentOverviewBatch() {
      if (this.state.documentOverviewIds.length === 0) {
        return;
      }

      const batch = this.state.documentOverviewIds.slice(0, BATCH_SIZE);

      if (this.cancelTokenSourcePost) {
        this.cancelTokenSourcePost.cancel('New request');
      }

      this.cancelTokenSourcePost = axios.CancelToken.source();

      const params = qs.stringify(this.props.filter, { strictNullHandling: true, ignoreQueryPrefix: true });

      axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}/dossiers/${this.props.dossier.id}/document-overview?${params}`,
          { ids: batch },
          {
            cancelToken: this.cancelTokenSourcePost.token,
          }
        )
        .then((response) => {
          this.handleBatchResponse(response.data);
        })
        .catch((err) => {
          if (!(err instanceof axios.Cancel)) {
            errorBus.emit('error', err);

            this.setState({
              error: err,
            });
          }
        });
    }

    handleBatchResponse(documents) {
      const newDocumentOverview = this.state.documentOverview.concat(documents);
      const newDocumentIds = newDocumentOverview.reduce((acc, t) => {
        acc = acc.concat(t.accountingDocuments.map((d) => d.id));
        return acc;
      }, []);
      const oldDocumentIds = this.state.documentOverviewIds.filter((id) => {
        return -1 === newDocumentIds.indexOf(id);
      });
      this.setState(
        { documentOverview: newDocumentOverview, documentOverviewIds: oldDocumentIds },
        this.loadDocumentOverviewBatch
      );
    }

    render() {
      return <WrappedComponent {...this.props} documentOverview={this.state.documentOverview} />;
    }
  }

  WithDocumentOverview.propTypes = {
    dossier: PropTypes.object.isRequired,
    selectedPeriod: PropTypes.object.isRequired,
    relevantSelection: PropTypes.object.isRequired,
    filter: PropTypes.object,
  };

  return consumesPeriods(consumesDossier(WithDocumentOverview));
}
