import React, { Component } from 'react';
import axios from 'axios';
import errorBus from 'lib/errorBus';

export default function withStatistic(WrappedComponent) {
  class withStatistic extends Component {
    constructor(props) {
      super(props);
      this.state = {
        statistic: null,
        error: null,
      };

      this.generateStatistic = this.generateStatistic.bind(this);
      this.generateRuleStatistic = this.generateRuleStatistic.bind(this);
      this.generatePeriodStatistic = this.generatePeriodStatistic.bind(this);
    }

    componentDidMount() {
      this._loadData();
    }

    componentWillUnmount() {
      this.getCancelTokenSource && this.getCancelTokenSource.cancel('Component unmounted');
      this.cancelTokenSourcePost && this.cancelTokenSourcePost.cancel('Component unmounted');
    }

    generateStatistic() {
      this.cancelTokenSourcePost && this.cancelTokenSourcePost.cancel('New request');

      this.cancelTokenSourcePost = axios.CancelToken.source();

      axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}/generate-statistic`,
          {},
          {
            cancelToken: this.cancelTokenSourcePost.token,
          }
        )
        .then(() => {
          this._loadData();
        })
        .catch((err) => {
          if (err instanceof axios.Cancel) {
            return;
          }
          errorBus.emit('error', err);

          this.setState({
            error: err,
          });
        });
    }

    generateRuleStatistic() {
      this.cancelTokenSourcePost && this.cancelTokenSourcePost.cancel('New request');

      this.cancelTokenSourcePost = axios.CancelToken.source();

      axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}/generate-rule-statistic`,
          {},
          {
            cancelToken: this.cancelTokenSourcePost.token,
          }
        )
        .then(() => {
          this._loadData();
        })
        .catch((err) => {
          if (err instanceof axios.Cancel) {
            return;
          }
          errorBus.emit('error', err);

          this.setState({
            error: err,
          });
        });
    }

    generatePeriodStatistic() {
      this.cancelTokenSourcePost && this.cancelTokenSourcePost.cancel('New request');

      this.cancelTokenSourcePost = axios.CancelToken.source();

      axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}/generate-period-statistic`,
          {},
          {
            cancelToken: this.cancelTokenSourcePost.token,
          }
        )
        .then(() => {
          this._loadData();
        })
        .catch((err) => {
          if (err instanceof axios.Cancel) {
            return;
          }
          errorBus.emit('error', err);

          this.setState({
            error: err,
          });
        });
    }

    _loadData() {
      this.getCancelTokenSource && this.getCancelTokenSource.cancel('New request');

      this.getCancelTokenSource = axios.CancelToken.source();

      axios
        .get(`${process.env.REACT_APP_API_ENDPOINT}/statistic`, {
          cancelToken: this.getCancelTokenSource.token,
        })

        .then((response) => {
          this.setState({
            statistic: response.data,
          });
        })
        .catch((err) => {
          if (err instanceof axios.Cancel) {
            return;
          }
          errorBus.emit('error', err);

          this.setState({
            error: err,
          });
        });
    }

    render() {
      if (this.state.error) {
        return <div>Error</div>;
      }
      if (!this.state.statistic) {
        return <div />;
      }

      return (
        <WrappedComponent
          {...this.props}
          statistic={this.state.statistic}
          generateStatistic={this.generateStatistic}
          generateRuleStatistic={this.generateRuleStatistic}
          generatePeriodStatistic={this.generatePeriodStatistic}
        />
      );
    }
  }
  return withStatistic;
}
