import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { UncontrolledTooltip } from 'reactstrap';
import { consumesDossier } from 'contexts/DossierContext';
import { getAccessTokenQueryString } from 'lib/configureAxios.js';
import './Tooltip.scss';

const DELAY = { show: 0, hide: 0 };
let counter = 0;

class Tooltip extends Component {
  constructor(props) {
    super(props);

    this.id = `Tooltip-${counter++}`;
  }

  renderChild() {
    if (this.props.wrap) {
      return (
        <div id={this.id} className="Tooltip">
          {this.props.children}
        </div>
      );
    } else {
      return React.cloneElement(this.props.children, { id: this.id });
    }
  }

  renderContent() {
    if (this.props.imageFileId) {
      return (
        <div className={'image'}>
          <img src={this.getImageSrc(this.props.imageFileId)} alt={'Beleg'} />
        </div>
      );
    }

    return this.props.title;
  }

  renderTooltip() {
    if (!document.getElementById(this.id)) {
      return;
    }

    return (
      <UncontrolledTooltip
        target={this.id}
        placement={this.props.placement}
        delay={DELAY}
        className={classnames('TooltipWindow', { imageFile: this.props.imageFileId })}
      >
        {this.renderContent()}
      </UncontrolledTooltip>
    );
  }

  getImageSrc(id) {
    let src = `${process.env.REACT_APP_API_ENDPOINT}/images/${id}/1?${getAccessTokenQueryString()}`;

    //Make sure the URL is always absolute to make it work inside the popup.
    return this.absoluteURL(src);
  }

  absoluteURL(href) {
    const link = document.createElement('a');
    link.href = href;
    return link.href;
  }

  render() {
    if (!this.props.title && !this.props.imageFileId) {
      return this.props.children;
    }

    return (
      <React.Fragment>
        {this.renderChild()}
        {this.renderTooltip()}
      </React.Fragment>
    );
  }
}

Tooltip.defaultProps = {
  placement: 'top',
  wrap: false,
};

Tooltip.propTypes = {
  dossier: PropTypes.object,
  title: PropTypes.string,
  imageFileId: PropTypes.string,
  placement: PropTypes.string.isRequired,
  wrap: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
};

export default consumesDossier(Tooltip);
