import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import NavigationGeneric from 'components/navigation/NavigationGeneric';
import SearchResult from 'components/searchResult/SearchResult';
import DossierList from 'components/dossierList/DossierList';
import Settings from 'components/settings/Settings';
import Statistic from 'components/statistic/Statistic';
import UserManual from 'components/userManual/UserManual';
import CompanyOverview from 'components/companyOverview/CompanyOverview';
import BankOverview from './bankOverview/BankOverview';
import BankPrediction from './bankPrediction/BankPrediction';

class LeaGeneric extends Component {
  render() {
    return (
      <React.Fragment>
        <NavigationGeneric />
        <Routes>
          <Route exact path={'/'} element={<DossierList />}></Route>
          <Route exact path={'/companies'} element={<CompanyOverview />}></Route>
          <Route exact path={'/banks'} element={<BankOverview />}></Route>
          <Route exact path={'/bank-prediction'} element={<BankPrediction />}></Route>
          <Route exact path={'/search/:searchTerm'} element={<SearchResult />}></Route>
          <Route exact path={'/settings'} element={<Settings />}></Route>
          <Route exact path={'/statistic'} element={<Statistic />}></Route>
          <Route exact path={'/manual'} element={<UserManual />}></Route>
        </Routes>
      </React.Fragment>
    );
  }
}

export default LeaGeneric;
