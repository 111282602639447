import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withCompanies from 'containers/withCompanies';
import FilterBar from 'components/filterBar/FilterBar';
import DocumentList from 'components/documentList/DocumentList';
import { providesUsedCompanies } from 'contexts/UsedCompaniesContext';
import './DocumentEdit.scss';

class DocumentEdit extends Component {
  render() {
    return (
      <div className={'DocumentEdit'}>
        <FilterBar companyNames={this.props.companyNames} />
        <DocumentList companyNames={this.props.companyNames} />
      </div>
    );
  }
}

DocumentEdit.propTypes = {
  companyNames: PropTypes.array.isRequired,
};

export default providesUsedCompanies(withCompanies(DocumentEdit));
