import React from 'react';
import PropTypes from 'prop-types';
import RemoteDataProvider from './RemoteDataProvider';
import { consumesDocument } from 'contexts/DocumentContext';

const BankingTransactionProposalsContext = React.createContext();

function providesBankingTransactionProposals(Component) {
  class RemoteBankingTransactionProposals extends React.Component {
    render() {
      const accountingDocumentId = this.props.document.id;
      const baseURL = `/accounting-documents/${accountingDocumentId}/banking-transaction-proposals`;

      return (
        <RemoteDataProvider
          baseURL={baseURL}
          isCollection
          render={(remoteData) => {
            return (
              <BankingTransactionProposalsContext.Provider value={remoteData}>
                <Component {...this.props} />
              </BankingTransactionProposalsContext.Provider>
            );
          }}
        />
      );
    }
  }

  RemoteBankingTransactionProposals.propTypes = {
    document: PropTypes.object.isRequired,
  };

  return consumesDocument(RemoteBankingTransactionProposals);
}

function consumesBankingTransactionProposals(Component, provideValueAs = 'bankingTransactionProposals') {
  return function ComponentThatConsumesBankingTransactionProposals(props) {
    return (
      <BankingTransactionProposalsContext.Consumer>
        {(remoteData) => {
          const consumedProps = {
            remoteBankingTransactionProposals: remoteData,
            [provideValueAs]: remoteData.value,
          };

          return <Component {...props} {...consumedProps} />;
        }}
      </BankingTransactionProposalsContext.Consumer>
    );
  };
}

export { providesBankingTransactionProposals, consumesBankingTransactionProposals };
