import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import translate from 'containers/translate';
import AccountEdit from 'components/accountEdit/AccountEdit';
import { consumesAccounts, providesAccounts } from 'contexts/AccountsContext';
import './DebitorAccountEdit.scss';

class DebitorAccountEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      account: this.props.presetAccount,
    };
  }

  render() {
    const { t } = this.props;

    return (
      <Modal isOpen className="DebitorAccountEdit" size="lg" toggle={() => this.props.onDismiss()}>
        <ModalHeader toggle={() => this.props.onDismiss()}>{t('choose_activity_account')}</ModalHeader>
        <ModalBody>
          <AccountEdit
            onAccountChange={(account) => this.setState({ account })}
            account={this.state.account}
            setFocusOnMount={true}
            accountBlacklist={this.props.accounts.filter((a) => a.hasDocument)}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="info" onClick={() => this.props.onDismiss(this.state.account)}>
            {t('save')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

DebitorAccountEdit.propTypes = {
  onDismiss: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  accounts: PropTypes.arrayOf(PropTypes.object).isRequired,

  presetAccount: PropTypes.string.isRequired,
};

export default providesAccounts(consumesAccounts(translate(DebitorAccountEdit)));
