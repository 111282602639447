export default (unformattedUID) => {
  let formattedUID = 'CHE-' + unformattedUID;
  formattedUID = _insertStringAtPositionInString('.', formattedUID, 7);
  formattedUID = _insertStringAtPositionInString('.', formattedUID, 11);
  return formattedUID;
};

function _insertStringAtPositionInString(insertion, target, position) {
  return [target.slice(0, position), insertion, target.slice(position)].join('');
}
