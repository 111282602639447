import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'containers/withRouter';
import { consumesDossier } from 'contexts/DossierContext';
import { consumesPeriods } from 'contexts/PeriodsContext';
import { getAccessTokenQueryString } from 'lib/configureAxios.js';

export default function withFileDownload(WrappedComponent) {
  class WithFileDownload extends Component {
    constructor(props) {
      super(props);

      this.onDownload = this.onDownload.bind(this);
      this.onDownloadChoiceFile = this.onDownloadChoiceFile.bind(this);
      this.onBankingDocumentDownload = this.onBankingDocumentDownload.bind(this);
    }

    onDownload(documentId) {
      window.open(`${this._getURLPrefix()}/accounting-documents/${documentId}/download?${getAccessTokenQueryString()}`);
    }

    onDownloadChoiceFile(customerChoiceId) {
      window.open(
        `${this._getURLPrefix()}/customer-choices/${customerChoiceId}/download?${getAccessTokenQueryString()}`
      );
    }

    onBankingDocumentDownload(documentId) {
      window.open(`${this._getURLPrefix()}/banking-documents/${documentId}/download?${getAccessTokenQueryString()}`);
    }

    _getURLPrefix() {
      return `${process.env.REACT_APP_API_ENDPOINT}/dossiers/${this.props.dossier.id}/${this.props.selectedPeriod.id}`;
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          onDownload={this.onDownload}
          onDownloadChoiceFile={this.onDownloadChoiceFile}
          onBankingDocumentDownload={this.onBankingDocumentDownload}
        />
      );
    }
  }

  WithFileDownload.propTypes = {
    dossier: PropTypes.object.isRequired,
    selectedPeriod: PropTypes.object.isRequired,
  };

  return withRouter(consumesPeriods(consumesDossier(WithFileDownload)));
}
