import React from 'react';
import RemoteDataProvider from './RemoteDataProvider';

const CustomerChoicesContext = React.createContext();

function providesCustomerChoices(Component) {
  class RemoteCustomerChoices extends React.Component {
    render() {
      const baseURL = '/customer-choices';

      return (
        <RemoteDataProvider
          baseURL={baseURL}
          isCollection
          updateStrategy={'fetch'}
          render={(remoteData) => {
            return (
              <CustomerChoicesContext.Provider value={remoteData}>
                <Component {...this.props} />
              </CustomerChoicesContext.Provider>
            );
          }}
        />
      );
    }
  }

  return RemoteCustomerChoices;
}

function consumesCustomerChoices(Component, provideValueAs = 'customerChoices') {
  return function ComponentThatConsumesCustomerChoices(props) {
    return (
      <CustomerChoicesContext.Consumer>
        {(remoteData) => {
          const consumedProps = {
            remoteCustomerChoices: remoteData,
            [provideValueAs]: remoteData.value ? remoteData.value : [],
          };

          return <Component {...props} {...consumedProps} />;
        }}
      </CustomerChoicesContext.Consumer>
    );
  };
}

export { providesCustomerChoices, consumesCustomerChoices };
