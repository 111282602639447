import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DocumentInformation from 'components/documentInformation/DocumentInformation';
import ImageContainer from 'components/imageContainer/ImageContainer';
import { consumesDocument } from 'contexts/DocumentContext';
import RemoteDataPropType from 'lib/prop-types/RemoteDataPropType';
import './Document.scss';

class Document extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={'Document'}>
        <DocumentInformation companyNames={this.props.companyNames} locked={this.props.locked} />
        {this.props.document.imageFile && (
          <ImageContainer imageFile={this.props.document.imageFile} inFlexbox={this.props.inFlexbox} />
        )}
      </div>
    );
  }
}

Document.defaultProps = {
  inFlexbox: true,
};

Document.propTypes = {
  companyNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  document: PropTypes.object.isRequired,
  remoteDocument: RemoteDataPropType,
  locked: PropTypes.bool.isRequired,
  inFlexbox: PropTypes.bool.isRequired,
};

export default consumesDocument(Document);
