import React from 'react';
import RemoteDataProvider from './RemoteDataProvider';

const TemplatesContext = React.createContext();

function providesTemplates(Component) {
  class RemoteTemplates extends React.Component {
    render() {
      return (
        <RemoteDataProvider
          scope="dossier"
          baseURL="/templates"
          isCollection
          updateStrategy="fetch"
          render={(remoteData) => {
            return (
              <TemplatesContext.Provider value={remoteData}>
                <Component {...this.props} />
              </TemplatesContext.Provider>
            );
          }}
        />
      );
    }
  }

  return RemoteTemplates;
}

function consumesTemplates(Component, provideValueAs = 'templates') {
  return function ComponentThatConsumesTemplates(props) {
    return (
      <TemplatesContext.Consumer>
        {(remoteData) => {
          const consumedProps = {
            remoteTemplates: remoteData,
            [provideValueAs]: remoteData.value,
          };

          return <Component {...props} {...consumedProps} />;
        }}
      </TemplatesContext.Consumer>
    );
  };
}

export { providesTemplates, consumesTemplates };
