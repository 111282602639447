import React from 'react';
import RemoteDataProvider from './RemoteDataProvider';

const BankingDocumentsContext = React.createContext();

function providesBankingDocuments(Component) {
  class RemoteBankingDocuments extends React.Component {
    constructor(props) {
      super(props);

      this.handleBeforeUpdate = this.handleBeforeUpdate.bind(this);
    }

    handleBeforeUpdate(bankingDocument) {
      bankingDocument = Object.assign({}, bankingDocument);
      delete bankingDocument.bankingTransactions;
      delete bankingDocument.imageFile;

      return bankingDocument;
    }

    render() {
      return (
        <RemoteDataProvider
          baseURL="/banking-documents"
          isCollection
          updateStrategy="fetch"
          onBeforeUpdate={this.handleBeforeUpdate}
          render={(remoteData) => {
            return (
              <BankingDocumentsContext.Provider value={remoteData}>
                <Component {...this.props} />
              </BankingDocumentsContext.Provider>
            );
          }}
        />
      );
    }
  }

  return RemoteBankingDocuments;
}

function consumesBankingDocuments(Component, provideValueAs = 'bankingDocuments') {
  return function ComponentThatConsumesBankingDocuments(props) {
    return (
      <BankingDocumentsContext.Consumer>
        {(remoteData) => {
          const consumedProps = {
            remoteBankingDocuments: remoteData,
            [provideValueAs]: remoteData.value,
          };

          return <Component {...props} {...consumedProps} />;
        }}
      </BankingDocumentsContext.Consumer>
    );
  };
}

export { providesBankingDocuments, consumesBankingDocuments };
