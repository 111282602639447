import React, { Component } from 'react';
import { withRouter } from 'containers/withRouter';
import PropTypes from 'prop-types';
import { Alert, Col, Container, Row } from 'reactstrap';
import moment from 'moment';
import keyRegistry from 'lib/GlobalKeyRegistry';
import { FaLock } from 'react-icons/fa';
import qs from 'qs';
import translate from 'containers/translate';
import Constants from 'config/Constants';
import DocumentListNavigation from 'components/documentListNavigation/DocumentListNavigation';
import Document from 'components/document/Document';
import StateButtons from 'components/stateButtons/StateButtons';
import { consumesDocument } from 'contexts/DocumentContext';
import { consumesDossier } from 'contexts/DossierContext';
import { consumesDocumentIds } from 'contexts/DocumentIdsContext';
import { consumesBankingTransactions } from 'contexts/BankingTransactionsContext';
import { consumesPeriods } from 'contexts/PeriodsContext';
import { consumesUsedCompanies } from 'contexts/UsedCompaniesContext';
import { consumesPeriodMetadata } from 'contexts/PeriodMetadataContext';
import RemoteDataPropType from 'lib/prop-types/RemoteDataPropType';
import './DocumentList.scss';

class DocumentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      virtualNavigation: false,
      virtualIndex: 0,
      ...this._getNavigationProperties(props),
    };

    this.navigateToDocumentAtIndex = this.navigateToDocumentAtIndex.bind(this);
    this.navigateToPreviousDocument = this.navigateToPreviousDocument.bind(this);
    this.navigateToNextDocument = this.navigateToNextDocument.bind(this);
    this.navigateToLastDocument = this.navigateToLastDocument.bind(this);
    this.navigateToFirstDocument = this.navigateToFirstDocument.bind(this);
    this.onDocumentConfirmed = this.onDocumentConfirmed.bind(this);
    this.changeDocumentState = this.changeDocumentState.bind(this);
    this.moveToNextPeriod = this.moveToNextPeriod.bind(this);
    this.moveToCurrentPeriod = this.moveToCurrentPeriod.bind(this);
    this.isState = this.isState.bind(this);
    this.isLocked = this.isLocked.bind(this);
    this.unlock = this.unlock.bind(this);
    this.handleLeftKeyDown = this.handleLeftKeyDown.bind(this);
    this.handleRightKeyDown = this.handleRightKeyDown.bind(this);
    this.stopVirtualNavigation = this.stopVirtualNavigation.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.virtualNavigation !== this.state.virtualNavigation && !this.state.virtualNavigation) {
      this.navigateToDocumentAtIndex(this.state.virtualIndex);
    }

    if (JSON.stringify(this.props) === JSON.stringify(prevProps)) {
      return;
    }
    this.setInitialDocumentId(this.props);
    //TODO: getDerivedStateFromProps
    const navigationProperties = this._getNavigationProperties(this.props);
    this.setState({
      ...navigationProperties,
    });
  }

  componentDidMount() {
    keyRegistry.pushContext('document');

    if (!this.props.remotePeriods.isReadOnlyPeriod) {
      keyRegistry.register('a', () => this.changeDocumentState('rejected'));
      keyRegistry.register('b', () => this.changeDocumentState('confirmed'));
      keyRegistry.register('o', () => this.changeDocumentState('open'));
    }

    keyRegistry.register('left', this.handleLeftKeyDown, this.stopVirtualNavigation);
    keyRegistry.register('right', this.handleRightKeyDown, this.stopVirtualNavigation);
  }

  componentWillUnmount() {
    keyRegistry.deregister('a');
    keyRegistry.deregister('b');
    keyRegistry.deregister('o');
    keyRegistry.deregister('left');
    keyRegistry.deregister('right');
    keyRegistry.popContext();
  }

  handleLeftKeyDown(e) {
    this.setState((prevState) => {
      const index = prevState.virtualNavigation ? prevState.virtualIndex : prevState.selectedIndex;
      const newIndex = index - 1;

      if (newIndex < 0) {
        e.preventRepeat();

        return {
          virtualNavigation: false,
        };
      } else {
        return {
          virtualNavigation: true,
          virtualIndex: newIndex,
        };
      }
    });
  }

  handleRightKeyDown(e) {
    this.setState((prevState) => {
      const index = prevState.virtualNavigation ? prevState.virtualIndex : prevState.selectedIndex;
      const newIndex = index + 1;

      if (newIndex >= this.props.documentIds.length) {
        e.preventRepeat();

        return {
          virtualNavigation: false,
        };
      } else {
        return {
          virtualNavigation: true,
          virtualIndex: newIndex,
        };
      }
    });
  }

  stopVirtualNavigation() {
    this.setState({
      virtualNavigation: false,
    });
  }

  setInitialDocumentId(props) {
    if (this.props.documentIds.length === 0) {
      return;
    }

    const documentId = parseInt(props.match.params.documentId, 10);
    if (isNaN(documentId) && props.documentIds.length > 0) {
      props.history.pushDossier(`/edit/${props.documentIds[0] + props.location.search}`);
    } else if (props.documentIds.length > 0 && !props.documentIds.includes(documentId)) {
      const query = qs.parse(this.props.location.search, { strictNullHandling: true, ignoreQueryPrefix: true });
      if (query.state || query.companyName) {
        props.history.pushDossier(`/edit/${props.documentIds[0] + props.location.search}`);
      }
    }
  }

  navigateToDocumentAtIndex(newIndex) {
    if (newIndex < 0) {
      newIndex = 0;
    } else if (newIndex >= this.props.documentIds.length) {
      newIndex = this.props.documentIds.length - 1;
    }

    this.props.history.pushDossier(`/edit/${this.props.documentIds[newIndex] + this.props.location.search}`);
  }

  navigateToNextDocument() {
    const nextIndex = this.state.selectedIndex + 2;
    if (nextIndex <= this.props.documentIds.length) {
      this.props.history.pushDossier(`/edit/${this.state.nextDocumentId + this.props.location.search}`);
    }
  }

  navigateToLastDocument() {
    const lastDocumentId = this.props.documentIds[this.props.documentIds.length - 1];
    this.props.history.pushDossier(`/edit/${lastDocumentId + this.props.location.search}`);
  }

  navigateToPreviousDocument() {
    const prevIndex = this.state.selectedIndex;
    if (prevIndex > 0) {
      this.props.history.pushDossier(`/edit/${this.state.previousDocumentId + this.props.location.search}`);
    }
  }
  navigateToFirstDocument() {
    const firstDocumentId = this.props.documentIds[0];
    this.props.history.pushDossier(`/edit/${firstDocumentId + this.props.location.search}`);
  }

  changeDocumentState(documentState) {
    if (this.props.currentDocument) {
      const newDocument = Object.assign({}, this.props.currentDocument, { state: documentState });
      if (!this.isReadyToConfirm(newDocument)) {
        return;
      }

      this.props.remoteDocument.api.update(newDocument, () => {
        if (documentState !== 'open') {
          this.onDocumentConfirmed();
        }

        this.props.remoteBankingTransactions.api.fetch();
        this.props.remoteUsedCompanies.api.fetch();
        this.props.remotePeriodMetadata.api.fetch();
      });
    }
  }

  moveToCurrentPeriod() {
    this.moveToPeriod(this.props.dossier.currentPeriodId);
  }

  moveToNextPeriod() {
    this.moveToPeriod(this.props.dossier.nextPeriodId, this.navigateToNextDocument);
  }

  moveToPeriod(periodId, cb) {
    if (!this.props.currentDocument) {
      return;
    }
    const newDocument = Object.assign({}, this.props.currentDocument, { periodId });
    this.props.remoteDocument.api.update(newDocument, cb);
  }

  isReadyToConfirm(document) {
    const { t } = this.props;

    if (document.state === 'confirmed') {
      if (!this.allTransactionsHaveAccount(document)) {
        this.setState({
          errorMessage: t('err_please_select_account'),
        });
        return false;
      }
      if (document.totalAmount !== this.getTotalAmountFromTransactions(document)) {
        this.setState({
          errorMessage: t('err_please_correct_amount_and_total_amount'),
        });
        return false;
      }
      if (!this.isDateInPeriodRange(document)) {
        this.setState({
          errorMessage: t('err_date_outside_period'),
        });
        return false;
      }
      if (document.contraAccount.account === '2099') {
        this.setState({
          errorMessage: t('err_confirm_clarification_account'),
        });
        return false;
      }
      return true;
    }
    return true;
  }

  getTotalAmountFromTransactions(document) {
    return document.accountingTransactions.reduce((acc, transaction) => {
      if (![Constants.INVERTED_TYPE.NONE, Constants.INVERTED_TYPE.VATONLY].includes(transaction.invertedType)) {
        return acc - transaction.amount;
      }
      return acc + transaction.amount;
    }, 0);
  }

  allTransactionsHaveAccount(document) {
    const transactionsWithAccount = document.accountingTransactions.reduce(
      (acc, transaction) => (parseInt(transaction.account, 10) > 0 ? acc + 1 : acc),
      0
    );
    return document.accountingTransactions.length === transactionsWithAccount;
  }

  isDateInPeriodRange(document) {
    const documentDate = moment(document.date);
    const periodStart = moment(this.props.selectedPeriod.startDate);
    const periodEnd = moment(this.props.selectedPeriod.endDate);
    return documentDate.isSameOrBefore(periodEnd) && periodStart.isSameOrBefore(documentDate);
  }

  onDocumentConfirmed() {
    this.navigateToNextDocument();
  }

  _getNavigationProperties(props) {
    const documentId = parseInt(props.match.params.documentId, 10);
    let selectedIndex = props.documentIds.findIndex((id) => id === documentId);
    if (selectedIndex < 0) {
      selectedIndex = 0;
    }
    const nextDocument = props.documentIds[selectedIndex + 1];
    const previousDocument = props.documentIds[selectedIndex - 1];
    let nextDocumentId = -1;
    let previousDocumentId = -1;
    if (nextDocument) {
      nextDocumentId = nextDocument;
    }
    if (previousDocument) {
      previousDocumentId = previousDocument;
    }

    return {
      selectedIndex,
      nextDocumentId,
      previousDocumentId,
      errorMessage: '',
    };
  }

  isState(state) {
    return this.getState() === state;
  }

  getState() {
    return (this.props.currentDocument && this.props.currentDocument.state) || 'open';
  }

  isLocked() {
    return this.props.remotePeriods.isReadOnlyPeriod || this.isState('confirmed') || this.isInNextPeriod();
  }

  renderLock() {
    if (this.props.remotePeriods.isReadOnlyPeriod) {
      return null;
    }

    if (this.isState('confirmed')) {
      return <FaLock onClick={this.unlock} size={30} className={'lockIcon'} />;
    }
  }

  renderStateButtons() {
    if (this.props.remotePeriods.isReadOnlyPeriod) {
      return null;
    }

    return (
      <StateButtons
        changeDocumentState={this.changeDocumentState}
        moveToNextPeriod={this.moveToNextPeriod}
        moveToCurrentPeriod={this.moveToCurrentPeriod}
        currentState={this.getState()}
        isInNextPeriod={this.isInNextPeriod()}
        isMoveForbidden={this.isMoveForbidden()}
      />
    );
  }

  unlock() {
    this.changeDocumentState('open');
  }

  isInNextPeriod() {
    if (!this.props.currentDocument) {
      return false;
    }
    return this.props.currentDocument.periodId === this.props.dossier.nextPeriodId;
  }

  isMoveForbidden() {
    return !!this.props.currentDocument && !!this.props.currentDocument.bankingTransaction;
  }

  renderDocument() {
    if (!this.props.currentDocument) {
      return <div />;
    }
    return <Document companyNames={this.props.companyNames} locked={this.isLocked()} />;
  }

  render() {
    const { t } = this.props;

    if (this.props.remoteDocumentIds.isLoading) {
      return (
        <div className={'DocumentList'}>
          <div className="loader" />
        </div>
      );
    }

    if (!this.props.documentIds.length) {
      return (
        <div className={'DocumentList'}>
          <Alert color={'info'}>{t('err_no_documents_found')}</Alert>
        </div>
      );
    }

    return (
      <div className={'DocumentList'}>
        <Container className={'headerBar'}>
          <Row>
            <Col xs={4} className={'navigation'}>
              <DocumentListNavigation
                numberOfDocuments={this.props.documentIds.length}
                selectedIndex={this.state.virtualNavigation ? this.state.virtualIndex : this.state.selectedIndex}
                previousDocumentId={this.state.previousDocumentId}
                nextDocumentId={this.state.nextDocumentId}
                state={this.getState()}
                onNavigateToDocumentAtIndex={this.navigateToDocumentAtIndex}
                onNextDocument={this.navigateToNextDocument}
                onLastDocument={this.navigateToLastDocument}
                onFirstDocument={this.navigateToFirstDocument}
                onPreviousDocument={this.navigateToPreviousDocument}
                loading={this.props.remoteDocument.isLoading}
                isInNextPeriod={this.isInNextPeriod()}
              />
            </Col>
            <Col xs={'auto'} className={'right'}>
              <span className={'errorMessage'}>{this.state.errorMessage}</span>
              {this.renderLock()}
              {this.renderStateButtons()}
            </Col>
          </Row>
        </Container>
        {this.renderDocument()}
      </div>
    );
  }
}

DocumentList.propTypes = {
  dossier: PropTypes.object.isRequired,
  companyNames: PropTypes.array.isRequired,
  documentIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  remoteDocumentIds: RemoteDataPropType,
  currentDocument: PropTypes.object,
  selectedPeriod: PropTypes.object.isRequired,
  remoteDocument: RemoteDataPropType,
  remotePeriods: RemoteDataPropType,
  remoteUsedCompanies: RemoteDataPropType,
  t: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  remoteBankingTransactions: RemoteDataPropType,
  remotePeriodMetadata: PropTypes.object.isRequired,
};

export default withRouter(
  translate(
    consumesPeriodMetadata(
      consumesUsedCompanies(
        consumesPeriods(
          consumesDocumentIds(
            consumesBankingTransactions(consumesDossier(consumesDocument(DocumentList, 'currentDocument')))
          )
        )
      )
    )
  )
);
