import React from 'react';
import translate from './translate';

export default function translateWithForwardRef(WrappedComponent) {
  const Translate = translate(WrappedComponent);

  // eslint-disable-next-line react/display-name
  return React.forwardRef((props, ref) => {
    return <Translate {...props} forwardedRef={ref} />;
  });
}
