import React, { Component } from 'react';
import NavigationDossier from 'components/navigation/NavigationDossier';
import Steps from 'components/Steps';
import { providesDossier } from 'contexts/DossierContext';
import { providesPeriods } from 'contexts/PeriodsContext';
import { providesPeriodMetadata } from 'contexts/PeriodMetadataContext';

class LeaDossier extends Component {
  render() {
    return (
      <React.Fragment>
        <NavigationDossier />
        <Steps />
      </React.Fragment>
    );
  }
}

export default providesDossier(providesPeriods(providesPeriodMetadata(LeaDossier)));
