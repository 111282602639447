import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Constants from 'config/Constants';

const POLL_INTERVAL = 500;

class ResponsiveImage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      large: false,
    };

    this.imageRef = React.createRef();
  }

  componentDidMount() {
    this.timer = setInterval(() => {
      const imageWidth = this.imageRef.current.offsetWidth * this.props.zoom;

      if (imageWidth > Constants.SMALL_IMAGE_WIDTH) {
        clearInterval(this.timer);
        this.setState({
          large: true,
        });
      }
    }, POLL_INTERVAL);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    const { smallSrc, largeSrc, ...props } = this.props;
    const src = this.state.large ? largeSrc : smallSrc;

    delete props.zoom;

    return <img ref={this.imageRef} src={src} {...props} />;
  }
}

ResponsiveImage.defaultProps = {
  zoom: 1,
};

ResponsiveImage.propTypes = {
  zoom: PropTypes.number.isRequired,
  smallSrc: PropTypes.string.isRequired,
  largeSrc: PropTypes.string.isRequired,
};

export default ResponsiveImage;
