export default {
  PERIOD_STATE: {
    OPEN: 'open',
    ACTIVE: 'active',
    CLARIFICATION: 'clarification',
    ARCHIVE: 'archive',
  },
  SPECIAL_ACCOUNT: {
    PRIVATE: 'private',
    CASH: 'cash',
    CLARIFICATION: 'clarification',
  },
  DOCUMENT_STATE: {
    OPEN: 'open',
    VERIFIED: 'verified',
    CONFIRMED: 'confirmed',
    REJECTED: 'rejected',
  },
  INVERTED_TYPE: {
    NONE: 'none',
    VATONLY: 'vatOnly',
    NORMAL: 'normal',
    INVERTED: 'inverted',
  },
  CUSTOMER_CHOICES: {
    NONE: 'none',
    PRIVATE: 'private',
    CASH: 'cash',
    NEXT_PERIOD: 'nextPeriod',
    DELETE: 'delete',
    UPLOAD: 'upload',
    NO_RISK: 'noRisk',
    NOT_SURE: 'notSure',
  },
  CHOICE_STATE: {
    OPEN: 'open',
    WAITING: 'waiting',
    APPLIED: 'applied',
  },
  VAT_TYPE: {
    SALDO: 'saldo',
    EFFECTIVE: 'effective',
    NONE: 'none',
  },
  SMALL_IMAGE_WIDTH: 1000,
  DEFAULT_ACCOUNT_FOR_NO_RISK_BOOKING: '6700',
  CURRENCY_OPTIONS: [
    { value: 'CHF', label: 'CHF' },
    { value: 'EUR', label: 'EUR' },
    { value: 'USD', label: 'USD' },
    { value: 'CAD', label: 'CAD' },
    { value: 'GBP', label: 'GBP' },
    { value: 'AUD', label: 'AUD' },
    { value: 'ZAR', label: 'ZAR' },
    { value: 'NOK', label: 'NOK' },
    { value: 'AED', label: 'AED' },
    { value: 'SEK', label: 'SEK' },
  ],
};

export const DOCUMENT_TYPE_EMOJI = {
  accountingDocument: '🧾',
  bankingDocument: '🏦',
  creditDocument: '💳',
};
