import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input, Label } from 'reactstrap';
import RemoteDataPropType from 'lib/prop-types/RemoteDataPropType';
import translate from 'containers/translate';
import './PostEditStep.scss';

class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        description: null,
        amountFrom: null,
        amountTo: null,
      },
    };

    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  handleFilterChange(key, e) {
    const value = e.target.value;

    this.setState(
      (prevState) => {
        const filter = Object.assign({}, prevState.filter);
        filter[key] = value;
        return { filter };
      },
      () => this.props.onFilterChange(this.state.filter)
    );
  }

  render() {
    const { t } = this.props;

    return (
      <div className={'filter'}>
        <FormGroup>
          <Label for="filterDescription">{t('description')}</Label>
          <Input
            type="text"
            name="description"
            id="filterDescription"
            value={this.state.description}
            onChange={this.handleFilterChange.bind(this, 'description')}
          />
        </FormGroup>
        <div className="form-row">
          <FormGroup className="col-md-6">
            <Label for="filterAmountFrom">{t('amount_from')}</Label>
            <Input
              type="text"
              name="amount-from"
              id="filterAmountFrom"
              value={this.state.amountFrom}
              onChange={this.handleFilterChange.bind(this, 'amountFrom')}
            />
          </FormGroup>
          <FormGroup className="col-md-6">
            <Label for="filterAmountTo">{t('amount_to')}</Label>
            <Input
              type="text"
              name="amount-to"
              id="filterAmountTo"
              value={this.state.amountTo}
              onChange={this.handleFilterChange.bind(this, 'amountTo')}
            />
          </FormGroup>
        </div>
      </div>
    );
  }
}

Filter.propTypes = {
  onFilterChange: RemoteDataPropType,
  t: PropTypes.func.isRequired,
};

export default translate(Filter);
