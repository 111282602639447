import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  consumesStartingBalanceProposal,
  providesStartingBalanceProposal,
} from 'contexts/StartingBalanceProposalContext';
import translate from 'containers/translate';
import formatAmount from 'lib/formatAmount';
import { Button } from 'reactstrap';

class StartingBalanceProposal extends Component {
  render() {
    const { t } = this.props;
    const startingBalanceProposal = this.props.startingBalanceProposal.value;
    const currency = this.props.bankingDocument.account.currency;

    if (this.props.startingBalance === startingBalanceProposal) {
      return null;
    }

    return (
      <span>
        {t('starting_balance_proposal')}: {currency} {formatAmount(startingBalanceProposal / 100)}
        <Button color="link" onClick={() => this.props.onStartingBalanceProposalSelected(startingBalanceProposal)}>
          {t('starting_balance_proposal_accept')}
        </Button>
      </span>
    );
  }
}

StartingBalanceProposal.propTypes = {
  startingBalanceProposal: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  bankingDocument: PropTypes.object.isRequired,
  startingBalance: PropTypes.number.isRequired,
  onStartingBalanceProposalSelected: PropTypes.func.isRequired,
};

export default providesStartingBalanceProposal(consumesStartingBalanceProposal(translate(StartingBalanceProposal)));
