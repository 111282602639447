import React from 'react';
import { consumesBankingDocuments } from 'contexts/BankingDocumentsContext';
import RemoteDataPropType from 'lib/prop-types/RemoteDataPropType';
import RemoteDataProvider from './RemoteDataProvider';

const BankingTransactionsContext = React.createContext();

function providesBankingTransaction(Component) {
  class RemoteBankingTransaction extends React.Component {
    constructor(props) {
      super(props);

      this.handleAfterCreate = this.handleAfterCreate.bind(this);
      this.handleAfterUpdate = this.handleAfterUpdate.bind(this);
    }

    handleAfterCreate() {
      this.props.remoteBankingDocuments.api.fetch();
    }

    handleAfterUpdate() {
      this.props.remoteBankingDocuments.api.fetch();
    }

    render() {
      return (
        <RemoteDataProvider
          baseURL="/banking-transactions"
          autoFetch={false}
          isCollection
          onAfterCreate={this.handleAfterCreate}
          onAfterUpdate={this.handleAfterUpdate}
          render={(remoteData) => {
            return (
              <BankingTransactionsContext.Provider value={remoteData}>
                <Component {...this.props} />
              </BankingTransactionsContext.Provider>
            );
          }}
        />
      );
    }
  }

  RemoteBankingTransaction.propTypes = {
    remoteBankingDocuments: RemoteDataPropType,
  };

  return consumesBankingDocuments(RemoteBankingTransaction);
}

function consumesBankingTransaction(Component) {
  return function ComponentThatConsumesBankingTransaction(props) {
    return (
      <BankingTransactionsContext.Consumer>
        {(remoteData) => {
          return <Component {...props} remoteBankingTransaction={remoteData} />;
        }}
      </BankingTransactionsContext.Consumer>
    );
  };
}

export { providesBankingTransaction, consumesBankingTransaction };
