import React from 'react';
import RemoteDataProvider from './RemoteDataProvider';
import PropTypes from 'prop-types';

const VatCodesContext = React.createContext();

function providesVatCodes(Component) {
  class RemoteVatCodes extends React.Component {
    render() {
      if (!this.props.accountingTransaction) {
        return <Component {...this.props} />;
      }
      return (
        <RemoteDataProvider
          baseURL="/accounting-transactions"
          subresource="/vatCodes"
          objectId={this.props.accountingTransaction.id}
          render={(remoteData) => {
            return (
              <VatCodesContext.Provider value={remoteData}>
                <Component {...this.props} />
              </VatCodesContext.Provider>
            );
          }}
        />
      );
    }
  }

  RemoteVatCodes.propTypes = {
    accountingTransaction: PropTypes.object,
  };

  return RemoteVatCodes;
}

function consumesVatCodes(Component, provideValueAs = 'vatCodes') {
  return function ComponentThatConsumesVatCodes(props) {
    return (
      <VatCodesContext.Consumer>
        {(remoteData) => {
          if (!remoteData) {
            return <Component {...props} />;
          }
          const consumedProps = {
            remoteVatCodes: remoteData,
            [provideValueAs]: remoteData.value,
          };

          return <Component {...props} {...consumedProps} />;
        }}
      </VatCodesContext.Consumer>
    );
  };
}

export { providesVatCodes, consumesVatCodes };
