import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, FormText } from 'reactstrap';
import translate from 'containers/translate';
import formatDescription from 'lib/formatDescription';
import './BankingTransactionDescription.scss';

const LINES_TO_SHOW = 4;

class BankingTransactionDescription extends Component {
  constructor(props) {
    super(props);

    this.state = {
      description: formatDescription(this.props.description),
      descriptionCropped: BankingTransactionDescription.hasDescriptionCropped(this.props),
    };

    this.onToggleMore = this.onToggleMore.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.description !== prevState.description) {
      return {
        description: formatDescription(nextProps.description),
        descriptionCropped: BankingTransactionDescription.hasDescriptionCropped(nextProps),
      };
    }

    return null;
  }

  static hasDescriptionCropped(props) {
    if (!props.description) {
      return false;
    }
    const lineCount = props.description.split(/\r\n|\r|\n/).length;
    return props.cropDescription && lineCount > LINES_TO_SHOW;
  }

  onToggleMore() {
    this.setState((prevState) => ({ descriptionCropped: !prevState.descriptionCropped }));
  }

  renderToggleMore() {
    const { t } = this.props;

    const description = this.state.descriptionCropped ? t('more') : t('less');

    if (this.props.cropDescription) {
      return (
        <Button color={'link'} className={'more'} onClick={this.onToggleMore}>
          {description}
        </Button>
      );
    }
  }

  getDescriptionParagraphClassName() {
    if (this.state.descriptionCropped) {
      return 'description--cropped';
    }
    return '';
  }

  render() {
    return (
      <div className={'BankingTransactionDescription'}>
        <p className={this.getDescriptionParagraphClassName()}>
          <FormText color={'muted'}>{formatDescription(this.props.description)}</FormText>
        </p>
        {this.renderToggleMore()}
      </div>
    );
  }
}

BankingTransactionDescription.propTypes = {
  description: PropTypes.string.isRequired,
  cropDescription: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

export default translate(BankingTransactionDescription);
