import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'containers/withRouter';
import RemoteDataProvider from './RemoteDataProvider';

const StartingBalanceProposalContext = React.createContext();

function providesStartingBalanceProposal(Component) {
  class RemoteStartingBalanceProposal extends React.Component {
    getObjectId() {
      const id = parseInt(this.props.match.params.documentId, 10);

      if (isNaN(id)) {
        return null;
      }

      return id;
    }

    render() {
      const objectId = this.getObjectId();

      return (
        <RemoteDataProvider
          baseURL="/banking-documents"
          subresource="/starting-balance-proposal"
          objectId={objectId}
          initialValue={{ value: 0 }}
          render={(remoteData) => {
            return (
              <StartingBalanceProposalContext.Provider value={remoteData}>
                <Component {...this.props} />
              </StartingBalanceProposalContext.Provider>
            );
          }}
        />
      );
    }
  }

  RemoteStartingBalanceProposal.propTypes = {
    match: PropTypes.object.isRequired,
  };

  return withRouter(RemoteStartingBalanceProposal);
}

function consumesStartingBalanceProposal(Component) {
  return function ComponentThatConsumesStartingBalanceProposal(props) {
    return (
      <StartingBalanceProposalContext.Consumer>
        {(remoteData) => {
          const consumedProps = {
            remoteStartingBalanceProposal: remoteData,
            startingBalanceProposal: remoteData.value,
          };

          return <Component {...props} {...consumedProps} />;
        }}
      </StartingBalanceProposalContext.Consumer>
    );
  };
}

export { providesStartingBalanceProposal, consumesStartingBalanceProposal };
