import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import errorBus from 'lib/errorBus';
import { consumesDossier } from 'contexts/DossierContext';

export default function withRule(WrappedComponent) {
  class WithRule extends Component {
    constructor(props) {
      super(props);

      this.onNewRule = this.onNewRule.bind(this);
    }

    onNewRule(rule, isDossierRule, cb) {
      if (isDossierRule && this.props.dossier) {
        rule.dossierId = this.props.dossier.id;
      }

      let url = `${process.env.REACT_APP_API_ENDPOINT}/rules`;

      if (this.props.documentId) {
        url += `?ignoreAccountingDocumentId=${this.props.documentId}`;
      }

      axios
        .post(url, rule)
        .then(() => {
          cb && cb();
        })
        .catch((err) => {
          errorBus.emit('error', err);
        });
    }

    render() {
      return <WrappedComponent {...this.props} onNewRule={this.onNewRule} />;
    }
  }

  WithRule.propTypes = {
    dossier: PropTypes.object,
    documentId: PropTypes.number,
  };

  return consumesDossier(WithRule);
}
