import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import formatUID from 'lib/formatUID';
import { MdMergeType } from 'react-icons/md';
import Tooltip from 'components/tooltip/Tooltip';
import { consumesCompanyNames } from 'contexts/CompanyNamesContext';
import './CompanyOverviewRow.scss';

class CompanyOverviewRow extends Component {
  constructor(props) {
    super(props);

    this.handleNameClick = this.handleNameClick.bind(this);
  }

  render() {
    const { mergeAllowed } = this.props;

    return (
      <Row
        style={this.props.style}
        className={classnames('CompanyOverviewRow', { mergeAllowed })}
        onClick={() => mergeAllowed && this.props.onCompanySelected(this.props.company)}
      >
        <Col xs={7}>
          <span className="companyName" onClick={this.handleNameClick}>
            {this.props.company.name}
          </span>
        </Col>
        <Col xs={3}>{this.renderUIDs()}</Col>
        <Col xs={1}>{this.renderRule()}</Col>
        <Col xs={1} className={'arrow'}>
          {this.renderMergeArrow()}
        </Col>
      </Row>
    );
  }

  handleNameClick(e) {
    e.stopPropagation();

    this.props.onNameClick(this.props.company);
  }

  renderUIDs() {
    const uids = this.props.company.uids;

    if (uids.length === 0) {
      return;
    }

    if (uids.length === 1) {
      return this.renderUID(uids[0]);
    }

    return (
      <ul>
        {uids.map((uid) => (
          <li key={uid}>{this.renderUID(uid)}</li>
        ))}
      </ul>
    );
  }

  renderUID(uid) {
    const title = this.props.companyNames[uid] || '';

    return (
      <Tooltip title={title}>
        <span>{formatUID(uid)}</span>
      </Tooltip>
    );
  }

  renderRule() {
    if (!this.props.rule) {
      return;
    }

    return <span>{this.props.rule.account}</span>;
  }

  renderMergeArrow() {
    if (!this.props.mergeAllowed) {
      return;
    }
    return <MdMergeType />;
  }
}

CompanyOverviewRow.propTypes = {
  company: PropTypes.object.isRequired,
  rule: PropTypes.object,
  mergeAllowed: PropTypes.bool.isRequired,
  companyNames: PropTypes.object.isRequired,
  onCompanySelected: PropTypes.func.isRequired,
  onNameClick: PropTypes.func.isRequired,
  style: PropTypes.object.isRequired,
};

export default consumesCompanyNames(CompanyOverviewRow);
