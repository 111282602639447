import React from 'react';
import PropTypes from 'prop-types';
import RemoteDataProvider from './RemoteDataProvider';
import { consumesDocument } from 'contexts/DocumentContext';

const DuplicateDocumentsContext = React.createContext();

function providesDuplicateDocuments(Component) {
  class RemoteDuplicateDocuments extends React.Component {
    render() {
      const accountingDocumentId = this.props.document.id;
      const baseURL = `/accounting-documents/${accountingDocumentId}/duplicates`;

      return (
        <RemoteDataProvider
          scoped
          baseURL={baseURL}
          isCollection
          render={(remoteData) => {
            return (
              <DuplicateDocumentsContext.Provider value={remoteData}>
                <Component {...this.props} />
              </DuplicateDocumentsContext.Provider>
            );
          }}
        />
      );
    }
  }

  RemoteDuplicateDocuments.propTypes = {
    document: PropTypes.object.isRequired,
  };

  return consumesDocument(RemoteDuplicateDocuments);
}

function consumesDuplicateDocuments(Component, provideValueAs = 'duplicateDocuments') {
  return function ComponentThatConsumesDuplicateDocuments(props) {
    return (
      <DuplicateDocumentsContext.Consumer>
        {(remoteData) => {
          const consumedProps = {
            remoteDuplicateDocuments: remoteData,
            [provideValueAs]: remoteData.value,
          };

          return <Component {...props} {...consumedProps} />;
        }}
      </DuplicateDocumentsContext.Consumer>
    );
  };
}

export { providesDuplicateDocuments, consumesDuplicateDocuments };
