import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import clone from 'clone';
import { FaFile, FaDollarSign, FaCheckCircle, FaTimesCircle, FaDownload } from 'react-icons/fa';
import RemoteDataPropType from 'lib/prop-types/RemoteDataPropType';
import translate from 'containers/translate';
import Constants from 'config/Constants';
import DocumentWindow from 'components/documentWindow/DocumentWindow';
import { consumesCustomerChoices, providesCustomerChoices } from 'contexts/CustomerChoicesContext';
import withFileDownload from 'containers/withFileDownload';
import Tooltip from 'components/tooltip/Tooltip';
import AccountEdit from 'components/accountEdit/AccountEdit';
import './ClarificationList.scss';

class ClarificationList extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();

    this.onDialogDismiss = this.onDialogDismiss.bind(this);
    this.onApproveDialogDismiss = this.onApproveDialogDismiss.bind(this);
  }

  getInitialState() {
    return {
      showClarificationList: false,
      showApproveDialog: false,
      documentId: null,
      accountForNoRiskBooking: Constants.DEFAULT_ACCOUNT_FOR_NO_RISK_BOOKING,
      pendingChoiceAction: null,
    };
  }

  onDialogDismiss() {
    const initialState = this.getInitialState();
    this.setState(initialState);
  }

  onApproveDialogDismiss() {
    this.setState({ showApproveDialog: false });
  }

  onUserAction(e, customerChoice, action) {
    e.stopPropagation();

    const isNoRiskChoice = customerChoice.choice === Constants.CUSTOMER_CHOICES.NO_RISK;
    if (action === 'approve' && isNoRiskChoice && !this.state.pendingChoiceAction) {
      return this.setState({ showApproveDialog: true, pendingChoiceAction: { customerChoice, action } });
    }
    this.applyUserAction(customerChoice, action);
  }

  applyUserAction(customerChoice, action, account = null) {
    const newCustomerChoice = clone(customerChoice);
    newCustomerChoice.action = action;
    if (account) {
      newCustomerChoice.account = account;
    }
    this.props.remoteCustomerChoices.api.update(newCustomerChoice);
  }

  renderApproveDialog() {
    const { t } = this.props;

    if (!this.state.showApproveDialog) {
      return null;
    }

    return (
      <Modal isOpen className="CustomerChoiceApproveDialog" size="lg" toggle={this.onApproveDialogDismiss}>
        <ModalHeader toggle={this.onApproveDialogDismiss}>{t('choose_account')}</ModalHeader>
        <ModalBody>
          <b>{t('accounting_text')}:</b>
          <p>{this.state.pendingChoiceAction.customerChoice.accountingText}</p>
          <AccountEdit
            onAccountChange={(accountForNoRiskBooking) => this.setState({ accountForNoRiskBooking })}
            account={Constants.DEFAULT_ACCOUNT_FOR_NO_RISK_BOOKING}
            setFocusOnMount={true}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              const { pendingChoiceAction, accountForNoRiskBooking } = this.state;
              this.applyUserAction(
                pendingChoiceAction.customerChoice,
                pendingChoiceAction.action,
                accountForNoRiskBooking
              );
              this.setState({ pendingChoiceAction: null });
              this.onApproveDialogDismiss();
            }}
          >
            {t('book')}
          </Button>{' '}
          <Button
            color="secondary"
            onClick={() => {
              this.setState({ pendingChoiceAction: null });
              this.onApproveDialogDismiss();
            }}
          >
            {t('cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  renderCustomerChoiceResolveDialog() {
    const { t } = this.props;

    if (!this.state.showClarificationList) {
      return null;
    }

    return (
      <Modal isOpen className="CustomerChoices" size="lg" toggle={this.onDialogDismiss}>
        <ModalHeader toggle={this.onDialogDismiss}>{t('customer_choices')}</ModalHeader>
        <ModalBody>
          <Table>
            <thead>
              <tr>
                <th>{t('type')}</th>
                <th>{t('name')}</th>
                <th>{t('choice')}</th>
                {this.renderAccountingTextHeader()}
                {this.renderDownloadHeader()}
                {this.renderCommentHeader()}
                <th>{t('action')}</th>
              </tr>
            </thead>
            <tbody>{this.renderCustomerChoices()}</tbody>
          </Table>
        </ModalBody>
      </Modal>
    );
  }

  renderCommentHeader() {
    const { t } = this.props;

    if (!this.props.customerChoices.some((choice) => !!choice.comment)) {
      return null;
    }

    return <th>{t('comment')}</th>;
  }

  renderAccountingTextHeader() {
    const { t } = this.props;

    if (!this.props.customerChoices.some((choice) => choice.choice === Constants.CUSTOMER_CHOICES.NO_RISK)) {
      return null;
    }

    return <th>{t('accounting_text')}</th>;
  }

  renderDownloadHeader() {
    if (!this.props.customerChoices.some((choice) => !!choice.fileId)) {
      return null;
    }

    return <th />;
  }

  renderCustomerChoices() {
    return this.props.customerChoices.map((customerChoice, index) => {
      if (customerChoice.accountingDocument) {
        return this.renderAccountingDocumentChoice(customerChoice, index);
      } else {
        return this.renderBankingTransactionChoice(customerChoice, index);
      }
    });
  }

  renderAccountingDocumentChoice(customerChoice, index) {
    const document = customerChoice.accountingDocument;
    return (
      <tr
        key={customerChoice.id + index}
        onClick={(e) => {
          this.setState({ documentId: document.id });
          e.stopPropagation();
        }}
      >
        <td>
          <FaFile />
        </td>
        <td>{this.getNameFromDocument(document)}</td>
        <td>{this.getChoiceName(customerChoice.choice)}</td>
        {this.renderAccountingText(customerChoice)}
        {this.renderDownload(customerChoice)}
        {this.renderComment(customerChoice)}
        {this.renderUserActionButtons(customerChoice)}
      </tr>
    );
  }

  renderBankingTransactionChoice(customerChoice, index) {
    const transaction = customerChoice.bankingTransaction;
    return (
      <tr key={customerChoice.id + index}>
        <td>
          <FaDollarSign />
        </td>
        <td>{transaction.description}</td>
        <td>{this.getChoiceName(customerChoice.choice)}</td>
        {this.renderAccountingText(customerChoice)}
        {this.renderDownload(customerChoice)}
        {this.renderComment(customerChoice)}
        {this.renderUserActionButtons(customerChoice)}
      </tr>
    );
  }

  renderDownload(customerChoice) {
    if (!this.props.customerChoices.some((choice) => !!choice.fileId)) {
      return null;
    }

    if (!customerChoice.fileId) {
      return <td />;
    }

    return (
      <td>
        <Tooltip imageFileId={customerChoice.imageFileId} placement={'left'}>
          <FaDownload
            className="download-icon"
            size={22}
            onClick={() => this.props.onDownloadChoiceFile(customerChoice.id)}
          />
        </Tooltip>
      </td>
    );
  }

  renderAccountingText(customerChoice) {
    if (!this.props.customerChoices.some((choice) => choice.choice === Constants.CUSTOMER_CHOICES.NO_RISK)) {
      return null;
    }

    if (!customerChoice.accountingText) {
      return <td />;
    }

    const truncatedAccountingText = customerChoice.accountingText.replace(/(.{13})..+/, '$1…');
    return (
      <td>
        <Tooltip title={customerChoice.accountingText}>
          <span>{truncatedAccountingText}</span>
        </Tooltip>
      </td>
    );
  }

  renderComment(customerChoice) {
    if (!this.props.customerChoices.some((choice) => !!choice.comment)) {
      return null;
    }
    return <td>{customerChoice.comment}</td>;
  }

  renderUserActionButtons(customerChoice) {
    const { NONE, UPLOAD, NOT_SURE } = Constants.CUSTOMER_CHOICES;

    if ([NONE, NOT_SURE].includes(customerChoice.choice)) {
      return <td />;
    }

    if (customerChoice.choice === UPLOAD && !customerChoice.fileId) {
      return <td />;
    }

    if (Constants.CHOICE_STATE.WAITING === customerChoice.state) {
      return <td />;
    }

    return (
      <td>
        <div className={'iconContainer'}>
          <FaCheckCircle
            className={'approveIcon'}
            size={25}
            onClick={(e) => this.onUserAction(e, customerChoice, 'approve')}
          />
          <FaTimesCircle
            className={'rejectIcon'}
            size={25}
            onClick={(e) => this.onUserAction(e, customerChoice, 'reject')}
          />
        </div>
      </td>
    );
  }

  getNameFromDocument(document) {
    let accountingText = document.accountingText;
    if (accountingText) {
      accountingText = document.company.name + ', ' + accountingText;
    } else {
      accountingText = document.company.name;
    }
    return accountingText;
  }

  getChoiceName(choice) {
    const { t } = this.props;

    const { NONE, PRIVATE, CASH, NEXT_PERIOD, DELETE, UPLOAD, NO_RISK, NOT_SURE } = Constants.CUSTOMER_CHOICES;

    if (choice === NONE) {
      return t('choice_none');
    } else if (choice === PRIVATE) {
      return t('choice_private');
    } else if (choice === CASH) {
      return t('choice_cash');
    } else if (choice === NEXT_PERIOD) {
      return t('choice_next_period');
    } else if (choice === DELETE) {
      return t('choice_delete');
    } else if (choice === UPLOAD) {
      return t('choice_upload');
    } else if (choice === NO_RISK) {
      return t('choice_no_risk');
    } else if (choice === NOT_SURE) {
      return t('choice_not_sure');
    }
  }

  renderCustomerChoiceResolveButton() {
    const { t, customerChoices } = this.props;

    if (!customerChoices || customerChoices.length === 0) {
      return null;
    }

    return (
      <Button color="primary" onClick={() => this.setState({ showClarificationList: true })}>
        {t('resolve_customer_choice')}
      </Button>
    );
  }

  renderDocumentWindow() {
    const documentId = this.state.documentId;
    if (!documentId) {
      return <div />;
    }

    return <DocumentWindow documentId={documentId} onDismiss={() => this.setState({ documentId: null })} />;
  }

  render() {
    return (
      <div className={'ClarificationList'}>
        {this.renderDocumentWindow()}
        {this.renderCustomerChoiceResolveDialog()}
        {this.renderApproveDialog()}
        {this.renderCustomerChoiceResolveButton()}
      </div>
    );
  }
}

ClarificationList.propTypes = {
  customerChoices: PropTypes.array.isRequired,
  remoteCustomerChoices: RemoteDataPropType,
  onDownloadChoiceFile: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withFileDownload(providesCustomerChoices(consumesCustomerChoices(translate(ClarificationList))));
