import React from 'react';
import PropTypes from 'prop-types';
import deepEqual from 'deep-equal';
import { consumesPeriods } from 'contexts/PeriodsContext';
import RemoteDataProvider, { createEmptyRemoteData } from './RemoteDataProvider';

const INITIAL_VALUE = {
  isStartDateEditable: false,
  isEndDateInPast: true,
  hasNoOpenDocuments: true,
  hasNoOpenBankingTransactions: true,
};

const PeriodMetadataContext = React.createContext(createEmptyRemoteData(INITIAL_VALUE));

function providesPeriodMetadata(Component) {
  class RemotePeriodMetadata extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        lastUpdateRequested: 0,
      };
    }

    componentDidUpdate(prevProps) {
      const prevSelectedPeriod = prevProps.selectedPeriod;
      const selectedPeriod = this.props.selectedPeriod;

      if (prevSelectedPeriod.id === selectedPeriod.id) {
        if (!deepEqual(prevSelectedPeriod, selectedPeriod)) {
          this.setState({
            lastUpdateRequested: Date.now(),
          });
        }
      }
    }

    render() {
      const baseURL = '/periods';

      return (
        <RemoteDataProvider
          scope="dossier"
          baseURL={baseURL}
          subresource="/metadata"
          objectId={this.props.selectedPeriod.id}
          initialValue={INITIAL_VALUE}
          lastUpdateRequested={this.state.lastUpdateRequested}
          render={(remoteData) => {
            return (
              <PeriodMetadataContext.Provider value={remoteData}>
                <Component {...this.props} />
              </PeriodMetadataContext.Provider>
            );
          }}
        />
      );
    }
  }

  RemotePeriodMetadata.propTypes = {
    selectedPeriod: PropTypes.object.isRequired,
  };

  return consumesPeriods(RemotePeriodMetadata);
}

function consumesPeriodMetadata(Component) {
  return function ComponentThatConsumesPeriodMetadata(props) {
    return (
      <PeriodMetadataContext.Consumer>
        {(remoteData) => {
          const consumedProps = {
            remotePeriodMetadata: remoteData,
            periodMetadata: remoteData.value,
          };

          return <Component {...props} {...consumedProps} />;
        }}
      </PeriodMetadataContext.Consumer>
    );
  };
}

export { providesPeriodMetadata, consumesPeriodMetadata };
