import React, { Component } from 'react';
import { Badge, Modal, ModalBody, ModalHeader, Table } from 'reactstrap';
import PropTypes from 'prop-types';
import keyRegistry from 'lib/GlobalKeyRegistry';
import { consumesTemplates, providesTemplates } from 'contexts/TemplatesContext';
import translate from 'containers/translate';
import VatSelect from 'components/vatSelect/VatSelect';
import { consumesDossier } from 'contexts/DossierContext';

const DEFAULT_KEY = '0';

const KEYS_FOR_INDEX = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
];

class TemplateSelect extends Component {
  componentDidMount() {
    keyRegistry.pushContext('TemplateSelect');

    keyRegistry.register(DEFAULT_KEY, this.handleKeyPress.bind(this, DEFAULT_KEY));

    KEYS_FOR_INDEX.forEach((key) => {
      keyRegistry.register(key, this.handleKeyPress.bind(this, key));
    });
  }

  componentWillUnmount() {
    KEYS_FOR_INDEX.forEach((key) => {
      keyRegistry.deregister(key);
    });

    keyRegistry.deregister(DEFAULT_KEY);

    keyRegistry.popContext();
  }

  handleKeyPress(key) {
    if (key === DEFAULT_KEY) {
      this.props.onDismiss('DEFAULT');
    } else {
      const index = KEYS_FOR_INDEX.indexOf(key);

      if (index >= this.props.templates.length) {
        return;
      }

      this.props.onDismiss(this.props.templates[index]);
    }
  }

  renderTemplates() {
    return this.props.templates.map((template, index) => {
      return (
        <tr key={template.id} onClick={() => this.props.onDismiss(template)}>
          <td>
            <Badge>{KEYS_FOR_INDEX[index]}</Badge> {template.name}
          </td>
          <td>{template.company.name}</td>
          <td>{template.account}</td>
          <td>
            <VatSelect
              presetVat={template.vat}
              presetVatType={template.vatType}
              dossier={this.props.dossier}
              locked={true}
              onVatChange={() => {}}
            />
          </td>
          <td>{template.accountingText}</td>
        </tr>
      );
    });
  }

  render() {
    const { t } = this.props;

    return (
      <Modal isOpen size="lg" className="BankingTransactionSelect" toggle={() => this.props.onDismiss()}>
        <ModalHeader toggle={() => this.props.onDismiss()}>{t('templates')}</ModalHeader>
        <ModalBody>
          <Table>
            <thead>
              <tr>
                <th>{t('name')}</th>
                <th>{t('company')}</th>
                <th>{t('account')}</th>
                <th>{t('vat')}</th>
                <th>{t('accounting_text')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan="5" onClick={() => this.props.onDismiss('DEFAULT')}>
                  <Badge>0</Badge> {t('default_template')}
                </td>
              </tr>
              {this.renderTemplates()}
            </tbody>
          </Table>
        </ModalBody>
      </Modal>
    );
  }
}

TemplateSelect.propTypes = {
  t: PropTypes.func.isRequired,
  dossier: PropTypes.object.isRequired,
  templates: PropTypes.arrayOf(PropTypes.object).isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default providesTemplates(consumesDossier(consumesTemplates(translate(TemplateSelect))));
