import React from 'react';
import PropTypes from 'prop-types';
import RemoteDataProvider from './RemoteDataProvider';

const StartClarificationContext = React.createContext();

function providesStartClarification(Component) {
  class StartClarification extends React.Component {
    render() {
      return (
        <RemoteDataProvider
          scope="period"
          baseURL="/clarifications/startClarification"
          render={(remoteData) => {
            return (
              <StartClarificationContext.Provider value={remoteData}>
                <Component {...this.props} />
              </StartClarificationContext.Provider>
            );
          }}
        />
      );
    }
  }

  StartClarification.propTypes = {
    selectedPeriod: PropTypes.object.isRequired,
  };

  return StartClarification;
}

function consumesStartClarification(Component, provideValueAs = 'startClarification') {
  return function ComponentThatConsumesStartClarification(props) {
    return (
      <StartClarificationContext.Consumer>
        {(remoteData) => {
          if (!remoteData) {
            return <Component {...props} />;
          }
          const consumedProps = {
            remoteStartClarification: remoteData,
            [provideValueAs]: remoteData.value,
          };

          return <Component {...props} {...consumedProps} />;
        }}
      </StartClarificationContext.Consumer>
    );
  };
}

export { providesStartClarification, consumesStartClarification };
