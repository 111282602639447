import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './progress.scss';

export const ProgressModal = ({ closeModal = () => {}, maxProgress = [], progressValue = '', error = '' }) => {
  useEffect(() => {
    window.document.body.style.overflow = 'hidden';
  }, []);

  return (
    <>
      <div className="modalbg">
        <div className="modals">
          <div className="modal__top"></div>
          <div className="modal__middle">
            <h3>
              Booking {progressValue} of {maxProgress}
            </h3>
            <progress id={error === 'error' ? 'file_error' : 'file'} value={progressValue} max={maxProgress} />
            {error === 'error' && <p>something get wrong</p>}
          </div>
          <div className="modal__bottom">
            <button
              className={progressValue === maxProgress && error !== 'error' ? 'modal_okbutton' : 'modal_button'}
              onClick={() => closeModal(progressValue !== maxProgress && error !== 'error')}
            >
              {error === 'error' ? 'Close' : progressValue === maxProgress ? 'Ok' : 'Cancel'}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

ProgressModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  maxProgress: PropTypes.array,
  progressValue: PropTypes.number,
  error: PropTypes.string,
};
