import React from 'react';
import { withRouter } from 'containers/withRouter';
import { consumesDossier } from 'contexts/DossierContext';
import RemoteDataProvider, { createEmptyRemoteData } from './RemoteDataProvider';

const AdminPeriodEditContext = React.createContext(createEmptyRemoteData([]));

function providesAdminPeriodEdit(Component) {
  class RemoteAdminPeriodEdit extends React.Component {
    render() {
      return (
        <RemoteDataProvider
          baseURL="/admin-edit"
          name="adminPeriodEdit"
          render={(remoteData) => {
            return (
              <AdminPeriodEditContext.Provider value={remoteData}>
                <Component {...this.props} />
              </AdminPeriodEditContext.Provider>
            );
          }}
        />
      );
    }
  }

  return consumesDossier(withRouter(RemoteAdminPeriodEdit));
}

function consumesAdminPeriodEdit(Component, provideValueAs = 'adminPeriodEdit') {
  return function ComponentThatConsumesAdminPeriodEdit(props) {
    return (
      <AdminPeriodEditContext.Consumer>
        {(remoteData) => {
          const consumedProps = {
            remoteAdminPeriodEdit: remoteData,
            [provideValueAs]: remoteData.value,
          };

          return <Component {...props} {...consumedProps} />;
        }}
      </AdminPeriodEditContext.Consumer>
    );
  };
}

export { providesAdminPeriodEdit, consumesAdminPeriodEdit };
