import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FaRegCircle, FaCheckCircle } from 'react-icons/fa';
import Constants from 'config/Constants';
import './DocumentStateIndicator.scss';

export default class DocumentStateIndicator extends Component {
  render() {
    if (this.props.isInNextPeriod) {
      return <FaCheckCircle className="DocumentStateIndicator blue" size="30" />;
    } else if (this.props.state === Constants.DOCUMENT_STATE.CONFIRMED) {
      return <FaCheckCircle className="DocumentStateIndicator green" size="30" />;
    } else if (this.props.state === Constants.DOCUMENT_STATE.REJECTED) {
      return <FaRegCircle className="DocumentStateIndicator red" size="30" />;
    } else if (this.props.state === Constants.DOCUMENT_STATE.VERIFIED) {
      return <FaCheckCircle className="DocumentStateIndicator yellow" size="30" />;
    }

    return <FaRegCircle className="DocumentStateIndicator" size="30" />;
  }
}

DocumentStateIndicator.propTypes = {
  state: PropTypes.string.isRequired,
  isInNextPeriod: PropTypes.bool.isRequired,
};
