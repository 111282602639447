import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import translate from 'containers/translate';
import { consumesDossier } from 'contexts/DossierContext';
import RemoteDataPropType from 'lib/prop-types/RemoteDataPropType';
import './DossierDelete.scss';

class DossierDelete extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  toggleDelete(value) {
    const dossier = {
      ...this.props.dossier,
      deleted: value,
    };

    this.props.remoteDossier.api.update(dossier);
  }

  renderDeleteButton() {
    const { t } = this.props;

    const isDeleted = this.props.dossier.deleted;

    if (isDeleted) {
      return (
        <Button type="button" onClick={() => this.toggleDelete(false)} color="warning">
          {t('dossier_restore')}
        </Button>
      );
    } else {
      return (
        <Button type="button" onClick={() => this.toggleDelete(true)} color="danger">
          {t('dossier_delete')}
        </Button>
      );
    }
  }

  render() {
    return (
      <div className={'DossierDelete'}>
        <hr />
        {this.renderDeleteButton()}
      </div>
    );
  }
}

DossierDelete.propTypes = {
  t: PropTypes.func.isRequired,
  dossier: PropTypes.object.isRequired,
  remoteDossier: RemoteDataPropType,
};

export default consumesDossier(translate(DossierDelete));
