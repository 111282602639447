import React from 'react';
import RemoteDataProvider from './RemoteDataProvider';

const BankingTransactionSplitContext = React.createContext();

function providesBankingTransactionSplit(Component) {
  class RemoteBankingTransactionSplit extends React.Component {
    render() {
      const baseURL = `/banking-transaction-split`;

      return (
        <RemoteDataProvider
          baseURL={baseURL}
          render={(remoteData) => {
            return (
              <BankingTransactionSplitContext.Provider value={remoteData}>
                <Component {...this.props} />
              </BankingTransactionSplitContext.Provider>
            );
          }}
        />
      );
    }
  }

  return RemoteBankingTransactionSplit;
}

function consumesBankingTransactionSplit(Component, provideValueAs = 'bankingTransactionSplitResult') {
  return function ComponentThatConsumesBankingTransactionSplit(props) {
    return (
      <BankingTransactionSplitContext.Consumer>
        {(remoteData) => {
          const consumedProps = {
            remoteBankingTransactionSplit: remoteData,
            [provideValueAs]: remoteData.value,
          };

          return <Component {...props} {...consumedProps} />;
        }}
      </BankingTransactionSplitContext.Consumer>
    );
  };
}

export { providesBankingTransactionSplit, consumesBankingTransactionSplit };
