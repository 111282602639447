import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'containers/withRouter';
import { clearAccessToken } from 'lib/configureAxios.js';
import { withAuth0 } from '@auth0/auth0-react';

export default function withLogout(WrappedComponent) {
  class WithLogout extends Component {
    constructor(props) {
      super(props);
      this.state = {};
      this.logout = this.logout.bind(this);
    }

    logout() {
      clearAccessToken();
      const { logout } = this.props.auth0;
      logout();
      this.props.history.push('/');
      window.location.reload(true);
    }

    render() {
      return <WrappedComponent {...this.props} onLogout={this.logout} />;
    }
  }

  WithLogout.propTypes = {
    history: PropTypes.object.isRequired,
    auth0: PropTypes.object.isRequired,
  };

  return withRouter(withAuth0(WithLogout));
}
