import React from 'react';
import RemoteDataProvider from './RemoteDataProvider';

const SpecialAccountsContext = React.createContext();

function providesSpecialAccounts(Component) {
  class RemoteSpecialAccounts extends React.Component {
    render() {
      return (
        <RemoteDataProvider
          baseURL="/specialAccounts"
          updateStrategy="fetch"
          scope="dossier"
          render={(remoteData) => {
            return (
              <SpecialAccountsContext.Provider value={remoteData}>
                <Component {...this.props} />
              </SpecialAccountsContext.Provider>
            );
          }}
        />
      );
    }
  }

  return RemoteSpecialAccounts;
}

function consumesSpecialAccounts(Component, provideValueAs = 'specialAccounts') {
  return function ComponentThatConsumesAccounts(props) {
    return (
      <SpecialAccountsContext.Consumer>
        {(remoteData) => {
          const consumedProps = {
            remoteSpecialAccounts: remoteData,
            [provideValueAs]: remoteData.value,
          };

          return <Component {...props} {...consumedProps} />;
        }}
      </SpecialAccountsContext.Consumer>
    );
  };
}

export { providesSpecialAccounts, consumesSpecialAccounts };
