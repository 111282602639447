import React from 'react';
import axios from 'axios';
import { animated, Spring } from 'react-spring';
import './AjaxProgress.scss';

const FAKE_PROGRESS_INTERVAL = 150;
const FAKE_PROGRESS_FACTOR = 0.2;

class AjaxProgress extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeRequests: 0,
      totalRequests: 1,
      fakeProgress: 0,
    };

    this.handleRequest = this.handleRequest.bind(this);
    this.handleResponse = this.handleResponse.bind(this);
    this.handleRequestError = this.handleRequestError.bind(this);
    this.handleResponseError = this.handleResponseError.bind(this);
    this.updateFakeProgress = this.updateFakeProgress.bind(this);
  }

  componentDidMount() {
    this.requestInterceptor = axios.interceptors.request.use(this.handleRequest, this.handleRequestError);
    this.responseIntercepetor = axios.interceptors.response.use(this.handleResponse, this.handleResponseError);
    this.fakeProgressTimer = setInterval(this.updateFakeProgress, FAKE_PROGRESS_INTERVAL);
  }

  componentWillUnmount() {
    axios.interceptors.request.eject(this.requestInterceptor);
    axios.interceptors.response.eject(this.responseIntercepetor);
    clearInterval(this.fakeProgressTimer);
  }

  updateFakeProgress() {
    if (this.state.activeRequests === 0) {
      return;
    }

    this.setState((prevState) => {
      const fakeProgress = (prevState.fakeProgress + FAKE_PROGRESS_FACTOR) / (1 + FAKE_PROGRESS_FACTOR);

      return {
        fakeProgress,
      };
    });
  }

  increment() {
    this.setState((prevState) => {
      const resetBatch = prevState.activeRequests === 0;
      const activeRequests = prevState.activeRequests + 1;

      return {
        activeRequests,
        totalRequests: resetBatch ? 1 : Math.max(prevState.totalRequests, activeRequests),
        fakeProgress: resetBatch ? 0 : prevState.fakeProgress,
      };
    });
  }

  decrement() {
    this.setState((prevState) => {
      const activeRequests = prevState.activeRequests - 1;

      return {
        activeRequests,
      };
    });
  }

  handleRequest(config) {
    this.increment();
    return config;
  }

  handleResponse(response) {
    this.decrement();
    return response;
  }

  handleRequestError(error) {
    this.increment();
    return Promise.reject(error);
  }

  handleResponseError(error) {
    this.decrement();
    return Promise.reject(error);
  }

  render() {
    const opacity = this.state.activeRequests > 0 ? 1 : 0;
    const actualProgress = this.state.totalRequests > 0 ? 1 - this.state.activeRequests / this.state.totalRequests : 1;
    const progress = parseInt(actualProgress * 50 + this.state.fakeProgress * 50, 10);
    const width = progress + '%';

    return (
      <Spring to={{ width, opacity }}>{(styles) => <animated.div className="AjaxProgress" style={styles} />}</Spring>
    );
  }
}

export default AjaxProgress;
