import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import parseAmount from 'lib/parseAmount.js';
import formatAmount from 'lib/formatAmount.js';
import './CurrencyInput.scss';

export default class CurrencyInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: formatAmount(this.props.value / 100),
      invalidValue: false,
    };

    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        value: formatAmount(this.props.value / 100),
      });
    }
  }

  focusTextInput() {
    this.textInput.focus();
  }

  onFocus(e) {
    const target = e.target;

    this.setState({ invalidValue: false }, () => {
      target.select();
    });
  }

  onBlur() {
    const parsedValue = parseAmount(this.state.value);

    if (isNaN(parsedValue)) {
      this.setState({ invalidValue: true });
    } else {
      this.setState({ invalidValue: false });
      this.props.onInputChange(Math.round(parsedValue * 100));
    }
  }

  onChange(e) {
    this.setState({
      value: e.target.value.replace(/[^0-9,.'’-]/g, ''),
    });
  }

  renderInput() {
    return (
      <input
        type="text"
        className="form-control"
        placeholder="Betrag"
        value={this.state.value}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        onChange={this.onChange}
        onKeyPress={this.handleKeyPress}
        disabled={this.props.disabled}
        ref={(input) => (this.textInput = input)}
      />
    );
  }

  handleKeyPress(target) {
    if (target.charCode === 13) {
      this.onBlur();
    }
  }

  render() {
    const invalidValue = this.state.invalidValue;
    return (
      <div className={classnames('CurrencyInput input-group', { invalidValue })}>
        <div className="input-group-prepend">
          <span className="input-group-text">{this.props.unit}</span>
        </div>
        {this.renderInput()}
      </div>
    );
  }
}

CurrencyInput.propTypes = {
  onInputChange: PropTypes.func.isRequired,
  unit: PropTypes.string.isRequired,
  onInputFocus: PropTypes.func,
  value: PropTypes.number,
  disabled: PropTypes.bool,
};
