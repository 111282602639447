import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormText } from 'reactstrap';

export default class Currency extends Component {
  formatValue() {
    return (this.props.value / 100).toFixed(2).toLocaleString('CH').replace(',', "'");
  }

  render() {
    return (
      <FormText tag={'span'} color={'muted'} className={this.props.className}>
        {this.props.currency}&nbsp;{this.formatValue()}
      </FormText>
    );
  }
}

Currency.propTypes = {
  currency: PropTypes.string.isRequired,
  className: PropTypes.string,
  value: PropTypes.number.isRequired,
};
