import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import translate from 'containers/translate';
import Constants from 'config/Constants';

class PeriodStateEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  renderPeriodState(state) {
    const { t } = this.props;

    if (state === Constants.PERIOD_STATE.OPEN) {
      return t('period_open_state');
    } else if (state === Constants.PERIOD_STATE.ACTIVE) {
      return t('period_active_state');
    } else if (state === Constants.PERIOD_STATE.CLARIFICATION) {
      return t('period_clarification_state');
    } else if (state === Constants.PERIOD_STATE.ARCHIVE) {
      return t('period_archive_state');
    }
  }

  render() {
    const { t } = this.props;

    const currentStateName = this.renderPeriodState(this.props.currentState);
    const targetStateName = this.renderPeriodState(this.props.targetState);

    return (
      <span className="PeriodStateEdit">
        <Button className={this.props.buttonClassName} color={this.props.buttonColor} onClick={this.toggle}>
          {this.props.buttonLabel}
        </Button>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>{t('confirm_state_change')}</ModalHeader>
          <ModalBody>{t('confirm_state_warning', currentStateName, targetStateName)}</ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => {
                this.toggle();
                this.props.onStateChange(this.props.targetState);
              }}
            >
              {t('confirm_state_change')}
            </Button>{' '}
          </ModalFooter>
        </Modal>
      </span>
    );
  }
}
PeriodStateEdit.propTypes = {
  buttonClassName: PropTypes.string,
  buttonColor: PropTypes.string,
  buttonLabel: PropTypes.string,
  currentState: PropTypes.string,
  targetState: PropTypes.string,
  onStateChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default translate(PeriodStateEdit);
