import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Badge, Button, Col, FormGroup, Row, Input } from 'reactstrap';
import deepEqual from 'deep-equal';
import moment from 'moment';
import MultiToggle from 'react-multi-toggle';
import keyRegistry from 'lib/GlobalKeyRegistry';
import Select from 'react-select';
import { withRouter } from 'containers/withRouter';
import { FaBars, FaTimes } from 'react-icons/fa';
import translate from 'containers/translate';
import CurrencyInput from 'components/currencyInput/CurrencyInput';
import CompanyNamePicker from 'components/CompanyNamePicker';
import AccountEdit from 'components/accountEdit/AccountEdit';
import BankingTransactionSelect from 'components/bankingTransactionSelect/BankingTransactionSelect';
import DuplicateDocuments from 'components/duplicateDocuments/DuplicateDocuments';
import DateInput from 'components/dateInput/DateInput';
import Currency from 'components/currency/Currency';
import BankingTransactionDescription from 'components/bankingTransactionDescription/BankingTransactionDescription';
import ShortcutHelpModal from 'components/shortcutHelpModal/ShortcutHelpModal';
import Tooltip from 'components/tooltip/Tooltip';
import AccountingTextAutoSuggest from 'components/accountingTextAutoSuggest/AccountingTextAutoSuggest';
import withTotalAmountCHF from 'containers/withTotalAmountCHF';
import { consumesDocument } from 'contexts/DocumentContext';
import {
  consumesBankingTransactionProposals,
  providesBankingTransactionProposals,
} from 'contexts/BankingTransactionProposalsContext';
import { consumesBankingTransactions } from 'contexts/BankingTransactionsContext';
import { consumesAccounts, providesAccounts } from 'contexts/AccountsContext';
import { providesDuplicateDocuments, consumesDuplicateDocuments } from 'contexts/DuplicateDocumentsContext';
import { consumesUsedCompanies } from 'contexts/UsedCompaniesContext';
import { consumesPeriods } from 'contexts/PeriodsContext';
import RemoteDataPropType from 'lib/prop-types/RemoteDataPropType';
import { FaClone } from 'react-icons/fa';
import { FaDownload } from 'react-icons/fa';
import withFileDownload from 'containers/withFileDownload';
import Constants from 'config/Constants';
import './BasicDocumentInformation.scss';

class BasicDocumentInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showBankingTransactionProposalsSelect: false,
      showDuplicateDocuments: false,
      showShortcutModal: false,
      dirtyDocument: props.document,
      type: 'creditor',
      clarificationText: this.props.document.clarificationText,
    };

    this.handleTPressed = this.handleTPressed.bind(this);
    this.handleFPressed = this.handleFPressed.bind(this);
    this.updateDocument = this.updateDocument.bind(this);
    this.onAddAccountingTransactionClick = this.onAddAccountingTransactionClick.bind(this);
    this.onTypeSelect = this.onTypeSelect.bind(this);
    this.onBankingTransactionProposalsSelectDismiss = this.onBankingTransactionProposalsSelectDismiss.bind(this);
    this.handleDuplicateDocumentsDismiss = this.handleDuplicateDocumentsDismiss.bind(this);
    this.renderMatchBatch = this.renderMatchBatch.bind(this);
    this.toggleShortcutModal = this.toggleShortcutModal.bind(this);
  }

  componentDidMount() {
    keyRegistry.pushContext('document');
    keyRegistry.register('t', this.handleTPressed);
    keyRegistry.register('f', this.handleFPressed);
    keyRegistry.register('h', this.toggleShortcutModal);
  }

  onTypeSelect(value) {
    this.setState({ type: value });
  }

  componentWillUnmount() {
    keyRegistry.deregister('t');
    keyRegistry.deregister('f');
    keyRegistry.deregister('h');
    keyRegistry.popContext();
  }

  handleTPressed() {
    this.currencyInput.focusTextInput();
  }

  handleFPressed() {
    this.companyNamePicker.focusTextInput();
  }

  handleChange(key, value, cb) {
    this.setState(
      (prevState) => {
        return {
          dirtyDocument: Object.assign({}, prevState.dirtyDocument, { [key]: value }),
        };
      },
      () => cb && cb()
    );
  }

  componentDidUpdate(prevProps) {
    if (!deepEqual(prevProps.document, this.props.document)) {
      this.setState({
        dirtyDocument: this.props.document,
        clarificationText: this.props.document.clarificationText,
      });
    }
  }

  updateDocument(cb) {
    if (!deepEqual(this.props.document, this.state.dirtyDocument)) {
      this.props.remoteDocument.api.update(this.state.dirtyDocument, () => {
        this.props.remoteUsedCompanies.api.fetch();
        this.props.remoteBankingTransactionProposals.api.fetch();
        cb && cb();
      });
    }
  }

  onAddAccountingTransactionClick() {
    this.handleChange('bankingTransaction', null, () => {
      this.updateDocument(this.props.remoteBankingTransactions.api.fetch);
    });
  }

  onBankingTransactionProposalsSelectDismiss(bankingTransaction) {
    this.setState({
      showBankingTransactionProposalsSelect: false,
    });
    if (bankingTransaction) {
      this.handleBankingTransactionAssigned(bankingTransaction);
    }
  }

  handleDuplicateDocumentsDismiss(document) {
    this.setState({ showDuplicateDocuments: false });

    if (document) {
      this.props.history.pushDossier(`/edit/${document.id}`);
    }
  }

  toggleShortcutModal() {
    this.setState(({ showShortcutModal }) => ({
      showShortcutModal: !showShortcutModal,
    }));
  }

  handleBankingTransactionAssigned(bankingTransaction) {
    const newDocument = Object.assign({}, this.props.document, { bankingTransaction });
    this.props.remoteDocument.api.update(newDocument, () => {
      this.props.remoteBankingTransactions.api.fetch();
    });
  }

  isDateTooOld(document) {
    const period = this.props.periods.find((period) => {
      return period.id === document.periodId;
    });

    if (!period) {
      return false;
    }

    const documentDate = moment(document.date, 'YYYY-MM-DD');
    const twoYearsBeforePeriodStartDate = moment(period.startDate, 'YYYY-MM-DD').subtract(2, 'years');

    return documentDate.isBefore(twoYearsBeforePeriodStartDate);
  }

  renderBankingTransactionDetails() {
    // Inputs have to be locked if there is a transaction.
    // Because editing of the transaction happens in the banking document edit.
    const hasTransaction = !!this.props.document.bankingTransaction;
    const locked = this.props.locked || hasTransaction;

    return (
      <Row>
        <Col>
          <FormGroup>
            <DateInput
              date={this.state.dirtyDocument.date}
              onInputChange={(value) => this.handleChange('date', value, this.updateDocument)}
              locked={locked}
              invalid={this.isDateTooOld(this.state.dirtyDocument)}
            />
            {this.renderTypeToggle()}
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <AccountEdit
              onAccountChange={(contraAccount) =>
                this.handleChange(
                  'contraAccount',
                  { ...this.state.dirtyDocument.contraAccount, account: contraAccount },
                  this.updateDocument
                )
              }
              account={this.state.dirtyDocument.contraAccount.account}
              locked={locked}
              creatable={false}
              accountProposals={this.props.accounts.filter((a) => !a.hasDocument && !a.deleted)}
            />
            <CurrencyInput
              value={this.state.dirtyDocument.totalAmountContraAccount}
              onInputChange={(value) => this.handleChange('totalAmountContraAccount', value, this.updateDocument)}
              unit={this.state.dirtyDocument.contraAccount.currency}
              disabled={locked}
            />
          </FormGroup>
        </Col>
        {this.renderDescriptionAndType()}
      </Row>
    );
  }

  renderDescriptionAndType() {
    if (!this.state.dirtyDocument.bankingTransaction) {
      return;
    }

    return (
      <Col>
        <BankingTransactionDescription
          cropDescription={true}
          description={this.state.dirtyDocument.bankingTransaction.description}
        />
      </Col>
    );
  }

  renderDissolveBankingTransactionButton() {
    const { t } = this.props;

    if (this.props.locked || !this.props.document.bankingTransaction) {
      return null;
    }
    return (
      <Button color={'link'} onClick={this.onAddAccountingTransactionClick}>
        {t('dissolve')}
      </Button>
    );
  }

  renderMatchBatch() {
    const { t } = this.props;

    if (!this.props.document.bankingTransaction) {
      return;
    }

    return <Badge color={'success'}>{t('match')}</Badge>;
  }

  renderBankingTransactionProposalButton() {
    const { t } = this.props;

    if (this.props.locked || this.props.bankingTransactionProposals.length === 0) {
      return null;
    }
    return (
      <Button color={'link'} onClick={() => this.setState({ showBankingTransactionProposalsSelect: true })}>
        {t('proposals')}
      </Button>
    );
  }

  renderBankingTransactionSelect() {
    if (!this.state.showBankingTransactionProposalsSelect) {
      return;
    }

    return (
      <BankingTransactionSelect
        bankingTransactions={this.props.bankingTransactionProposals}
        onDismiss={this.onBankingTransactionProposalsSelectDismiss}
      />
    );
  }

  renderAuxiliaryButtons() {
    return (
      <div className="auxButtons">
        {this.renderDuplicateDocumentsButton()}
        {this.renderDownloadButton()}
      </div>
    );
  }

  renderDownloadButton() {
    if (!this.props.document.originalFileId) {
      return;
    }

    const { t } = this.props;

    return (
      <Tooltip title={t('download_original')}>
        <FaDownload className="download-icon" onClick={() => this.props.onDownload(this.props.document.id)} />
      </Tooltip>
    );
  }

  renderDuplicateDocumentsButton() {
    if (this.props.duplicateDocuments.length === 0) {
      return null;
    }

    const { t } = this.props;

    return (
      <Tooltip title={t('duplicate_documents')}>
        <FaClone className="duplicate-icon" onClick={() => this.setState({ showDuplicateDocuments: true })} />
      </Tooltip>
    );
  }

  renderDuplicateDocuments() {
    if (!this.state.showDuplicateDocuments) {
      return;
    }

    return <DuplicateDocuments onDismiss={this.handleDuplicateDocumentsDismiss} />;
  }

  renderShortcutHelp() {
    if (!this.state.showShortcutModal) {
      return;
    }

    return <ShortcutHelpModal onDismiss={() => this.setState({ showShortcutModal: false })} />;
  }

  renderAccountingText() {
    const { t } = this.props;

    if (this.props.document.individualAccountingTexts) {
      return (
        <div className="AccountingTextWrapper">
          <Button
            disabled={this.props.locked}
            onClick={() => this.handleChange('individualAccountingTexts', false, this.updateDocument)}
          >
            {t('disable_individual_accounting_texts')}
          </Button>
        </div>
      );
    }

    let individualAccountingTexts;

    if (!this.props.locked) {
      individualAccountingTexts = (
        <Tooltip title={t('tooltip_enable_individual_accounting_texts')}>
          <span
            className="individualAccountingTexts"
            onClick={() => this.handleChange('individualAccountingTexts', true, this.updateDocument)}
          >
            <FaBars />
          </span>
        </Tooltip>
      );
    }

    return (
      <div className="AccountingTextWrapper">
        <AccountingTextAutoSuggest
          presetAccountingText={this.state.dirtyDocument.accountingText || ''}
          onAccountingTextChange={(accountingText) =>
            this.handleChange('accountingText', accountingText, this.updateDocument)
          }
          disabled={this.props.locked}
        />
        {individualAccountingTexts}
      </div>
    );
  }

  renderClarificationText() {
    const { t } = this.props;

    return (
      <div className="ClarificationText">
        <Input
          id="clarificationText"
          placeholder={t('clarificationText')}
          value={this.state.clarificationText ?? ''}
          disabled={this.props.locked}
          onChange={(e) => {
            this.setState({ clarificationText: e.target.value });
          }}
          onBlur={() => this.handleChange('clarificationText', this.state.clarificationText, this.updateDocument)}
        />
      </div>
    );
  }

  renderCompanyNameLabel() {
    const { t } = this.props;
    const { type } = this.state.dirtyDocument;

    if (type === 'creditor') {
      return t('invoicing_party');
    }
    if (type === 'debitor') {
      return t('invoice_receiver');
    }
    return '';
  }

  renderTypeToggle() {
    //The MultiToggles have two different keys (single and multi) to prevent
    //weird CSS transitions (from one to two elements) when they would otherwise be reused.

    if (this.props.locked || this.props.document.bankingTransaction) {
      return (
        <MultiToggle
          key="single"
          options={[
            {
              displayName: this.getTypeLabel(this.props.document.type),
              value: this.props.document.type,
            },
          ]}
          selectedOption={this.props.document.type}
          onSelectOption={() => {}}
        />
      );
    }

    return (
      <MultiToggle
        key="multi"
        options={[
          {
            displayName: this.getTypeLabel('creditor'),
            value: 'creditor',
          },
          {
            displayName: this.getTypeLabel('debitor'),
            value: 'debitor',
          },
        ]}
        selectedOption={this.props.document.type}
        onSelectOption={(value) => this.handleChange('type', value, this.updateDocument)}
      />
    );
  }

  getTypeLabel(type) {
    const { t } = this.props;

    if (type === 'creditor') {
      return t('debit_transaction');
    }
    if (type === 'debitor') {
      return t('credit_transaction');
    }
    return '';
  }

  renderTotalAmountCHF() {
    if (this.state.dirtyDocument.totalAmountCHFOverride === null) {
      return (
        <div
          onClick={() =>
            this.state.dirtyDocument.currency !== 'CHF' &&
            this.handleChange('totalAmountCHFOverride', this.props.totalAmountCHF, this.updateDocument)
          }
        >
          <Currency className={'totalAmountCHF'} currency={'CHF'} value={this.props.totalAmountCHF} />
        </div>
      );
    } else {
      return (
        <div className="totalAmountCHFOverride d-flex align-items-center">
          <CurrencyInput
            value={this.state.dirtyDocument.totalAmountCHFOverride}
            onInputChange={(value) => this.handleChange('totalAmountCHFOverride', value, this.updateDocument)}
            unit={'CHF'}
            disabled={this.props.locked}
          />
          <Button
            className="ml-1"
            disabled={this.props.locked}
            onClick={() => this.handleChange('totalAmountCHFOverride', null, this.updateDocument)}
          >
            <FaTimes />
          </Button>
        </div>
      );
    }
  }

  render() {
    const { t } = this.props;

    const currencyOptions = Constants.CURRENCY_OPTIONS;
    const currencyValue = currencyOptions.find((option) => option.value === this.state.dirtyDocument.currency);

    return (
      <div className={'BasicDocumentInformation'}>
        {this.renderBankingTransactionSelect()}
        {this.renderDuplicateDocuments()}
        {this.renderShortcutHelp()}
        <Row>
          <Col xs={3}>
            <fieldset>
              <legend>{this.renderCompanyNameLabel()}</legend>
              <CompanyNamePicker
                presetCompanyName={this.props.document.company.name}
                allCompanyNames={this.props.companyNames}
                usedCompanyNames={this.props.usedCompanyNames}
                onCompanyNameChange={(name) => this.handleChange('company', { name: name }, this.updateDocument)}
                clearable={false}
                ref={(picker) => (this.companyNamePicker = picker)}
                disabled={this.props.locked}
              />
              {this.renderAccountingText()}
              {this.renderClarificationText()}
            </fieldset>
          </Col>
          <Col xs={3}>
            <fieldset>
              <legend>{t('total_amount')}</legend>
              <div className={'totalAmountInput'}>
                <CurrencyInput
                  value={this.state.dirtyDocument.totalAmount}
                  onInputChange={(value) => this.handleChange('totalAmount', value, this.updateDocument)}
                  unit={this.state.dirtyDocument.currency}
                  ref={(input) => (this.currencyInput = input)}
                  disabled={this.props.locked}
                />
                <Select
                  className={'currency Select'}
                  options={currencyOptions}
                  isClearable={false}
                  backspaceRemovesValue={false}
                  value={currencyValue}
                  onChange={({ value }) => this.handleChange('currency', value, this.updateDocument)}
                  isDisabled={this.props.locked}
                />
                {this.renderTotalAmountCHF()}
              </div>
            </fieldset>
          </Col>
          <Col>
            <fieldset>
              <legend>
                {t('payment_account')}
                {this.renderMatchBatch()}
                {this.renderDissolveBankingTransactionButton()}
                {this.renderBankingTransactionProposalButton()}
                {this.renderAuxiliaryButtons()}
              </legend>
              {this.renderBankingTransactionDetails()}
            </fieldset>
          </Col>
        </Row>
      </div>
    );
  }
}

BasicDocumentInformation.propTypes = {
  companyNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  document: PropTypes.object.isRequired,
  remoteDocument: RemoteDataPropType,
  usedCompanyNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  remoteUsedCompanies: RemoteDataPropType,
  totalAmountCHF: PropTypes.number.isRequired,
  bankingTransactionProposals: PropTypes.arrayOf(PropTypes.object).isRequired,
  onDownload: PropTypes.func.isRequired,
  remoteBankingTransactions: RemoteDataPropType,
  remoteBankingTransactionProposals: RemoteDataPropType,
  accounts: PropTypes.arrayOf(PropTypes.object).isRequired,
  t: PropTypes.func.isRequired,
  locked: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  duplicateDocuments: PropTypes.arrayOf(PropTypes.object).isRequired,
  periods: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default translate(
  consumesPeriods(
    consumesUsedCompanies(
      providesDuplicateDocuments(
        consumesDuplicateDocuments(
          providesBankingTransactionProposals(
            consumesBankingTransactionProposals(
              providesAccounts(
                consumesAccounts(
                  withTotalAmountCHF(
                    withFileDownload(
                      consumesBankingTransactions(consumesDocument(withRouter(BasicDocumentInformation)))
                    )
                  )
                )
              )
            )
          )
        )
      )
    )
  )
);
