import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FaTrash } from 'react-icons/fa';
import AccountEdit from 'components/accountEdit/AccountEdit';
import deepEqual from 'deep-equal';
import formatUID from 'lib/formatUID';
import './OverviewRuleEdit.scss';

class OverviewRuleEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dirtyRule: this.props.rule,
    };

    this.onBlur = this.onBlur.bind(this);
    this.handleAccountChange = this.handleAccountChange.bind(this);
    this.onRuleDelete = this.onRuleDelete.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (!deepEqual(prevProps.rule, this.props.rule)) {
      this.setState({
        dirtyRule: this.props.rule,
      });
    }
  }

  onBlur() {
    this.props.onRuleChange(this.state.dirtyRule);
  }

  handleAccountChange(account) {
    this.setState((prevState) => {
      return {
        dirtyRule: Object.assign({}, prevState.dirtyRule, { account }),
      };
    }, this.onBlur);
  }

  onRuleDelete() {
    this.props.onRuleDelete(this.props.rule);
  }

  renderTrashIcon() {
    if (!this.props.editable) {
      return;
    }

    return <FaTrash onClick={this.onRuleDelete} />;
  }

  renderAccount() {
    if (!this.props.editable) {
      return <span>{this.state.dirtyRule.account}</span>;
    }
    return <AccountEdit onAccountChange={this.handleAccountChange} account={this.state.dirtyRule.account} />;
  }

  render() {
    let uidString = '';

    if (this.props.rule.company.uids.length > 0) {
      uidString = `(${formatUID(this.props.rule.company.uids[0])})`;
    }

    return (
      <tr className={'OverviewRuleEdit'}>
        <td className={'companyInput'}>
          <span className="uid">
            {this.state.dirtyRule.company.name} {uidString}
          </span>
        </td>
        <td>{this.renderAccount()}</td>
        <td>{this.renderTrashIcon()}</td>
      </tr>
    );
  }
}

OverviewRuleEdit.propTypes = {
  rule: PropTypes.object.isRequired,
  editable: PropTypes.bool.isRequired,
  onRuleChange: PropTypes.func.isRequired,
  onRuleDelete: PropTypes.func.isRequired,
};

export default OverviewRuleEdit;
