import React, { Component } from 'react';
import { Button, Input, Label, Modal, ModalBody, Table } from 'reactstrap';
import PropTypes from 'prop-types';
import { FaEdit } from 'react-icons/fa';
import { withRouter } from 'containers/withRouter';
import translate from 'containers/translate';
import { consumesBanks, providesBanks } from 'contexts/BanksContext';
import RemoteDataPropType from 'lib/prop-types/RemoteDataPropType';
import './BankOverview.scss';

class BankOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: '',

      showEditModal: false,
      bankIdToEdit: undefined,
      editingBankName: '',
      editingAlternativeBankName: '',
    };

    this.renderBank = this.renderBank.bind(this);
    this.addBank = this.addBank.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidMount() {
    this.input && this.input.focus();
  }

  render() {
    return (
      <div className={'BankOverview'}>
        {this.renderEditModal()}
        {this.renderBankList()}
      </div>
    );
  }

  renderBankList() {
    const { banks, t } = this.props;

    return (
      <div className="BankOverview">
        <h1>{t('banks')}</h1>

        <br />
        {this.renderAddButton()}
        {this.renderPredictionButton()}

        <Table>
          <thead>{this.renderHeader()}</thead>
          <tbody>{banks.map(this.renderBank)}</tbody>
        </Table>
      </div>
    );
  }

  renderAddButton() {
    const { t } = this.props;

    return (
      <Button className={'addBank'} onClick={this.addBank} color={'link'}>
        {t('add_bank')}
      </Button>
    );
  }

  renderPredictionButton() {
    const { t } = this.props;

    return (
      <Button className={'predictBanks'} onClick={() => this.props.history.push('/bank-prediction')} color={'link'}>
        {t('predict_banks')}
      </Button>
    );
  }

  renderHeader() {
    const { t } = this.props;

    return (
      <tr>
        <th colSpan={3}>{t('name')}</th>
      </tr>
    );
  }

  renderBank(bank) {
    return (
      <tr key={bank.id}>
        <td>{bank.name}</td>
        <td>{bank.alternativeName}</td>
        <td>{this.renderEditIcon(bank)}</td>
      </tr>
    );
  }

  renderEditIcon(bank) {
    return <FaEdit size={20} className="editIcon" onClick={() => this.showEditModal(bank)} />;
  }

  showEditModal(bank) {
    this.setState({ editingBankName: bank.name, bankIdToEdit: bank.id, showEditModal: true }, () => {
      this.input && this.input.select();
    });
  }

  renderEditModal() {
    const { t } = this.props;

    if (!this.state.showEditModal) {
      return <div />;
    }

    return (
      <Modal isOpen size="m" toggle={this.toggleModal}>
        <ModalBody>
          <Label for="bankName">{t('bank_name')}</Label>
          <Input
            value={this.state.editingBankName}
            type="text"
            name="bankName"
            onKeyDown={this.handleKeyPress}
            autoFocus
            innerRef={(input) => (this.input = input)}
            onChange={(e) => this.setState({ editingBankName: e.target.value })}
          />
          <Label for="alternativeBankName">{t('alternative_bank_name')}</Label>
          <Input
            value={this.state.editingAlternativeBankName}
            type="text"
            name="alternativeBankName"
            onKeyDown={this.handleKeyPress}
            autoFocus
            onChange={(e) => this.setState({ editingAlternativeBankName: e.target.value })}
          />
        </ModalBody>
      </Modal>
    );
  }

  toggleModal() {
    this.setState((prevState) => {
      return { showEditModal: !prevState.showEditModal };
    });
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.props.remoteBanks.api.update({
        id: this.state.bankIdToEdit,
        name: this.state.editingBankName,
        alternativeName: this.state.editingAlternativeBankName,
      });
      this.toggleModal();
    }
  }

  addBank() {
    this.props.remoteBanks.api.create({ name: '00 New bank' });
  }
}

BankOverview.propTypes = {
  banks: PropTypes.arrayOf(PropTypes.object).isRequired,
  remoteBanks: RemoteDataPropType,
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withRouter(providesBanks(consumesBanks(translate(BankOverview))));
