import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'containers/withRouter';
import RemoteDataProvider from './RemoteDataProvider';

const DocumentContext = React.createContext();

function providesDocument(Component) {
  class RemoteDocument extends React.Component {
    getObjectId() {
      if (this.props.documentId) {
        return this.props.documentId;
      }

      const id = parseInt(this.props.match.params.documentId, 10);

      if (isNaN(id)) {
        return null;
      }

      return id;
    }

    render() {
      const objectId = this.getObjectId();

      return (
        <RemoteDataProvider
          baseURL="/accounting-documents"
          objectId={objectId}
          render={(remoteData) => {
            return (
              <DocumentContext.Provider value={remoteData}>
                <Component {...this.props} />
              </DocumentContext.Provider>
            );
          }}
        />
      );
    }
  }

  RemoteDocument.propTypes = {
    documentId: PropTypes.number,
    match: PropTypes.object.isRequired,
  };

  return withRouter(RemoteDocument);
}

function consumesDocument(Component, provideValueAs = 'document') {
  return function ComponentThatConsumesDocument(props) {
    return (
      <DocumentContext.Consumer>
        {(remoteData) => {
          const consumedProps = {
            remoteDocument: remoteData,
            [provideValueAs]: remoteData.value,
          };

          return <Component {...props} {...consumedProps} />;
        }}
      </DocumentContext.Consumer>
    );
  };
}

export { providesDocument, consumesDocument };
