import React from 'react';
import { Input, Nav, NavItem } from 'reactstrap';
import PropTypes from 'prop-types';
import { withRouter } from 'containers/withRouter';
import translate from 'containers/translate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

class NavigationSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: '',
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(event) {
    this.setState({ search: event.target.value });
  }

  onSubmit(event) {
    const search = this.state.search.replace('#', '');
    this.props.history.push(`/search/${search}`);
    event.preventDefault();
  }

  render() {
    const { t } = this.props;

    return (
      <>
        <Nav className="ml-auto" navbar>
          <NavItem>
            <form onSubmit={this.onSubmit}>
              <div className="search-box another-search">
                <div className="search-icon">
                  <FontAwesomeIcon icon={faSearch} />
                </div>
                <Input
                  value={this.state.search}
                  type="search"
                  name="search"
                  id="exampleSearch"
                  placeholder={t('search')}
                  onChange={this.onChange}
                />
              </div>
            </form>
          </NavItem>
        </Nav>
      </>
    );
  }
}

NavigationSearch.propTypes = {
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withRouter(translate(NavigationSearch));
