import React from 'react';
import PropTypes from 'prop-types';
import RemoteDataProvider from './RemoteDataProvider';

const DocumentsContext = React.createContext();

const RemoteDocumentsProvider = function (props) {
  return (
    <RemoteDataProvider
      baseURL="/full-accounting-documents"
      isCollection
      filter={props.filter}
      render={(remoteData) => {
        return <DocumentsContext.Provider value={remoteData}>{props.children}</DocumentsContext.Provider>;
      }}
    />
  );
};

RemoteDocumentsProvider.propTypes = {
  filter: PropTypes.object,
  children: PropTypes.node,
};

function consumesDocuments(Component) {
  return function ComponentThatConsumesDocuments(props) {
    return (
      <DocumentsContext.Consumer>
        {(remoteData) => {
          const consumedProps = {
            remoteDocuments: remoteData,
            documents: remoteData.value,
          };

          return <Component {...props} {...consumedProps} />;
        }}
      </DocumentsContext.Consumer>
    );
  };
}

export { consumesDocuments, RemoteDocumentsProvider };
