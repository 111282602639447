import React, { Component } from 'react';
import Moment from 'react-moment';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Badge, FormText } from 'reactstrap';
import deepEqual from 'deep-equal';
import { NavLink as RRNavLink } from 'react-router-dom';
import { FaShareSquare, FaPlusCircle, FaEdit } from 'react-icons/fa';
import { withRouter } from 'containers/withRouter';
import translate from 'containers/translate';
import Currency from 'components/currency/Currency';
import BankingTransactionDescription from 'components/bankingTransactionDescription/BankingTransactionDescription';
import Tooltip from 'components/tooltip/Tooltip';
import { consumesDocument } from 'contexts/DocumentContext';

import './BankingTransaction.scss';

class BankingTransaction extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bankingTransaction: this.props.bankingTransaction,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!deepEqual(nextProps.bankingTransaction, prevState.bankingTransaction)) {
      return {
        bankingTransaction: nextProps.bankingTransaction,
      };
    }

    return null;
  }

  render() {
    const bankingTransaction = this.props.bankingTransaction;

    return (
      <div
        className={classnames('BankingTransaction', {
          clickable: !!this.props.onClick,
          selected: this.props.selected,
        })}
        onClick={(e) => {
          this.props.onClick && this.props.onClick(e, bankingTransaction);
        }}
        style={this.props.style}
      >
        <div className={'wrapper'}>
          <div className={'details'}>
            <FormText color={'muted'}>
              <Moment date={bankingTransaction.date} format={'DD.MM.YYYY'} />
              <p>{this.renderType()}</p>
              <p className={'currency'}>
                <Currency value={bankingTransaction.amount} currency={this.props.currency} />
              </p>
              {this.renderStateBadge(bankingTransaction)}
            </FormText>
          </div>
          <div className={'description'}>
            <BankingTransactionDescription
              description={bankingTransaction.description}
              cropDescription={this.props.cropDescription}
            />
          </div>
          {this.renderIcons()}
        </div>
      </div>
    );
  }

  renderType() {
    const { t } = this.props;

    if (this.props.bankingTransaction.type === 'credit') {
      return t('credit_transaction');
    } else if (this.props.bankingTransaction.type === 'debit') {
      return t('debit_transaction');
    }
  }

  renderStateBadge(bankingTransaction) {
    return (
      <Badge color={this.getStateBadgeColor(bankingTransaction)}>{this.getStateBadgeText(bankingTransaction)}</Badge>
    );
  }

  getStateBadgeText(bankingTransaction) {
    const { t } = this.props;

    let badgeText = t('open');
    if (bankingTransaction.state === 'unconfirmed') {
      badgeText = t('unconfirmed');
    } else if (bankingTransaction.state === 'confirmed') {
      badgeText = t('confirmed');
    }
    return badgeText;
  }

  getStateBadgeColor(bankingTransaction) {
    let badgeColor = 'success';
    if (bankingTransaction.state === 'unconfirmed') {
      badgeColor = 'warning';
    } else if (bankingTransaction.state === 'confirmed') {
      badgeColor = 'danger';
    }
    return badgeColor;
  }

  renderIcons() {
    const { t } = this.props;

    if (this.props.readOnly) {
      return <div className="icons" />;
    }

    if (!this.props.onAssign || !this.props.onGenerate || this.props.bankingTransaction.accountingDocumentId) {
      return <div className="icons" />;
    }

    return (
      <div className="icons" onClick={(e) => e.stopPropagation()}>
        {this.renderAssignIcon()}
        <div>
          <Tooltip title={t('tooltip_create_document_from_transaction')}>
            <FaPlusCircle size="16" className="actionIcon" onClick={this.props.onGenerate} />
          </Tooltip>
        </div>
        {this.renderPostIcon()}
      </div>
    );
  }

  renderAssignIcon() {
    if (!this.props.document) {
      return;
    }

    const { t, onAssign } = this.props;

    return (
      <div>
        <Tooltip
          title={t(
            this.props.document.state !== 'confirmed'
              ? 'tooltip_assign_transaction_to_document'
              : 'tooltip_assign_transaction_to_document_disabled'
          )}
        >
          <FaShareSquare
            id="AssignIcon"
            size="16"
            className={`actionIcon ${this.props.document.state !== 'confirmed' ? '' : 'actionIcon_disabled'}`}
            onClick={this.props.document.state !== 'confirmed' ? onAssign : null}
          />
        </Tooltip>
      </div>
    );
  }

  renderPostIcon() {
    if (this.props.bankingTransaction.bankingTransactionId) {
      return;
    }

    const { t } = this.props;
    const postEditURL = this.props.history.resolveDossierURL(`/edit/post-edit/${this.props.bankingTransaction.id}`);

    return (
      <div>
        <Tooltip title={t('to_post_edit')}>
          <RRNavLink to={postEditURL}>
            <FaEdit size="16" className="actionIcon" />
          </RRNavLink>
        </Tooltip>
      </div>
    );
  }
}

BankingTransaction.defaultProps = {
  readOnly: false,
  selected: false,
};

BankingTransaction.propTypes = {
  bankingTransaction: PropTypes.object.isRequired,
  currency: PropTypes.string.isRequired,
  cropDescription: PropTypes.bool.isRequired,
  document: PropTypes.object,
  readOnly: PropTypes.bool.isRequired,
  selected: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  onAssign: PropTypes.func,
  onGenerate: PropTypes.func,
  onClick: PropTypes.func,
  style: PropTypes.object,
  onUpdateGrid: PropTypes.func,
};

export default translate(consumesDocument(withRouter(BankingTransaction)));
