import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'containers/withRouter';
import Constants from 'config/Constants';
import { consumesDossier } from 'contexts/DossierContext';
import RemoteDataProvider, { createEmptyRemoteData } from './RemoteDataProvider';

const PeriodsContext = React.createContext(createEmptyRemoteData([]));

function providesPeriods(Component) {
  class RemotePeriods extends React.Component {
    getSelectedPeriodId() {
      return parseInt(this.props.match.params.periodId, 10);
    }

    render() {
      return (
        <RemoteDataProvider
          scope="dossier"
          baseURL="/periods"
          isCollection
          updateStrategy="fetch"
          name="periods"
          render={(remoteData) => {
            if (!this.props.dossier) {
              return null;
            }

            const selectedPeriodId = this.getSelectedPeriodId();
            let selectedPeriod = remoteData.value.find((period) => {
              return period.id === selectedPeriodId;
            });

            if (!selectedPeriod) {
              selectedPeriod = this.props.dossier.currentPeriod;
            }

            remoteData.selectedPeriod = selectedPeriod;
            remoteData.isReadOnlyPeriod = selectedPeriod.state === Constants.PERIOD_STATE.ARCHIVE;

            return (
              <PeriodsContext.Provider value={remoteData}>
                <Component {...this.props} />
              </PeriodsContext.Provider>
            );
          }}
        />
      );
    }
  }

  RemotePeriods.propTypes = {
    dossier: PropTypes.object,
    match: PropTypes.object.isRequired,
  };

  return consumesDossier(withRouter(RemotePeriods));
}

function consumesPeriods(Component, provideValueAs = 'periods') {
  return function ComponentThatConsumesPeriods(props) {
    return (
      <PeriodsContext.Consumer>
        {(remoteData) => {
          const consumedProps = {
            remotePeriods: remoteData,
            selectedPeriod: remoteData.selectedPeriod,
            [provideValueAs]: remoteData.value,
          };

          return <Component {...props} {...consumedProps} />;
        }}
      </PeriodsContext.Consumer>
    );
  };
}

export { providesPeriods, consumesPeriods };
