import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table, Button } from 'reactstrap';
import formatAmount from 'lib/formatAmount.js';
import formatNumber from 'lib/formatNumber.js';
import formatMilliseconds from 'lib/formatMilliseconds';
import translate from 'containers/translate';
import withStatistic from 'containers/withStatistic';
import Tooltip from '../tooltip/Tooltip';
import './Statistic.scss';

class Statistic extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  renderStatisticGenerateButton() {
    const { t } = this.props;

    return (
      <div className={'generateButtons'}>
        <Button color="danger" onClick={this.props.generateStatistic}>
          {t('generate')}
        </Button>
        <Button color="warning" onClick={this.props.generateRuleStatistic}>
          {t('generate_rule')}
        </Button>
        <Button color="warning" onClick={this.props.generatePeriodStatistic}>
          {t('generate_period')}
        </Button>
      </div>
    );
  }

  renderPredictionPercentage(value) {
    return formatAmount(value * 100) + '%';
  }

  render() {
    const { t, statistic } = this.props;
    const { prediction } = statistic;

    const totalDocuments = parseInt(statistic.accountingDocuments.confirmed, 10);

    const confirmed = totalDocuments - parseInt(statistic.accountingDocuments.archived, 10);

    return (
      <div className={'Statistic'}>
        <h1>{t('statistic')}</h1>
        <Table size="sm">
          <tbody>
            <tr>
              <td colSpan={2}>
                <h2>{t('documents')}</h2>
              </td>
            </tr>
            <tr>
              <td>{t('open')}</td>
              <td>{formatNumber(statistic.accountingDocuments.open)}</td>
            </tr>
            <tr>
              <td>{t('confirmed')}</td>
              <td>{formatNumber(confirmed)}</td>
            </tr>
            <tr>
              <td>{t('deleted')}</td>
              <td>{formatNumber(statistic.accountingDocuments.rejected)}</td>
            </tr>
            <tr>
              <td>{t('archived')}</td>
              <td>{formatNumber(statistic.accountingDocuments.archived)}</td>
            </tr>
            <tr className={'total'}>
              <td>
                {t('total')} ({t('confirmed_or_archived')})
              </td>
              <td>{formatNumber(totalDocuments)}</td>
            </tr>
            <tr>
              <td colSpan={2}>
                <h2>{t('banking_documents')}</h2>
              </td>
            </tr>
            <tr>
              <td>{t('all')}</td>
              <td>{formatNumber(statistic.bankingDocuments.count)}</td>
            </tr>
            <tr>
              <td colSpan={2}>
                <h2>{t('dossiers')}</h2>
              </td>
            </tr>
            <tr>
              <td>{t('deleted')}</td>
              <td>{formatNumber(statistic.dossiers.deleted)}</td>
            </tr>
            <tr>
              <td>{t('active')}</td>
              <td>{formatNumber(statistic.dossiers.count)}</td>
            </tr>
            <tr>
              <td colSpan={2}>
                <h2>{t('prediction')}</h2>
                <span>{t('based_on_documents', formatNumber(statistic.prediction.documentsCount))}</span>
                <br />
                <br />
              </td>
            </tr>
            <tr>
              <td>{t('total_amount')}</td>
              <td>{this.renderPredictionPercentage(prediction.totalAmountPrediction)}</td>
            </tr>
            <tr>
              <td>{t('vat')}</td>
              <td>{this.renderPredictionPercentage(prediction.vatPrediction)}</td>
            </tr>
            <tr>
              <td>{this.renderCompany(prediction)}</td>
              <td>{this.renderPredictionPercentage(prediction.companyPrediction)}</td>
            </tr>
            <tr>
              <td>{t('rule')}</td>
              <td>{this.renderPredictionPercentage(prediction.rulePrediction)}</td>
            </tr>
            <tr>
              <td>{t('vat_special_case')}</td>
              <td>{this.renderPredictionPercentage(prediction.vatSpecialCase)}</td>
            </tr>
            <tr>
              <td>{t('date')}</td>
              <td>{this.renderPredictionPercentage(prediction.datePrediction)}</td>
            </tr>
            <tr>
              <td>{t('overall')}</td>
              <td>{this.renderPredictionPercentage(prediction.totalPrediction)}</td>
            </tr>
            <tr>
              <td>
                <Tooltip title={t('explanation_perfect_predicted')}>
                  <span>{t('perfect_predicted')}</span>
                </Tooltip>
              </td>
              <td>{this.renderPredictionPercentage(prediction.perfectPrediction)}</td>
            </tr>
            {this.renderDocumentStatistic(statistic.documentStatistic)}
            {this.renderBankingDocumentStatistic(statistic.documentStatistic)}
          </tbody>
        </Table>
        {this.renderStatisticGenerateButton()}
      </div>
    );
  }

  renderCompany(prediction) {
    const { t } = this.props;

    const uidPercentage = this.renderPredictionPercentage(prediction.uidPrediction);
    const namePercentage = this.renderPredictionPercentage(prediction.namePrediction);
    const company = t('company');
    const withUID = t('document_with_uid');
    const withoutUID = t('document_without_uid');

    return `${company} (${withUID}: ${uidPercentage}, ${withoutUID}: ${namePercentage})`;
  }

  renderDocumentStatistic(statistic) {
    const { t } = this.props;

    return (
      <React.Fragment>
        <tr>
          <td colSpan={2}>
            <h2>{t('documents_processing')}</h2>
          </td>
        </tr>
        <tr>
          <td>{t('total_documents')}</td>
          <td>{statistic.documentCount}</td>
        </tr>
        <tr>
          <td>{t('total_edit_time')}</td>
          <td>{formatMilliseconds(statistic.totalEditTime)}</td>
        </tr>
        <tr>
          <td>{t('average_edit_time')}</td>
          <td>{formatMilliseconds(statistic.averageTotalEditTime)}</td>
        </tr>
        <tr>
          <td>{t('total_mutations')}</td>
          <td>{statistic.totalDocumentMutationCount}</td>
        </tr>
        <tr>
          <td>{t('average_mutations')}</td>
          <td>{statistic.averageMutationsPerDocument}</td>
        </tr>
      </React.Fragment>
    );
  }

  renderBankingDocumentStatistic(statistic) {
    const { t } = this.props;

    return (
      <React.Fragment>
        <tr>
          <td colSpan={2}>
            <h2>{t('banking_documents_processing')}</h2>
          </td>
        </tr>
        <tr>
          <td>{t('total_banking_documents')}</td>
          <td>{statistic.bankingDocumentCount}</td>
        </tr>
        <tr>
          <td>{t('total_banking_document_mutations')}</td>
          <td>{statistic.totalBankMutationCount}</td>
        </tr>
        <tr>
          <td>{t('average_banking_document_mutations')}</td>
          <td>{statistic.averageMutationsPerBankingDocument}</td>
        </tr>
        <tr>
          <td>{t('total_edit_time')}</td>
          <td>{formatMilliseconds(statistic.totalBankingDocumentEditTime)}</td>
        </tr>
        <tr>
          <td>{t('average_edit_time')}</td>
          <td>{formatMilliseconds(statistic.averageBankTotalEditTime)}</td>
        </tr>
      </React.Fragment>
    );
  }
}

Statistic.propTypes = {
  t: PropTypes.func.isRequired,
  generateStatistic: PropTypes.func.isRequired,
  generateRuleStatistic: PropTypes.func.isRequired,
  generatePeriodStatistic: PropTypes.func.isRequired,
  statistic: PropTypes.object.isRequired,
};

export default translate(withStatistic(Statistic));
