import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavItem } from 'reactstrap';
import { withAuth0 } from '@auth0/auth0-react';

class CurrentUser extends Component {
  render() {
    const { auth0 } = this.props;

    if (auth0.isAuthenticated) {
      return <NavItem className="navbar-text mr-1">{auth0.user.name}</NavItem>;
    } else {
      return null;
    }
  }
}

CurrentUser.propTypes = {
  auth0: PropTypes.object.isRequired,
};

export default withAuth0(CurrentUser);
