import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FaLevelDownAlt, FaPlus } from 'react-icons/fa';
import classnames from 'classnames';
import translate from 'containers/translate';
import { consumesDossier } from 'contexts/DossierContext';
import './BankingTransactionListSpacer.scss';

class BankingTransactionListSpacer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isDragOver: false,
    };

    this.onDrop = this.onDrop.bind(this);
    this.createBankingTransaction = this.createBankingTransaction.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.position !== this.props.position) {
      this.setState({
        isDragOver: false,
      });
    }
  }

  onDrop(e) {
    const id = e.dataTransfer.getData('text');
    const numericId = parseInt(id, 10);
    this.props.onBankingTransactionMove(numericId, this.props.position + 1);
    this.setState({
      isDragOver: false,
    });
  }

  createBankingTransaction() {
    const transaction = {
      dossierId: this.props.dossier.id,
      bankingDocumentId: this.props.bankingDocumentId,
      date: this.props.newDate,
      amount: 0,
      description: '',
      type: 'debit',
      position: this.props.position + 1,
    };

    this.props.onCreateBankingTransaction(transaction);
  }

  renderIcon() {
    if (this.state.isDragOver) {
      return <FaLevelDownAlt />;
    }
    return <FaPlus />;
  }

  render() {
    let events = {};

    if (!this.props.disabled) {
      events = {
        onClick: this.createBankingTransaction,
        onDrop: this.onDrop,
        onDragEnter: () => this.setState({ isDragOver: true }),
        onDragLeave: () => this.setState({ isDragOver: false }),
        onDragOver: (e) => e.preventDefault(),
      };
    }

    return (
      <div
        className={classnames('BankingTransactionListSpacer', {
          dragOver: this.state.isDragOver,
          disabled: this.props.disabled,
        })}
        style={this.props.style}
        {...events}
      >
        {this.renderIcon()}
      </div>
    );
  }
}

BankingTransactionListSpacer.defaultProps = {
  newDate: moment().format('YYYY-MM-DD'),
};

BankingTransactionListSpacer.propTypes = {
  position: PropTypes.number.isRequired,
  bankingDocumentId: PropTypes.number.isRequired,
  dossier: PropTypes.object.isRequired,
  onCreateBankingTransaction: PropTypes.func.isRequired,
  onBankingTransactionMove: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  newDate: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  style: PropTypes.object,
};

export default translate(consumesDossier(BankingTransactionListSpacer));
