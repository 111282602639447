import React from 'react';
import RemoteDataProvider from './RemoteDataProvider';

const CompanyNamesContext = React.createContext();

function providesCompanyNames(Component) {
  class RemoteCompanyNames extends React.Component {
    render() {
      return (
        <RemoteDataProvider
          scope="root"
          baseURL="/companies/names"
          isCollection
          render={(remoteData) => {
            return (
              <CompanyNamesContext.Provider value={remoteData}>
                <Component {...this.props} />
              </CompanyNamesContext.Provider>
            );
          }}
        />
      );
    }
  }

  return RemoteCompanyNames;
}

function consumesCompanyNames(Component) {
  return function ComponentThatConsumesCompanyNames(props) {
    return (
      <CompanyNamesContext.Consumer>
        {(remoteData) => {
          const companyNames = {};

          for (let i = 0; i < remoteData.value.length; i++) {
            let item = remoteData.value[i];
            companyNames[item.uid] = item.zefixName;
          }

          const consumedProps = {
            remoteCompanyNames: remoteData,
            companyNames,
          };

          return <Component {...props} {...consumedProps} />;
        }}
      </CompanyNamesContext.Consumer>
    );
  };
}

export { providesCompanyNames, consumesCompanyNames };
