import React, { Fragment } from 'react';
import { Link, NavLink as RRNavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Nav, Navbar, NavbarBrand, Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';
import { MdMoreVert } from 'react-icons/md';
import translate from 'containers/translate';
import withLogout from 'containers/withLogout';
import NavigationSearch from 'components/NavigationSearch';
import AjaxProgress from 'components/ajaxProgress/AjaxProgress';
import CurrentUser from './CurrentUser';
import './Navigation.scss';

class Navigation extends React.Component {
  constructor(props) {
    super(props);

    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.state = {
      dropdownOpen: false,
    };
  }

  toggleDropdown() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  renderDropDownItems() {
    if (!this.props.dropdownItems) {
      return <div />;
    }
    const { t } = this.props;

    return (
      <Fragment>
        <DropdownItem header>{t('dossier_specific')}</DropdownItem>
        {this.props.dropdownItems}
      </Fragment>
    );
  }

  renderSettings() {
    const { t } = this.props;

    return (
      <DropdownItem tag={RRNavLink} to={`/settings`}>
        {t('settings')}
      </DropdownItem>
    );
  }

  renderCompanyOverview() {
    const { t } = this.props;

    return (
      <DropdownItem tag={RRNavLink} to={`/companies`}>
        {t('edit_companies')}
      </DropdownItem>
    );
  }

  renderLogout() {
    const { t } = this.props;

    return <DropdownItem onClick={this.props.onLogout}>{t('logout')}</DropdownItem>;
  }

  render() {
    const { t } = this.props;

    return (
      <div className="Navigation">
        <Navbar color="light" light expand="md">
          <NavbarBrand tag={Link} to="/">
            LEA
          </NavbarBrand>
          {this.props.children}
          <Nav navbar className="ml-auto">
            <CurrentUser />
            <NavigationSearch />
            <Dropdown nav inNavbar isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
              <DropdownToggle nav>
                <MdMoreVert />
              </DropdownToggle>
              <DropdownMenu right>
                {this.renderDropDownItems()}

                <DropdownItem header>{t('general')}</DropdownItem>
                {this.renderSettings()}
                {this.renderCompanyOverview()}
                {this.renderLogout()}
              </DropdownMenu>
            </Dropdown>
          </Nav>
        </Navbar>
        <AjaxProgress />
      </div>
    );
  }
}

Navigation.propTypes = {
  onLogout: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  children: PropTypes.node,
  dropdownItems: PropTypes.node,
};

export default withLogout(translate(Navigation));
