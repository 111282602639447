import React from 'react';
import RemoteDataProvider from './RemoteDataProvider';

const DossiersContext = React.createContext();

function providesDossiers(Component) {
  class RemoteDossiers extends React.Component {
    render() {
      const baseURL = '/dossiers';

      return (
        <RemoteDataProvider
          scope="root"
          baseURL={baseURL}
          isCollection
          render={(remoteData) => {
            return (
              <DossiersContext.Provider value={remoteData}>
                <Component {...this.props} />
              </DossiersContext.Provider>
            );
          }}
        />
      );
    }
  }

  return RemoteDossiers;
}

function consumesDossiers(Component, provideValueAs = 'dossiers') {
  return function ComponentThatConsumesDossiers(props) {
    return (
      <DossiersContext.Consumer>
        {(remoteData) => {
          const consumedProps = {
            remoteDossiers: remoteData,
            [provideValueAs]: remoteData.value,
          };

          return <Component {...props} {...consumedProps} />;
        }}
      </DossiersContext.Consumer>
    );
  };
}

export { providesDossiers, consumesDossiers };
