import React from 'react';
import PropTypes from 'prop-types';
import RemoteDataProvider from './RemoteDataProvider';

const ClarificationContext = React.createContext();

function providesClarification(Component) {
  class Clarification extends React.Component {
    render() {
      return (
        <RemoteDataProvider
          scope="period"
          baseURL="/clarifications"
          isCollection
          render={(remoteData) => {
            return (
              <ClarificationContext.Provider value={remoteData}>
                <Component {...this.props} />
              </ClarificationContext.Provider>
            );
          }}
        />
      );
    }
  }

  Clarification.propTypes = {
    selectedPeriod: PropTypes.object.isRequired,
  };

  return Clarification;
}

function consumesClarification(Component, provideValueAs = 'clarifications') {
  return function ComponentThatConsumesClarification(props) {
    return (
      <ClarificationContext.Consumer>
        {(remoteData) => {
          if (!remoteData) {
            return <Component {...props} />;
          }
          const consumedProps = {
            remoteClarification: remoteData,
            [provideValueAs]: remoteData.value?.list,
            clarificationsErrors: remoteData.value?.errors ?? [],
          };

          return <Component {...props} {...consumedProps} />;
        }}
      </ClarificationContext.Consumer>
    );
  };
}

export { providesClarification, consumesClarification };
