import React from 'react';
import RemoteDataProvider from './RemoteDataProvider';
import PropTypes from 'prop-types';

const VatContext = React.createContext();

function providesVat(Component) {
  class RemoteVat extends React.Component {
    render() {
      return (
        <RemoteDataProvider
          baseURL="/accounting-transactions"
          subresource="/vat"
          objectId={this.props.accountingTransaction.id}
          render={(remoteData) => {
            return (
              <VatContext.Provider value={remoteData}>
                <Component {...this.props} />
              </VatContext.Provider>
            );
          }}
        />
      );
    }
  }

  RemoteVat.propTypes = {
    accountingTransaction: PropTypes.object.isRequired,
  };

  return RemoteVat;
}

function consumesVat(Component, provideValueAs = 'vatCode') {
  return function ComponentThatConsumesVat(props) {
    return (
      <VatContext.Consumer>
        {(remoteData) => {
          const consumedProps = {
            remoteVat: remoteData,
            [provideValueAs]: remoteData.value,
          };

          return <Component {...props} {...consumedProps} />;
        }}
      </VatContext.Consumer>
    );
  };
}

export { providesVat, consumesVat };
