import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Input } from 'reactstrap';
import classnames from 'classnames';

export default class DateInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dateValue: moment(this.props.date, 'YYYY-MM-DD').format('DD.MM.YYYY'),
    };
    this.onDateBlur = this.onDateBlur.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.date === this.props.date) {
      return;
    }
    this.setState({
      dateValue: moment(this.props.date, 'YYYY-MM-DD').format('DD.MM.YYYY'),
    });
  }

  onDateBlur() {
    const date = moment(this.state.dateValue, 'DD.MM.YYYY');

    if (date.isValid()) {
      this.props.onInputChange(date.format('YYYY-MM-DD'));
    } else {
      this.setState({
        dateValue: moment(this.props.date, 'YYYY-MM-DD').format('DD.MM.YYYY'),
      });
    }
  }

  handleKeyPress(target) {
    if (target.charCode === 13) {
      this.onDateBlur();
    }
  }

  render() {
    return (
      <Input
        type="text"
        name="date"
        placeholder="TT.MM.JJJJ"
        value={this.state.dateValue}
        onChange={(e) => this.setState({ dateValue: e.target.value })}
        onFocus={(e) => e.target.select()}
        onBlur={this.onDateBlur}
        className={classnames('DateInput', { 'is-invalid': this.props.invalid })}
        disabled={this.props.locked}
        onKeyPress={this.handleKeyPress}
      />
    );
  }
}

DateInput.defaultProps = {
  invalid: false,
};

DateInput.propTypes = {
  date: PropTypes.string.isRequired,
  onInputChange: PropTypes.func,
  locked: PropTypes.bool,
  invalid: PropTypes.bool.isRequired,
};
